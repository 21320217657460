import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EEntities, ERecruitingStatus } from '../../definitions/entities.definition';
import { IStore } from '../../definitions/redux/store.definitions';
import { getAllOpenVacanciesAction, getRecruitingListAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import { getRecruitingPageRequestObject, getRecruitingsWithStatus } from '../../selectors/recruiting.selectors';
import CustomTabContainer from '../../components/CustomTabContainer/CustomTabContainer';
import { translate } from '../../translation/translate.utils';
import RecruitingList from './components/RecruitingList';
import autobind from 'autobind-decorator';
import { setRecruitingPageActiveTabAction } from '../../redux/recruitingPage/recruitingPage.actions';
import { ERecruitingPageTabs } from '../../definitions/recruitingPage.definitions';
import VacancyOpenList from './components/VacancyOpenList';
import { getVacancyPageOpenListRequestObject } from '../../selectors/vacancy.selectors';
import { themeOrange } from '../../theme/theme';
import { EEntityView } from '../../definitions/ui.definitions';
import ContractRecruitingView from '../EditViews/Recruiting/ContractRecruitingView';
import DeclineRecruitingView from '../EditViews/Recruiting/DeclineRecruitingView';
import { isOnlyShowMineModeActive } from '../../selectors/employee.selectors';
import CustomSideFilterPanel from '../../components/CustomFilter/CustomSideFilter/CustomSideFilterPanel';

class RecruitingPage extends React.PureComponent<PropsFromRedux> {
    constructor(props: PropsFromRedux) {
        super(props);
    }

    @autobind
    reloadVacancies() {
        this.props.getAllOpenVacanciesAction(this.props.token, this.props.requestObjectVacancies);
    }

    @autobind
    reloadRecruitings(status?: ERecruitingStatus) {
        if (status === ERecruitingStatus.STATUS_SUGGEST || !status) {
            this.props.getRecruitingListAction(this.props.token, this.props.requestObjectStatusSuggest, ERecruitingStatus.STATUS_SUGGEST);
        }
        if (status === ERecruitingStatus.STATUS_INTERVIEW || !status) {
            this.props.getRecruitingListAction(this.props.token, this.props.requestObjectStatusInterview, ERecruitingStatus.STATUS_INTERVIEW);
        }
        if (status === ERecruitingStatus.STATUS_CONTRACT || !status) {
            this.props.getRecruitingListAction(this.props.token, this.props.requestObjectStatusContract, ERecruitingStatus.STATUS_CONTRACT);
        }
        if (status === ERecruitingStatus.STATUS_ABORT || !status) {
            this.props.getRecruitingListAction(this.props.token, this.props.requestObjectStatusAbort, ERecruitingStatus.STATUS_ABORT);
        }
    }

    componentDidMount() {
        if (!this.props.initialOpenVacanciesLoaded) {
            this.reloadVacancies();
        }
    }

    componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
        if (this.props.onlyShowMine !== prevProps.onlyShowMine) {
            this.reloadRecruitings();
            this.reloadVacancies();
            return;
        }

        if (this.props.filterVacancies !== prevProps.filterVacancies || this.props.globalFilter !== prevProps.globalFilter || (!prevProps.reloadRecruitings && this.props.reloadRecruitings)) {
            this.reloadRecruitings();
            this.reloadVacancies();
            return;
        }

        if (this.props.activeTab !== prevProps.activeTab && !this.props.recruitingsSuggestInitialLoaded && this.props.activeTab === ERecruitingPageTabs.tabRecruitingsWithStatusSuggest) {
            this.reloadRecruitings(ERecruitingStatus.STATUS_SUGGEST);
            return;
        }

        if (this.props.activeTab !== prevProps.activeTab && !this.props.recruitingsInterviewInitialLoaded && this.props.activeTab === ERecruitingPageTabs.tabRecruitingsWithStatusInterview) {
            this.reloadRecruitings(ERecruitingStatus.STATUS_INTERVIEW);
            return;
        }

        if (this.props.activeTab !== prevProps.activeTab && !this.props.recruitingsContractInitialLoaded && this.props.activeTab === ERecruitingPageTabs.tabRecruitingsWithStatusContract) {
            this.reloadRecruitings(ERecruitingStatus.STATUS_CONTRACT);
            return;
        }

        if (this.props.activeTab !== prevProps.activeTab && !this.props.recruitingsContractInitialLoaded && this.props.activeTab === ERecruitingPageTabs.tabRecruitingsWithStatusAbort) {
            this.reloadRecruitings(ERecruitingStatus.STATUS_ABORT);
            return;
        }

        if (this.props.sortingVacancies !== prevProps.sortingVacancies ||
            JSON.stringify(this.props.filterVacancies) !== JSON.stringify(prevProps.filterVacancies)
        ) {
            this.reloadVacancies();
        }

        if (this.props.sortingSuggest !== prevProps.sortingSuggest ||
            JSON.stringify(this.props.filterSuggest) !== JSON.stringify(prevProps.filterSuggest) ||
            (!prevProps.reloadSuggestedRecruitings && this.props.reloadSuggestedRecruitings)
        ) {
            this.reloadRecruitings(ERecruitingStatus.STATUS_SUGGEST);
        }

        if (this.props.sortingInterview !== prevProps.sortingInterview ||
            JSON.stringify(this.props.filterInterview) !== JSON.stringify(prevProps.filterInterview) ||
            (!prevProps.reloadInterviewRecruitings && this.props.reloadInterviewRecruitings)
        ) {
            this.reloadRecruitings(ERecruitingStatus.STATUS_INTERVIEW);
        }

        if (this.props.sortingContract !== prevProps.sortingContract ||
            JSON.stringify(this.props.filterContract) !== JSON.stringify(prevProps.filterContract)
        ) {
            this.reloadRecruitings(ERecruitingStatus.STATUS_CONTRACT);
        }

        if (this.props.sortingAbort !== prevProps.sortingAbort ||
            JSON.stringify(this.props.filterAbort) !== JSON.stringify(prevProps.filterAbort)
        ) {
            this.reloadRecruitings(ERecruitingStatus.STATUS_ABORT);
        }
    }

    render() {
        return (
            <>
                <ContractRecruitingView/>
                <DeclineRecruitingView/>
                <div className={"marginTop5 marginLeft5 flexContainerColumn flex1"} style={{overflow: 'hidden'}}>
                    <CustomTabContainer
                        color={themeOrange.palette.primary.main}
                        activeTab={this.props.activeTab}
                        onTabChange={(tabId: ERecruitingPageTabs) => this.props.setRecruitingPageActiveTabAction(tabId)}
                        tabs={[{
                            label: translate('pages.vacancy.title'),
                            component: (
                                <CustomSideFilterPanel entity={EEntities.Vacancy}>
                                    <VacancyOpenList />
                                </CustomSideFilterPanel>
                            ),
                        }, {
                            label: translate('pages.recruiting.statusValues.suggest') + " (" + this.props.totalSuggested + ")",
                            component: (
                                <CustomSideFilterPanel entity={EEntities.Vacancy}>
                                    <RecruitingList
                                        status={ERecruitingStatus.STATUS_SUGGEST}
                                        recruitings={this.props.recruitingsStatusSuggest}
                                        entity={EEntities.RecruitingsSuggest}
                                        count={this.props.countSuggested}
                                        total={this.props.totalSuggested}
                                        filter={{
                                            ...this.props.globalFilter,
                                            employeeId: this.props.filterVacancies.responsibleEmployeeId,
                                            companyId: this.props.filterVacancies.companyId,
                                            start: this.props.filterSuggest.start,
                                            limit: this.props.filterSuggest.limit
                                        }}
                                        onReload={() => this.props.getRecruitingListAction(
                                            this.props.token,
                                            this.props.requestObjectStatusSuggest,
                                            ERecruitingStatus.STATUS_SUGGEST
                                        )}
                                    />
                                </CustomSideFilterPanel>
                            ),
                        }, {
                            label:  translate('pages.recruiting.statusValues.interview') + " (" + this.props.totalInterview + ")",
                            component: (
                                <CustomSideFilterPanel entity={EEntities.Vacancy}>
                                    <RecruitingList
                                        status={ERecruitingStatus.STATUS_INTERVIEW}
                                        recruitings={this.props.recruitingsStatusInterview}
                                        entity={EEntities.RecruitingsInterview}
                                        count={this.props.countInterview}
                                        total={this.props.totalInterview}
                                        filter={{
                                            ...this.props.globalFilter,
                                            employeeId: this.props.filterVacancies.responsibleEmployeeId,
                                            companyId: this.props.filterVacancies.companyId,
                                            start: this.props.filterInterview.start,
                                            limit: this.props.filterInterview.limit
                                        }}
                                        onReload={() => this.props.getRecruitingListAction(
                                            this.props.token,
                                            this.props.requestObjectStatusInterview,
                                            ERecruitingStatus.STATUS_INTERVIEW
                                        )}
                                    />
                                </CustomSideFilterPanel>
                            ),
                        }, {
                            label: translate('pages.recruiting.statusValues.contract') + " (" + this.props.totalContract + ")",
                            component: (
                                <CustomSideFilterPanel entity={EEntities.Vacancy}>
                                    <RecruitingList
                                        status={ERecruitingStatus.STATUS_CONTRACT}
                                        recruitings={this.props.recruitingsStatusContract}
                                        entity={EEntities.RecruitingsContract}
                                        count={this.props.countContract}
                                        total={this.props.totalContract}
                                        filter={{
                                            ...this.props.globalFilter,
                                            employeeId: this.props.filterVacancies.responsibleEmployeeId,
                                            companyId: this.props.filterVacancies.companyId,
                                            start: this.props.filterContract.start,
                                            limit: this.props.filterContract.limit
                                        }}
                                        onReload={() => this.props.getRecruitingListAction(
                                            this.props.token,
                                            this.props.requestObjectStatusContract,
                                            ERecruitingStatus.STATUS_CONTRACT
                                        )}
                                    />
                                </CustomSideFilterPanel>
                            ),
                        }, {
                            label: translate('pages.recruiting.statusValues.abort') + " (" + this.props.totalAbort + ")",
                            component: (
                                <CustomSideFilterPanel entity={EEntities.Vacancy}>
                                    <RecruitingList
                                        status={ERecruitingStatus.STATUS_ABORT}
                                        recruitings={this.props.recruitingsStatusAbort}
                                        entity={EEntities.RecruitingsAbort}
                                        count={this.props.countAbort}
                                        total={this.props.totalAbort}
                                        filter={{
                                            ...this.props.globalFilter,
                                            employeeId: this.props.filterVacancies.responsibleEmployeeId,
                                            companyId: this.props.filterVacancies.companyId,
                                            start: this.props.filterAbort.start,
                                            limit: this.props.filterAbort.limit
                                        }}
                                        onReload={() => this.props.getRecruitingListAction(
                                            this.props.token,
                                            this.props.requestObjectStatusAbort,
                                            ERecruitingStatus.STATUS_ABORT
                                        )}
                                    />
                                </CustomSideFilterPanel>
                            ),
                        }]}
                    />
                </div>
            </>
        );
    }
}

const mapState = (store: IStore) => ({
    token: getToken(store),
    vacancyViewOpen: store.ui.openViews.length > 0 && store.ui.openViews[0].view === EEntityView.vacancy,
    recruitingViewOpen: store.ui.openViews.length > 0 && store.ui.openViews[0].view === EEntityView.recruiting,
    recruitingsStatusSuggest: getRecruitingsWithStatus(store, ERecruitingStatus.STATUS_SUGGEST),
    recruitingsStatusInterview: getRecruitingsWithStatus(store, ERecruitingStatus.STATUS_INTERVIEW),
    recruitingsStatusContract: getRecruitingsWithStatus(store, ERecruitingStatus.STATUS_CONTRACT),
    recruitingsStatusAbort: getRecruitingsWithStatus(store, ERecruitingStatus.STATUS_ABORT),
    requestObjectStatusSuggest: getRecruitingPageRequestObject(store, ERecruitingStatus.STATUS_SUGGEST),
    requestObjectStatusInterview: getRecruitingPageRequestObject(store, ERecruitingStatus.STATUS_INTERVIEW),
    requestObjectStatusContract: getRecruitingPageRequestObject(store, ERecruitingStatus.STATUS_CONTRACT),
    requestObjectStatusAbort: getRecruitingPageRequestObject(store, ERecruitingStatus.STATUS_ABORT),
    requestObjectVacancies: getVacancyPageOpenListRequestObject(store),
    totalApplications: store.entities.recruiting.totalApplications,
    countSuggested: store.entities.recruiting.orderSuggest.length,
    totalSuggested: store.entities.recruiting.totalSuggest,
    countInterview: store.entities.recruiting.orderInterview.length,
    totalInterview: store.entities.recruiting.totalInterview,
    countContract: store.entities.recruiting.orderContract.length,
    totalContract: store.entities.recruiting.totalContract,
    countAbort: store.entities.recruiting.orderAbort.length,
    totalAbort: store.entities.recruiting.totalAbort,
    globalFilter: store.recruitingPage.filterRecruitingsGlobal,
    sortingSuggest: store.recruitingPage.sortingTabRecruitingsSuggest,
    sortingInterview: store.recruitingPage.sortingTabRecruitingsInterview,
    sortingContract: store.recruitingPage.sortingTabRecruitingsContract,
    sortingAbort: store.recruitingPage.sortingTabRecruitingsAbort,
    sortingVacancies: store.vacancyPage.vacancyListSorting,
    filterSuggest: store.recruitingPage.filterTabRecruitingsSuggest,
    filterInterview: store.recruitingPage.filterTabRecruitingsInterview,
    filterContract: store.recruitingPage.filterTabRecruitingsContract,
    filterAbort: store.recruitingPage.filterTabRecruitingsAbort,
    filterVacancies: store.vacancyPage.vacancyListFilter,
    reloadRecruitings: store.recruitingPage.reloadAllRecruitings,
    reloadSuggestedRecruitings: store.recruitingPage.reloadSuggestedRecruitings,
    reloadInterviewRecruitings: store.recruitingPage.reloadInterviewRecruitings,
    activeTab: store.recruitingPage.activeTab,
    initialOpenVacanciesLoaded: store.vacancyPage.initialOpenVacanciesLoaded,
    recruitingsSuggestInitialLoaded: store.recruitingPage.recruitingsSuggestInitialLoaded,
    recruitingsInterviewInitialLoaded: store.recruitingPage.recruitingsInterviewInitialLoaded,
    recruitingsContractInitialLoaded: store.recruitingPage.recruitingsContractInitialLoaded,
    onlyShowMine: isOnlyShowMineModeActive(store),
});

const mapDispatch = {
    getRecruitingListAction,
    getAllOpenVacanciesAction,
    setRecruitingPageActiveTabAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RecruitingPage);
