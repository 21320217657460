import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { EPublicationSource, IVacancyPublication } from '../../../../../definitions/entities.definition';
import CustomEditableValue from '../../../../../components/CustomInput/CustomEditableValue';
import { EIcons, IconFactory } from '../../../../../components/Icons/IconFactory';
import { ClientApi } from '../../../../../requests/ClientApi';
import { postVacancyPublicationRouteConfig } from '../../../../../requests/routes';
import { registerError } from '../../../../../redux/error/error.actions';
import CustomTextField from '../../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../../translation/translate.utils';
import { getVacancyAction } from '../../../../../redux/entities/entities.actions';
import VacancyPublicationItemView from './component/VacancyPublicationItemView';
import VacancyPublicationSourceDropDown
    from '../../../../../components/CustomInput/CustomDropDownInput/VacancyPublicationSourceDropDown';
import { setVacancyPageAddPublicationToFormAction } from '../../../../../redux/vacancyPage/vacancyPage.actions';

interface IProps {
    vacancyId: number;
    records: IVacancyPublication[];
}

const VacancyPublicationListView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.vacancyId;
    const publications = props.records;

    const [urlText, setUrlText] = useState('');
    const [comment, setComment] = useState('');
    const [source, setSource] = useState<EPublicationSource>(EPublicationSource.LinkedIn);

    const onAdd = () => {
        if (!vacancyId) {
            if (!urlText) {
                return Promise.resolve();
            }
            dispatch(setVacancyPageAddPublicationToFormAction({
                id: 0,
                url: urlText,
                source,
                comment,
                created: ''
            }));
            return Promise.resolve();
        }

        return ClientApi.request(postVacancyPublicationRouteConfig, {
            token,
            vacancyId,
            url: urlText,
            source,
            comment
        }).then(() => {
            setComment('');
            setUrlText('');
            setSource(EPublicationSource.LinkedIn);
            dispatch(getVacancyAction(token, vacancyId, false));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht hinzugefügt werden'));
        });
    }

    return <>
        <div className={"flexContainerColumn"}>
            {publications.map((url, index) => {
                return <div key={url.id} className={"flexContainerRow alignVerticalCenter"}>
                    <VacancyPublicationItemView
                        index={index}
                        vacancyId={vacancyId}
                        publication={url}
                    />
                </div>
            })}
        </div>
        <CustomEditableValue
            theme={'light'}
            input={<div className={"flexContainerColumn flex1 gap5"}>
                <CustomTextField
                    required
                    autoFocus
                    size={'small'}
                    value={urlText}
                    onChange={(value) => setUrlText(value)}
                    label={translate('misc.url')}
                />
                <VacancyPublicationSourceDropDown
                    value={source}
                    disablePortal
                    size={'small'}
                    onChange={(event) => setSource(event.target.value)}
                />
                <CustomTextField
                    multiline
                    size={'small'}
                    rows={5}
                    label={translate('misc.comment')}
                    value={comment}
                    onChange={(value) => setComment(value)}
                />
            </div>}
            emptyValue={
                <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.Add)}
                    Url hinzufügen
                </div>
            }
            onSave={onAdd}
        />
    </>
}

export default VacancyPublicationListView;
