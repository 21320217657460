import React, { ReactNode } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, CircularProgress } from '@material-ui/core';
import { TBrainzEntity } from '../../../definitions/entities.definition';
import { IRouteConfig } from '../../../definitions/requests.definitions';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useMultipleAutocompleteHooks } from './CustomMultipleAutocomplete.hooks';
import { getOptionLabel } from './Autocomplete.utils';
import CustomTextField from '../CustomTextField';

interface IProps {
    id: string;

    value: TBrainzEntity[]|number[];
    onChange: (value: TBrainzEntity[]) => void;
    getOptionLabel: (option: TBrainzEntity) => string;
    renderOption?: (option: TBrainzEntity) => ReactNode;

    routeConfig: IRouteConfig;
    routeParams: object;
    onAdd?: () => void;
    filter?: (option: TBrainzEntity) => boolean;

    //autocomplete params
    startAdornment?: React.ReactNode;
    label: string;
    disabled?: boolean;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    groupBy?: (option: TBrainzEntity) => string;
}

const CustomMultipleAutocomplete: React.FC<IProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const value = props.value;
    const routeConfig = props.routeConfig;
    const routeParams = props.routeParams;
    const label = props.label;
    const startAdornment = props.startAdornment;

    const { selectedValue, records, loading } = useMultipleAutocompleteHooks(open, value, routeParams, routeConfig);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    return <>
        <Autocomplete
            id={props.id}
            className={"flex"}
            loading={loading}
            disabled={props.disabled}
            disableClearable={props.required}
            groupBy={props.groupBy}
            multiple

            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}

            options={records.filter((option) => props.filter ? props.filter(option) : true)}
            value={selectedValue}

            getOptionSelected={(value: TBrainzEntity, option: TBrainzEntity) => value.id === option.id}
            getOptionLabel={(record) => getOptionLabel(record, props.getOptionLabel)}
            onChange={(event, value) => {
                props.onChange(value);
            }}

            renderOption={(option, {selected}) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {props.renderOption ? props.renderOption(option) : props.getOptionLabel(option)}
                </React.Fragment>
            )}

            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={index}
                        color={"secondary"}
                        style={{border: '1px solid rgba(233, 237, 239, 0.2)'}}
                        label={props.getOptionLabel(option)}
                        {...getTagProps({ index })}
                    />
                ))
            }

            renderInput={(params) => <>
                <CustomTextField
                    {...params}
                    onMouseDown={() => {
                        if (open) {
                            setOpen(false);
                        }
                    }}
                    autoComplete={"random"} //workaround to really disable autocomplete.
                    autoFocus={props.autoFocus}
                    error={props.error}
                    helperText={props.helperText}
                    required={props.required}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: params.InputProps.startAdornment ? <>{startAdornment}{params.InputProps.startAdornment}</> : startAdornment,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    onAddNewEntryCallback={props.onAdd}
                />
            </>
            }
        />
    </>
}

export default React.memo(CustomMultipleAutocomplete);
