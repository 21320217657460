import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { IAppBarActionButtonConfig } from '../../../../definitions/components.definitions';

interface IProps {
    fastSearch: string;
    setFastSearch: (fastSearch: string) => void;
    addAction: () => void;
    leftButtons?: IAppBarActionButtonConfig[];
}

const FastSearchPanelActivityList: React.FC<IProps> = (props) => {
    const fastSearch = props.fastSearch;
    const leftButtons = props.leftButtons;

    const configuration: IAppBarConfig = {
        entity: EEntities.Activity,
        value: fastSearch,
        emptyValue: translate('misc.fastSearchEmptyText'),
        fastSearchCallback: props.setFastSearch,
        leftButtons,
        rightButtons: [{
            action: props.addAction,
            tooltip: "Neue Aktivität dokumentieren",
            icon: EIcons.Add
        }]
    };

    return <>
        <div className={"flexAutoGrow"}>
            <CustomFastSearchPanel appBarConfig={configuration}/>
        </div>
    </>
}

export default FastSearchPanelActivityList;
