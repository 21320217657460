import React, { PropsWithChildren } from 'react';
import { EEntities } from '../../../definitions/entities.definition';
import { Divider, IconButton, Paper, Slide, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CustomSideFilterPanel.module.css';
import { setUiFilterPanelClosed } from '../../../redux/ui/ui.actions';
import { EIcons, IconFactory } from '../../Icons/IconFactory';
import CandidateFilterPanel from './Panel/CandidateFilterPanel';
import CandidateSearchInVacancyFilterPanel from './Panel/CandidateSearchInVacancyFilterPanel';
import WebVacancyFilterPanel from './Panel/WebVacancyFilterPanel';
import { IStore } from '../../../definitions/redux/store.definitions';
import VacancyFilterPanel from './Panel/VacancyFilterPanel';

interface IProps extends PropsWithChildren<any> {
    entity: EEntities;
}

const getFilterMenu = (entity: EEntities) => {
    switch (entity) {
        case EEntities.Vacancy:
            return <VacancyFilterPanel/>
        case EEntities.WebVacancy:
            return <WebVacancyFilterPanel/>
        case EEntities.Candidate:
            return <CandidateFilterPanel/>
        case EEntities.VacancyCandidateSearch:
            return <CandidateSearchInVacancyFilterPanel/>
    }

    return null;
}

const CustomSideFilterPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const entity = props.entity;
    const table = props.children;

    const onClose = () => {
        dispatch(setUiFilterPanelClosed(entity));
    }

    const openFilterPanels = useSelector((store: IStore) => store.ui.filterPanelsOpened);
    const open = openFilterPanels.includes(entity);

    return <>
        <div className={"flex"} style={{height: '100%'}}>
            <Slide direction="right" timeout={400} in={open} mountOnEnter unmountOnExit>
                <Paper variant={"outlined"} square className={styles.paper}>
                    <div className={"flex flexCentered"}>
                        <Typography variant={"h5"} style={props.style} className={styles.header}>Filter</Typography>
                        <div className={styles.right}>
                            <IconButton onClick={onClose}>
                                {IconFactory.getIconComponent(EIcons.Close)}
                            </IconButton>
                        </div>
                    </div>
                    <Divider className={styles.divider}/>
                    <div className={styles.filterPanel}>
                        {getFilterMenu(entity)}
                    </div>
                </Paper>
            </Slide>
            { table }
        </div>
    </>;
};

export default CustomSideFilterPanel;
