import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EApplicationStatus } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: EApplicationStatus;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

const ApplicationStatusDropDown: React.FC<IProps> = (props) => {
    const applicationStatuses: EApplicationStatus[] = [
        EApplicationStatus.open,
        EApplicationStatus.inspection,
        EApplicationStatus.declined,
        EApplicationStatus.suggestion,
        EApplicationStatus.recruiting,
    ];
    const menuItems = applicationStatuses.map((status: EApplicationStatus, index: number) => (
        <MenuItem key={index + 1} value={status}>
            {translate('pages.application.statusValue.' + status)}
        </MenuItem>
    ));

    return (
        <CustomDropDownInput
            disabled={props.disabled}
            onChange={props.onChange}
            value={props.value}
            id={"application-status-dropdown"}
            error={props.error}
            helperText={props.helperText}
            label={translate('pages.candidate.applicationStatus')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default ApplicationStatusDropDown;
