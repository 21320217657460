import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import CustomAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/CustomAutocomplete';
import { translate } from '../../../../translation/translate.utils';
import { API_URL, getCandidateDocumentsForCandidateRouteConfig } from '../../../../requests/routes';
import { ECandidateDocumentType, ICandidateDocument, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getFormattedDateString } from '../../../../utils/date.utils';
import CandidateDocumentUploadView from './components/CandidateDocumenUploadView';
import { Link } from '@material-ui/core';
import CustomClickableIcon from '../../../../components/CustomInput/CustomClickableIcon';
import { EIcons } from '../../../../components/Icons/IconFactory';
import CustomHoverIcon from '../../../../components/CustomInput/CustomHoverIcon';
import CandidateDocumentDeleteView from './components/CandidateDocumentDeleteView';
import { getCandidateAction, updateCandidateAction } from '../../../../redux/entities/entities.actions';
import CandidateDocumentEditView from './components/CandidateDocumentEditView';

interface IProps {
    candidateId: number;
    selectedDocumentId?: number;
    splitView?: boolean;
    closeSplitView?: () => void;
}

const CandidateDocumentPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const candidateId = props.candidateId;
    const token = useSelector(getToken);
    const [selectedDocumentId, setSelectedDocumentId] = useState(props.selectedDocumentId || 0);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<ICandidateDocument|null>(null);
    const [deleteViewOpen, setDeleteViewOpen] = useState(false);
    const [editViewOpen, setEditViewOpen] = useState(false);

    const setDefaultDocumentId = (id: number) => {
        dispatch(updateCandidateAction(token, {
            id: candidateId,
            latestDocumentCvId: id
        }));
    }

    useEffect(() => {
        setSelectedDocumentId(props.selectedDocumentId || 0);
    }, [props.selectedDocumentId]);

    const autoCompleteEndAdornment = selectedDocumentId > 0 && <>
        <Link target={"_blank"} href={`${API_URL}/candidate-document/${selectedDocumentId}/preview?Authorization=${token}`}>
            <CustomHoverIcon
                icon={EIcons.Launch}
                tooltip={"Dokument in neuem Fenster öffnen"}
            />
        </Link>
        {Boolean(
            selectedDocument &&
            (selectedDocument.type === ECandidateDocumentType.CV || selectedDocument.type === ECandidateDocumentType.INTERN_PROFILE) &&
            selectedDocumentId !== props.selectedDocumentId
        ) &&
            <CustomClickableIcon
                onClick={() => setDefaultDocumentId(selectedDocumentId)}
                icon={EIcons.Check}
                tooltip={"Dokument als Haupt-Dokument kennzeichnen"}
            />
        }
        <CustomClickableIcon
            onClick={() => setEditViewOpen(true)}
            icon={EIcons.Edit}
            tooltip={"Dokument bearbeiten"}
        />
        <CustomClickableIcon
            onClick={() => setDeleteViewOpen(true)}
            icon={EIcons.Delete}
            tooltip={"Dokument löschen"}
        />
    </>;

    return <>
        <CandidateDocumentDeleteView
            documentId={selectedDocumentId}
            open={deleteViewOpen}
            onClose={(deleted) => {
                setDeleteViewOpen(false);
                if (deleted) {
                    setSelectedDocument(null);
                    setSelectedDocumentId(0);
                }
            }}
        />
        <CandidateDocumentEditView
            document={selectedDocument}
            open={editViewOpen}
            onClose={() => {
                setEditViewOpen(false);
            }}
        />
        <CandidateDocumentUploadView
            candidateId={candidateId}
            open={uploadOpen}
            onClose={() => {
                setUploadOpen(false);
            }}
            successCallback={(response) => {
                setUploadOpen(false);
                setSelectedDocumentId(response[0].id);
                dispatch(getCandidateAction(token, candidateId, false));
            }}
        />
        <div className={"flexContainerRow alignVerticalCenter flexAutoGrow "} style={{padding: 5}}>
            <CustomAutocomplete
                id={"candidate-document-autocomplete"}
                label={"Dokumente"}
                size={"small"}
                disableClearable
                value={selectedDocumentId || null}
                onChange={(entity) => {
                    if (!entity) {
                        return;
                    }

                    setSelectedDocumentId(entity.id);
                    setSelectedDocument(entity as ICandidateDocument);
                }}
                groupBy={(option) => {
                    const document = option as ICandidateDocument;

                    return translate('pages.candidateDocument.typeValuePlural.' + document.type);
                }}
                routeConfig={getCandidateDocumentsForCandidateRouteConfig}
                getOptionLabel={(option: TBrainzEntity|null) => {
                    const document = option as ICandidateDocument;

                    if (document.id === props.selectedDocumentId) {
                        return '[Haupt-Dokument] ' + getFormattedDateString(document.created) + ': ' + document.displayFileName;
                    }
                    return getFormattedDateString(document.created) + ': ' + document.displayFileName;
                }}
                endAdornment={autoCompleteEndAdornment}
                onAdd={() => setUploadOpen(true)}
                routeParams={{
                    candidateId
                }}
            />
            {(props.splitView && props.closeSplitView) &&
                <>
                    &nbsp;
                    <CustomClickableIcon
                        onClick={props.closeSplitView}
                        icon={EIcons.Close}
                        iconStyle={{color: 'inerhit'}}
                        tooltip={"Dokumente nicht mehr anheften"}
                    />
                </>
            }
        </div>
        {(selectedDocument && selectedDocument.fileType === 'pdf') &&
        <iframe
            style={{margin: 5, height: '100%', border: '1px solid var(--brainz-darkest)'}}
            frameBorder="0"
            src={API_URL + "/candidate-document/" + selectedDocumentId + "/preview?Authorization=" + token}
        />
        }
        {(selectedDocument && selectedDocument.fileType !== 'pdf') &&
        <img src={API_URL + "/candidate-document/" + selectedDocumentId + "/preview?Authorization=" + token}/>
        }
    </>
}

export default React.memo(CandidateDocumentPanel);
