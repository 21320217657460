import React from 'react';
import { EEntities } from '../../../../../definitions/entities.definition';
import { translate } from '../../../../../translation/translate.utils';
import {
    setVacancyPageAddVacancyDialogAction,
    setVacancyPageFastSearchAction,
} from '../../../../../redux/vacancyPage/vacancyPage.actions';
import { EIcons } from '../../../../../components/Icons/IconFactory';
import { EFilterPanelType } from '../../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../../definitions/ui.definitions';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { useHistory } from 'react-router-dom';
import { ERoutes } from '../../../../../definitions/app.definitions';
import { setUiCurrentRouteAction } from '../../../../../redux/ui/ui.actions';

const FastSearchPanelVacancyList: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchVacancyOpen = useSelector((store: IStore) => store.vacancyPage.vacancyListFilter.fastSearch);
    const history = useHistory();

    const configuration: IAppBarConfig = {
        entity: EEntities.Vacancy,
        value: fastSearchVacancyOpen ?? '',
        emptyValue: translate('pages.vacancy.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setVacancyPageFastSearchAction(value)),
        rightButtons: [{
            action: () => {
                dispatch(setUiCurrentRouteAction(ERoutes.Archive));
                history.push(ERoutes.Archive);
            },
            icon: EIcons.Archive,
            tooltip: 'Archiv öffnen'
        }, {
            action: () => dispatch(setVacancyPageAddVacancyDialogAction(true)),
            icon: EIcons.AddBox,
            tooltip: 'Neue Vakanz anlegen'
        }],
        filterPanelType: EFilterPanelType.sidePanel
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelVacancyList;
