import React, { useCallback, useEffect, useState } from 'react';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../translation/translate.utils';
import {
    EEntities,
    IEntitySkill,
    ISkillPartial,
    IVacancyForApi,
    IVacancySkill,
} from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import HomeOfficePossibilityDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/HomeOfficePossibilityDropDown';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { setUiCompanyContactAddDataAction, setUiCompanyLocationAddDataAction } from '../../../../redux/ui/ui.actions';
import CustomNumberInput from '../../../../components/CustomInput/CustomNumberInput';
import VacancySkillElement from '../../../RecruitingPage/components/components/VacancySkillElement';
import BillingConfigurationElement from '../../../Shared/BillingSetting/BillingConfigurationElement';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import WebVacancyAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/WebVacancyAutocomplete';
import CompanyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyAutocomplete';
import EmployeeAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import LanguageMultipleAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/LanguageMultipleAutocomplete';
import { addVacancyAction, updateVacancyAction } from '../../../../redux/entities/entities.actions';
import WebVacancyDataPanel from '../../WebVacancy/WebVacancyDataPanel';
import { debounce, Divider } from '@material-ui/core';
import {
    setVacancyPageFormAction,
    setVacancyPageVacancyToArchiveAction,
    setVacancyPageVacancyToDeleteAction,
} from '../../../../redux/vacancyPage/vacancyPage.actions';
import CustomValidationErrors from '../../../../components/CustomComponent/CustomValidationErrors';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import { getDateStringForApi } from '../../../../utils/date.utils';
import VacancyPublicationListView from './component/VacancyPublicationListView';
import { IVacancyForm } from '../../../../definitions/vacancyPage.definitions';
import CompanyContactAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';
import SkillAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/SkillAutocomplete';
import CompanyLocationMultipleAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyLocationMultipleAutocomplete';

interface IProps {
    vacancyId?: number;
}

const VacancyEditForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.vacancyId || 0;
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId]);
    const initialVacancyForm = useSelector((store: IStore) => store.vacancyPage.vacancyForm);
    const [vacancyForm, setVacancyForm] = useState<IVacancyForm>(initialVacancyForm);
    const saveToStore = (form: IVacancyForm) => {dispatch(setVacancyPageFormAction(form));}
    const saveToStoreHandler = useCallback(debounce(saveToStore, 300), []);
    const setVacancyFormPartial = (vacancyFormChange: Partial<IVacancyForm>) => {
        const form = {
            ...vacancyForm,
            ...vacancyFormChange
        };
        setVacancyForm(form);
        saveToStoreHandler(form);
    }
    useEffect(() => {
        setVacancyForm(initialVacancyForm);
    }, [
        initialVacancyForm.publications
    ])

    const validationErrors = useSelector(getValidationErrorsFromStore);

    const save = () => {
        const vacancyApiObject: IVacancyForApi = {
            count: vacancyForm.count,
            title: vacancyForm.title,
            comment: vacancyForm.comment,
            skills: vacancyForm.skills.map((skill: IEntitySkill) => {
                return {
                    id: skill.id,
                    importantSkill: skill.importantSkill,
                    skillId: skill.skill.id
                }
            }),
            companyId: vacancyForm.companyId,
            companyLocationIds: vacancyForm.companyLocationIds,
            responsibleEmployeeId: vacancyForm.responsibleEmployeeId,
            withLeadership: vacancyForm.withLeadership,
            needDriverLicense: vacancyForm.needDriverLicense,
            salaryFrom: vacancyForm.salaryFrom,
            salaryTo: vacancyForm.salaryTo,
            homeOfficePossibility: vacancyForm.homeOfficePossibility,
            responsibleContactId: vacancyForm.responsibleContactId,
            webVacancyId: vacancyForm.webVacancyId,
            partTime: vacancyForm.partTime,
            languageIds: vacancyForm.languageIds,
            suggestionDueDate: vacancyForm.suggestionDueDate,
            suggestionDueSinceDate: vacancyForm.suggestionDueSinceDate,
            orderReceivedDate: vacancyForm.orderReceivedDate
        };

        if (!vacancyId) {
            dispatch(addVacancyAction(token, vacancyApiObject));
            return;
        }

        vacancyApiObject.id = vacancyId;
        dispatch(updateVacancyAction(token, vacancyApiObject));
        return;
    }

    const onRemoveSkill = (skill: ISkillPartial) => {
        setVacancyFormPartial({
            skills: vacancyForm.skills.filter((vacancySkill: IEntitySkill) => !vacancySkill.skill || skill.id !== vacancySkill.skill.id)
        });
    };

    const onSetImportantSkill = (skill: ISkillPartial) => {
        setVacancyFormPartial({
            skills: vacancyForm.skills.map((vacancySkill: IEntitySkill) => {
                if (vacancySkill.skill.id === skill.id) {
                    return {
                        ...vacancySkill,
                        importantSkill: !vacancySkill.importantSkill
                    }
                }
                return vacancySkill;
            })
        })
    }

    return <>
        <CustomValidationErrors translationKey={'pages.vacancy'}/>
        <div className={"flexContainerColumn gap5 padding10 overflow"}>
            <div className={"flexContainerRow flex1 gap10"}>
                <CompanyAutocomplete
                    required
                    value={vacancyForm.company || vacancyForm.companyId || null}
                    error={Boolean(validationErrors.companyId)}
                    helperText={validationErrors.companyId}
                    onChange={(value) => {
                        if (value && value.id === vacancyForm.companyId) {
                            return;
                        }
                        setVacancyFormPartial({
                            company: value || undefined,
                            companyId: value?.id,
                            companyLocationIds:[]
                        });
                    }}
                />
                <CompanyContactAutocomplete
                    disabled={!vacancyForm.companyId}
                    onChange={(value) => {
                        setVacancyFormPartial({
                            responsibleContactId:value?.id,
                            responsibleContact: value || undefined
                        });
                    }}
                    addEnabled
                    value={vacancyForm.responsibleContact || vacancyForm.responsibleContactId || null}
                    companyId={vacancyForm.companyId}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <CompanyLocationMultipleAutocomplete
                    disabled={!vacancyForm.companyId}
                    onChange={(value) => {
                        setVacancyFormPartial({
                            companyLocationIds:value.map((location) => location.id)
                        });
                    }}
                    addEnabled
                    required
                    error={Boolean(validationErrors.companyLocationId)}
                    helperText={validationErrors.companyLocationId}
                    value={vacancyForm.companyLocationIds}
                    companyId={vacancyForm.companyId}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <EmployeeAutocomplete
                    required
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={vacancyForm.responsibleEmployee || vacancyForm.responsibleEmployeeId || null}
                    onChange={(value) => setVacancyFormPartial({
                        responsibleEmployeeId: value?.id,
                        responsibleEmployee: value || undefined
                    })}
                    error={Boolean(validationErrors.responsibleEmployeeId)}
                    helperText={validationErrors.responsibleEmployeeId}
                />
                <CustomDatePicker
                    label={'Auftrag erhalten am'}
                    value={vacancyForm.orderReceivedDate}
                    flex
                    onChange={(value) => setVacancyFormPartial({
                        orderReceivedDate: value? getDateStringForApi(value): undefined
                    })}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <div className={"flex flex1 gap10"}>
                    <CustomNumberInput
                        width={150}
                        value={vacancyForm.count}
                        onChange={(value) => setVacancyFormPartial({
                            count: value || 1
                        })}
                        error={Boolean(validationErrors.count)}
                        helperText={validationErrors.count}
                        label={"Anzahl Ziel-Vermittlungen"}
                    />
                    <CustomNumberInput
                        value={vacancyForm.salaryFrom}
                        onChange={(value) => setVacancyFormPartial({salaryFrom: value || 0})}
                        suffix={"k €"}
                        label={translate('pages.vacancy.salaryFrom')}
                        max={999}
                    />
                    <CustomNumberInput
                        value={vacancyForm.salaryTo}
                        onChange={(value) =>  setVacancyFormPartial({salaryTo: value || 0})}
                        suffix={"k €"}
                        label={translate('pages.vacancy.salaryTo')}
                        max={999}
                    />
                </div>
                <div className={"flex flex1 gap10"}>
                    <CustomDatePicker
                        label={'Nächster Vorschlag geplant bis'}
                        value={vacancyForm.suggestionDueDate}
                        flex
                        onChange={(value) => {
                            if (value) {
                                setVacancyFormPartial({
                                    suggestionDueDate: getDateStringForApi(value),
                                    suggestionDueSinceDate: getDateStringForApi(new Date())
                                });

                                return;
                            }

                            setVacancyFormPartial({
                                suggestionDueDate: undefined,
                            });
                        }}
                    />
                    {vacancyForm.suggestionDueDate &&
                        <CustomDatePicker
                            label={'Vorschläge ab Datum berücksichtigen'}
                            value={vacancyForm.suggestionDueSinceDate}
                            flex
                            onChange={(value) => {
                                if (value) {
                                    setVacancyFormPartial({
                                        suggestionDueSinceDate: getDateStringForApi(value),
                                    });

                                    return;
                                }

                                setVacancyFormPartial({
                                    suggestionDueSinceDate: undefined,
                                });
                            }}
                        />
                    }
                </div>
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <CustomTextField
                    value={vacancyForm.title}
                    required={true}
                    onChange={(value) => setVacancyFormPartial({title: value})}
                    error={Boolean(validationErrors.title)}
                    helperText={validationErrors.title}
                    label={translate('pages.vacancy.properties.title')}
                />
                <WebVacancyAutocomplete
                    value={vacancyForm.webVacancyId || null}
                    onChange={(webVacancy) => setVacancyFormPartial({webVacancyId: webVacancy?.id})}
                    companyId={vacancyForm.companyId}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <div className={"flexContainerColumn flex1"}>
                    <h5 style={{margin: 5}}>Veröffentlichungen</h5>
                    <VacancyPublicationListView
                        vacancyId={vacancyId}
                        records={vacancyForm.publications}
                    />
                </div>
            </div>
            <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"}>
                <h5 style={{margin: 5}}>Skills ({vacancyForm.skills.length})</h5>
            </div>
            <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"} style={{width: 300}}>
                <SkillAutocomplete
                    label={"Skill hinzufügen"}
                    onChange={(skill) => {
                        if (!skill) {
                            return;
                        }

                        let skillExists = false;
                        vacancyForm.skills.forEach((vacancySkill) => {
                            if (vacancySkill.skill && vacancySkill.skill.id === skill.id) {
                                skillExists = true;
                            }
                        });

                        if (skillExists) {
                            return;
                        }

                        setVacancyFormPartial({skills: [
                                ...vacancyForm.skills, {
                                    skill: skill,
                                    id: - (skill.id),
                                    count: 0,
                                    importantSkill: false,
                                    addedByParser: true,
                                    identifier: 'candidate'
                                }
                            ]})
                    }}
                    clearOnSelect
                    value={null}
                />
            </div>
            <div className={"flexContainerRow"} style={{alignItems: 'start'}}>
                <div className={"flexContainerRow gap5 flexWrap"}>
                    {vacancyForm.skills.map((vacancySkill, index) =>
                        <VacancySkillElement
                            key={index}
                            skill={vacancySkill as IVacancySkill}
                            onRemove={onRemoveSkill}
                            onSetImportant={onSetImportantSkill}
                        />
                    )}
                </div>
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <CustomTextField
                    multiline
                    rows={8}
                    value={vacancyForm.comment}
                    onChange={(value) => setVacancyFormPartial({comment: value})}
                    label={translate('misc.furtherInformation')}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <LanguageMultipleAutocomplete
                    value={vacancyForm.languageIds}
                    onChange={(languages) => setVacancyFormPartial({languageIds: languages.map((language) => language.id)})}
                    label={"Must-Have Sprache/n"}
                />
                <HomeOfficePossibilityDropDown
                    onChange={(homeOfficePossibility) => setVacancyFormPartial({homeOfficePossibility: homeOfficePossibility||undefined})}
                    value={vacancyForm.homeOfficePossibility}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <CustomCheckboxInput
                    labelTranslation={"pages.vacancy.properties.withLeadership"}
                    checked={vacancyForm.withLeadership}
                    onChange={() => setVacancyFormPartial({withLeadership: !vacancyForm.withLeadership})}
                />
                <CustomCheckboxInput
                    labelTranslation={"pages.vacancy.properties.needDriverLicense"}
                    checked={vacancyForm.needDriverLicense}
                    onChange={() => setVacancyFormPartial({needDriverLicense: !vacancyForm.needDriverLicense})}
                />
                <CustomCheckboxInput
                    labelTranslation={"pages.vacancy.partTime"}
                    checked={vacancyForm.partTime}
                    onChange={() => setVacancyFormPartial({partTime: !vacancyForm.partTime})}
                />
            </div>
            {(vacancyForm.webVacancyId) &&
                <>
                    <Divider/>
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"}>
                        <h4 style={{margin:10, marginLeft: 0}}>Informationen aus der Stellenanzeige</h4>
                    </div>
                    <WebVacancyDataPanel webVacancyId={vacancyForm.webVacancyId} />
                </>
            }
            { vacancy &&
                <>
                    <Divider/>
                    <div className={"flexContainerRow flex1 gap10"}>
                        <BillingConfigurationElement
                            billingSetting={vacancy}
                            entity={EEntities.Vacancy}
                            entityId={vacancyId}
                        />
                    </div>
                </>
            }
        </div>
        <CustomDialogActions
            divider
            buttons={[{
                hidden: !props.vacancyId,
                action: () => dispatch(setVacancyPageVacancyToArchiveAction(vacancyId)),
                label: 'Archivieren'
            }, {
                hidden: !props.vacancyId,
                action: () => dispatch(setVacancyPageVacancyToDeleteAction(vacancyId)),
                label: translate('misc.buttons.delete')
            }, {
                action: () => save(),
                label: translate('misc.buttons.save')
            }]}
        />
    </>;
}

export default VacancyEditForm;
