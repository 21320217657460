import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import {
    ICandidatePartial,
    ICompany,
    ICompanyContactPartial,
    IEmployee,
    IEmployeePartial,
    ITodoForApi,
    IVacancy,
} from '../../../../definitions/entities.definition';
import { addTodoAction } from '../../../../redux/entities/entities.actions';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomDateTimePicker from '../../../../components/CustomInput/CustomDateTimePicker';
import { translate } from '../../../../translation/translate.utils';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { getDateStringForApi, momentToDate } from '../../../../utils/date.utils';
import LegacyCompanyAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyAutocompleteDropDown';
import LegacyCandidateAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCandidateAutocompleteDropDown';
import LegacyCompanyVacancyAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyVacancyAutocompleteDropDown';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import Moment from 'moment';
import CompanyContactAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';

interface IProps {
    onClose: () => void;
    initialDueDate?: Date;
}

const TodoAddForm: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const validationErrors = useSelector(getValidationErrorsFromStore);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const [employee, setEmployee] = useState<IEmployee|IEmployeePartial>(loggedInEmployee);
    const [company, setCompany] = useState<ICompany|undefined>(undefined);
    const [vacancy, setVacancy] = useState<IVacancy|undefined>(undefined);
    const [companyContact, setCompanyContact] = useState<ICompanyContactPartial|undefined>(undefined);
    const [candidate, setCandidate] = useState<ICandidatePartial|undefined>(undefined);
    const [dueDate, setDueDate] = useState<Date|undefined>(props.initialDueDate);
    const [dueDateTo, setDueDateTo] = useState<Date|undefined>(props.initialDueDate ? momentToDate(Moment(props.initialDueDate).add(30, 'm')) : undefined);
    const [description, setDescription] = useState('');
    const [shareWithEmployees, setShareWithEmployees] = useState<IEmployeePartial[]>([]);
    const [flexibleTime, setDueDateFlexibleTime] = useState(false);

    const save = () => {
        const todo: ITodoForApi = {
            employeeId: employee?.id || undefined,
            companyId: company?.id || undefined,
            candidateId: candidate?.id || undefined,
            vacancyId: vacancy?.id || undefined,
            companyContactId: companyContact?.id || undefined,
            dueDate: dueDate ? getDateStringForApi(dueDate) : undefined,
            dueDateTo: dueDateTo ? getDateStringForApi(dueDateTo) : undefined,
            shareWithEmployeeIds: shareWithEmployees.map((employee) => employee.id),
            flexibleTime,
            description
        };

        dispatch(addTodoAction(token, todo));
        return;
    }

    return (
        <div>
            <PaperRow>
                <LegacyEmployeeAutocompleteDropDown
                    value={employee}
                    label={translate('misc.responsibleEmployee')}
                    onChange={(event, value) => setEmployee(value)}
                    error={Boolean(validationErrors.responsibleId)}
                    helperText={validationErrors.responsibleId}
                />
                <LegacyEmployeeAutocompleteDropDown
                    label={'Teilen mit'}
                    multiple={true}
                    value={shareWithEmployees}
                    onChange={(event, value: IEmployeePartial[]) => setShareWithEmployees(value)}
                />
                <LegacyCandidateAutocompleteDropDown
                    value={candidate}
                    onChange={(event, value) => setCandidate(value)}
                />
            </PaperRow>
            <PaperRow>
                <div style={{display: "flex", flex: 1, paddingRight: flexibleTime ? 8 : 16}}>
                    <CustomCheckboxInput
                        labelTranslation={"pages.todo.flexibleTime"}
                        checked={flexibleTime}
                        onChange={() => setDueDateFlexibleTime(!flexibleTime)}
                    />
                </div>
                {flexibleTime &&
                    <div style={{flex: 2, paddingRight: 16}}>
                        <CustomDatePicker
                            label={translate('pages.todo.dueDateAt')}
                            value={dueDate}
                            onChange={(value) => setDueDate(value)}
                            error={Boolean(validationErrors.dueDate)}
                            helperText={validationErrors.dueDate}
                        />
                    </div>
                }
                {!flexibleTime &&
                    <>
                        <CustomDateTimePicker
                            label={translate('pages.todo.dueDate')}
                            value={dueDate}
                            onChange={(value) => {
                                if (!dueDateTo) {
                                    setDueDateTo(momentToDate(Moment(value).add(30, 'm')));
                                    setDueDate(value);
                                    return;
                                }

                                setDueDate(value);
                            }}
                            error={Boolean(validationErrors.dueDate)}
                            helperText={validationErrors.dueDate}
                        />
                        <CustomDateTimePicker
                            label={translate('pages.todo.dueDateTo')}
                            value={dueDateTo}
                            onChange={(value) => setDueDateTo(value)}
                            error={Boolean(validationErrors.dueDateTo)}
                            helperText={validationErrors.dueDateTo}
                        />
                    </>
                }
            </PaperRow>
            <PaperRow>
                <LegacyCompanyAutocompleteDropDown
                    value={company}
                    onChange={(event, value) => setCompany(value)}
                />
                <LegacyCompanyVacancyAutocompleteDropDown
                    label={translate('misc.vacancy')}
                    value={vacancy}
                    onChange={(event, value) => setVacancy(value)}
                    companyId={company ? company.id : 0}
                />
                <CompanyContactAutocomplete
                    addEnabled
                    value={companyContact || null}
                    onChange={(value) => setCompanyContact(value)}
                    companyId={company ? company.id : 0}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    multiline
                    rows={10}
                    label={translate('pages.todo.description')}
                    value={description}
                    onChange={(value) => setDescription(value)}
                    error={Boolean(validationErrors.description)}
                    helperText={validationErrors.description}
                />
            </PaperRow>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: save,
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </div>
    );
}

export default TodoAddForm;
