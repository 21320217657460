import React, { useEffect, useState } from 'react';
import { ESuggestionStatus, IEmployeePartial } from '../../../../definitions/entities.definition';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { translate } from '../../../../translation/translate.utils';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import {
    reopenSuggestionAction,
    updateSuggestionAndReloadListAction,
} from '../../../../redux/entities/entities.actions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getLoggedInEmployeeFromStore, userHasRole } from '../../../../selectors/employee.selectors';
import { ROLE_ADMIN } from '../../../../definitions/actor.definitions';
import SuggestionDeclineReasonDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/SuggestionDeclineReasonDropDown';
import VacancyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import SourceRenderer from '../../../../components/CustomCellRenderer/SourceRenderer';
import { setVacancyPageSuggestionToDeleteAction } from '../../../../redux/vacancyPage/vacancyPage.actions';
import CustomValidationErrors from '../../../../components/CustomComponent/CustomValidationErrors';
import EmployeeAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import SuggestionChangeResponsibleView from './SuggestionChangeResponsibleView';

interface IProps {
    suggestionId: number;
}

const SuggestionEditForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const suggestionId = props.suggestionId;
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const initialSuggestion = useSelector((store: IStore) => store.entities.recruitingSuggestion.byId[suggestionId]);

    const [suggestedBy, setSuggestedBy] = useState<IEmployeePartial | null>();
    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial | null>();
    const [vacancy, setVacancy] = useState(initialSuggestion?.vacancy);
    const [comment, setComment] = useState(initialSuggestion?.comment || '');
    const [declineReason, setDeclineReason] = useState(initialSuggestion?.declineReason);
    const [declineReasonComment, setDeclineReasonComment] = useState(initialSuggestion?.declineReasonComment || '');
    const [responsibleChangedViewOpen, setResponsibleChangedViewOpen] = useState(false);
    const [responsibleChanged, setResponsibleChanged] = useState(false);
    const [responsibleChangedComment, setResponsibleChangedComment] = useState('');

    useEffect(() => {
        setSuggestedBy(initialSuggestion.suggestedByEmployee);
        setResponsibleEmployee(initialSuggestion.responsibleEmployee);
        setVacancy(initialSuggestion.vacancy);
        setComment(initialSuggestion.comment || '');
        setDeclineReason(initialSuggestion.declineReason);
        setDeclineReasonComment(initialSuggestion.declineReasonComment || '');
    }, [initialSuggestion]);

    useEffect(() => {
        if (!responsibleEmployee) {
            return;
        }

        if (initialSuggestion.responsibleEmployee.id !== responsibleEmployee.id && responsibleEmployee.id !== loggedInEmployee.id) {
            setResponsibleChangedViewOpen(true);
        }
    }, [responsibleEmployee]);

    const onSave = () => {
        dispatch(updateSuggestionAndReloadListAction(token, {
            id: initialSuggestion.id,
            vacancyId: vacancy?.id,
            suggestedByEmployeeId: suggestedBy ? suggestedBy.id : undefined,
            responsibleEmployeeId: responsibleEmployee ? responsibleEmployee.id : undefined,
            responsibleChanged,
            responsibleChangedComment,
            comment,
            declineReason,
            declineReasonComment,
        }));
    };

    const userIsAdmin = userHasRole(ROLE_ADMIN);

    return <>
        <CustomValidationErrors translationKey={'pages.recruiting'}/>
        <div style={{width: '100%', overflow: 'auto'}}>
            <SuggestionChangeResponsibleView
                onClose={() => {
                    setResponsibleChanged(false);
                    setResponsibleChangedViewOpen(false);
                }}
                onSave={(comment) => {
                    setResponsibleChanged(true);
                    setResponsibleChangedComment(comment);
                }}
                open={responsibleChangedViewOpen}
                newResponsibleName={responsibleEmployee?.fullName}
            />
            <PaperRow>
                <div className={"flexContainerColumn alignVerticalCenter"}>
                    <SourceRenderer size={"medium"} source={initialSuggestion.source}/>
                </div>
            </PaperRow>
            <PaperRow>
                <VacancyAutocomplete
                    label={translate('misc.vacancy')}
                    value={vacancy}
                    required
                    onChange={(value) => setVacancy(value)}
                />
            </PaperRow>
            <PaperRow>
                <EmployeeAutocomplete
                    label={translate('pages.vacancy.suggestedByEmployee')}
                    value={suggestedBy || null}
                    required
                    disabled={initialSuggestion && !userIsAdmin && initialSuggestion.suggestedByEmployee.id !== loggedInEmployee.id}
                    onChange={( value) => setSuggestedBy(value)}
                />
                <EmployeeAutocomplete
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={responsibleEmployee || null}
                    required
                    onChange={(value) => setResponsibleEmployee(value)}
                />
            </PaperRow>
            {initialSuggestion?.status === ESuggestionStatus.DECLINED &&
            <>
                <PaperRow>
                    <SuggestionDeclineReasonDropDown
                        value={declineReason}
                        onChange={(value) => setDeclineReason(value)}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={'Kommentar zur Absage'}
                        value={declineReasonComment}
                        onChange={(value) => setDeclineReasonComment(value)}
                        multiline={true}
                        rows={4}
                    />
                </PaperRow>
            </>
            }
            <PaperRow>
                <CustomTextField
                    multiline
                    rows={10}
                    value={comment}
                    onChange={(value) => setComment(value)}
                    label={translate('misc.comment')}
                />
            </PaperRow>
        </div>
        <CustomDialogActions
            buttons={[{
                action: () => {
                    dispatch(setVacancyPageSuggestionToDeleteAction(suggestionId));
                },
                hidden: initialSuggestion.status === ESuggestionStatus.ACCEPTED,
                label: 'Löschen',
            }, {
                action: () => {
                    dispatch(reopenSuggestionAction(
                        token,
                        suggestionId,
                    ));
                },
                hidden: initialSuggestion.status !== ESuggestionStatus.DECLINED,
                label: 'Vorschlag wieder öffnen',
            }, {
                action: onSave,
                label: 'Speichern',
            }]}
        />
    </>;
};

export default SuggestionEditForm;
