import React, { useState } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { useSelector } from 'react-redux';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import { translate } from '../../translation/translate.utils';
import PaperRow from '../../components/CustomPaper/PaperRow';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import { ICompanyContact, TSalutation } from '../../definitions/entities.definition';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import CustomBackdropLoadingInsideDiv from '../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { ClientApi } from '../../requests/ClientApi';
import { postCustomerContactListRouteConfig } from '../../requests/routes.customer';
import SalutationDropDown from '../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import CustomMailField from '../../components/CustomInput/CustomMailField';

interface IProps {
    open: boolean;
    onClose: () => void;
    callback: (contact: ICompanyContact) => void;
}

const AddContactView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const open = props.open;

    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{[field: string]: string}>({});
    const [salutation, setSalutation] = useState<TSalutation>('mr');
    const [title, setTitle] = useState('');
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [position, setPosition] = useState('');
    const [mailAddress, setMailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const onClose = () => {
        props.onClose();
    };

    const save = () => {
        setLoading(true);
        ClientApi.request(postCustomerContactListRouteConfig, {
            token,
            salutation,
            title,
            name,
            firstName,
            position,
            mailAddress,
            phoneNumber
        }).then((result: ICompanyContact) => {
            setLoading(false);
            props.callback(result);
            onClose();
        }).catch((result) => {
            if (result && result.includes("validationErrors: ")) {
                setValidationErrors(JSON.parse(result.replace("validationErrors: ", "")));
            }
            setLoading(false);
        });
    }

    if (!open) {
        return null;
    }

    return <>
        <CustomDialog maxWidth={"md"} open={open} onClose={onClose}>
            <CustomBackdropLoadingInsideDiv open={loading} />
            <CustomDialogHeader string={"Neuen Ansprechpartner anlegen"} onClose={onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <div className={"flexContainerRow flex1 gap5"}>
                        <SalutationDropDown
                            onChange={(event: any) => setSalutation(event.target.value)}
                            value={salutation}
                            required={true}
                            error={Boolean(validationErrors.salutation)}
                            helperText={validationErrors.salutation}
                        />
                        <CustomTextField
                            label={translate('pages.companyContact.properties.title')}
                            value={title}
                            onChange={(value) => setTitle(value)}
                        />
                    </div>
                    <CustomTextField
                        label={translate('pages.companyContact.properties.name')}
                        value={name}
                        required={true}
                        onChange={(value) => setName(value)}
                        error={Boolean(validationErrors.name)}
                        helperText={validationErrors.name}
                    />
                    <CustomTextField
                        label={translate('pages.companyContact.properties.firstName')}
                        value={firstName}
                        required={true}
                        onChange={(value) => setFirstName(value)}
                        error={Boolean(validationErrors.firstName)}
                        helperText={validationErrors.firstName}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('pages.companyContact.properties.position')}
                        value={position}
                        onChange={(value) => setPosition(value)}
                    />
                    <CustomMailField
                        label={translate('pages.companyContact.properties.mailAddress')}
                        value={mailAddress}
                        onChange={(value) => setMailAddress(value)}
                    />
                    <CustomTextField
                        label={translate('misc.phoneNumber')}
                        value={phoneNumber}
                        onChange={(value) => setPhoneNumber(value)}
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    action: () => save(),
                    label: translate('misc.buttons.save')
                }]}
            />
        </CustomDialog>
    </>;
};

export default AddContactView;
