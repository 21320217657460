import {
    IGetMailboxListAction,
    ISetMailboxAction, ISetMailingChannelAction,
    ISetMailingFilterAction, ISetMailingFolderAction,
    ISetMailingPagingAction,
    ISetMailingSearchOpen,
    ISetShowMessageAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { IMailingState, mailingFilterInitialState, preloadedMailingState } from '../../definitions/mailing.definitions';
import {
    ENTITIES_GET_MAIL_FULFILLED,
    ENTITIES_GET_MAIL_LIST_BY_FOLDER_FULFILLED,
    ENTITIES_GET_MAIL_LIST_FULFILLED,
    ENTITIES_GET_MAIL_PENDING,
    ENTITIES_GET_MAIL_REJECTED,
    ENTITIES_GET_MAILBOX_LIST_FULFILLED,
} from '../entities/entities.actions';
import {
    MAILING_SET_CHANNEL,
    MAILING_SET_FILTER, MAILING_SET_FOLDER,
    MAILING_SET_MAILBOX,
    MAILING_SET_PAGING,
    MAILING_SET_SEARCH_OPEN,
    MAILING_SET_SHOW_MESSAGE,
} from './mailing.actions';

const mailingReducer = (state = preloadedMailingState, action: TReducerAction): IMailingState => {
    switch (action.type) {
        case ENTITIES_GET_MAIL_PENDING: {
            return {
                ...state,
                loading: true
            }
        }
        case ENTITIES_GET_MAIL_REJECTED:
        case ENTITIES_GET_MAIL_FULFILLED: {
            return {
                ...state,
                loading: false
            }
        }
        case MAILING_SET_SEARCH_OPEN: {
            const actionCast = action as ISetMailingSearchOpen;

            return {
                ...state,
                search: actionCast.payload,
                filter: mailingFilterInitialState
            }
        }
        case MAILING_SET_MAILBOX: {
            const actionCast = action as ISetMailboxAction;

            return {
                ...state,
                selectedMailboxId: actionCast.payload.id,
                search: false,
                filter: {
                    folderId: actionCast.payload.defaultFolderId
                }
            }
        }
        case ENTITIES_GET_MAIL_LIST_FULFILLED:
        case ENTITIES_GET_MAIL_LIST_BY_FOLDER_FULFILLED: {
            return {
                ...state,
                showMessageOfMailId: 0
            }
        }
        case MAILING_SET_SHOW_MESSAGE: {
            const actionCast = action as ISetShowMessageAction;

            return {
                ...state,
                showMessageOfMailId: actionCast.payload
            }
        }
        case MAILING_SET_PAGING: {
            const actionCast = action as ISetMailingPagingAction;

            return {
                ...state,
                paging: {
                    start: actionCast.payload.start,
                    limit: actionCast.payload.limit
                }
            }
        }
        case MAILING_SET_CHANNEL: {
            const actionCast = action as ISetMailingChannelAction

            return {
                ...state,
                search: false,
                paging: {
                    start: 0,
                    limit: 20
                },
                filter: {
                    ...state.filter,
                    channel: actionCast.payload
                }
            }
        }
        case MAILING_SET_FOLDER: {
            const actionCast = action as ISetMailingFolderAction;

            return {
                ...state,
                search: false,
                paging: {
                    start: 0,
                    limit: 20
                },
                filter: {
                    ...state.filter,
                    folderId: actionCast.payload
                }
            }
        }
        case MAILING_SET_FILTER: {
            const actionCast = action as ISetMailingFilterAction;

            return {
                ...state,
                paging: {
                    start: 0,
                    limit: 20
                },
                filter: {
                    ...state.filter,
                    ...actionCast.payload
                }
            }
        }
        case ENTITIES_GET_MAILBOX_LIST_FULFILLED: {
            const actionCast = action as IGetMailboxListAction;

            if (actionCast.payload.defaultMailbox) {
                return {
                    ...state,
                    selectedMailboxId: actionCast.payload.defaultMailbox.id,
                    filter: {
                        folderId: actionCast.payload.defaultMailbox.defaultFolderId
                    }
                };
            }
            return {
                ...state
            }
        }
    }

    return {
        ...state
    };
}

export default mailingReducer;
