import React, { useEffect, useState } from 'react';
import { EApplicationStatus } from '../../../definitions/entities.definition';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { translate } from '../../../translation/translate.utils';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setApplicationPageDeclineAction } from '../../../redux/applicationPage/applicationPage.actions';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';
import { getApplicationPageActivitiesRequestObject } from '../../../selectors/activity.selectors';
import {
    getActivityListForApplicationAction,
    getApplicationAction,
    updateApplicationAction,
} from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import {
    setRecruitingPageAddSuggestionAction,
    setRecruitingPageStartRecruitingAction,
} from '../../../redux/recruitingPage/recruitingPage.actions';
import CustomValueElement from '../../../components/CustomInput/CustomValueElement';
import { getFormattedDateString } from '../../../utils/date.utils';
import UrlCellRenderer from '../../../components/CustomCellRenderer/UrlCellRenderer';
import CustomEditableValue from '../../../components/CustomInput/CustomEditableValue';
import VacancyAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import CandidateActivityChatPanel from '../Candidate/components/CandidateActivityChatPanel';
import { themeOrange } from '../../../theme/theme';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import ApplicationStatusDropDown from '../../../components/CustomInput/CustomDropDownInput/ApplicationStatusDropDown';
import EmployeeAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { EIcons } from '../../../components/Icons/IconFactory';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import { registerInfo } from '../../../redux/error/error.actions';

interface IProps {
    id: number;
}

const ApplicationView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const applicationId = props.id;
    const application = useSelector((store: IStore) => store.entities.applications.byId[applicationId]);
    const activityRequestObject = useSelector(getApplicationPageActivitiesRequestObject);
    const reloadActivities = useSelector((store: IStore) => store.ui.reloadActivityGrids);

    const [status, setStatus] = useState(application?.status || EApplicationStatus.open);
    const [responsibleEmployee, setResponsibleEmployee] = useState(application?.candidate.responsible || null);
    const [vacancy, setVacancy] = useState(application?.vacancy || null);

    const updateApplication = (field: string, value: any): void => {
        dispatch(updateApplicationAction(token, {
            id: applicationId,
            [field]: value
        }));
    };

    useEffect(() => {
        if (applicationId > 0 && (!application || application.id !== applicationId)) {
            dispatch(getApplicationAction(token, applicationId));
        }
    }, [applicationId]);

    useEffect(() => {
        if (applicationId === 0) {
            return;
        }
        dispatch(getActivityListForApplicationAction(
            token,
            applicationId,
            activityRequestObject,
        ));
        if (!application) {
            dispatch(getApplicationAction(token, applicationId));
        }
    }, [applicationId]);

    useEffect(() => {
        if (applicationId === 0 || !reloadActivities) {
            return;
        }
        dispatch(getActivityListForApplicationAction(
            token,
            applicationId,
            activityRequestObject,
        ));
    }, [reloadActivities]);

    if (!applicationId) {
        return null;
    }

    if (!application) {
        return <CustomBackdropLoading open={true}/>;
    }


    return (
        <div className={'flexContainerColumn flex'} style={{width: 900}}>
            <CustomTabContainer
                verticalWidth={190}
                color={themeOrange.palette.primary.main}
                tabs={[{
                    label: 'Stammdaten',
                    component: <div className={'flexAutoGrow'}>
                        <PaperRow>
                            <CustomValueElement
                                value={<CustomEditableValue
                                    theme={'light'}
                                    size={'small'}
                                    width={300}
                                    value={application.status}
                                    input={
                                        <ApplicationStatusDropDown
                                            value={status}
                                            onChange={(event) => {
                                                const valueCast = event.target.value as EApplicationStatus;
                                                setStatus(valueCast);
                                            }}
                                        />
                                    }
                                    onSave={() => {
                                        if (status === application.status) {
                                            return Promise.resolve();
                                        }

                                        if (status === EApplicationStatus.declined) {
                                            dispatch(setApplicationPageDeclineAction(applicationId));
                                            return Promise.resolve();
                                        }

                                        if (status === EApplicationStatus.recruiting) {
                                            dispatch(setRecruitingPageStartRecruitingAction({
                                                open: true,
                                                candidateId: application.candidate.id,
                                                vacancyId: application.vacancy?.id,
                                                applicationId: applicationId,
                                            }));
                                            return Promise.resolve();
                                        }

                                        if (status === EApplicationStatus.suggestion) {
                                            dispatch(setRecruitingPageAddSuggestionAction({
                                                open: true,
                                                candidateId: application.candidate.id,
                                                vacancyId: application.vacancy?.id,
                                                applicationId: applicationId
                                            }));
                                        }

                                        updateApplication('status', EApplicationStatus.inspection);
                                        return Promise.resolve();
                                    }}
                                >
                                    <div className={"flexContainerRow"}>
                                        {translate('pages.application.statusValue.' + application.status)}
                                        {application.recruiting &&
                                            <>
                                                &nbsp;
                                                <CustomClickableIcon
                                                    onClick={() => dispatch(setUiEditView(EEntityView.recruiting, application.recruiting.id))}
                                                    icon={EIcons.Launch}
                                                    tooltip={"Prozess öffnen"}
                                                />
                                            </>
                                        }
                                    </div>
                                </CustomEditableValue>
                                }
                                label={translate('pages.recruiting.status')}
                            />
                            <CustomValueElement
                                value={getFormattedDateString(application.created)}
                                label={'Beworben am'}
                            />
                            {application.declinedAt &&
                                <CustomValueElement
                                    value={getFormattedDateString(application.declinedAt)}
                                    label={'Abgelehnt am'}
                                />
                            }
                        </PaperRow>
                        <PaperRow>
                            <CustomValueElement
                                className={'flex1'}
                                value={
                                    <CustomEditableValue
                                        theme={'light'}
                                        size={'small'}
                                        width={300}
                                        value={application.vacancy}
                                        input={
                                            <VacancyAutocomplete
                                                size={'small'}
                                                autoFocus
                                                value={vacancy}
                                                onChange={(value) => setVacancy(value)}
                                            />
                                        }
                                        onSave={() => {
                                            if (vacancy && application.vacancy && vacancy.id === application.vacancy.id) {
                                                return Promise.resolve();
                                            }
                                            if (!vacancy && !application.vacancy) {
                                                return Promise.resolve();
                                            }

                                            updateApplication('vacancyId', vacancy ? vacancy.id : null);
                                            return Promise.resolve();
                                        }}
                                        emptyValue={'Vakanz eintragen'}
                                    >
                                        {application.vacancy?.title}
                                    </CustomEditableValue>
                                }
                                label={'Vakanz'}
                            />
                        </PaperRow>
                        <PaperRow>
                            <CustomValueElement
                                className={'flex1'}
                                value={
                                    <CustomEditableValue
                                        theme={'light'}
                                        size={'small'}
                                        width={300}
                                        value={application.candidate.responsible}
                                        input={
                                            <EmployeeAutocomplete
                                                size={'small'}
                                                autoFocus
                                                value={application.candidate.responsible}
                                                onChange={(value) => setResponsibleEmployee(value)}
                                            />
                                        }
                                        onSave={() => {
                                            if (responsibleEmployee && application.candidate.responsible && responsibleEmployee.id === application.candidate.responsible.id) {
                                                return Promise.resolve();
                                            }
                                            if (!responsibleEmployee && !application.candidate.responsible) {
                                                return Promise.resolve();
                                            }

                                            updateApplication('responsibleEmployeeId', responsibleEmployee ? responsibleEmployee.id : null);
                                            return Promise.resolve();
                                        }}
                                        emptyValue={'Verantwortlichen eintragen'}
                                    >
                                        {application.responsibleEmployee?.fullName}
                                    </CustomEditableValue>
                                }
                                label={translate('misc.responsibleEmployee')}
                            />
                        </PaperRow>
                        {application.url &&
                            <PaperRow>

                                <CustomValueElement
                                    value={
                                    <div className={"flexContainerRow"}>
                                        <CustomClickableIcon
                                            onClick={() => {
                                                navigator.clipboard.writeText(application?.url || '');
                                                dispatch(registerInfo('Url wurde kopiert'));
                                            }}
                                            icon={EIcons.Copy}
                                            tooltip={"Url kopieren"}
                                        />
                                        <UrlCellRenderer value={application.url} url={application.url || ''}/>
                                    </div>
                                    }
                                    label={'Beworben über URL'}
                                />
                            </PaperRow>
                        }
                    </div>,
                }, {
                    label: 'Historie',
                    component: <>
                        <CandidateActivityChatPanel candidateId={application.candidate.id} application={application}/>
                    </>,
                }]}
            />
        </div>
    );
};

export default ApplicationView;
