import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objectsAreEqual } from '../../../../utils/application.utils';
import CustomList from '../CustomSidePanelList/CustomList';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomListSection from '../CustomSidePanelList/CustomListSection';
import EmployeeFilter from '../Filter/EmployeeFilter';
import {
    setVacancyPageFilterAction,
    setVacancyPageFilterMenuAction,
} from '../../../../redux/vacancyPage/vacancyPage.actions';
import CompanyFilter from '../Filter/CompanyFilter';
import { IVacancyFilterMenu } from '../../../../definitions/vacancyPage.definitions';

const VacancyFilterPanel: React.FC = () => {
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.vacancyPage.vacancyListFilter);
    const filterMenuInStore = useSelector((store: IStore) => store.vacancyPage.vacancyListFilterMenu);
    const setFilterAction = setVacancyPageFilterAction;
    const setFilterMenuInStoreAction = setVacancyPageFilterMenuAction;
    const initialFilterMenu:IVacancyFilterMenu = {
        company: undefined,
        responsibleEmployee: undefined
    };

    const [filterMenu, setFilterMenu] = useState(filterMenuInStore);
    const [executeSubmit, setExecuteSubmit] = useState(false);

    const submit = () => {
        dispatch(setFilterMenuInStoreAction(filterMenu));
        dispatch(setFilterAction({
            fastSearch: filter.fastSearch,
            start: filter.start,
            limit: filter.limit,
            responsibleEmployeeId: filterMenu.responsibleEmployee?.id,
            companyId: filterMenu.company?.id
        }));
    };

    useEffect(() => {
        if (executeSubmit) {
            submit();
            setExecuteSubmit(false);
        }
    }, [executeSubmit])

    const reset = () => {
        setFilterMenu(initialFilterMenu);
        setExecuteSubmit(true);
    };

    const resetEnabled = !objectsAreEqual(filterMenu, initialFilterMenu);

    return (
        <>
            <CustomList
                reset={reset}
                resetEnabled={resetEnabled}
            >
                <CustomListSection header={"Basis-Filter"}>
                    <CompanyFilter
                        value={filterMenu.company || null}
                        onChange={(company) => {
                            setFilterMenu({
                                ...filterMenu,
                                company: company || undefined
                            });
                            setExecuteSubmit(true);
                        }}
                        onlyWithVacancies
                        onlyWithoutParent
                    />
                    <EmployeeFilter
                        value={filterMenu.responsibleEmployee || null}
                        onChange={(responsibleEmployee) => {
                            setFilterMenu({
                                ...filterMenu,
                                responsibleEmployee: responsibleEmployee || undefined
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                </CustomListSection>
            </CustomList>
        </>
    )
};

export default React.memo(VacancyFilterPanel);
