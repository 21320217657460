import React, { PropsWithChildren } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IListActionButtonConfig } from '../../definitions/components.definitions';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { translate } from '../../translation/translate.utils';

const initialState = {
    mouseX: null,
    mouseY: null,
};

export interface IContextMenuItem {
    action: () => void;
    label: string;
    icon?: EIcons;
    hidden?: boolean;
}

interface IProps extends PropsWithChildren<any> {
    menuItems: IContextMenuItem[]
    variant?: 'onClick'|'onContextMenu';
}

const CustomContextMenuEntity: React.FC<IProps> = (props) => {
    const variant = props.variant || 'onContextMenu';
    const [state, setState] = React.useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialState);
    const menuItems = props.menuItems.filter((menuItem) => !menuItem.hidden);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (variant === 'onContextMenu' && event.target && (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA')) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (state.mouseY) {
            setState(initialState);
            return;
        }

        setState(initialState);

        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };

    return <>
        <div
            onContextMenu={variant === 'onContextMenu' ? handleClick : undefined}
            onClick={variant === 'onClick' ? handleClick : undefined}
        >
            {props.children}
            {menuItems.length > 0 &&
                <Menu
                    open={state.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    {menuItems.map((menuItem) => {
                        return (
                            <MenuItem
                                key={"actionButton" + menuItem.label}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    menuItem.action();
                                    handleClose();
                                }}
                            >
                                {menuItem.icon &&
                                    <>{IconFactory.getIconComponent(menuItem.icon)}&nbsp;</>
                                }
                                {menuItem.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            }
        </div>
    </>;
};

export default CustomContextMenuEntity;
