import React from 'react';
import { Tooltip } from '@material-ui/core';
import { ESuggestionStatus, IVacancyCount } from '../../definitions/entities.definition';
import { setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';
import moment from 'moment/moment';
import { setVacancyPageSuggestionListOfVacancyFilterAction } from '../../redux/vacancyPage/vacancyPage.actions';
import { useDispatch } from 'react-redux';
import styles from '../../Pages/RecruitingPage/components/VacancyOpenList.module.css';

interface IProps {
    id: number;
    orderReceivedDate?: string;
    counts: IVacancyCount;
    size?: 'small'|'medium';
    locationId?: number;
}

const VacancyCountsCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const id = props.id;
    const counts = props.counts;
    const locationId = props.locationId;
    const size = props.size;
    const orderReceivedDate = props.orderReceivedDate;
    const suggestionsGreenAndYellow = counts.countSuggestionsWithStatusOpenAndContact;
    const className = "alignVerticalCenter alignCenter hoverOpacity " + (size === 'small' ? styles.timeLineDivSmall : styles.timeLineDiv);

    return <>
        <div className={"flexContainerColumn ellipseText"}>
            <div>
                <div className={"flexContainerRow gap5"}>
                    <Tooltip title={"Auftrag erhalten am"}>
                        <div
                            className={"backgroundRed " + className}
                            onClick={() => dispatch(setUiEditView(EEntityView.vacancy, id, 2))}
                        >
                            {orderReceivedDate ? moment(orderReceivedDate).format('DD.MM') : '-'}
                        </div>
                    </Tooltip>

                    {(counts.countSuggestionsWithStatusOpen > 0 || suggestionsGreenAndYellow > 0 || counts.countRecruitingsWithStatusOpen > 0 || counts.countRecruitingsWithStatusContract > 0) &&
                        <Tooltip title={"Favoriten"}>
                            <div
                                className={"backgroundOrange " + className}
                                onClick={() => {
                                    dispatch(setUiEditView(EEntityView.vacancy, id, 0));
                                    dispatch(setVacancyPageSuggestionListOfVacancyFilterAction({
                                        status: ESuggestionStatus.OPEN,
                                        contact: undefined,
                                        companyLocationId: locationId
                                    }));
                                }}
                            >
                                {counts.countSuggestionsWithStatusOpen}
                            </div>
                        </Tooltip>
                    }
                    {(suggestionsGreenAndYellow > 0 || counts.countRecruitingsWithStatusOpen > 0 || counts.countRecruitingsWithStatusContract > 0)&&
                        <Tooltip title={"Favoriten in Kontakt | Koordinierung"}>
                            <div
                                className={"backgroundYellow " + className}
                                onClick={() => {
                                    dispatch(setUiEditView(EEntityView.vacancy, id, 0));
                                    dispatch(setVacancyPageSuggestionListOfVacancyFilterAction({
                                        status: ESuggestionStatus.OPEN,
                                        contact: true,
                                        companyLocationId: locationId
                                    }));
                                }}
                            >
                                {suggestionsGreenAndYellow}
                            </div>
                        </Tooltip>
                    }
                    {(counts.countRecruitingsWithStatusOpen > 0 || counts.countRecruitingsWithStatusContract > 0) &&
                        <Tooltip title={"Laufende Prozesse"}>
                            <div
                                className={"backgroundGreen " + className}
                                onClick={() => dispatch(setUiEditView(EEntityView.vacancy, id, 1))}
                            >
                                {counts.countRecruitingsWithStatusOpen}
                            </div>
                        </Tooltip>
                    }
                    {counts.countRecruitingsWithStatusContract > 0 &&
                        <Tooltip title={"Vermittlungen"}>
                            <div
                                style={{background: '#aaa'}}
                                className={className}
                                onClick={() => dispatch(setUiEditView(EEntityView.vacancy, id, 1))}
                            >
                                {counts.countRecruitingsWithStatusContract}
                            </div>
                        </Tooltip>
                    }
                </div>
            </div>
        </div>
    </>
};

export default VacancyCountsCellRenderer;
