import React, { useEffect, useState } from 'react';
import { ELanguageLevel, ICandidateLanguage } from '../../../../../definitions/entities.definition';
import CustomDialogHeader from '../../../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../../../components/CustomDialog/CustomDialogBody';
import PaperRow from '../../../../../components/CustomPaper/PaperRow';
import { translate } from '../../../../../translation/translate.utils';
import CustomDialogActions from '../../../../../components/CustomInput/CustomDialogActions';
import CustomDialog from '../../../../../components/CustomDialog/CustomDialog';
import CustomTextField from '../../../../../components/CustomInput/CustomTextField';
import LanguageLevelDropDown from '../../../../../components/CustomInput/CustomDropDownInput/LanguageLevelDropDown';

interface IProps {
    language?: ICandidateLanguage;
    onClose: () => void;
    open: boolean;
    onSave: (language: ICandidateLanguage) => void;
}

const CandidateEditLanguagesView: React.FC<IProps> = (props) => {
    const candidateLanguage = props.language;
    const open = props.open;
    const [level, setLevel] = useState<ELanguageLevel>(ELanguageLevel.unknown);

    useEffect(() => {
        setLevel(candidateLanguage?.level || ELanguageLevel.unknown);
    }, [candidateLanguage])

    if (!open || !candidateLanguage) {
        return null;
    }

    return <>
        <CustomDialog maxWidth={"sm"} open={props.open} onClose={props.onClose}>
            <CustomDialogHeader translationString={'misc.editLanguage'} onClose={props.onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <CustomTextField
                        label={translate('misc.language')}
                        value={candidateLanguage.language.name}
                        disabled
                    />
                </PaperRow>
                <PaperRow>
                    <LanguageLevelDropDown
                        onChange={(event) => setLevel(event.target.value)}
                        value={level}
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                buttons={[{
                    action: () => {
                        props.onSave({
                            ...candidateLanguage,
                            level
                        });
                    },
                    color: 'secondary',
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </CustomDialog>
    </>;
}

export default CandidateEditLanguagesView;
