import React from 'react';
import Moment from 'moment';
import { Tooltip } from '@material-ui/core';
import { getFormattedDateWithDayString } from '../../utils/date.utils';
import { COLOR_RED } from '../../theme/theme';

interface IProps {
    date?: string;
    withoutLeadingText?: boolean;
    beforeText?: string;
    hideTooltip?: boolean;
    markOverdueDays?: number;
    ignoreTime?: boolean;
}

const DateDiffCellRenderer: React.FC<IProps> = (props) => {
    if (!props.date) {
        return null;
    }

    const ignoreTime = props.ignoreTime;
    const markOverdueDays = props.markOverdueDays;
    const hideTooltip = props.hideTooltip;
    const today = Moment();
    const yesterday = Moment().subtract('1', 'day');
    const date = Moment(props.date);

    if (ignoreTime) {
        date.set({h: 11, m: 0, s: 0});
        today.set({h: 11, m: 0, s: 0});
    }


    if (date.format('Y-MM-DD') === today.format('Y-MM-DD')) {
        return <>Heute</>;
    }

    if (date.format('Y-MM-DD') === yesterday.format('Y-MM-DD')) {
        return <>Gestern</>;
    }

    const daysDifference = today.diff(date, 'days');
    const monthDifference = today.diff(date, 'month');
    const yearsDifference = today.diff(date, 'year');

    const getText = (daysDifference: number, monthDifference: number, yearDifference: number, withoutLeadingText?: boolean) => {
        const valueParts: string[] = [];
        if (!withoutLeadingText) {
            valueParts.push(daysDifference < 0 ? 'in' : props.beforeText || 'vor');
        }

        if (daysDifference < 0) {
            daysDifference = daysDifference*-1;
            monthDifference = monthDifference*-1;
        }

        if (yearDifference === 1) {
            valueParts.push('einem Jahr');
            return valueParts.join(' ');
        }

        if (yearDifference > 1) {
            valueParts.push(yearDifference + ' Jahren');
            return valueParts.join(' ');
        }

        if (monthDifference === 1) {
            valueParts.push('einem Monat');
            return valueParts.join(' ');
        }

        if (monthDifference > 1) {
            valueParts.push(monthDifference + ' Monaten');
            return valueParts.join(' ');
        }

        if (daysDifference === 1) {
            valueParts.push('einem Tag');
            return valueParts.join(' ');
        }

        if (daysDifference > 1) {
            valueParts.push(daysDifference + ' Tagen');
            return valueParts.join(' ');
        }
    }

    const element = <div style={{color: markOverdueDays && daysDifference >= markOverdueDays ? COLOR_RED : 'inherit'}}>
        {getText(daysDifference, monthDifference, yearsDifference, props.withoutLeadingText)}
    </div>;

    if (hideTooltip) {
        return <>{element}</>;
    }

    return <>
        <Tooltip title={getFormattedDateWithDayString(props.date, true)}>
            {element}
        </Tooltip>
    </>;
}

export default DateDiffCellRenderer;
