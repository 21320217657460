import React from 'react';
import { ICompanyLocation, ICompanyLocationPartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getCompanyLocationListRouteConfig } from '../../../../requests/routes';
import CustomMultipleAutocomplete from '../CustomMultipleAutocomplete';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../CustomClickableIcon';
import { EIcons } from '../../../Icons/IconFactory';
import { setUiCompanyLocationAddDataAction, UI_SET_COMPANY_LOCATION_ADD_DATA } from '../../../../redux/ui/ui.actions';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useReduxAction } from '../../../../utils/actions.utils';
import { IStore } from '../../../../definitions/redux/store.definitions';

interface IProps {
    value: ICompanyLocationPartial[]|number[];
    onChange: (value: ICompanyLocationPartial[]) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    companyId?: number;
    addEnabled?: boolean;
}

const CompanyLocationMultipleAutocomplete: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const addEnabled = props.addEnabled || false;
    const companyId = props.companyId;
    const value = props.value;

    const onChange = (value: TBrainzEntity[]) => {
        props.onChange(value as ICompanyLocationPartial[]);
    }

    const onAdd = () => {
        if (!companyId) {
            return;
        }
        dispatch(setUiCompanyLocationAddDataAction({
            companyId
        }));
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        const entityCast = record as ICompanyLocation;
        if (!props.companyId) {
            return `${entityCast.company.name} ${entityCast.zipCode} ${entityCast.city}, ${entityCast.address}`
        }
        return `${entityCast.zipCode} ${entityCast.city}, ${entityCast.address}`
    }

    return <CustomMultipleAutocomplete
        id={"company-location-multiple-autocomplete"}
        label={"Adresse/n"}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getCompanyLocationListRouteConfig}
        getOptionLabel={getOptionLabel}
        startAdornment={(props.companyId && addEnabled) ? (
            <InputAdornment position="start">
                <CustomClickableIcon onClick={onAdd} icon={EIcons.Add}/>
            </InputAdornment>
        ) : undefined}
        routeParams={{
            start: 0,
            limit: 0,
            orderBy: 'city',
            companyId: props.companyId
        }}
    />;
}

export default React.memo(CompanyLocationMultipleAutocomplete);
