import React from 'react';
import { Card, CardHeader } from '@material-ui/core';
import styles from '../Todo/TodoCard.module.css';
import { useDispatch } from 'react-redux';
import { IApplication } from '../../../definitions/entities.definition';
import CandidateProfilesRenderer from '../../../components/CustomCellRenderer/CandidateProfilesRenderer';
import SourceRenderer from '../../../components/CustomCellRenderer/SourceRenderer';
import { getFormattedDateString } from '../../../utils/date.utils';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';

interface IProps {
    application: IApplication;
    parentView: 'candidate'|'vacancy'|'company';
}

const RenderApplicationListItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const parentView = props.parentView;
    const application = props.application;

    return <>
        <div>
            <Card
                className={styles.root}
                onClick={() => dispatch(setUiEditView(EEntityView.application, application.id))}
            >
                <CardHeader
                    title={
                        <>
                            <div className={"flexContainerRow"}>
                                <div className={"flex1 flexContainerRow alignVerticalCenter"}>
                                    <SourceRenderer source={application.candidate.source} />&nbsp;
                                    {parentView !== 'candidate' &&
                                        <> | {application.candidate.fullName} | </>
                                    }
                                    {parentView !== 'vacancy' && application.vacancy &&
                                        <> | {application.vacancy.title}
                                            <>
                                                {parentView !== 'company' &&
                                                    <> | {application.vacancy.company.name} </>
                                                }
                                                |
                                            </>
                                        </>
                                    }
                                    {getFormattedDateString(application.created)}
                                </div>
                                <div className={"flexAutoGrow flexContainerRow alignVerticalCenter alignHorizontalRight"}>
                                    {parentView !== 'candidate' &&
                                        <CandidateProfilesRenderer profiles={application.candidateProfiles} />
                                    }
                                </div>
                            </div>
                        </>
                    }
                    titleTypographyProps={{
                        variant: "body2"
                    }}
                    subheaderTypographyProps={{
                        variant: "body2"
                    }}
                />
            </Card>
        </div>
    </>;
}

export default RenderApplicationListItem;
