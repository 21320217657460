import {
    ISetMailboxAction, ISetMailingChannelAction,
    ISetMailingFilterAction,
    ISetMailingFolderAction,
    ISetMailingPagingAction,
    ISetMailingSearchOpen,
    ISetShowMessageAction,
} from '../../definitions/redux/actions.definition';
import { IMailingFilter, TMailboxChannel } from '../../definitions/mailing.definitions';
import { IMailbox } from '../../definitions/entities.definition';

export const MAILING_SET_FILTER = "MAILING_SET_FILTER";
export const MAILING_SET_FOLDER = "MAILING_SET_FOLDER";
export const MAILING_SET_CHANNEL = "MAILING_SET_CHANNEL";
export const MAILING_SET_PAGING = "MAILING_SET_PAGING";
export const MAILING_SET_SHOW_MESSAGE = "MAILING_SET_SHOW_MESSAGE";
export const MAILING_SET_MAILBOX = "MAILING_SET_MAILBOX";
export const MAILING_SET_SEARCH_OPEN = "MAILING_SET_SEARCH_OPEN";

export const setMailingSearchOpenAction = (open: boolean): ISetMailingSearchOpen => ({
    type: MAILING_SET_SEARCH_OPEN,
    payload: open
});

export const setMailingFolderAction = (folderId: number): ISetMailingFolderAction => ({
    type: MAILING_SET_FOLDER,
    payload: folderId
});

export const setMailingChannelAction = (channel: TMailboxChannel): ISetMailingChannelAction => ({
    type: MAILING_SET_CHANNEL,
    payload: channel
});

export const setMailingFilterAction = (filter: Partial<IMailingFilter>): ISetMailingFilterAction => ({
    type: MAILING_SET_FILTER,
    payload: filter
});

export const setMailingPagingAction = (start: number, limit: number): ISetMailingPagingAction => ({
    type: MAILING_SET_PAGING,
    payload: { start, limit }
});

export const setShowMessageAction = (messageId: number): ISetShowMessageAction => ({
    type: MAILING_SET_SHOW_MESSAGE,
    payload: messageId
});

export const setMailboxAction = (mailbox: IMailbox): ISetMailboxAction => ({
    type: MAILING_SET_MAILBOX,
    payload: mailbox
});
