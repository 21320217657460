import React, { useEffect, useState } from 'react';
import {
    ECandidateSource,
    ESuggestionAction, ICompanyLocation, ICompanyLocationPartial,
    ICountry,
    IEmployee,
    IEmployeePartial,
    IPluginCandidate,
    ISuggestionGroup,
    IVacancyPartial,
    TSalutation,
} from '../../../../definitions/entities.definition';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { translate } from '../../../../translation/translate.utils';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { addSuggestionAction } from '../../../../redux/entities/entities.actions';
import CandidateSourceDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { getSuggestionExternalUrl } from '../../../../utils/suggestion.utils';
import SalutationDropDown from '../../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import CandidateAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import CustomRadioGroupInput from '../../../../components/CustomInput/CustomRadioGroupInput';
import { getDayDifference } from '../../../../utils/date.utils';
import CandidateInformationColumnCellRenderer
    from '../../../../components/CustomCellRenderer/CandidateInformationColumnCellRenderer';
import { DialogActions, Divider } from '@material-ui/core';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { registerError } from '../../../../redux/error/error.actions';
import { addOnBrainzPluginHandler, sendUrlToBrainzPlugin } from '../../../../utils/components.utils';
import { useCreateCandidateHook } from '../../../CandidatePage/components/CandidatAddView.hooks';
import CountryAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/CountryAutocomplete';
import CustomInfo from '../../../../components/CustomComponent/CustomInfo';
import SuggestionGroupAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/SuggestionGroupAutocomplete';
import CandidateImportExternalForm from '../../../CandidatePage/components/CandidateImportExternalForm';
import CompanyLocationDropDown from '../../../../components/CustomInput/CustomDropDownInput/CompanyLocationDropDown';

interface IProps {
    onClose: () => void;
    vacancy: IVacancyPartial
}

const AddSuggestionForVacancyForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancy = props.vacancy;
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const errors = useSelector(getValidationErrorsFromStore);
    const [addType, setAddType] = useState<'candidate'|'newCandidate'>('newCandidate');
    const [salutation, setSalutation] = useState<TSalutation>('mr');
    const [candidateId, setCandidateId] = useState(0);
    const [name, setName] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [currentJobDescription, setCurrentJobDescription] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [country, setCountry] = useState<number|ICountry>(55);
    const [zipCode, setZipCode] = useState<string>("");
    const [externalCandidateReference, setExternalCandidateReference] = useState<string>("");
    const [externalCandidateUrl, setExternalCandidateUrl] = useState<string>("");
    const [source, setSource] = useState(ECandidateSource.jobcenter);
    const [suggestedBy, setSuggestedBy] = useState<IEmployeePartial|undefined>();
    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial|undefined>();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [mailAddress, setMailAddress] = useState<string>("");
    const [addAction, setAddAction] = useState(false);
    const addActions = [ESuggestionAction.ACTION_MESSAGE_MAIL, ESuggestionAction.ACTION_MESSAGE_PORTAL, ESuggestionAction.ACTION_MESSAGE_MOBILE, ESuggestionAction.ACTION_LETTER_SEND, ESuggestionAction.ACTION_CALL, ESuggestionAction.ACTION_CALL_NOT_REACHED];
    const [userInput, setUserInput] = useState("");
    const [action, setAction] = useState<ESuggestionAction>();
    const [importInProgress, setImportInProgress] = useState(false);
    const [importData, setImportData] = useState<IPluginCandidate>();
    const [suggestionGroup, setSuggestionGroup] = useState<ISuggestionGroup|null>(null);
    const [companyLocationId, setCompanyLocationId] = useState<number|null>(vacancy.companyLocations[0].id);

    addOnBrainzPluginHandler((event: MessageEvent) => {
        setImportInProgress(false);
        if (!event.data.success) {
            dispatch(registerError('URL konnte nicht ausgelesen werden'));
            return;
        }

        const importData: IPluginCandidate = event.data.data;
        setFirstName(importData.firstName);
        setName(importData.lastName);
        setCurrentJobDescription(importData.currentJobDescription);
        setMailAddress(importData.mailAddress);
        setCity(importData.city.replace(', Deutschland', '').replace('Metropolregion ', ''));
        setImportData(importData);
    });

    useEffect(() => {
        setResponsibleEmployee(vacancy?.responsibleEmployee);
        setSuggestedBy(loggedInEmployee);
    }, [dispatch]);

    const onSave = () => {
        dispatch(addSuggestionAction(token, {
            vacancyId: vacancy.id,
            candidateId,
            salutation,
            name,
            firstName,
            externalCandidateUrl,
            externalCandidateReference,
            source: source,
            mailAddress,
            phoneNumber,
            suggestedByEmployeeId: suggestedBy ? suggestedBy.id : undefined,
            responsibleEmployeeId: responsibleEmployee ? responsibleEmployee.id : undefined,
            companyLocationId: companyLocationId as number,
            contactAction: addAction ? action : undefined,
            contactUserInput: addAction ? userInput : undefined,
            currentJobDescription,
            countryId: country ? country.id : undefined,
            importData: importData,
            groupId: suggestionGroup?.id,
            city,
            zipCode,
            address
        }));
        return;
    }

    const externalUrl = getSuggestionExternalUrl(source, externalCandidateUrl, externalCandidateReference);
    const {duplicateCandidates} = useCreateCandidateHook(name, firstName, externalCandidateReference);
    const [saveDuplicate, setSaveDuplicate] = useState(false);
    const [hideInfo, setHideInfo] = useState(false);

    const footer = <>
        <PaperRow>
            <LegacyEmployeeAutocompleteDropDown
                label={translate('pages.vacancy.suggestedByEmployee')}
                value={suggestedBy}
                onChange={(event, value: IEmployee) => setSuggestedBy(value)}
            />
            <LegacyEmployeeAutocompleteDropDown
                label={translate('pages.vacancy.properties.responsibleEmployee')}
                value={responsibleEmployee}
                onChange={(event, value: IEmployee) => setResponsibleEmployee(value)}
            />
        </PaperRow>
        <PaperRow>
            <CustomCheckboxInput
                onChange={() => setAddAction(!addAction)}
                checked={addAction}
                label={"Favorit wurde bereits kontaktiert"}
            />
        </PaperRow>
        {addAction &&
        <>
            <div className={"flexContainerColumn marginLeft5"}>
                <div className={"flexContainerRow marginLeft5"} style={{flexWrap: 'wrap'}}>
                    {addActions.map((actionValue: ESuggestionAction, index: number) => {
                        return (
                            <div key={index} style={{width: 140}}>
                                <CustomCheckboxInput
                                    checked={action === actionValue}
                                    onChange={() => setAction(actionValue)}
                                    label={translate('pages.activity.suggestionActionValueShort.' + actionValue)}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            <PaperRow>
                <CustomTextField
                    label={translate('pages.activity.properties.userInput')}
                    value={userInput}
                    multiline={true}
                    onChange={(value) => setUserInput(value)}
                    rows={10}
                />
            </PaperRow>
        </>
        }
    </>

    if (addType === 'candidate') {
        return <>
            <div style={{ width: 800, marginRight:10, marginLeft: 10, overflow: "auto", backgroundColor: 'var(--brainz-darker)' }}>
                <CustomBackdropLoadingInsideDiv open={importInProgress} />
                <PaperRow>
                    <CustomRadioGroupInput
                        value={addType}
                        //@ts-ignore
                        onChange={(type) => {setAddType(type)}}
                        states={[{
                            value: 'candidate',
                            label: 'Kandidat aus Datenbank auswählen'
                        }, {
                            value: 'newCandidate',
                            label: 'Favorit händisch anlegen'
                        }]}
                    />
                </PaperRow>
                <PaperRow>
                    <CandidateAutocomplete
                        value={candidateId}
                        onChange={(value) => {
                            if (value) {
                                setCandidateId(value.id)
                                if (getDayDifference(value.created) <= 30) {
                                    setSource(value.source);
                                    return;
                                }
                                setSource(ECandidateSource.brainz);
                            } else {
                                setCandidateId(0);
                            }
                        }}
                    />
                    <SuggestionGroupAutocomplete
                        value={suggestionGroup}
                        required
                        error={Boolean(errors.suggestionGroupId)}
                        helperText={errors.suggestionGroupId}
                        onChange={(value) => {
                            if (value === null) {
                                setSuggestionGroup(null);
                                return;
                            }
                            setSuggestionGroup(value);
                        }}
                        vacancyId={vacancy.id}
                    />
                    <CandidateSourceDropDown
                        value={source}
                        required
                        onChange={(event) => setSource(event.target.value)}
                    />
                    {vacancy.companyLocations.length > 1 &&
                        <CompanyLocationDropDown
                            onChange={(event) => setCompanyLocationId(event.target.value)}
                            value={companyLocationId}
                            values={vacancy.companyLocations}
                            required
                            error={Boolean(errors.companyLocationId)}
                            helperText={errors.companyLocationId}
                        />
                    }
                </PaperRow>
                {footer}
            </div>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: onSave,
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </>;
    }

    return <>
        <div style={{
            display: 'flex',
            width: importData ? 1260 : 800,
            marginRight:10,
            marginLeft: 10,
            overflow: importData ? "hidden" : "auto",
            backgroundColor: 'var(--brainz-darker)',
            gap: 5
        }}>
            <CustomBackdropLoadingInsideDiv open={importInProgress} />
            <div className={"flex2 overflow"}>
                <PaperRow>
                    <CustomRadioGroupInput
                        value={addType}
                        //@ts-ignore
                        onChange={(type) => {setAddType(type)}}
                        states={[{
                            value: 'candidate',
                            label: 'Kandidat aus Datenbank auswählen'
                        }, {
                            value: 'newCandidate',
                            label: 'Favorit händisch anlegen'
                        }]}
                    />
                </PaperRow>
                <PaperRow>
                    <div className={"flexContainerRow gap10 flex1"}>
                        <SuggestionGroupAutocomplete
                            value={suggestionGroup}
                            required
                            error={Boolean(errors.suggestionGroupId)}
                            helperText={errors.suggestionGroupId}
                            onChange={(value) => {
                                if (value === null) {
                                    setSuggestionGroup(null);
                                    return;
                                }
                                setSuggestionGroup(value);
                            }}
                            vacancyId={vacancy.id}
                        />
                        <CandidateSourceDropDown
                            value={source}
                            required
                            onChange={(event) => setSource(event.target.value)}
                        />
                    </div>
                    <div className={"flexContainerRow gap5 flex1"}>
                        {vacancy.companyLocations.length > 1 &&
                            <CompanyLocationDropDown
                                onChange={(event) => setCompanyLocationId(event.target.value)}
                                value={companyLocationId}
                                values={vacancy.companyLocations}
                                required
                                error={Boolean(errors.companyLocationId)}
                                helperText={errors.companyLocationId}
                            />
                        }
                        {source === ECandidateSource.jobcenter &&
                        <CustomUrlField
                            flex={2}
                            required
                            url={externalUrl}
                            label={translate('pages.candidate.properties.externalReference')}
                            value={externalCandidateReference}
                            onChange={(value) => setExternalCandidateReference(value)}
                            error={Boolean(errors.externalCandidateReference)}
                            helperText={errors.externalCandidateReference}
                        />
                        }
                        {(source !== ECandidateSource.recommendation && source !== ECandidateSource.jobcenter && source !== ECandidateSource.brainz && source !== ECandidateSource.further) &&
                        <>
                            <CustomUrlField
                                flex={2}
                                required
                                label={translate('misc.url')}
                                value={externalCandidateUrl}
                                onChange={(value) => {
                                    if (source === ECandidateSource.linkedIn && sendUrlToBrainzPlugin(value)) {
                                        setImportInProgress(true);
                                    }
                                    setExternalCandidateUrl(value)
                                }}
                                error={Boolean(errors.externalCandidateUrl)}
                                helperText={errors.externalCandidateUrl}
                            />
                        </>
                        }
                    </div>
                </PaperRow>
                {(duplicateCandidates.length > 0 && !hideInfo) &&
                <>
                    <PaperRow>
                        <CustomInfo buttonConfig={{
                            action: () => setHideInfo(true),
                            text: 'Ausblenden'
                        }}>
                            <h4 style={{margin: 5}}>Kandidat/en mit gleichen Namen/gleicher Referenz</h4>
                            <div className={"flexContainerRow flexWrap flex1 gap10"}>
                                {duplicateCandidates.map((candidate) => <>
                                        <div className={"flexContainerRow CandidateInformationPanel"}>
                                            <CandidateInformationColumnCellRenderer
                                                key={candidate.id}
                                                showDetails
                                                token={token}
                                                candidate={candidate}
                                                buttonConfig={{
                                                    label: 'Auswählen',
                                                    action: () => {
                                                        setAddType('candidate');
                                                        setCandidateId(candidate.id);
                                                        setName('');
                                                        setFirstName('');
                                                        setPhoneNumber('');
                                                        setMailAddress('');
                                                        setExternalCandidateReference('');
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </CustomInfo>
                    </PaperRow>
                </>
                }
                <PaperRow>
                    <div className={"flexContainerRow gap10 flex1"}>
                        <SalutationDropDown
                            // @ts-ignore-next-line
                            onChange={(event: any) => setSalutation(event.target.value)}
                            value={salutation}
                            required={true}
                        />
                        <CustomTextField
                            value={firstName}
                            onChange={(value) => setFirstName(value)}
                            label={translate('pages.candidate.properties.firstName')}
                        />
                    </div>
                    <CustomTextField
                        value={name}
                        onChange={(value) => setName(value)}
                        label={translate('pages.candidate.properties.name')}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        value={phoneNumber}
                        onChange={(value) => setPhoneNumber(value)}
                        label={translate('misc.mobileNumber')}
                    />
                    <CustomTextField
                        value={mailAddress}
                        onChange={(value) => setMailAddress(value)}
                        label={translate('misc.email')}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('pages.candidate.properties.currentJobDescription')}
                        error={Boolean(errors.currentJobDescription)}
                        value={currentJobDescription}
                        onChange={(value) => setCurrentJobDescription(value)}
                    />
                </PaperRow>
                <PaperRow>
                    <div className={"flexContainerRow flex1 gap10"}>
                        <CustomTextField
                            value={zipCode}
                            onChange={(value) => setZipCode(value)}
                            label={translate('misc.zipCode')}
                            width={100}
                        />
                        <CustomTextField
                            label={translate('misc.city')}
                            value={city}
                            onChange={(value) => setCity(value)}
                        />
                    </div>
                    <CustomTextField
                        value={address}
                        onChange={(value) => setAddress(value)}
                        label={translate('misc.address')}
                    />
                    <CountryAutocomplete
                        value={country}
                        onChange={(country) => setCountry(country)}
                    />
                </PaperRow>
                {footer}
            </div>
            {importData &&
                <>
                    <Divider orientation={"vertical"} />
                    <h3 style={{marginLeft:0, margin: 5}}>Daten aus LinkedIn</h3>
                    <CandidateImportExternalForm importData={importData} />
                </>
            }
        </div>
        {(duplicateCandidates.length === 0 || saveDuplicate) &&
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: onSave,
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        }
        {duplicateCandidates.length > 0 && !saveDuplicate &&
            <DialogActions>
                <CustomCheckboxInput checked={saveDuplicate} label={"Kandidat anlegen trotz Warnung vor Duplikat"} onChange={() => setSaveDuplicate(true)}/>
            </DialogActions>
        }
    </>;
};

export default AddSuggestionForVacancyForm;
