import { ClientApi } from '../../requests/ClientApi';
import {
    deleteActivityRouteConfig,
    deleteCandidateDocumentRouteConfig,
    deleteCandidateProfessionRouteConfig,
    deleteCandidateRouteConfig,
    deleteCompanyContactRouteConfig,
    deleteCompanyDocumentRouteConfig,
    deleteCompanyLocationRouteConfig,
    deleteCompanyRouteConfig,
    deleteInvoiceRouteConfig,
    deleteMailRelationRouteConfig,
    deleteRecruitingDocumentRouteConfig,
    deleteRecruitingRouteConfig,
    deleteSkillRouteConfig,
    deleteSuggestionRouteConfig,
    deleteTodoRouteConfig,
    deleteVacancyRouteConfig,
    downloadCandidateDocumentRouteConfig,
    downloadCompanyDocumentRouteConfig,
    downloadPrintInvoiceRouteConfig,
    downloadRecruitingDocumentRouteConfig,
    getActivityChatListRouteConfig,
    getActivityListForApplicationRouteConfig,
    getActivityListForCandidateRouteConfig,
    getActivityListForSuggestionRouteConfig,
    getActivityListForTodoRouteConfig,
    getActivityListForVacancyRouteConfig,
    getActivityListForWebVacancyRouteConfig,
    getActivityListRouteConfig,
    getActivityRouteConfig,
    getApplicationListRouteConfig,
    getApplicationRouteConfig,
    getCandidateListRouteConfig,
    getCandidateRouteConfig,
    getCandidatesCreatedNewRouteConfig,
    getCandidatesWithBirthdayRouteConfig,
    getCompanyContactListRouteConfig,
    getCompanyContactRouteConfig,
    getCompanyDocumentsForCompanyRouteConfig,
    getCompanyListRouteConfig,
    getCompanyLocationListRouteConfig,
    getCompanyRouteConfig,
    getEmployeeListRouteConfig,
    getEmployeeRouteConfig,
    getExternalSkillConfig,
    getInvoiceGroupedEvaluationRouteConfig,
    getInvoiceListRouteConfig,
    getInvoiceRouteConfig,
    getJobTemplateListRouteConfig,
    getJobTemplateNameListRouteConfig,
    getJobTemplateRouteConfig,
    getJobTitleListRouteConfig,
    getMailAddressesRouteConfig,
    getMailboxListRouteConfig,
    getMailboxMailMessageRouteConfig,
    getMailListByChannelRouteConfig,
    getMailListByFolderRouteConfig, getMailListBySearchRouteConfig,
    getMailRouteConfig,
    getNewsListRouteConfig,
    getNotifyUnreadCountRouteConfig,
    getProcessAggregationListByCandidateRouteConfig,
    getRecruitingDocumentsRouteConfig,
    getRecruitingGroupedEvaluationRouteConfig,
    getRecruitingListRouteConfig,
    getRecruitingRouteConfig,
    getSkillConfig,
    getSkillListConfig,
    getSkillTreeRouteConfig,
    getSuccessActivityListRouteConfig,
    getSuggestionListRouteConfig,
    getSuggestionRouteConfig,
    getTargetListByMonthRouteConfig,
    getTodoListOfEmployeeRouteConfig,
    getTodoListRouteConfig,
    getTodoRouteConfig,
    getVacancyAndWebVacancyListRouteConfig,
    getVacancyListOfCompanyContactRouteConfig,
    getVacancyListOfCompanyRouteConfig,
    getVacancyListRouteConfig,
    getVacancyRouteConfig,
    getWebVacancyListRouteConfig,
    getWebVacancyRouteConfig,
    postActivityRouteConfig,
    postApplicationRouteConfig,
    postCandidateDocumentRouteConfig,
    postCandidateProfessionRouteConfig,
    postCandidateRouteConfig,
    postCompanyContactRouteConfig,
    postCompanyDocumentRouteConfig,
    postCompanyLocationRouteConfig,
    postCompanyRouteConfig,
    postContractOfferRouteConfig,
    postEmployeeRouteConfig,
    postInterviewRouteConfig,
    postInvoiceRouteConfig,
    postJobTemplateRouteConfig,
    postMailRelationRouteConfig,
    postRecruitingDocumentRouteConfig,
    postRecruitingRouteConfig,
    postSkillRouteConfig,
    postSuggestionActivityRouteConfig,
    postSuggestionRouteConfig,
    postTodoRouteConfig,
    postVacancyRouteConfig,
    putActivityRouteConfig, putApplicationRouteConfig,
    putCandidateDocumentRouteConfig,
    putCandidateProfessionRouteConfig,
    putCandidateRouteConfig,
    putCompanyContactRouteConfig,
    putCompanyDocumentRouteConfig,
    putCompanyLocationRouteConfig,
    putCompanyRouteConfig,
    putContractOfferRouteConfig,
    putEmployeeRouteConfig,
    putExternalSkillRouteConfig,
    putInterviewRouteConfig,
    putInvoiceRouteConfig,
    putJobTemplateRouteConfig,
    putJobTitleRouteConfig,
    putMailRouteConfig,
    putMarkMailAsReadRouteConfig,
    putNewsReadTimestampRouteConfig,
    putRecruitingDocumentRouteConfig,
    putRecruitingRouteConfig,
    putSkillRouteConfig,
    putSuggestionDeclineRouteConfig,
    putSuggestionReopenRouteConfig,
    putSuggestionRouteConfig,
    putTodoRouteConfig,
    putTodosReadTimestampRouteConfig,
    putVacancyBulkUpdateInvoiceCreationVariantRouteConfig,
    putVacancyRouteConfig,
    putWebVacancyRouteConfig,
} from '../../requests/routes';
import { IGetRequestObject } from '../../definitions/requests.definitions';
import {
    EActivityModule,
    ECandidateSource,
    EEntities,
    ERecruitingStatus,
    ESuggestionDeclineReason,
    ESuggestionStatus,
    IActivityForApi,
    IAddInterviewForApi,
    IBulkUpdateVacancyInvoiceCreationVariant,
    ICandidateDocument,
    ICandidateDocumentForApi,
    ICandidateForApi,
    ICandidateProfessionForApi,
    ICompanyContactForApi,
    ICompanyDocumentForApi,
    ICompanyForApi,
    ICompanyLocationForApi,
    IContractOfferForApi,
    IEmployeeForApi,
    IExternalSkillForApi,
    IInvoiceForApi,
    IJobTemplateForApi,
    IJobTitleForApi,
    IPostSkillForAPI,
    IPutSkillForAPI,
    IRecruitingDocumentForApi,
    IRecruitingForAPI,
    ISuggestion,
    ISuggestionForApi,
    ITodoForApi,
    IUpdateInterviewForApi,
    IVacancyForApi,
    IVacancySkillAssign,
    TEntity,
    TMailProperty,
} from '../../definitions/entities.definition';
import { asyncActionCreator, downloadFile } from '../../utils/actions.utils';
import { ESortingOptions } from '../../definitions/components.definitions';
import {
    IChangeTodoOrder,
    IChangeVacancyOrder,
    IResetRecruitingStatusChangedToAbortAction,
    IResetRecruitingStatusChangedToContractAction,
    IUpdateSuggestionInStoreAction,
} from '../../definitions/redux/actions.definition';
import { ICalendarTodoFilter } from '../../definitions/calendarPage.definitions';
import { ISkillTreeFilter } from '../../definitions/skillPage.definitions';
import { IPostSuggestionActivity } from '../../definitions/recruitingSuggestion.definitions';
import { TMailboxChannel } from '../../definitions/mailing.definitions';
import { EDocumentStatus } from '../../definitions/billingPage.definitions';

export const ENTITIES_RESET_COMPANY_INVOICE_CREATION_VARIANT_CHANGED = "ENTITIES_RESET_COMPANY_INVOICE_CREATION_VARIANT_CHANGED";
export const ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_CONTRACT = "ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_CONTRACT";
export const ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_ABORT = "ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_ABORT";

export const ENTITIES_POST_TODO = "ENTITIES_POST_TODO";
export const ENTITIES_POST_TODO_REJECTED = "ENTITIES_POST_TODO_REJECTED";
export const ENTITIES_POST_TODO_PENDING = "ENTITIES_POST_TODO_PENDING";
export const ENTITIES_POST_TODO_FULFILLED = "ENTITIES_POST_TODO_FULFILLED";

export const ENTITIES_GET_INVOICE = "ENTITIES_GET_INVOICE";
export const ENTITIES_GET_INVOICE_REJECTED = "ENTITIES_GET_INVOICE_REJECTED";
export const ENTITIES_GET_INVOICE_PENDING = "ENTITIES_GET_INVOICE_PENDING";
export const ENTITIES_GET_INVOICE_FULFILLED = "ENTITIES_GET_INVOICE_FULFILLED";

export const ENTITIES_POST_INVOICE = "ENTITIES_POST_INVOICE";
export const ENTITIES_POST_INVOICE_REJECTED = "ENTITIES_POST_INVOICE_REJECTED";
export const ENTITIES_POST_INVOICE_PENDING = "ENTITIES_POST_INVOICE_PENDING";
export const ENTITIES_POST_INVOICE_FULFILLED = "ENTITIES_POST_INVOICE_FULFILLED";

export const ENTITIES_PUT_INVOICE = "ENTITIES_PUT_INVOICE";
export const ENTITIES_PUT_INVOICE_REJECTED = "ENTITIES_PUT_INVOICE_REJECTED";
export const ENTITIES_PUT_INVOICE_PENDING = "ENTITIES_PUT_INVOICE_PENDING";
export const ENTITIES_PUT_INVOICE_FULFILLED = "ENTITIES_PUT_INVOICE_FULFILLED";

export const ENTITIES_DELETE_INVOICE = "ENTITIES_DELETE_INVOICE";
export const ENTITIES_DELETE_INVOICE_REJECTED = "ENTITIES_DELETE_INVOICE_REJECTED";
export const ENTITIES_DELETE_INVOICE_FULFILLED = "ENTITIES_DELETE_INVOICE_FULFILLED";

export const ENTITIES_GET_INVOICE_LIST = "ENTITIES_GET_INVOICE_LIST";
export const ENTITIES_GET_INVOICE_LIST_REJECTED = "ENTITIES_GET_INVOICE_LIST_REJECTED";
export const ENTITIES_GET_INVOICE_LIST_PENDING = "ENTITIES_GET_INVOICE_LIST_PENDING";
export const ENTITIES_GET_INVOICE_LIST_FULFILLED = "ENTITIES_GET_INVOICE_LIST_FULFILLED";

export const ENTITIES_GET_INVOICE_OPEN_LIST = "ENTITIES_GET_INVOICE_OPEN_LIST";
export const ENTITIES_GET_INVOICE_OPEN_LIST_REJECTED = "ENTITIES_GET_INVOICE_OPEN_LIST_REJECTED";
export const ENTITIES_GET_INVOICE_OPEN_LIST_PENDING = "ENTITIES_GET_INVOICE_OPEN_LIST_PENDING";
export const ENTITIES_GET_INVOICE_OPEN_LIST_FULFILLED = "ENTITIES_GET_INVOICE_OPEN_LIST_FULFILLED";

export const ENTITIES_GET_INVOICE_LIST_OF_RECRUITING = "ENTITIES_GET_INVOICE_LIST_OF_RECRUITING";
export const ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_REJECTED = "ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_REJECTED";
export const ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_PENDING = "ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_PENDING";
export const ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_FULFILLED = "ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_FULFILLED";

export const ENTITIES_GET_INVOICE_LIST_OF_COMPANY = "ENTITIES_GET_INVOICE_LIST_OF_COMPANY";
export const ENTITIES_GET_INVOICE_LIST_OF_COMPANY_REJECTED = "ENTITIES_GET_INVOICE_LIST_OF_COMPANY_REJECTED";
export const ENTITIES_GET_INVOICE_LIST_OF_COMPANY_PENDING = "ENTITIES_GET_INVOICE_LIST_OF_COMPANY_PENDING";
export const ENTITIES_GET_INVOICE_LIST_OF_COMPANY_FULFILLED = "ENTITIES_GET_INVOICE_LIST_OF_COMPANY_FULFILLED";

export const ENTITIES_GET_MAIL = "ENTITIES_GET_MAIL";
export const ENTITIES_GET_MAIL_REJECTED = "ENTITIES_GET_MAIL_REJECTED";
export const ENTITIES_GET_MAIL_PENDING = "ENTITIES_GET_MAIL_PENDING";
export const ENTITIES_GET_MAIL_FULFILLED = "ENTITIES_GET_MAIL_FULFILLED";

export const ENTITIES_PUT_MAIL = "ENTITIES_PUT_MAIL";
export const ENTITIES_PUT_MAIL_REJECTED = "ENTITIES_PUT_MAIL_REJECTED";
export const ENTITIES_PUT_MAIL_PENDING = "ENTITIES_PUT_MAIL_PENDING";
export const ENTITIES_PUT_MAIL_FULFILLED = "ENTITIES_PUT_MAIL_FULFILLED";

export const ENTITIES_POST_MAIL_RELATION = "ENTITIES_POST_MAIL_RELATION";
export const ENTITIES_POST_MAIL_RELATION_REJECTED = "ENTITIES_POST_MAIL_RELATION_REJECTED";
export const ENTITIES_POST_MAIL_RELATION_PENDING = "ENTITIES_POST_MAIL_RELATION_PENDING";
export const ENTITIES_POST_MAIL_RELATION_FULFILLED = "ENTITIES_POST_MAIL_RELATION_FULFILLED";

export const ENTITIES_GET_MAIL_MESSAGE = "ENTITIES_GET_MAIL_MESSAGE";
export const ENTITIES_GET_MAIL_MESSAGE_REJECTED = "ENTITIES_GET_MAIL_MESSAGE_REJECTED";
export const ENTITIES_GET_MAIL_MESSAGE_PENDING = "ENTITIES_GET_MAIL_MESSAGE_PENDING";
export const ENTITIES_GET_MAIL_MESSAGE_FULFILLED = "ENTITIES_GET_MAIL_MESSAGE_FULFILLED";

export const ENTITIES_GET_MAIL_LIST_BY_FOLDER = "ENTITIES_GET_MAIL_LIST_BY_FOLDER";
export const ENTITIES_GET_MAIL_LIST_BY_FOLDER_REJECTED = "ENTITIES_GET_MAIL_LIST_BY_FOLDER_REJECTED";
export const ENTITIES_GET_MAIL_LIST_BY_FOLDER_PENDING = "ENTITIES_GET_MAIL_LIST_BY_FOLDER_PENDING";
export const ENTITIES_GET_MAIL_LIST_BY_FOLDER_FULFILLED = "ENTITIES_GET_MAIL_LIST_BY_FOLDER_FULFILLED";

export const ENTITIES_GET_MAIL_LIST = "ENTITIES_GET_MAIL_LIST";
export const ENTITIES_GET_MAIL_LIST_REJECTED = "ENTITIES_GET_MAIL_LIST_REJECTED";
export const ENTITIES_GET_MAIL_LIST_PENDING = "ENTITIES_GET_MAIL_LIST_PENDING";
export const ENTITIES_GET_MAIL_LIST_FULFILLED = "ENTITIES_GET_MAIL_LIST_FULFILLED";

export const ENTITIES_GET_MAILBOX_LIST = "ENTITIES_GET_MAILBOX_LIST";
export const ENTITIES_GET_MAILBOX_LIST_REJECTED = "ENTITIES_GET_MAILBOX_LIST_REJECTED";
export const ENTITIES_GET_MAILBOX_LIST_PENDING = "ENTITIES_GET_MAILBOX_LIST_PENDING";
export const ENTITIES_GET_MAILBOX_LIST_FULFILLED = "ENTITIES_GET_MAILBOX_LIST_FULFILLED";

export const ENTITIES_GET_MAILBOX_FOLDER_TREE = "ENTITIES_GET_MAILBOX_FOLDER_TREE";
export const ENTITIES_GET_MAILBOX_FOLDER_TREE_REJECTED = "ENTITIES_GET_MAILBOX_FOLDER_TREE_REJECTED";
export const ENTITIES_GET_MAILBOX_FOLDER_TREE_PENDING = "ENTITIES_GET_MAILBOX_FOLDER_TREE_PENDING";
export const ENTITIES_GET_MAILBOX_FOLDER_TREE_FULFILLED = "ENTITIES_GET_MAILBOX_FOLDER_TREE_FULFILLED";

export const ENTITIES_SET_MAIL_READ = "ENTITIES_SET_MAIL_READ";
export const ENTITIES_SET_MAIL_READ_REJECTED = "ENTITIES_SET_MAIL_READ_REJECTED";
export const ENTITIES_SET_MAIL_READ_PENDING = "ENTITIES_SET_MAIL_READ_PENDING";
export const ENTITIES_SET_MAIL_READ_FULFILLED = "ENTITIES_SET_MAIL_READ_FULFILLED";

export const ENTITIES_DELETE_MAIL_RELATION = "ENTITIES_DELETE_MAIL_RELATION";
export const ENTITIES_DELETE_MAIL_RELATION_REJECTED = "ENTITIES_DELETE_MAIL_RELATION_REJECTED";
export const ENTITIES_DELETE_MAIL_RELATION_PENDING = "ENTITIES_DELETE_MAIL_RELATION_PENDING";
export const ENTITIES_DELETE_MAIL_RELATION_FULFILLED = "ENTITIES_DELETE_MAIL_RELATION_FULFILLED";

export const ENTITIES_GET_NEWS_LIST = "ENTITIES_GET_NEWS_LIST";
export const ENTITIES_GET_NEWS_LIST_REJECTED = "ENTITIES_GET_NEWS_LIST_REJECTED";
export const ENTITIES_GET_NEWS_LIST_PENDING = "ENTITIES_GET_NEWS_LIST_PENDING";
export const ENTITIES_GET_NEWS_LIST_FULFILLED = "ENTITIES_GET_NEWS_LIST_FULFILLED";

export const ENTITIES_SET_NEWS_READ_TIMESTAMP = "ENTITIES_SET_NEWS_READ_TIMESTAMP";
export const ENTITIES_SET_NEWS_READ_TIMESTAMP_REJECTED = "ENTITIES_SET_NEWS_READ_TIMESTAMP_REJECTED";
export const ENTITIES_SET_NEWS_READ_TIMESTAMP_PENDING = "ENTITIES_SET_NEWS_READ_TIMESTAMP_PENDING";
export const ENTITIES_SET_NEWS_READ_TIMESTAMP_FULFILLED = "ENTITIES_SET_NEWS_READ_TIMESTAMP_FULFILLED";

export const ENTITIES_GET_NOTIFY_UNREAD_COUNT = "ENTITIES_GET_NOTIFY_UNREAD_COUNT";
export const ENTITIES_GET_NOTIFY_UNREAD_COUNT_REJECTED = "ENTITIES_GET_NOTIFY_UNREAD_COUNT_REJECTED";
export const ENTITIES_GET_NOTIFY_UNREAD_COUNT_PENDING = "ENTITIES_GET_NOTIFY_UNREAD_COUNT_PENDING";
export const ENTITIES_GET_NOTIFY_UNREAD_COUNT_FULFILLED = "ENTITIES_GET_NOTIFY_UNREAD_COUNT_FULFILLED";

export const ENTITIES_GET_APPLICATION_LIST = "ENTITIES_GET_APPLICATION_LIST";
export const ENTITIES_GET_APPLICATION_LIST_REJECTED = "ENTITIES_GET_APPLICATION_LIST_REJECTED";
export const ENTITIES_GET_APPLICATION_LIST_PENDING = "ENTITIES_GET_APPLICATION_LIST_PENDING";
export const ENTITIES_GET_APPLICATION_LIST_FULFILLED = "ENTITIES_GET_APPLICATION_LIST_FULFILLED";

export const ENTITIES_POST_APPLICATION = "ENTITIES_POST_APPLICATION";
export const ENTITIES_POST_APPLICATION_REJECTED = "ENTITIES_POST_APPLICATION_REJECTED";
export const ENTITIES_POST_APPLICATION_PENDING = "ENTITIES_POST_APPLICATION_PENDING";
export const ENTITIES_POST_APPLICATION_FULFILLED = "ENTITIES_POST_APPLICATION_FULFILLED";

export const ENTITIES_PUT_APPLICATION = "ENTITIES_PUT_APPLICATION";
export const ENTITIES_PUT_APPLICATION_REJECTED = "ENTITIES_PUT_APPLICATION_REJECTED";
export const ENTITIES_PUT_APPLICATION_PENDING = "ENTITIES_PUT_APPLICATION_PENDING";
export const ENTITIES_PUT_APPLICATION_FULFILLED = "ENTITIES_PUT_APPLICATION_FULFILLED";

export const ENTITIES_GET_APPLICATION = "ENTITIES_GET_APPLICATION";
export const ENTITIES_GET_APPLICATION_REJECTED = "ENTITIES_GET_APPLICATION_REJECTED";
export const ENTITIES_GET_APPLICATION_PENDING = "ENTITIES_GET_APPLICATION_PENDING";
export const ENTITIES_GET_APPLICATION_FULFILLED = "ENTITIES_GET_APPLICATION_FULFILLED";

export const ENTITIES_GET_APPLICATION_LIST_BY_VACANCY = "ENTITIES_GET_APPLICATION_LIST_BY_VACANCY";
export const ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_REJECTED = "ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_REJECTED";
export const ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_PENDING = "ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_PENDING";
export const ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED = "ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED";

export const ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE = "ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE";
export const ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_REJECTED = "ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_REJECTED";
export const ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_PENDING = "ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_PENDING";
export const ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_FULFILLED = "ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_FULFILLED";

export const ENTITIES_GET_APPLICATION_LIST_BY_COMPANY = "ENTITIES_GET_APPLICATION_LIST_BY_COMPANY";
export const ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_REJECTED = "ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_REJECTED";
export const ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_PENDING = "ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_PENDING";
export const ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_FULFILLED = "ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_FULFILLED";

export const ENTITIES_GET_TODO = "ENTITIES_GET_TODO";
export const ENTITIES_GET_TODO_REJECTED = "ENTITIES_GET_TODO_REJECTED";
export const ENTITIES_GET_TODO_PENDING = "ENTITIES_GET_TODO_PENDING";
export const ENTITIES_GET_TODO_FULFILLED = "ENTITIES_GET_TODO_FULFILLED";

export const ENTITIES_GET_TODO_LIST = "ENTITIES_GET_TODO_LIST";
export const ENTITIES_GET_TODO_LIST_REJECTED = "ENTITIES_GET_TODO_LIST_REJECTED";
export const ENTITIES_GET_TODO_LIST_PENDING = "ENTITIES_GET_TODO_LIST_PENDING";
export const ENTITIES_GET_TODO_LIST_FULFILLED = "ENTITIES_GET_TODO_LIST_FULFILLED";

export const ENTITIES_GET_DUE_TODO_LIST = "ENTITIES_GET_DUE_TODO_LIST";
export const ENTITIES_GET_DUE_TODO_LIST_REJECTED = "ENTITIES_GET_DUE_TODO_LIST_REJECTED";
export const ENTITIES_GET_DUE_TODO_LIST_PENDING = "ENTITIES_GET_DUE_TODO_LIST_PENDING";
export const ENTITIES_GET_DUE_TODO_LIST_FULFILLED = "ENTITIES_GET_DUE_TODO_LIST_FULFILLED";

export const ENTITIES_GET_TODO_LIST_OF_EMPLOYEE = "ENTITIES_GET_TODO_LIST_OF_EMPLOYEE";
export const ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_REJECTED = "ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_REJECTED";
export const ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_PENDING = "ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_PENDING";
export const ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_FULFILLED = "ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_FULFILLED";

export const ENTITIES_PUT_TODO = "ENTITIES_PUT_TODO";
export const ENTITIES_PUT_TODO_REJECTED = "ENTITIES_PUT_TODO_REJECTED";
export const ENTITIES_PUT_TODO_PENDING = "ENTITIES_PUT_TODO_PENDING";
export const ENTITIES_PUT_TODO_FULFILLED = "ENTITIES_PUT_TODO_FULFILLED";

export const ENTITIES_PUT_TODO_ORDER = "ENTITIES_PUT_TODO_ORDER";
export const ENTITIES_PUT_TODO_ORDER_REJECTED = "ENTITIES_PUT_TODO_ORDER_REJECTED";
export const ENTITIES_PUT_TODO_ORDER_PENDING = "ENTITIES_PUT_TODO_ORDER_PENDING";
export const ENTITIES_PUT_TODO_ORDER_FULFILLED = "ENTITIES_PUT_TODO_ORDER_FULFILLED";

export const ENTITIES_SET_TODOS_READ_TIMESTAMP = "ENTITIES_SET_TODOS_READ_TIMESTAMP";
export const ENTITIES_SET_TODOS_READ_TIMESTAMP_REJECTED = "ENTITIES_SET_TODOS_READ_TIMESTAMP_REJECTED";
export const ENTITIES_SET_TODOS_READ_TIMESTAMP_PENDING = "ENTITIES_SET_TODOS_READ_TIMESTAMP_PENDING";
export const ENTITIES_SET_TODOS_READ_TIMESTAMP_FULFILLED = "ENTITIES_SET_TODOS_READ_TIMESTAMP_FULFILLED";

export const ENTITIES_CHANGE_TODO_ORDER = "ENTITIES_CHANGE_TODO_ORDER";
export const ENTITIES_CHANGE_VACANCY_ORDER = "ENTITIES_CHANGE_VACANCY_ORDER";

export const ENTITIES_DELETE_TODO = "ENTITIES_DELETE_TODO";
export const ENTITIES_DELETE_TODO_REJECTED = "ENTITIES_DELETE_TODO_REJECTED";
export const ENTITIES_DELETE_TODO_PENDING = "ENTITIES_DELETE_TODO_PENDING";
export const ENTITIES_DELETE_TODO_FULFILLED = "ENTITIES_DELETE_TODO_FULFILLED";

export const ENTITIES_GET_CANDIDATE_LIST = "ENTITIES_GET_CANDIDATE_LIST";
export const ENTITIES_GET_CANDIDATE_LIST_REJECTED = "ENTITIES_GET_CANDIDATE_LIST_REJECTED";
export const ENTITIES_GET_CANDIDATE_LIST_PENDING = "ENTITIES_GET_CANDIDATE_LIST_PENDING";
export const ENTITIES_GET_CANDIDATE_LIST_FULFILLED = "ENTITIES_GET_CANDIDATE_LIST_FULFILLED";

export const ENTITIES_GET_CANDIDATE_LIST_APPEND = "ENTITIES_GET_CANDIDATE_LIST_APPEND";
export const ENTITIES_GET_CANDIDATE_LIST_APPEND_REJECTED = "ENTITIES_GET_CANDIDATE_LIST_APPEND_REJECTED";
export const ENTITIES_GET_CANDIDATE_LIST_APPEND_PENDING = "ENTITIES_GET_CANDIDATE_LIST_APPEND_PENDING";
export const ENTITIES_GET_CANDIDATE_LIST_APPEND_FULFILLED = "ENTITIES_GET_CANDIDATE_LIST_APPEND_FULFILLED";

export const ENTITIES_DELETE_CANDIDATE_PROFESSION = "ENTITIES_DELETE_CANDIDATE_PROFESSION";
export const ENTITIES_DELETE_CANDIDATE_PROFESSION_PENDING = "ENTITIES_DELETE_CANDIDATE_PROFESSION_PENDING";
export const ENTITIES_DELETE_CANDIDATE_PROFESSION_REJECTED = "ENTITIES_DELETE_CANDIDATE_PROFESSION_REJECTED";
export const ENTITIES_DELETE_CANDIDATE_PROFESSION_FULFILLED = "ENTITIES_DELETE_CANDIDATE_PROFESSION_FULFILLED";

export const ENTITIES_POST_CANDIDATE_PROFESSION = "ENTITIES_POST_CANDIDATE_PROFESSION";
export const ENTITIES_POST_CANDIDATE_PROFESSION_PENDING = "ENTITIES_POST_CANDIDATE_PROFESSION_PENDING";
export const ENTITIES_POST_CANDIDATE_PROFESSION_REJECTED = "ENTITIES_POST_CANDIDATE_PROFESSION_REJECTED";
export const ENTITIES_POST_CANDIDATE_PROFESSION_FULFILLED = "ENTITIES_POST_CANDIDATE_PROFESSION_FULFILLED";

export const ENTITIES_PUT_CANDIDATE_PROFESSION = "ENTITIES_PUT_CANDIDATE_PROFESSION";
export const ENTITIES_PUT_CANDIDATE_PROFESSION_PENDING = "ENTITIES_PUT_CANDIDATE_PROFESSION_PENDING";
export const ENTITIES_PUT_CANDIDATE_PROFESSION_REJECTED = "ENTITIES_PUT_CANDIDATE_PROFESSION_REJECTED";
export const ENTITIES_PUT_CANDIDATE_PROFESSION_FULFILLED = "ENTITIES_PUT_CANDIDATE_PROFESSION_FULFILLED";

export const ENTITIES_DELETE_CANDIDATE = "ENTITIES_DELETE_CANDIDATE";
export const ENTITIES_DELETE_CANDIDATE_REJECTED = "ENTITIES_DELETE_CANDIDATE_REJECTED";
export const ENTITIES_DELETE_CANDIDATE_FULFILLED = "ENTITIES_DELETE_CANDIDATE_FULFILLED";

export const ENTITIES_POST_CANDIDATE = "ENTITIES_POST_CANDIDATE";
export const ENTITIES_POST_CANDIDATE_PENDING = "ENTITIES_POST_CANDIDATE_PENDING";
export const ENTITIES_POST_CANDIDATE_REJECTED = "ENTITIES_POST_CANDIDATE_REJECTED";
export const ENTITIES_POST_CANDIDATE_FULFILLED = "ENTITIES_POST_CANDIDATE_FULFILLED";

export const ENTITIES_GET_CANDIDATE = "ENTITIES_GET_CANDIDATE";
export const ENTITIES_GET_CANDIDATE_REJECTED = "ENTITIES_GET_CANDIDATE_REJECTED";
export const ENTITIES_GET_CANDIDATE_PENDING = "ENTITIES_GET_CANDIDATE_PENDING";
export const ENTITIES_GET_CANDIDATE_FULFILLED = "ENTITIES_GET_CANDIDATE_FULFILLED";

export const ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING = "ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING";
export const ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING_REJECTED = "ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING_REJECTED";
export const ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING_PENDING = "ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING_PENDING";
export const ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING_FULFILLED = "ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING_FULFILLED";

export const ENTITIES_PUT_CANDIDATE = "ENTITIES_PUT_CANDIDATE";
export const ENTITIES_PUT_CANDIDATE_PENDING = "ENTITIES_PUT_CANDIDATE_PENDING";
export const ENTITIES_PUT_CANDIDATE_REJECTED = "ENTITIES_PUT_CANDIDATE_REJECTED";
export const ENTITIES_PUT_CANDIDATE_FULFILLED = "ENTITIES_PUT_CANDIDATE_FULFILLED";

export const ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE = "ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE";
export const ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_PENDING = "ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_PENDING";
export const ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_REJECTED = "ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_REJECTED";
export const ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_FULFILLED = "ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_FULFILLED";

export const ENTITIES_DELETE_CANDIDATE_DOCUMENT = "ENTITIES_DELETE_CANDIDATE_DOCUMENT";
export const ENTITIES_DELETE_CANDIDATE_DOCUMENT_REJECTED = "ENTITIES_DELETE_CANDIDATE_DOCUMENT_REJECTED";
export const ENTITIES_DELETE_CANDIDATE_DOCUMENT_FULFILLED = "ENTITIES_DELETE_CANDIDATE_DOCUMENT_FULFILLED";

export const ENTITIES_DOWNLOAD_CANDIDATE_DOCUMENT = "ENTITIES_DOWNLOAD_CANDIDATE_DOCUMENT";
export const ENTITIES_DOWNLOAD_CANDIDATE_DOCUMENT_REJECTED = "ENTITIES_DOWNLOAD_CANDIDATE_DOCUMENT_REJECTED";
export const ENTITIES_DOWNLOAD_CANDIDATE_DOCUMENT_FULFILLED = "ENTITIES_DOWNLOAD_CANDIDATE_DOCUMENT_FULFILLED";

export const ENTITIES_DOWNLOAD_CANDIDATE_CV_DOCUMENT = "ENTITIES_DOWNLOAD_CANDIDATE_CV_DOCUMENT";
export const ENTITIES_DOWNLOAD_CANDIDATE_CV_DOCUMENT_REJECTED = "ENTITIES_DOWNLOAD_CANDIDATE_CV_DOCUMENT_REJECTED";
export const ENTITIES_DOWNLOAD_CANDIDATE_CV_DOCUMENT_FULFILLED = "ENTITIES_DOWNLOAD_CANDIDATE_CV_DOCUMENT_FULFILLED";

export const ENTITIES_PUT_CANDIDATE_DOCUMENT = "ENTITIES_PUT_CANDIDATE_DOCUMENT";
export const ENTITIES_PUT_CANDIDATE_DOCUMENT_PENDING = "ENTITIES_PUT_CANDIDATE_DOCUMENT_PENDING";
export const ENTITIES_PUT_CANDIDATE_DOCUMENT_FULFILLED = "ENTITIES_PUT_CANDIDATE_DOCUMENT_FULFILLED";
export const ENTITIES_PUT_CANDIDATE_DOCUMENT_REJECTED = "ENTITIES_PUT_CANDIDATE_DOCUMENT_REJECTED";

export const ENTITIES_POST_CANDIDATE_DOCUMENT = "ENTITIES_POST_CANDIDATE_DOCUMENT";
export const ENTITIES_POST_CANDIDATE_DOCUMENT_PENDING = "ENTITIES_POST_CANDIDATE_DOCUMENT_PENDING";
export const ENTITIES_POST_CANDIDATE_DOCUMENT_FULFILLED = "ENTITIES_POST_CANDIDATE_DOCUMENT_FULFILLED";
export const ENTITIES_POST_CANDIDATE_DOCUMENT_REJECTED = "ENTITIES_POST_CANDIDATE_DOCUMENT_REJECTED";

export const ENTITIES_GET_DOCUMENTS_FOR_COMPANY = "ENTITIES_GET_DOCUMENTS_FOR_COMPANY";
export const ENTITIES_GET_DOCUMENTS_FOR_COMPANY_PENDING = "ENTITIES_GET_DOCUMENTS_FOR_COMPANY_PENDING";
export const ENTITIES_GET_DOCUMENTS_FOR_COMPANY_REJECTED = "ENTITIES_GET_DOCUMENTS_FOR_COMPANY_REJECTED";
export const ENTITIES_GET_DOCUMENTS_FOR_COMPANY_FULFILLED = "ENTITIES_GET_DOCUMENTS_FOR_COMPANY_FULFILLED";

export const ENTITIES_DELETE_COMPANY_DOCUMENT = "ENTITIES_DELETE_COMPANY_DOCUMENT";
export const ENTITIES_DELETE_COMPANY_DOCUMENT_REJECTED = "ENTITIES_DELETE_COMPANY_DOCUMENT_REJECTED";
export const ENTITIES_DELETE_COMPANY_DOCUMENT_FULFILLED = "ENTITIES_DELETE_COMPANY_DOCUMENT_FULFILLED";

export const ENTITIES_DOWNLOAD_COMPANY_DOCUMENT = "ENTITIES_DOWNLOAD_COMPANY_DOCUMENT";
export const ENTITIES_DOWNLOAD_COMPANY_DOCUMENT_REJECTED = "ENTITIES_DOWNLOAD_COMPANY_DOCUMENT_REJECTED";
export const ENTITIES_DOWNLOAD_COMPANY_DOCUMENT_FULFILLED = "ENTITIES_DOWNLOAD_COMPANY_DOCUMENT_FULFILLED";

export const ENTITIES_PUT_COMPANY_DOCUMENT = "ENTITIES_PUT_COMPANY_DOCUMENT";
export const ENTITIES_PUT_COMPANY_DOCUMENT_PENDING = "ENTITIES_PUT_COMPANY_DOCUMENT_PENDING";
export const ENTITIES_PUT_COMPANY_DOCUMENT_FULFILLED = "ENTITIES_PUT_COMPANY_DOCUMENT_FULFILLED";
export const ENTITIES_PUT_COMPANY_DOCUMENT_REJECTED = "ENTITIES_PUT_COMPANY_DOCUMENT_REJECTED";

export const ENTITIES_POST_COMPANY_DOCUMENT = "ENTITIES_POST_COMPANY_DOCUMENT";
export const ENTITIES_POST_COMPANY_DOCUMENT_PENDING = "ENTITIES_POST_COMPANY_DOCUMENT_PENDING";
export const ENTITIES_POST_COMPANY_DOCUMENT_FULFILLED = "ENTITIES_POST_COMPANY_DOCUMENT_FULFILLED";
export const ENTITIES_POST_COMPANY_DOCUMENT_REJECTED = "ENTITIES_POST_COMPANY_DOCUMENT_REJECTED";

export const ENTITIES_GET_DOCUMENTS_FOR_RECRUITING = "ENTITIES_GET_DOCUMENTS_FOR_RECRUITING";
export const ENTITIES_GET_DOCUMENTS_FOR_RECRUITING_PENDING = "ENTITIES_GET_DOCUMENTS_FOR_RECRUITING_PENDING";
export const ENTITIES_GET_DOCUMENTS_FOR_RECRUITING_REJECTED = "ENTITIES_GET_DOCUMENTS_FOR_RECRUITING_REJECTED";
export const ENTITIES_GET_DOCUMENTS_FOR_RECRUITING_FULFILLED = "ENTITIES_GET_DOCUMENTS_FOR_RECRUITING_FULFILLED";

export const ENTITIES_DELETE_RECRUITING_DOCUMENT = "ENTITIES_DELETE_RECRUITING_DOCUMENT";
export const ENTITIES_DELETE_RECRUITING_DOCUMENT_REJECTED = "ENTITIES_DELETE_RECRUITING_DOCUMENT_REJECTED";
export const ENTITIES_DELETE_RECRUITING_DOCUMENT_FULFILLED = "ENTITIES_DELETE_RECRUITING_DOCUMENT_FULFILLED";

export const ENTITIES_DOWNLOAD_RECRUITING_DOCUMENT = "ENTITIES_DOWNLOAD_RECRUITING_DOCUMENT";
export const ENTITIES_DOWNLOAD_RECRUITING_DOCUMENT_REJECTED = "ENTITIES_DOWNLOAD_RECRUITING_DOCUMENT_REJECTED";
export const ENTITIES_DOWNLOAD_RECRUITING_DOCUMENT_FULFILLED = "ENTITIES_DOWNLOAD_RECRUITING_DOCUMENT_FULFILLED";

export const ENTITIES_PUT_RECRUITING_DOCUMENT = "ENTITIES_PUT_RECRUITING_DOCUMENT";
export const ENTITIES_PUT_RECRUITING_DOCUMENT_PENDING = "ENTITIES_PUT_RECRUITING_DOCUMENT_PENDING";
export const ENTITIES_PUT_RECRUITING_DOCUMENT_FULFILLED = "ENTITIES_PUT_RECRUITING_DOCUMENT_FULFILLED";
export const ENTITIES_PUT_RECRUITING_DOCUMENT_REJECTED = "ENTITIES_PUT_RECRUITING_DOCUMENT_REJECTED";

export const ENTITIES_POST_RECRUITING_DOCUMENT = "ENTITIES_POST_RECRUITING_DOCUMENT";
export const ENTITIES_POST_RECRUITING_DOCUMENT_PENDING = "ENTITIES_POST_RECRUITING_DOCUMENT_PENDING";
export const ENTITIES_POST_RECRUITING_DOCUMENT_FULFILLED = "ENTITIES_POST_RECRUITING_DOCUMENT_FULFILLED";
export const ENTITIES_POST_RECRUITING_DOCUMENT_REJECTED = "ENTITIES_POST_RECRUITING_DOCUMENT_REJECTED";

export const ENTITIES_GET_COMPANY_LIST = "ENTITIES_GET_COMPANY_LIST";
export const ENTITIES_GET_COMPANY_LIST_PENDING = "ENTITIES_GET_COMPANY_LIST_PENDING";
export const ENTITIES_GET_COMPANY_LIST_REJECTED = "ENTITIES_GET_COMPANY_LIST_REJECTED";
export const ENTITIES_GET_COMPANY_LIST_FULFILLED = "ENTITIES_GET_COMPANY_LIST_FULFILLED";

export const ENTITIES_GET_COMPANY = "ENTITIES_GET_COMPANY";
export const ENTITIES_GET_COMPANY_PENDING = "ENTITIES_GET_COMPANY_PENDING";
export const ENTITIES_GET_COMPANY_REJECTED = "ENTITIES_GET_COMPANY_REJECTED";
export const ENTITIES_GET_COMPANY_FULFILLED = "ENTITIES_GET_COMPANY_FULFILLED";

export const ENTITIES_POST_COMPANY = "ENTITIES_POST_COMPANY";
export const ENTITIES_POST_COMPANY_PENDING = "ENTITIES_POST_COMPANY_PENDING";
export const ENTITIES_POST_COMPANY_REJECTED = "ENTITIES_POST_COMPANY_REJECTED";
export const ENTITIES_POST_COMPANY_FULFILLED = "ENTITIES_POST_COMPANY_FULFILLED";

export const ENTITIES_PUT_COMPANY = "ENTITIES_PUT_COMPANY";
export const ENTITIES_PUT_COMPANY_PENDING = "ENTITIES_PUT_COMPANY_PENDING";
export const ENTITIES_PUT_COMPANY_REJECTED = "ENTITIES_PUT_COMPANY_REJECTED";
export const ENTITIES_PUT_COMPANY_FULFILLED = "ENTITIES_PUT_COMPANY_FULFILLED";

export const ENTITIES_DELETE_COMPANY = "ENTITIES_DELETE_COMPANY";
export const ENTITIES_DELETE_COMPANY_REJECTED = "ENTITIES_DELETE_COMPANY_REJECTED";
export const ENTITIES_DELETE_COMPANY_FULFILLED = "ENTITIES_DELETE_COMPANY_FULFILLED";

export const ENTITIES_GET_COMPANY_CONTACT = "ENTITIES_GET_COMPANY_CONTACT";
export const ENTITIES_GET_COMPANY_CONTACT_REJECTED = "ENTITIES_GET_COMPANY_CONTACT_REJECTED";
export const ENTITIES_GET_COMPANY_CONTACT_PENDING = "ENTITIES_GET_COMPANY_CONTACT_PENDING";
export const ENTITIES_GET_COMPANY_CONTACT_FULFILLED = "ENTITIES_GET_COMPANY_CONTACT_FULFILLED";

export const ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY = "ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_PENDING = "ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_PENDING";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_REJECTED = "ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_REJECTED";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_FULFILLED = "ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_FULFILLED";

export const ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY = "ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_PENDING = "ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_PENDING";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_FULFILLED = "ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_FULFILLED";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_REJECTED = "ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_REJECTED";

export const ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE = "ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_PENDING = "ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_PENDING";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_FULFILLED = "ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_FULFILLED";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_REJECTED = "ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_REJECTED";

export const ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN = "ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN_PENDING = "ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN_PENDING";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN_REJECTED = "ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN_REJECTED";
export const ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN_FULFILLED = "ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN_FULFILLED";

export const ENTITIES_DELETE_COMPANY_CONTACT = "ENTITIES_DELETE_COMPANY_CONTACT";
export const ENTITIES_DELETE_COMPANY_CONTACT_PENDING = "ENTITIES_DELETE_COMPANY_CONTACT_PENDING";
export const ENTITIES_DELETE_COMPANY_CONTACT_REJECTED = "ENTITIES_DELETE_COMPANY_CONTACT_REJECTED";
export const ENTITIES_DELETE_COMPANY_CONTACT_FULFILLED = "ENTITIES_DELETE_COMPANY_CONTACT_FULFILLED";

export const ENTITIES_PUT_COMPANY_CONTACT = "ENTITIES_PUT_COMPANY_CONTACT";
export const ENTITIES_PUT_COMPANY_CONTACT_PENDING = "ENTITIES_PUT_COMPANY_CONTACT_PENDING";
export const ENTITIES_PUT_COMPANY_CONTACT_REJECTED = "ENTITIES_PUT_COMPANY_CONTACT_REJECTED";
export const ENTITIES_PUT_COMPANY_CONTACT_FULFILLED = "ENTITIES_PUT_COMPANY_CONTACT_FULFILLED";

export const ENTITIES_POST_COMPANY_CONTACT = "ENTITIES_POST_COMPANY_CONTACT";
export const ENTITIES_POST_COMPANY_CONTACT_PENDING = "ENTITIES_POST_COMPANY_CONTACT_PENDING";
export const ENTITIES_POST_COMPANY_CONTACT_FULFILLED = "ENTITIES_POST_COMPANY_CONTACT_FULFILLED";
export const ENTITIES_POST_COMPANY_CONTACT_REJECTED = "ENTITIES_POST_COMPANY_CONTACT_REJECTED";

export const ENTITIES_GET_LOCATIONS_FOR_COMPANY = "ENTITIES_GET_LOCATIONS_FOR_COMPANY";
export const ENTITIES_GET_LOCATIONS_FOR_COMPANY_PENDING = "ENTITIES_GET_LOCATIONS_FOR_COMPANY_PENDING";
export const ENTITIES_GET_LOCATIONS_FOR_COMPANY_REJECTED = "ENTITIES_GET_LOCATIONS_FOR_COMPANY_REJECTED";
export const ENTITIES_GET_LOCATIONS_FOR_COMPANY_FULFILLED = "ENTITIES_GET_LOCATIONS_FOR_COMPANY_FULFILLED";

export const ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN = "ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN";
export const ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN_PENDING = "ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN_PENDING";
export const ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN_REJECTED = "ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN_REJECTED";
export const ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN_FULFILLED = "ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN_FULFILLED";

export const ENTITIES_GET_COMPANY_LOCATION_LIST = "ENTITIES_GET_COMPANY_LOCATION_LIST";
export const ENTITIES_GET_COMPANY_LOCATION_LIST_REJECTED = "ENTITIES_GET_COMPANY_LOCATION_LIST_REJECTED";
export const ENTITIES_GET_COMPANY_LOCATION_LIST_PENDING = "ENTITIES_GET_COMPANY_LOCATION_LIST_PENDING";
export const ENTITIES_GET_COMPANY_LOCATION_LIST_FULFILLED = "ENTITIES_GET_COMPANY_LOCATION_LIST_FULFILLED";

export const ENTITIES_DELETE_COMPANY_LOCATION = "ENTITIES_DELETE_COMPANY_LOCATION";
export const ENTITIES_DELETE_COMPANY_LOCATION_REJECTED = "ENTITIES_DELETE_COMPANY_LOCATION_REJECTED";
export const ENTITIES_DELETE_COMPANY_LOCATION_FULFILLED = "ENTITIES_DELETE_COMPANY_LOCATION_FULFILLED";

export const ENTITIES_PUT_COMPANY_LOCATION = "ENTITIES_PUT_COMPANY_LOCATION";
export const ENTITIES_PUT_COMPANY_LOCATION_PENDING = "ENTITIES_PUT_COMPANY_LOCATION_PENDING";
export const ENTITIES_PUT_COMPANY_LOCATION_FULFILLED = "ENTITIES_PUT_COMPANY_LOCATION_FULFILLED";
export const ENTITIES_PUT_COMPANY_LOCATION_REJECTED = "ENTITIES_PUT_COMPANY_LOCATION_REJECTED";

export const ENTITIES_POST_COMPANY_LOCATION = "ENTITIES_POST_COMPANY_LOCATION";
export const ENTITIES_POST_COMPANY_LOCATION_PENDING = "ENTITIES_POST_COMPANY_LOCATION_PENDING";
export const ENTITIES_POST_COMPANY_LOCATION_FULFILLED = "ENTITIES_POST_COMPANY_LOCATION_FULFILLED";
export const ENTITIES_POST_COMPANY_LOCATION_REJECTED = "ENTITIES_POST_COMPANY_LOCATION_REJECTED";

export const ENTITIES_GET_EMPLOYEE_LIST = "ENTITIES_GET_EMPLOYEE_LIST";
export const ENTITIES_GET_EMPLOYEE_LIST_PENDING = "ENTITIES_GET_EMPLOYEE_LIST_PENDING";
export const ENTITIES_GET_EMPLOYEE_LIST_FULFILLED = "ENTITIES_GET_EMPLOYEE_LIST_FULFILLED";
export const ENTITIES_GET_EMPLOYEE_LIST_REJECTED = "ENTITIES_GET_EMPLOYEE_LIST_REJECTED";

export const ENTITIES_POST_EMPLOYEE = "ENTITIES_POST_EMPLOYEE";
export const ENTITIES_POST_EMPLOYEE_PENDING = "ENTITIES_POST_EMPLOYEE_PENDING";
export const ENTITIES_POST_EMPLOYEE_FULFILLED = "ENTITIES_POST_EMPLOYEE_FULFILLED";
export const ENTITIES_POST_EMPLOYEE_REJECTED = "ENTITIES_POST_EMPLOYEE_REJECTED";

export const ENTITIES_PUT_EMPLOYEE = "ENTITIES_PUT_EMPLOYEE";
export const ENTITIES_PUT_EMPLOYEE_PENDING = "ENTITIES_PUT_EMPLOYEE_PENDING";
export const ENTITIES_PUT_EMPLOYEE_FULFILLED = "ENTITIES_PUT_EMPLOYEE_FULFILLED";
export const ENTITIES_PUT_EMPLOYEE_REJECTED = "ENTITIES_PUT_EMPLOYEE_REJECTED";

export const ENTITIES_GET_EMPLOYEE = "ENTITIES_GET_EMPLOYEE";
export const ENTITIES_GET_EMPLOYEE_FULFILLED = "ENTITIES_GET_EMPLOYEE_FULFILLED";
export const ENTITIES_GET_EMPLOYEE_REJECTED = "ENTITIES_GET_EMPLOYEE_REJECTED";

export const ENTITIES_GET_JOB_TITLE_LIST = "ENTITIES_GET_JOB_TITLE_LIST";
export const ENTITIES_GET_JOB_TITLE_LIST_PENDING = "ENTITIES_GET_JOB_TITLE_LIST_PENDING";
export const ENTITIES_GET_JOB_TITLE_LIST_FULFILLED = "ENTITIES_GET_JOB_TITLE_LIST_FULFILLED";
export const ENTITIES_GET_JOB_TITLE_LIST_REJECTED = "ENTITIES_GET_JOB_TITLE_LIST_REJECTED";

export const ENTITIES_PUT_JOB_TITLE = "ENTITIES_PUT_JOB_TITLE";
export const ENTITIES_PUT_JOB_TITLE_PENDING = "ENTITIES_PUT_JOB_TITLE_PENDING";
export const ENTITIES_PUT_JOB_TITLE_FULFILLED = "ENTITIES_PUT_JOB_TITLE_FULFILLED";
export const ENTITIES_PUT_JOB_TITLE_REJECTED = "ENTITIES_PUT_JOB_TITLE_REJECTED";

export const ENTITIES_GET_JOB_TEMPLATE_NAME_LIST = "ENTITIES_GET_JOB_TEMPLATE_NAME_LIST";
export const ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_PENDING = "ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_PENDING";
export const ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_FULFILLED = "ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_FULFILLED";
export const ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_REJECTED = "ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_REJECTED";

export const ENTITIES_GET_JOB_TEMPLATE_LIST = "ENTITIES_GET_JOB_TEMPLATE_LIST";
export const ENTITIES_GET_JOB_TEMPLATE_LIST_PENDING = "ENTITIES_GET_JOB_TEMPLATE_LIST_PENDING";
export const ENTITIES_GET_JOB_TEMPLATE_LIST_FULFILLED = "ENTITIES_GET_JOB_TEMPLATE_LIST_FULFILLED";
export const ENTITIES_GET_JOB_TEMPLATE_LIST_REJECTED = "ENTITIES_GET_JOB_TEMPLATE_LIST_REJECTED";

export const ENTITIES_POST_JOB_TEMPLATE = "ENTITIES_POST_JOB_TEMPLATE";
export const ENTITIES_POST_JOB_TEMPLATE_PENDING = "ENTITIES_POST_JOB_TEMPLATE_PENDING";
export const ENTITIES_POST_JOB_TEMPLATE_FULFILLED = "ENTITIES_POST_JOB_TEMPLATE_FULFILLED";
export const ENTITIES_POST_JOB_TEMPLATE_REJECTED = "ENTITIES_POST_JOB_TEMPLATE_REJECTED";

export const ENTITIES_PUT_JOB_TEMPLATE = "ENTITIES_PUT_JOB_TEMPLATE";
export const ENTITIES_PUT_JOB_TEMPLATE_PENDING = "ENTITIES_PUT_JOB_TEMPLATE_PENDING";
export const ENTITIES_PUT_JOB_TEMPLATE_FULFILLED = "ENTITIES_PUT_JOB_TEMPLATE_FULFILLED";
export const ENTITIES_PUT_JOB_TEMPLATE_REJECTED = "ENTITIES_PUT_JOB_TEMPLATE_REJECTED";

export const ENTITIES_GET_JOB_TEMPLATE = "ENTITIES_GET_JOB_TEMPLATE";
export const ENTITIES_GET_JOB_TEMPLATE_PENDING = "ENTITIES_GET_JOB_TEMPLATE_PENDING";
export const ENTITIES_GET_JOB_TEMPLATE_FULFILLED = "ENTITIES_GET_JOB_TEMPLATE_FULFILLED";
export const ENTITIES_GET_JOB_TEMPLATE_REJECTED = "ENTITIES_GET_JOB_TEMPLATE_REJECTED";

export const ENTITIES_GET_SKILL_LIST = "ENTITIES_GET_SKILL_LIST";
export const ENTITIES_GET_SKILL_LIST_FULFILLED = "ENTITIES_GET_SKILL_LIST_FULFILLED";
export const ENTITIES_GET_SKILL_LIST_REJECTED = "ENTITIES_GET_SKILL_LIST_REJECTED";

export const ENTITIES_GET_SKILL = "ENTITIES_GET_SKILL";
export const ENTITIES_GET_SKILL_PENDING = "ENTITIES_GET_SKILL_PENDING";
export const ENTITIES_GET_SKILL_FULFILLED = "ENTITIES_GET_SKILL_FULFILLED";
export const ENTITIES_GET_SKILL_REJECTED = "ENTITIES_GET_SKILL_REJECTED";

export const ENTITIES_GET_EXTERNAL_SKILL = "ENTITIES_GET_EXTERNAL_SKILL";
export const ENTITIES_GET_EXTERNAL_SKILL_PENDING = "ENTITIES_GET_EXTERNAL_SKILL_PENDING";
export const ENTITIES_GET_EXTERNAL_SKILL_FULFILLED = "ENTITIES_GET_EXTERNAL_SKILL_FULFILLED";
export const ENTITIES_GET_EXTERNAL_SKILL_REJECTED = "ENTITIES_GET_EXTERNAL_SKILL_REJECTED";

export const ENTITIES_GET_SKILL_TREE = "ENTITIES_GET_SKILL_TREE";
export const ENTITIES_GET_SKILL_TREE_PENDING = "ENTITIES_GET_SKILL_TREE_PENDING";
export const ENTITIES_GET_SKILL_TREE_FULFILLED = "ENTITIES_GET_SKILL_TREE_FULFILLED";
export const ENTITIES_GET_SKILL_TREE_REJECTED = "ENTITIES_GET_SKILL_TREE_REJECTED";

export const ENTITIES_POST_SKILL = "ENTITIES_POST_SKILL";
export const ENTITIES_POST_SKILL_PENDING = "ENTITIES_POST_SKILL_PENDING";
export const ENTITIES_POST_SKILL_FULFILLED = "ENTITIES_POST_SKILL_FULFILLED";
export const ENTITIES_POST_SKILL_REJECTED = "ENTITIES_POST_SKILL_REJECTED";

export const ENTITIES_PUT_SKILL = "ENTITIES_PUT_SKILL";
export const ENTITIES_PUT_SKILL_PENDING = "ENTITIES_PUT_SKILL_PENDING";
export const ENTITIES_PUT_SKILL_FULFILLED = "ENTITIES_PUT_SKILL_FULFILLED";
export const ENTITIES_PUT_SKILL_REJECTED = "ENTITIES_PUT_SKILL_REJECTED";

export const ENTITIES_PUT_EXTERNAL_SKILL = "ENTITIES_PUT_EXTERNAL_SKILL";
export const ENTITIES_PUT_EXTERNAL_SKILL_PENDING = "ENTITIES_PUT_EXTERNAL_SKILL_PENDING";
export const ENTITIES_PUT_EXTERNAL_SKILL_FULFILLED = "ENTITIES_PUT_EXTERNAL_SKILL_FULFILLED";
export const ENTITIES_PUT_EXTERNAL_SKILL_REJECTED = "ENTITIES_PUT_EXTERNAL_SKILL_REJECTED";

export const ENTITIES_DELETE_SKILL = "ENTITIES_DELETE_SKILL";
export const ENTITIES_DELETE_SKILL_REJECTED = "ENTITIES_DELETE_SKILL_REJECTED";
export const ENTITIES_DELETE_SKILL_FULFILLED = "ENTITIES_DELETE_SKILL_FULFILLED";

export const ENTITIES_GET_OPEN_VACANCY_LIST = "ENTITIES_GET_OPEN_VACANCY_LIST";
export const ENTITIES_GET_OPEN_VACANCY_LIST_PENDING = "ENTITIES_GET_OPEN_VACANCY_LIST_PENDING";
export const ENTITIES_GET_OPEN_VACANCY_LIST_REJECTED = "ENTITIES_GET_OPEN_VACANCY_LIST_REJECTED";
export const ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED = "ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED";

export const ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH = "ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH";
export const ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_PENDING = "ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_PENDING";
export const ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_REJECTED = "ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_REJECTED";
export const ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_FULFILLED = "ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_FULFILLED";

export const ENTITIES_GET_ARCHIVED_VACANCY_LIST = "ENTITIES_GET_ARCHIVED_VACANCY_LIST";
export const ENTITIES_GET_ARCHIVED_VACANCY_LIST_PENDING = "ENTITIES_GET_ARCHIVED_VACANCY_LIST_PENDING";
export const ENTITIES_GET_ARCHIVED_VACANCY_LIST_REJECTED = "ENTITIES_GET_ARCHIVED_VACANCY_LIST_REJECTED";
export const ENTITIES_GET_ARCHIVED_VACANCY_LIST_FULFILLED = "ENTITIES_GET_ARCHIVED_VACANCY_LIST_FULFILLED";

export const ENTITIES_GET_VACANCY_LIST_OF_COMPANY = "ENTITIES_GET_VACANCY_LIST_OF_COMPANY";
export const ENTITIES_GET_VACANCY_LIST_OF_COMPANY_PENDING = "ENTITIES_GET_VACANCY_LIST_OF_COMPANY_PENDING";
export const ENTITIES_GET_VACANCY_LIST_OF_COMPANY_REJECTED = "ENTITIES_GET_VACANCY_LIST_OF_COMPANY_REJECTED";
export const ENTITIES_GET_VACANCY_LIST_OF_COMPANY_FULFILLED = "ENTITIES_GET_VACANCY_LIST_OF_COMPANY_FULFILLED";

export const ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT = "ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT";
export const ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_PENDING = "ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_PENDING";
export const ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_REJECTED = "ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_REJECTED";
export const ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_FULFILLED = "ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT_FULFILLED";

export const ENTITIES_POST_VACANCY = "ENTITIES_POST_VACANCY";
export const ENTITIES_POST_VACANCY_REJECTED = "ENTITIES_POST_VACANCY_REJECTED";
export const ENTITIES_POST_VACANCY_PENDING = "ENTITIES_POST_VACANCY_PENDING";
export const ENTITIES_POST_VACANCY_FULFILLED = "ENTITIES_POST_VACANCY_FULFILLED";

export const ENTITIES_GET_VACANCY = "ENTITIES_GET_VACANCY";
export const ENTITIES_GET_VACANCY_REJECTED = "ENTITIES_GET_VACANCY_REJECTED";
export const ENTITIES_GET_VACANCY_PENDING = "ENTITIES_GET_VACANCY_PENDING";
export const ENTITIES_GET_VACANCY_FULFILLED = "ENTITIES_GET_VACANCY_FULFILLED";

export const ENTITIES_PUT_VACANCY = "ENTITIES_PUT_VACANCY";
export const ENTITIES_PUT_VACANCY_PENDING = "ENTITIES_PUT_VACANCY_PENDING";
export const ENTITIES_PUT_VACANCY_REJECTED = "ENTITIES_PUT_VACANCY_REJECTED";
export const ENTITIES_PUT_VACANCY_FULFILLED = "ENTITIES_PUT_VACANCY_FULFILLED";

export const ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT = "ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT";
export const ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_PENDING = "ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_PENDING";
export const ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_REJECTED = "ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_REJECTED";
export const ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_FULFILLED = "ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT_FULFILLED";

export const ENTITIES_DELETE_VACANCY = "ENTITIES_DELETE_VACANCY";
export const ENTITIES_DELETE_VACANCY_REJECTED = "ENTITIES_DELETE_VACANCY_REJECTED";
export const ENTITIES_DELETE_VACANCY_FULFILLED = "ENTITIES_DELETE_VACANCY_FULFILLED";

export const ENTITIES_GET_RECRUITING_GROUPED_EVALUATION = "ENTITIES_GET_RECRUITING_GROUPED_EVALUATION";
export const ENTITIES_GET_RECRUITING_GROUPED_EVALUATION_PENDING = "ENTITIES_GET_RECRUITING_GROUPED_EVALUATION_PENDING";
export const ENTITIES_GET_RECRUITING_GROUPED_EVALUATION_FULFILLED = "ENTITIES_GET_RECRUITING_GROUPED_EVALUATION_FULFILLED";
export const ENTITIES_GET_RECRUITING_GROUPED_EVALUATION_REJECTED = "ENTITIES_GET_RECRUITING_GROUPED_EVALUATION_REJECTED";

export const ENTITIES_GET_INVOICE_GROUPED_EVALUATION = "ENTITIES_GET_INVOICE_GROUPED_EVALUATION";
export const ENTITIES_GET_INVOICE_GROUPED_EVALUATION_PENDING = "ENTITIES_GET_INVOICE_GROUPED_EVALUATION_PENDING";
export const ENTITIES_GET_INVOICE_GROUPED_EVALUATION_FULFILLED = "ENTITIES_GET_INVOICE_GROUPED_EVALUATION_FULFILLED";
export const ENTITIES_GET_INVOICE_GROUPED_EVALUATION_REJECTED = "ENTITIES_GET_INVOICE_GROUPED_EVALUATION_REJECTED";

export const ENTITIES_GET_TARGET_BY_MONTH_LIST = "ENTITIES_GET_TARGET_BY_MONTH_LIST";
export const ENTITIES_GET_TARGET_BY_MONTH_LIST_PENDING = "ENTITIES_GET_TARGET_BY_MONTH_LIST_PENDING";
export const ENTITIES_GET_TARGET_BY_MONTH_LIST_FULFILLED = "ENTITIES_GET_TARGET_BY_MONTH_LIST_FULFILLED";
export const ENTITIES_GET_TARGET_BY_MONTH_LIST_REJECTED = "ENTITIES_GET_TARGET_BY_MONTH_LIST_REJECTED";

export const ENTITIES_GET_RECRUITING_LIST = "ENTITIES_GET_RECRUITING_LIST";
export const ENTITIES_GET_RECRUITING_LIST_PENDING = "ENTITIES_GET_RECRUITING_LIST_PENDING";
export const ENTITIES_GET_RECRUITING_LIST_FULFILLED = "ENTITIES_GET_RECRUITING_LIST_FULFILLED";
export const ENTITIES_GET_RECRUITING_LIST_REJECTED = "ENTITIES_GET_RECRUITING_LIST_REJECTED";

export const ENTITIES_GET_PROCESS_AGGREGATION_LIST = "ENTITIES_GET_PROCESS_AGGREGATION_LIST";
export const ENTITIES_GET_PROCESS_AGGREGATION_LIST_PENDING = "ENTITIES_GET_PROCESS_AGGREGATION_LIST_PENDING";
export const ENTITIES_GET_PROCESS_AGGREGATION_LIST_FULFILLED = "ENTITIES_GET_PROCESS_AGGREGATION_LIST_FULFILLED";
export const ENTITIES_GET_PROCESS_AGGREGATION_LIST_REJECTED = "ENTITIES_GET_PROCESS_AGGREGATION_LIST_REJECTED";

export const ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE = "ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE";
export const ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_PENDING = "ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_PENDING";
export const ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_REJECTED = "ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_REJECTED";
export const ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_FULFILLED = "ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_FULFILLED";

export const ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS = "ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS";
export const ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_PENDING = "ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_PENDING";
export const ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_REJECTED = "ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_REJECTED";
export const ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_FULFILLED = "ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_FULFILLED";

export const ENTITIES_GET_RECRUITING_LIST_BY_VACANCY = "ENTITIES_GET_RECRUITING_LIST_BY_VACANCY";
export const ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_PENDING = "ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_PENDING";
export const ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED = "ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED";
export const ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_REJECTED = "ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_REJECTED";

export const ENTITIES_GET_RECRUITING_LIST_BY_COMPANY = "ENTITIES_GET_RECRUITING_LIST_BY_COMPANY";
export const ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_PENDING = "ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_PENDING";
export const ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_FULFILLED = "ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_FULFILLED";
export const ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_REJECTED = "ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_REJECTED";

export const ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT = "ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT";
export const ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_PENDING = "ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_PENDING";
export const ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_FULFILLED = "ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_FULFILLED";
export const ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_REJECTED = "ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT_REJECTED";

export const ENTITIES_GET_RECRUITING = "ENTITIES_GET_RECRUITING";
export const ENTITIES_GET_RECRUITING_REJECTED = "ENTITIES_GET_RECRUITING_REJECTED";
export const ENTITIES_GET_RECRUITING_PENDING = "ENTITIES_GET_RECRUITING_PENDING";
export const ENTITIES_GET_RECRUITING_FULFILLED = "ENTITIES_GET_RECRUITING_FULFILLED";

export const ENTITIES_POST_RECRUITING = "ENTITIES_POST_RECRUITING";
export const ENTITIES_POST_RECRUITING_REJECTED = "ENTITIES_POST_RECRUITING_REJECTED";
export const ENTITIES_POST_RECRUITING_PENDING = "ENTITIES_POST_RECRUITING_PENDING";
export const ENTITIES_POST_RECRUITING_FULFILLED = "ENTITIES_POST_RECRUITING_FULFILLED";

export const ENTITIES_PUT_RECRUITING = "ENTITIES_PUT_RECRUITING";
export const ENTITIES_PUT_RECRUITING_REJECTED = "ENTITIES_PUT_RECRUITING_REJECTED";
export const ENTITIES_PUT_RECRUITING_PENDING = "ENTITIES_PUT_RECRUITING_PENDING";
export const ENTITIES_PUT_RECRUITING_FULFILLED = "ENTITIES_PUT_RECRUITING_FULFILLED";

export const ENTITIES_DELETE_RECRUITING = "ENTITIES_DELETE_RECRUITING";
export const ENTITIES_DELETE_RECRUITING_REJECTED = "ENTITIES_DELETE_RECRUITING_REJECTED";
export const ENTITIES_DELETE_RECRUITING_FULFILLED = "ENTITIES_DELETE_RECRUITING_FULFILLED";

export const ENTITIES_PUT_ACTIVITY = "ENTITIES_PUT_ACTIVITY";
export const ENTITIES_PUT_ACTIVITY_PENDING = "ENTITIES_PUT_ACTIVITY_PENDING";
export const ENTITIES_PUT_ACTIVITY_FULFILLED = "ENTITIES_PUT_ACTIVITY_FULFILLED";
export const ENTITIES_PUT_ACTIVITY_REJECTED = "ENTITIES_PUT_ACTIVITY_REJECTED";

export const ENTITIES_POST_ACTIVITY = "ENTITIES_POST_ACTIVITY";
export const ENTITIES_POST_ACTIVITY_PENDING = "ENTITIES_POST_ACTIVITY_PENDING";
export const ENTITIES_POST_ACTIVITY_FULFILLED = "ENTITIES_POST_ACTIVITY_FULFILLED";
export const ENTITIES_POST_ACTIVITY_REJECTED = "ENTITIES_POST_ACTIVITY_REJECTED";

export const ENTITIES_POST_SUGGESTION_ACTIVITY = "ENTITIES_POST_SUGGESTION_ACTIVITY";
export const ENTITIES_POST_SUGGESTION_ACTIVITY_PENDING = "ENTITIES_POST_SUGGESTION_ACTIVITY_PENDING";
export const ENTITIES_POST_SUGGESTION_ACTIVITY_FULFILLED = "ENTITIES_POST_SUGGESTION_ACTIVITY_FULFILLED";
export const ENTITIES_POST_SUGGESTION_ACTIVITY_REJECTED = "ENTITIES_POST_SUGGESTION_ACTIVITY_REJECTED";

export const ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION = "ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION";
export const ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_FULFILLED = "ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_FULFILLED";
export const ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_PENDING = "ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_PENDING";
export const ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_REJECTED = "ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_REJECTED";

export const ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH = "ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH";
export const ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_FULFILLED = "ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_FULFILLED";
export const ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_PENDING = "ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_PENDING";
export const ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_REJECTED = "ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_REJECTED";

export const ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH = "ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH";
export const ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_FULFILLED = "ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_FULFILLED";
export const ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_PENDING = "ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_PENDING";
export const ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_REJECTED = "ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_REJECTED";

export const ENTITIES_GET_CANDIDATES_CREATED_NEW = "ENTITIES_GET_CANDIDATES_CREATED_NEW";
export const ENTITIES_GET_CANDIDATES_CREATED_NEW_FULFILLED = "ENTITIES_GET_CANDIDATES_CREATED_NEW_FULFILLED";
export const ENTITIES_GET_CANDIDATES_CREATED_NEW_PENDING = "ENTITIES_GET_CANDIDATES_CREATED_NEW_PENDING";
export const ENTITIES_GET_CANDIDATES_CREATED_NEW_REJECTED = "ENTITIES_GET_CANDIDATES_CREATED_NEW_REJECTED";

export const ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY = "ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY";
export const ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_PENDING = "ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_PENDING";
export const ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_FULFILLED = "ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_FULFILLED";
export const ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_REJECTED = "ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_REJECTED";

export const ENTITIES_GET_SUGGESTION_LIST = "ENTITIES_GET_SUGGESTION_LIST";
export const ENTITIES_GET_SUGGESTION_LIST_PENDING = "ENTITIES_GET_SUGGESTION_LIST_PENDING";
export const ENTITIES_GET_SUGGESTION_LIST_REJECTED = "ENTITIES_GET_SUGGESTION_LIST_REJECTED";
export const ENTITIES_GET_SUGGESTION_LIST_FULFILLED = "ENTITIES_GET_SUGGESTION_LIST_FULFILLED";

export const ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY = "ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY";
export const ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_PENDING = "ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_PENDING";
export const ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_REJECTED = "ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_REJECTED";
export const ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED = "ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED";

export const ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS = "ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS";
export const ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_PENDING = "ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_PENDING";
export const ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_REJECTED = "ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_REJECTED";
export const ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_FULFILLED = "ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_FULFILLED";

export const ENTITIES_PUT_SUGGESTION = "ENTITIES_PUT_SUGGESTION";
export const ENTITIES_PUT_SUGGESTION_PENDING = "ENTITIES_PUT_SUGGESTION_PENDING";
export const ENTITIES_PUT_SUGGESTION_FULFILLED = "ENTITIES_PUT_SUGGESTION_FULFILLED";
export const ENTITIES_PUT_SUGGESTION_REJECTED = "ENTITIES_PUT_SUGGESTION_REJECTED";

export const ENTITIES_UPDATE_SUGGESTION_IN_STORE = "ENTITIES_UPDATE_SUGGESTION_IN_STORE";

export const ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST = "ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST";
export const ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_PENDING = "ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_PENDING";
export const ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_FULFILLED = "ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_FULFILLED";
export const ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_REJECTED = "ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_REJECTED";

export const ENTITIES_PUT_ACCEPT_SUGGESTION = "ENTITIES_PUT_ACCEPT_SUGGESTION";
export const ENTITIES_PUT_ACCEPT_SUGGESTION_PENDING = "ENTITIES_PUT_ACCEPT_SUGGESTION_PENDING";
export const ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED = "ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED";
export const ENTITIES_PUT_ACCEPT_SUGGESTION_REJECTED = "ENTITIES_PUT_ACCEPT_SUGGESTION_REJECTED";

export const ENTITIES_DELETE_SUGGESTION = "ENTITIES_DELETE_SUGGESTION";
export const ENTITIES_DELETE_SUGGESTION_PENDING = "ENTITIES_DELETE_SUGGESTION_PENDING";
export const ENTITIES_DELETE_SUGGESTION_FULFILLED = "ENTITIES_DELETE_SUGGESTION_FULFILLED";
export const ENTITIES_DELETE_SUGGESTION_REJECTED = "ENTITIES_DELETE_SUGGESTION_REJECTED";

export const ENTITIES_PUT_DECLINE_SUGGESTION = "ENTITIES_PUT_DECLINE_SUGGESTION";
export const ENTITIES_PUT_DECLINE_SUGGESTION_PENDING = "ENTITIES_PUT_DECLINE_SUGGESTION_PENDING";
export const ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED = "ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED";
export const ENTITIES_PUT_DECLINE_SUGGESTION_REJECTED = "ENTITIES_PUT_DECLINE_SUGGESTION_REJECTED";

export const ENTITIES_PUT_REOPEN_SUGGESTION = "ENTITIES_PUT_REOPEN_SUGGESTION";
export const ENTITIES_PUT_REOPEN_SUGGESTION_PENDING = "ENTITIES_PUT_REOPEN_SUGGESTION_PENDING";
export const ENTITIES_PUT_REOPEN_SUGGESTION_FULFILLED = "ENTITIES_PUT_REOPEN_SUGGESTION_FULFILLED";
export const ENTITIES_PUT_REOPEN_SUGGESTION_REJECTED = "ENTITIES_PUT_REOPEN_SUGGESTION_REJECTED";

export const ENTITIES_POST_SUGGESTION = "ENTITIES_POST_SUGGESTION";
export const ENTITIES_POST_SUGGESTION_PENDING = "ENTITIES_POST_SUGGESTION_PENDING";
export const ENTITIES_POST_SUGGESTION_FULFILLED = "ENTITIES_POST_SUGGESTION_FULFILLED";
export const ENTITIES_POST_SUGGESTION_REJECTED = "ENTITIES_POST_SUGGESTION_REJECTED";

export const ENTITIES_GET_SUGGESTION = "ENTITIES_GET_SUGGESTION";
export const ENTITIES_GET_SUGGESTION_PENDING = "ENTITIES_GET_SUGGESTION_PENDING";
export const ENTITIES_GET_SUGGESTION_FULFILLED = "ENTITIES_GET_SUGGESTION_FULFILLED";
export const ENTITIES_GET_SUGGESTION_REJECTED = "ENTITIES_GET_SUGGESTION_REJECTED";

export const ENTITIES_GET_MAIL_ADDRESSES = "ENTITIES_GET_MAIL_ADDRESSES";
export const ENTITIES_GET_MAIL_ADDRESSES_PENDING = "ENTITIES_GET_MAIL_ADDRESSES_PENDING";
export const ENTITIES_GET_MAIL_ADDRESSES_FULFILLED = "ENTITIES_GET_MAIL_ADDRESSES_FULFILLED";

export const ENTITIES_GET_ACTIVITY = "ENTITIES_GET_ACTIVITY";
export const ENTITIES_GET_ACTIVITY_PENDING = "ENTITIES_GET_ACTIVITY_PENDING";
export const ENTITIES_GET_ACTIVITY_REJECTED = "ENTITIES_GET_ACTIVITY_REJECTED";
export const ENTITIES_GET_ACTIVITY_FULFILLED = "ENTITIES_GET_ACTIVITY_FULFILLED";

export const ENTITIES_GET_SUCCESS_ACTIVITY_LIST = "ENTITIES_GET_SUCCESS_ACTIVITY_LIST";
export const ENTITIES_GET_SUCCESS_ACTIVITY_LIST_PENDING = "ENTITIES_GET_SUCCESS_ACTIVITY_LIST_PENDING";
export const ENTITIES_GET_SUCCESS_ACTIVITY_LIST_REJECTED = "ENTITIES_GET_SUCCESS_ACTIVITY_LIST_REJECTED";
export const ENTITIES_GET_SUCCESS_ACTIVITY_LIST_FULFILLED = "ENTITIES_GET_SUCCESS_ACTIVITY_LIST_FULFILLED";

export const ENTITIES_GET_ACTIVITY_LIST = "ENTITIES_GET_ACTIVITY_LIST";
export const ENTITIES_GET_ACTIVITY_LIST_PENDING = "ENTITIES_GET_ACTIVITY_LIST_PENDING";
export const ENTITIES_GET_ACTIVITY_LIST_REJECTED = "ENTITIES_GET_ACTIVITY_LIST_REJECTED";
export const ENTITIES_GET_ACTIVITY_LIST_FULFILLED = "ENTITIES_GET_ACTIVITY_LIST_FULFILLED";

export const ENTITIES_GET_ACTIVITY_CHAT_LIST = "ENTITIES_GET_ACTIVITY_CHAT_LIST";
export const ENTITIES_GET_ACTIVITY_CHAT_LIST_PENDING = "ENTITIES_GET_ACTIVITY_CHAT_LIST_PENDING";
export const ENTITIES_GET_ACTIVITY_CHAT_LIST_REJECTED = "ENTITIES_GET_ACTIVITY_CHAT_LIST_REJECTED";
export const ENTITIES_GET_ACTIVITY_CHAT_LIST_FULFILLED = "ENTITIES_GET_ACTIVITY_CHAT_LIST_FULFILLED";

export const ENTITIES_GET_WEB_VACANCY = "ENTITIES_GET_WEB_VACANCY";
export const ENTITIES_GET_WEB_VACANCY_PENDING = "ENTITIES_GET_WEB_VACANCY_PENDING";
export const ENTITIES_GET_WEB_VACANCY_REJECTED = "ENTITIES_GET_WEB_VACANCY_REJECTED";
export const ENTITIES_GET_WEB_VACANCY_FULFILLED = "ENTITIES_GET_WEB_VACANCY_FULFILLED";

export const ENTITIES_PUT_WEB_VACANCY = "ENTITIES_PUT_WEB_VACANCY";
export const ENTITIES_PUT_WEB_VACANCY_PENDING = "ENTITIES_PUT_WEB_VACANCY_PENDING";
export const ENTITIES_PUT_WEB_VACANCY_REJECTED = "ENTITIES_PUT_WEB_VACANCY_REJECTED";
export const ENTITIES_PUT_WEB_VACANCY_FULFILLED = "ENTITIES_PUT_WEB_VACANCY_FULFILLED";

export const ENTITIES_GET_WEB_VACANCY_LIST = "ENTITIES_GET_WEB_VACANCY_LIST";
export const ENTITIES_GET_WEB_VACANCY_LIST_PENDING = "ENTITIES_GET_WEB_VACANCY_LIST_PENDING";
export const ENTITIES_GET_WEB_VACANCY_LIST_REJECTED = "ENTITIES_GET_WEB_VACANCY_LIST_REJECTED";
export const ENTITIES_GET_WEB_VACANCY_LIST_FULFILLED = "ENTITIES_GET_WEB_VACANCY_LIST_FULFILLED";

export const ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY = "ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY";
export const ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_PENDING = "ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_PENDING";
export const ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_REJECTED = "ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_REJECTED";
export const ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_FULFILLED = "ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_FULFILLED";

export const ENTITIES_DELETE_ACTIVITY = "ENTITIES_DELETE_ACTIVITY";
export const ENTITIES_DELETE_ACTIVITY_REJECTED = "ENTITIES_DELETE_ACTIVITY_REJECTED";
export const ENTITIES_DELETE_ACTIVITY_FULFILLED = "ENTITIES_DELETE_ACTIVITY_FULFILLED";

/**
 * VIEW ACTIONS
 */
export const resetRecruitingStatusChangedToContractAction = (): IResetRecruitingStatusChangedToContractAction => ({
    type: ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_CONTRACT,
});
export const resetRecruitingStatusChangedToAbortAction = (): IResetRecruitingStatusChangedToAbortAction => ({
    type: ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_ABORT,
});

/**
 * WEB VACANCY
 */
export const getWebVacancyAction = (token: string, webVacancyId: number) =>
    asyncActionCreator(
        ENTITIES_GET_WEB_VACANCY,
        () => ClientApi.request(getWebVacancyRouteConfig, { token, webVacancyId })
    );

export const getWebVacancyListAction = (token: string, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_WEB_VACANCY_LIST,
        () => ClientApi.request(getWebVacancyListRouteConfig, { token, ...parameters })
    );

export const getWebVacancyListOfCompanyAction = (token: string, companyId: number) =>
    asyncActionCreator(
        ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY,
        () => ClientApi.request(getWebVacancyListRouteConfig, {
            token,
            companyIds: [companyId],
            start: 0,
            limit: 0,
            orderBy: 'dateOfPublication',
            orderDirection: ESortingOptions.Descending,
            onlyNotLinked: true
        })
    );

export const updateWebVacancyAction = (token: string, id: number, skills: undefined|IVacancySkillAssign[], hide: undefined|boolean) =>
    asyncActionCreator(
        ENTITIES_PUT_WEB_VACANCY,
        () => ClientApi.request(putWebVacancyRouteConfig, {
            token,
            id,
            skills,
            hide
        })
    );
/**
 * ACTIVITY
 */
export const addActivityAction = (token: string, parameters: IActivityForApi, successCallback?: () => void) =>
    asyncActionCreator(
        ENTITIES_POST_ACTIVITY,
        () => ClientApi.request(postActivityRouteConfig, { token, ...parameters }),
        undefined,
        successCallback
    );

export const updateActivityAction = (token: string, parameters: IActivityForApi, successCallback?: () => void) =>
    asyncActionCreator(
        ENTITIES_PUT_ACTIVITY,
        () => ClientApi.request(putActivityRouteConfig, { token, ...parameters }),
        undefined,
        successCallback
    );

export const addInterviewAction = (token: string, parameters: IAddInterviewForApi) =>
    asyncActionCreator(
        ENTITIES_POST_ACTIVITY,
        () => ClientApi.request(postInterviewRouteConfig, { token, ...parameters })
    );

export const updateInterviewAction = (token: string, parameters: IUpdateInterviewForApi, successCallback?: () => void) =>
    asyncActionCreator(
        ENTITIES_PUT_ACTIVITY,
        () => ClientApi.request(putInterviewRouteConfig, { token, ...parameters }),
        undefined,
        successCallback
    );

export const addContractOfferAction = (token: string, parameters: IContractOfferForApi) =>
    asyncActionCreator(
        ENTITIES_POST_ACTIVITY,
        () => ClientApi.request(postContractOfferRouteConfig, { token, ...parameters })
    );

export const updateContractOfferAction = (token: string, parameters: IContractOfferForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_ACTIVITY,
        () => ClientApi.request(putContractOfferRouteConfig, { token, ...parameters })
    );

export const deleteActivityAction = (token: string, activityId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_ACTIVITY,
        () => ClientApi.request(deleteActivityRouteConfig, {
            token,
            id: activityId,
        }),
        {
            activityId
        }
    );

export const getActivityAction = (token: string, activityId: number) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY,
        () => ClientApi.request(getActivityRouteConfig, { token, activityId })
    );

export const getActivityChatListAction = (token: string, parameters?: Partial<IGetRequestObject>) => {
    return asyncActionCreator(
        ENTITIES_GET_ACTIVITY_CHAT_LIST,
        () => ClientApi.request(getActivityChatListRouteConfig, {
            token,
            modules: [
                EActivityModule.mail,
                EActivityModule.recruiting,
                EActivityModule.recruitingSuggestion,
                EActivityModule.candidate,
                EActivityModule.company,
                EActivityModule.vacancy,
                EActivityModule.application
            ],
            dateTo: 'tomorrow',
            ...parameters
        }),
        {
            activityEntity: EEntities.Activity
        }
    );
}

export const getActivityChatListForSuggestionAction = (token: string, suggestionId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_CHAT_LIST,
        () => ClientApi.request(getActivityChatListRouteConfig, {
            token,
            suggestionId,
            ...parameters,
        }),
        {
            entityId: suggestionId,
            activityEntity: EEntities.SuggestionActivity
        }
    );

export const getActivityChatListForVacancyAction = (token: string, vacancyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_CHAT_LIST,
        () => ClientApi.request(getActivityChatListRouteConfig, {
            token,
            vacancyId,
            ...parameters,
        }),
        {
            entityId: vacancyId,
            activityEntity: EEntities.VacancyActivity
        }
    );

export const getActivityChatListForCompanyAction = (token: string, companyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_CHAT_LIST,
        () => ClientApi.request(getActivityChatListRouteConfig, {
            token,
            companyId,
            ...parameters,
        }),
        {
            entityId: companyId,
            activityEntity: EEntities.CompanyActivity
        }
    );

export const getActivityChatListForCompanyContactAction = (token: string, companyContactId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_CHAT_LIST,
        () => ClientApi.request(getActivityChatListRouteConfig, {
            token,
            companyContactId,
            ...parameters,
        }),
        {
            entityId: companyContactId,
            activityEntity: EEntities.CompanyContactActivity
        }
    );

export const getActivityChatListForCandidateAction = (token: string, candidateId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_CHAT_LIST,
        () => ClientApi.request(getActivityChatListRouteConfig, {
            token,
            candidateId,
            ...parameters,
        }),
        {
            entityId: candidateId,
            activityEntity: EEntities.CandidateActivity
        }
    );

export const getActivityChatListForRecruitingAction = (token: string, recruitingId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_CHAT_LIST,
        () => ClientApi.request(getActivityChatListRouteConfig, {
            token,
            recruitingId,
            ...parameters,
        }),
        {
            entityId: recruitingId,
            activityEntity: EEntities.RecruitingActivity
        }
    );

export const getActivityListAction = (token: string, parameters?: Partial<IGetRequestObject>) => {
    return asyncActionCreator(
        ENTITIES_GET_ACTIVITY_LIST,
        () => ClientApi.request(getActivityListRouteConfig, {
            token,
            withTimes: true,
            ...parameters
        }),
        {
            activityEntity: EEntities.ActivityList
        }
    );
}

export const getActivityListForCandidateAction = (token: string, candidateId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_LIST,
        () => ClientApi.request(getActivityListForCandidateRouteConfig, {
            token,
            candidateId,
            ...parameters,
        }),
        {
            entityId: candidateId,
            activityEntity: EEntities.CandidateActivity
        }
    );

export const getActivityListForApplicationAction = (token: string, applicationId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_LIST,
        () => ClientApi.request(getActivityListForApplicationRouteConfig, {
            token,
            applicationId,
            ...parameters,
        }),
        {
            entityId: applicationId,
            activityEntity: EEntities.Application
        }
    );

export const getActivityListForVacancyAction = (token: string, vacancyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_LIST,
        () => ClientApi.request(getActivityListForVacancyRouteConfig, {
            token,
            vacancyId,
            ...parameters,
        }),
        {
            entityId: vacancyId,
            activityEntity: EEntities.VacancyActivity
        }
    );

export const getActivityListForSuccessListAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_SUCCESS_ACTIVITY_LIST,
        () => ClientApi.request(getSuccessActivityListRouteConfig, {
            token
        })
    );

export const getActivityListForWebVacancyAction = (token: string, webVacancyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_LIST,
        () => ClientApi.request(getActivityListForWebVacancyRouteConfig, {
            token,
            webVacancyId,
            ...parameters,
        }),
        {
            entityId: webVacancyId,
            activityEntity: EEntities.WebVacancyActivity
        }
    );

export const getActivityListForSuggestionAction = (token: string, suggestionId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_LIST,
        () => ClientApi.request(getActivityListForSuggestionRouteConfig, {
            token,
            suggestionId,
            ...parameters,
        }),
        {
            entityId: suggestionId,
            activityEntity: EEntities.SuggestionActivity
        }
    );

export const getActivityListForTodoAction = (token: string, todoId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ACTIVITY_LIST,
        () => ClientApi.request(getActivityListForTodoRouteConfig, {
            token,
            todoId,
            ...parameters,
        }),
        {
            entityId: todoId,
            activityEntity: EEntities.TodoActivity
        }
    );


/**
 * PROCESS AGGREGATION
 */
export const getProcessAggregationListByCandidateAction = (token: string, candidateId?: number) =>
    asyncActionCreator(
        ENTITIES_GET_PROCESS_AGGREGATION_LIST,
        () => ClientApi.request(getProcessAggregationListByCandidateRouteConfig, {
            token,
            candidateId
        }),{
            candidateId
        }
    );

/**
 * RECRUITING
 */

export enum EGroupEvaluationGroupBy {
    GROUP_BY_EMPLOYEE,
    GROUP_BY_COMPANY,
    GROUP_BY_MONTH,
    GROUP_BY_MONTH_SUMMED_UP,
    GROUP_BY_CANDIDATE_SOURCE,
    GROUP_BY_BRANCH
}

export const getRecruitingGroupedEvaluationAction = (token: string, year: number, groupBy: EGroupEvaluationGroupBy, employeeId?: number) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING_GROUPED_EVALUATION,
        () => ClientApi.request(getRecruitingGroupedEvaluationRouteConfig, {
            token,
            year,
            groupBy,
            employeeId
        }), {
            groupBy,
            lastYear: false
        }
    );

export const getRecruitingGroupedEvaluationForLastYearAction = (token: string, year: number, groupBy: EGroupEvaluationGroupBy, employeeId?: number) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING_GROUPED_EVALUATION,
        () => ClientApi.request(getRecruitingGroupedEvaluationRouteConfig, {
            token,
            year,
            groupBy,
            employeeId
        }), {
            groupBy,
            lastYear: true
        }
    );

export const getTargetByMonthListAction = (token: string, year: number, employeeId?: number) =>
    asyncActionCreator(
        ENTITIES_GET_TARGET_BY_MONTH_LIST,
        () => ClientApi.request(getTargetListByMonthRouteConfig, {
            token,
            year,
            employeeId
        })
    );

export const getRecruitingListAction = (token: string, parameters: Partial<IGetRequestObject>, status: ERecruitingStatus) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING_LIST,
        () => ClientApi.request(getRecruitingListRouteConfig, {
            token,
            ...parameters,
            status: status === ERecruitingStatus.STATUS_CONTRACT ? undefined : status,
            statusArray: status === ERecruitingStatus.STATUS_CONTRACT ? [ERecruitingStatus.STATUS_CONTRACT, ERecruitingStatus.STATUS_FINISH, ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD] : undefined
        }),
        {
            status
        }
    );

export const getRecruitingsForCompanyAction = (token: string, companyId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING_LIST_BY_COMPANY,
        () => ClientApi.request(getRecruitingListRouteConfig, {
            token,
            companyId,
            ...parameters
        }),
        {
            companyId
        }
    );


export const getRecruitingsForCompanyContactAction = (token: string, companyContactId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_CONTACT,
        () => ClientApi.request(getRecruitingListRouteConfig, {
            token,
            companyContactId,
            ...parameters
        }),
        {
            companyContactId
        }
    );

export const getRecruitingsForVacancyAction = (token: string, vacancyId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING_LIST_BY_VACANCY,
        () => ClientApi.request(getRecruitingListRouteConfig, {
            token,
            vacancyId,
            ...parameters
        }), {
            vacancyId
        }
    );

export const getRecruitingsForEmployeeAction = (token: string, employeeId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE,
        () => ClientApi.request(getRecruitingListRouteConfig, {
            token,
            employeeId,
            orderBy: parameters.orderBy,
            orderDirection: parameters.orderDirection,
            filterFastSearch: '',
            start: 0,
            limit: 0,
            statusArray: [ERecruitingStatus.STATUS_SUGGEST, ERecruitingStatus.STATUS_INTERVIEW]
        })
    );

export const getRecruitingsOfLast7DaysAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS,
        () => ClientApi.request(getRecruitingListRouteConfig, {
            token,
            ...parameters,
            start: 0,
            limit: 0,
            dateFrom: '-' + parameters.days + ' days',
        })
    );

export const getRecruitingAction = (token: string, recruitingId: number) =>
    asyncActionCreator(
        ENTITIES_GET_RECRUITING,
        () => ClientApi.request(getRecruitingRouteConfig, {
            token,
            recruitingId,
        })
    );

export const startRecruitingAction = (
    type: string,
    jobTitle: string,
    token: string,
    companyId: null|number,
    vacancyId: null|number,
    candidateId: number,
    responsibleEmployeeId: number,
    suggestedByEmployeeId: number,
    companyLocationId: null|number,
    source: ECandidateSource,
    openRecruiting: boolean,
    applicationId: null|number,
    webVacancyId: null|number,
    url: null|string,
    desiredSalary: null|number,
    sharedDocumentIds: number[]
) =>
    asyncActionCreator(
        ENTITIES_POST_RECRUITING,
        () => ClientApi.request(postRecruitingRouteConfig, {
            type,
            token,
            companyId,
            jobTitle,
            vacancyId,
            candidateId,
            responsibleEmployeeId,
            suggestedByEmployeeId,
            companyLocationId,
            source,
            applicationId,
            webVacancyId,
            url,
            desiredSalary,
            sharedDocumentIds
        }),
        openRecruiting
    );

export const updateRecruitingAction = (token: string, parameters: Partial<IRecruitingForAPI>) =>
    asyncActionCreator(
        ENTITIES_PUT_RECRUITING,
        () => ClientApi.request(putRecruitingRouteConfig, {
            token,
            ...parameters
        })
    );

export const deleteRecruitingAction = (token: string, recruitingId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_RECRUITING,
        () => ClientApi.request(deleteRecruitingRouteConfig, {token, id: recruitingId,}),
        recruitingId
    );


/**
 * DND order updates
 */
export const changeTodoOrder = (newOrder: number[], employeeId?: number): IChangeTodoOrder => ({
    type: ENTITIES_CHANGE_TODO_ORDER,
    payload: {
        newOrder,
        employeeId
    },
});

export const changeVacancyOrder = (newOrder: number[]): IChangeVacancyOrder => ({
    type: ENTITIES_CHANGE_VACANCY_ORDER,
    payload: {
        newOrder
    },
});

/**
 * APPLICATION
 */
export const postApplicationAction = (token: string, candidateId: number, vacancyId: number, employeeId: number) =>
    asyncActionCreator(
        ENTITIES_POST_APPLICATION,
        () => ClientApi.request(postApplicationRouteConfig, {
            token,
            candidateId,
            vacancyId,
            employeeId
        })
    );

export const updateApplicationAction = (token: string, parameters: {}) =>
    asyncActionCreator(
        ENTITIES_PUT_APPLICATION,
        () => ClientApi.request(putApplicationRouteConfig, {
            token,
            ...parameters
        })
    );

export const getApplicationListAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_APPLICATION_LIST,
        () => ClientApi.request(getApplicationListRouteConfig, {
            token,
            ...parameters
        })
    );

export const getApplicationAction = (token: string, applicationId: number) =>
    asyncActionCreator(
        ENTITIES_GET_APPLICATION,
        () => ClientApi.request(getApplicationRouteConfig, {
            token,
            applicationId
        })
    );

export const getApplicationListForVacancyAction = (token: string, vacancyId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_APPLICATION_LIST_BY_VACANCY,
        () => ClientApi.request(getApplicationListRouteConfig, {
            token,
            vacancyId,
            ...parameters
        }),
        {
            vacancyId
        }
    );

export const getApplicationListForCandidateAction = (token: string, candidateId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE,
        () => ClientApi.request(getApplicationListRouteConfig, {
            token,
            candidateId,
            ...parameters
        }),
        {
            candidateId
        }
    );
export const getApplicationListForCompanyAction = (token: string, companyId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_APPLICATION_LIST_BY_COMPANY,
        () => ClientApi.request(getApplicationListRouteConfig, {
            token,
            companyId,
            ...parameters
        }),
        {
            companyId
        }
    );

/**
 * TODOS
 */
export const getTodoAction = (token: string, todoId: number) =>
    asyncActionCreator(
        ENTITIES_GET_TODO,
        () => ClientApi.request(getTodoRouteConfig, { token, todoId })
    );

export const addTodoAction = (token: string, parameters: ITodoForApi) =>
    asyncActionCreator(
        ENTITIES_POST_TODO,
        () => ClientApi.request(postTodoRouteConfig, { token, ...parameters })
    );

export const getTodoListOfEmployeeAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_TODO_LIST_OF_EMPLOYEE,
        () => ClientApi.request(getTodoListOfEmployeeRouteConfig, {
            token
        })
    );

export const getTodoListAction = (token: string, filter: ICalendarTodoFilter) =>
    asyncActionCreator(
        ENTITIES_GET_TODO_LIST,
        () => ClientApi.request(getTodoListRouteConfig, {
            token,
            onlyOpen: true,
            filterFastSearch: filter.fastSearch,
            employeeId: filter.employeeId
        })
    );

export const getDueTodoListAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_DUE_TODO_LIST,
        () => ClientApi.request(getTodoListRouteConfig, { token })
    );

export const updateTodoAction = (token: string, parameters: ITodoForApi, closeEditView?: boolean) =>
    asyncActionCreator(
        ENTITIES_PUT_TODO,
        () => ClientApi.request(putTodoRouteConfig, { token, ...parameters }),
        {
            closeEditView
        }
    );

export const updateTodoOrderAction = (token: string, parameters: Partial<ITodoForApi>) =>
    asyncActionCreator(
        ENTITIES_PUT_TODO_ORDER,
        () => ClientApi.request(putTodoRouteConfig, { token, ...parameters })
    );

export const deleteTodoAction = (token: string, id: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_TODO,
        () => ClientApi.request(deleteTodoRouteConfig, {token, id}),
        id
    );

export const putTodosReadTimestampAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_SET_TODOS_READ_TIMESTAMP,
        () => ClientApi.request(putTodosReadTimestampRouteConfig, { token })
    );

/**
 * INVOICES
 */


export const deleteInvoiceAction = (token: string, id: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_INVOICE,
        () => ClientApi.request(deleteInvoiceRouteConfig, {token, id}),
        id
    );

export const getInvoiceGroupedEvaluationAction = (token: string, year: number, groupBy: EGroupEvaluationGroupBy, employeeId?: number) =>
    asyncActionCreator(
        ENTITIES_GET_INVOICE_GROUPED_EVALUATION,
        () => ClientApi.request(getInvoiceGroupedEvaluationRouteConfig, {
            token,
            year,
            groupBy,
            employeeId
        }),
        groupBy
    );

export const addInvoiceAction = (token: string, parameters: IInvoiceForApi) =>
    asyncActionCreator(
        ENTITIES_POST_INVOICE,
        () => ClientApi.request(postInvoiceRouteConfig, {
            token,
            ...parameters,
        })
    );

export const updateInvoiceAction = (token: string, parameters: IInvoiceForApi, closeWindow: boolean) =>
    asyncActionCreator(
        ENTITIES_PUT_INVOICE,
        () => ClientApi.request(putInvoiceRouteConfig, {
            token,
            ...parameters,
        }),
        closeWindow
    );

export const getInvoiceListAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_INVOICE_LIST,
        () => ClientApi.request(getInvoiceListRouteConfig, { token, ...parameters })
    );

export const getInvoiceListOpenAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_INVOICE_OPEN_LIST,
        () => ClientApi.request(getInvoiceListRouteConfig, {
            token,
            start:0,
            limit:0,
            orderBy: 'date',
            orderDirection: 'DESC',
            status: [
                EDocumentStatus.open,
                EDocumentStatus.send,
                EDocumentStatus.remind,
                EDocumentStatus.first_dunning,
                EDocumentStatus.second_dunning,
                EDocumentStatus.last_dunning
            ]
        })
    );

export const getInvoiceListOfRecruitingAction = (token: string, recruitingId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_INVOICE_LIST_OF_RECRUITING,
        () => ClientApi.request(getInvoiceListRouteConfig, { token, recruitingId, ...parameters })
    );

export const getInvoiceListOfCompanyAction = (token: string, companyId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_INVOICE_LIST_OF_COMPANY,
        () => ClientApi.request(getInvoiceListRouteConfig, { token, companyId, ...parameters })
    );

export const getInvoiceAction = (token: string, id: number) =>
    asyncActionCreator(
        ENTITIES_GET_INVOICE,
        () => ClientApi.request(getInvoiceRouteConfig, { token, id })
    );

export const downloadInvoiceDocument = (token: string, invoiceId: number) => {
    ClientApi.request(downloadPrintInvoiceRouteConfig, {
        token,
        invoiceId,
        preview: false
    }).then(downloadFile);
};

/**
 * NOTIFY
 */
export const getNotifyUnreadCountAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_NOTIFY_UNREAD_COUNT,
        () => ClientApi.request(getNotifyUnreadCountRouteConfig, { token })
    );

/**
 * MAILS
 */
export const getMailAction = (token: string, id: number) =>
    asyncActionCreator(
        ENTITIES_GET_MAIL,
        () => ClientApi.request(getMailRouteConfig, { token, id })
    );

export const putMailAction = (token: string, id: number, flagForDebug: boolean) =>
    asyncActionCreator(
        ENTITIES_PUT_MAIL,
        () => ClientApi.request(putMailRouteConfig, { token, id, flagForDebug })
    );

export const postMailRelationAction = (token: string, addMailAddress: null|string, id: number, mailProperty: TMailProperty, entityType: TEntity, entityId: number) =>
    asyncActionCreator(
        ENTITIES_POST_MAIL_RELATION,
        () => ClientApi.request(postMailRelationRouteConfig, { token, id, addMailAddress, mailProperty, entityType, entityId }),
        undefined
    );

export const getMailMessageAction = (token: string, mailboxMailId: number) =>
    asyncActionCreator(
        ENTITIES_GET_MAIL_MESSAGE,
        () => ClientApi.request(getMailboxMailMessageRouteConfig, { token, id: mailboxMailId })
    );


export const getMailListByFolderAction = (
    token: string,
    folderId: number,
    mailboxId: number,
    start: number,
    limit: number,
    syncFolder: boolean
) =>
    asyncActionCreator(
        ENTITIES_GET_MAIL_LIST_BY_FOLDER,
        () => ClientApi.request(getMailListByFolderRouteConfig, {
            token,
            folderId,
            mailboxId,
            start,
            limit,
            syncFolder
        }),
        {
            folderId,
            mailboxId
        },
    );

export const getMailListByChannelAction = (token: string, channel: TMailboxChannel) =>
    asyncActionCreator(
        ENTITIES_GET_MAIL_LIST,
        () => ClientApi.request(getMailListByChannelRouteConfig, {
            token,
            channel
        })
    );

export const getMailListBySearchAction = (token: string, fastSearch: string, searchAllMailBoxes: boolean, start: number, limit: number) =>
    asyncActionCreator(
        ENTITIES_GET_MAIL_LIST,
        () => ClientApi.request(getMailListBySearchRouteConfig, {
            token,
            fastSearch,
            searchAllMailBoxes,
            start,
            limit
        })
    );

export const getMailboxListAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_MAILBOX_LIST,
        () => ClientApi.request(getMailboxListRouteConfig, {
            token
        })
    );

export const markMailboxMailAsReadAction = (token: string, id: number) =>
    asyncActionCreator(
        ENTITIES_SET_MAIL_READ,
        () => ClientApi.request(putMarkMailAsReadRouteConfig, {
            token,
            id
        }),
        undefined
    );

export const deleteMailRelationAction = (token: string, id: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_MAIL_RELATION,
        () => ClientApi.request(deleteMailRelationRouteConfig, {
            token,
            id
        })
    );

/**
 * NEWS
 */
export const getNewsListAction = (token: string, start: number, limit: number) =>
    asyncActionCreator(
        ENTITIES_GET_NEWS_LIST,
        () => ClientApi.request(getNewsListRouteConfig, { token, start, limit })
    );

export const putNewsReadTimestampAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_SET_NEWS_READ_TIMESTAMP,
        () => ClientApi.request(putNewsReadTimestampRouteConfig, { token })
    );

/**
 * CANDIDATES
 */
export const addCandidateProfessionAction = (token: string, parameters: ICandidateProfessionForApi, successCallback: () => void) =>
    asyncActionCreator(
        ENTITIES_POST_CANDIDATE_PROFESSION,
        () => ClientApi.request(postCandidateProfessionRouteConfig, { token, ...parameters }),
        undefined,
        successCallback
    );

export const updateCandidateProfessionAction = (token: string, parameters: ICandidateProfessionForApi, successCallback: () => void) =>
    asyncActionCreator(
        ENTITIES_PUT_CANDIDATE_PROFESSION,
        () => ClientApi.request(putCandidateProfessionRouteConfig, { token, ...parameters }),
        undefined,
        successCallback
    );

export const deleteCandidateProfessionAction = (token: string, candidateId: number, id: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_CANDIDATE_PROFESSION,
        () => ClientApi.request(deleteCandidateProfessionRouteConfig, { token, candidateId, id })
    );

export const getCandidateListAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_CANDIDATE_LIST,
        () => ClientApi.request(getCandidateListRouteConfig, { token, ...parameters })
    );

export const getCandidateListOfWebVacancySearchAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH,
        () => ClientApi.request(getCandidateListRouteConfig, { token, ...parameters })
    );

export const getCandidateListOfVacancySearchAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH,
        () => ClientApi.request(getCandidateListRouteConfig, { token, ...parameters })
    );

export const getCandidateListAppendAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_CANDIDATE_LIST_APPEND,
        () => ClientApi.request(getCandidateListRouteConfig, { token, ...parameters })
    );

export const addCandidateAction = (token: string, parameters: ICandidateForApi) =>
    asyncActionCreator(
        ENTITIES_POST_CANDIDATE,
        () => ClientApi.request(postCandidateRouteConfig, { token, ...parameters })
    );

export const getCandidateAction = (token: string, candidateId: number, onlyUpdateCounts: boolean) =>
    asyncActionCreator(
        ENTITIES_GET_CANDIDATE,
        () => ClientApi.request(getCandidateRouteConfig, { token, candidateId }),
        {onlyUpdateCounts}
    );


export const updateCandidateAction = (token: string, parameters: Partial<ICandidateForApi>) =>
    asyncActionCreator(
        ENTITIES_PUT_CANDIDATE,
        () => ClientApi.request(putCandidateRouteConfig, { token, ...parameters }),
        undefined
    );

export const deleteCandidateAction = (token: string, candidateId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_CANDIDATE,
        () => ClientApi.request(deleteCandidateRouteConfig, {token, id: candidateId,}),
        candidateId
    );

export const getCandidatesWithBirthdayAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY,
        () => ClientApi.request(getCandidatesWithBirthdayRouteConfig, {
            token,
            ...parameters
        })
    );

export const getCandidatesCreatedNewAction = (token: string, createdSince: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(ENTITIES_GET_CANDIDATES_CREATED_NEW,
        () => ClientApi.request(getCandidatesCreatedNewRouteConfig, { token, createdSince, ...parameters })
    );

/**
 * RECRUITING DOCUMENT
 */
export const getDocumentsForRecruitingAction = (token: string, recruitingId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_DOCUMENTS_FOR_RECRUITING,
        () => ClientApi.request(getRecruitingDocumentsRouteConfig, {
            token,
            recruitingId,
            ...parameters,
        })
    );

export const addRecruitingDocumentAction = (token: string, parameters: IRecruitingDocumentForApi[]) =>
    asyncActionCreator(
        ENTITIES_POST_RECRUITING_DOCUMENT,
        () => ClientApi.request(postRecruitingDocumentRouteConfig, {
            token,
            documents: parameters,
        })
    );

export const updateRecruitingDocumentAction = (token: string, parameters: IRecruitingDocumentForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_RECRUITING_DOCUMENT,
        () => ClientApi.request(putRecruitingDocumentRouteConfig, {
            token,
            ...parameters,
        })
    );

export const deleteRecruitingDocumentAction = (token: string, documentId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_RECRUITING_DOCUMENT,
        () =>
            ClientApi.request(deleteRecruitingDocumentRouteConfig, {
                token,
                id: documentId,
            }),
        { recruitingDocumentId: documentId }
    );

export const downloadRecruitingDocument = (token: string, documentId: number) => {
    ClientApi.request(downloadRecruitingDocumentRouteConfig, {
        token,
        recruitingDocumentId: documentId,
    }).then(downloadFile);
};

/**
 * CANDIDATE DOCUMENT
 */
export const addCandidateDocumentAction = (token: string, parameters: ICandidateDocumentForApi[], successCallback: (response: ICandidateDocument[]) => void) =>
    asyncActionCreator(
        ENTITIES_POST_CANDIDATE_DOCUMENT,
        () => ClientApi.request(postCandidateDocumentRouteConfig, {
            token,
            documents: parameters,
        }),
        undefined,
        successCallback
    );

export const updateCandidateDocumentAction = (token: string, parameters: ICandidateDocumentForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_CANDIDATE_DOCUMENT,
        () => ClientApi.request(putCandidateDocumentRouteConfig, {
            token,
            ...parameters,
        })
    );

export const deleteCandidateDocumentAction = (token: string, documentId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_CANDIDATE_DOCUMENT,
        () =>
            ClientApi.request(deleteCandidateDocumentRouteConfig, {
                token,
                id: documentId,
            }),
        { candidateDocumentId: documentId }
    );

export const downloadCandidateDocument = (token: string, documentId: number) => {
    ClientApi.request(downloadCandidateDocumentRouteConfig, {
        token,
        candidateDocumentId: documentId,
    }).then(downloadFile);
};



/**
 * COMPANY DOCUMENT
 */
export const getDocumentsForCompanyAction = (token: string, companyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_DOCUMENTS_FOR_COMPANY,
        () => ClientApi.request(getCompanyDocumentsForCompanyRouteConfig, {
            token,
            companyId,
            ...parameters,
        })
    );

export const addCompanyDocumentAction = (token: string, parameters: ICompanyDocumentForApi[]) =>
    asyncActionCreator(
        ENTITIES_POST_COMPANY_DOCUMENT,
        () => ClientApi.request(postCompanyDocumentRouteConfig, {
            token,
            documents: parameters,
        })
    );

export const updateCompanyDocumentAction = (token: string, parameters: ICompanyDocumentForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_COMPANY_DOCUMENT,
        () => ClientApi.request(putCompanyDocumentRouteConfig, {
            token,
            ...parameters,
        })
    );

export const deleteCompanyDocumentAction = (token: string, documentId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_COMPANY_DOCUMENT,
        () =>
            ClientApi.request(deleteCompanyDocumentRouteConfig, {
                token,
                id: documentId,
            }),
        { companyDocumentId: documentId }
    );

export const downloadCompanyDocument = (token: string, documentId: number) => {
    ClientApi.request(downloadCompanyDocumentRouteConfig, {
        token,
        companyDocumentId: documentId,
    }).then(downloadFile);
};


/**
 * VACANCIES
 */
export const getVacanciesForCandidateSearchAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH,
        () => ClientApi.request(getVacancyAndWebVacancyListRouteConfig, {
            token,
            ...parameters
        })
    );

export const getAllOpenVacanciesAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_OPEN_VACANCY_LIST,
        () => ClientApi.request(getVacancyListRouteConfig, {
            token,
            completed: false,
            ...parameters
        })
    );

export const getAllArchivedVacanciesAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_ARCHIVED_VACANCY_LIST,
        () => ClientApi.request(getVacancyListRouteConfig, {
            token,
            completed: true,
            ...parameters
        })
    );

export const getAllVacanciesOfCompanyAction = (token: string, companyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_VACANCY_LIST_OF_COMPANY,
        () => ClientApi.request(getVacancyListOfCompanyRouteConfig, {
            token,
            companyId,
            ...parameters
        })
    );

export const getAllVacanciesOfCompanyContactAction = (token: string, companyContactId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_VACANCY_LIST_OF_COMPANY_CONTACT,
        () => ClientApi.request(getVacancyListOfCompanyContactRouteConfig, {
            token,
            companyContactId,
            ...parameters
        })
    );


export const addVacancyAction = (token: string, parameters: IVacancyForApi) =>
    asyncActionCreator(
        ENTITIES_POST_VACANCY,
        () => ClientApi.request(postVacancyRouteConfig, {
            token,
            ...parameters
        })
    );

export const getVacancyAction = (token: string, vacancyId: number, onlyUpdateCounts: boolean) =>
    asyncActionCreator(
        ENTITIES_GET_VACANCY,
        () => ClientApi.request(getVacancyRouteConfig, {
            token,
            vacancyId
        }), {
            onlyUpdateCounts
        }
    );

export const updateVacancyAction = (token: string, parameters: Partial<IVacancyForApi>, successCallback?: () => void) =>
    asyncActionCreator(
        ENTITIES_PUT_VACANCY,
        () => ClientApi.request(putVacancyRouteConfig, {
            token,
            ...parameters
        }),
        undefined,
        successCallback
    );

export const bulkUpdateVacancyInvoiceCreationVariantAction = (
    token: string,
    parameters: IBulkUpdateVacancyInvoiceCreationVariant,
    successCallback?: () => void
) =>
    asyncActionCreator(
        ENTITIES_PUT_VACANCY_BULK_UPDATE_INVOICE_CREATION_VARIANT,
        () => ClientApi.request(putVacancyBulkUpdateInvoiceCreationVariantRouteConfig, {
            token,
            ...parameters
        }),
        null,
        successCallback
    );

export const deleteVacancyAction = (token: string, vacancyId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_VACANCY,
        () => ClientApi.request(deleteVacancyRouteConfig, {
            token,
            id: vacancyId,
        }),
        vacancyId
    );
/**
 * SUGGESTION
 */
export const getSuggestionsWithTopRatingsAction = (token: string, employeeId: number, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS,
        () => ClientApi.request(getSuggestionListRouteConfig, {
            token,
            start: 0,
            limit: 0,
            minimumRating: 6,
            onlyOpenVacancies: true,
            responsibleEmployeeId: employeeId,
            orderBy: parameters.orderBy,
            orderDirection: parameters.orderDirection,
            status: ESuggestionStatus.OPEN
        })
    );

export const getSuggestionsForVacancyAction = (token: string, vacancyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY,
        () => ClientApi.request(getSuggestionListRouteConfig, {
            token,
            vacancyId,
            ...parameters,
        }),
        {vacancyId}
    );

export const getSuggestionListAction = (token: string, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_SUGGESTION_LIST,
        () => ClientApi.request(getSuggestionListRouteConfig, {
            token,
            start: 0,
            limit: 10,
            ...parameters,
        })
    );

export const addSuggestionAction = (token: string, parameters: Partial<ISuggestionForApi>) =>
    asyncActionCreator(
        ENTITIES_POST_SUGGESTION,
        () => ClientApi.request(postSuggestionRouteConfig, {
            token,
            ...parameters,
        })
    );


export const updateSuggestionAction = (token: string, parameters: Partial<ISuggestionForApi>) =>
    asyncActionCreator(
        ENTITIES_PUT_SUGGESTION,
        () => ClientApi.request(putSuggestionRouteConfig, {
            token,
            ...parameters,
        })
    );

export const updateSuggestionInStoreAction = (suggestion: ISuggestion): IUpdateSuggestionInStoreAction => ({
    type: ENTITIES_UPDATE_SUGGESTION_IN_STORE,
    payload: suggestion,
});

export const updateSuggestionAndReloadListAction = (token: string, parameters: Partial<ISuggestionForApi>) =>
    asyncActionCreator(
        ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST,
        () => ClientApi.request(putSuggestionRouteConfig, {
            token,
            ...parameters,
        })
    );

export const getSuggestionAction = (token: string, recruitingSuggestionId: number) =>
    asyncActionCreator(
        ENTITIES_GET_SUGGESTION,
        () => ClientApi.request(getSuggestionRouteConfig, {
            token,
            recruitingSuggestionId,
        })
    );

export const deleteSuggestionAction = (token: string, recruitingSuggestionId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_SUGGESTION,
        () => ClientApi.request(deleteSuggestionRouteConfig, {
            token,
            id: recruitingSuggestionId
        }),
        recruitingSuggestionId
    );

export const addSuggestionActivityAction = (
    token: string,
    data: Partial<IPostSuggestionActivity>,
    successCallback?: () => void
) =>
    asyncActionCreator(
        ENTITIES_POST_SUGGESTION_ACTIVITY,
        () => ClientApi.request(postSuggestionActivityRouteConfig, {
            token,
            ...data
        }),
        null,
        successCallback
    );



export const declineSuggestionAction = (
    token: string,
    recruitingSuggestionId: number,
    reason: ESuggestionDeclineReason,
    followup: boolean,
    followupTimeRange: string,
    additionalInput?: string,
    mailText?: string,
    sendDeclineMailAt?: string,
    sendDeclineMail?: boolean,
    successCallback?: () => void
) =>
    asyncActionCreator(
        ENTITIES_PUT_DECLINE_SUGGESTION,
        () => ClientApi.request(putSuggestionDeclineRouteConfig, {
            token,
            recruitingSuggestionId,
            additionalInput,
            reason,
            followup,
            followupTimeRange,
            sendDeclineMail,
            sendDeclineMailAt,
            mailText
        }),
        undefined,
        successCallback
    );

export const reopenSuggestionAction = (token: string, recruitingSuggestionId: number) =>
    asyncActionCreator(
        ENTITIES_PUT_REOPEN_SUGGESTION,
        () => ClientApi.request(putSuggestionReopenRouteConfig, {
            token,
            recruitingSuggestionId
        })
    );

/**
 * COMPANIES
 */
export const getCompanyListAction = (token: string, parameters: Partial<IGetRequestObject>, loadForCompanyMainList: boolean) =>
    asyncActionCreator(
        ENTITIES_GET_COMPANY_LIST,
        () => ClientApi.request(getCompanyListRouteConfig, { token, ...parameters }),
        loadForCompanyMainList
    );

export const addCompanyAction = (token: string, parameters: ICompanyForApi, callback?: (response) => void) =>
    asyncActionCreator(
        ENTITIES_POST_COMPANY,
        () => ClientApi.request(postCompanyRouteConfig, { token, ...parameters }),
        undefined,
        callback
    );

export const getCompanyAction = (token: string, companyId: number) =>
    asyncActionCreator(
        ENTITIES_GET_COMPANY,
        () => ClientApi.request(getCompanyRouteConfig, { token, companyId })
    );

export const updateCompanyAction = (token: string, parameters: ICompanyForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_COMPANY,
        () => ClientApi.request(putCompanyRouteConfig, { token, ...parameters })
    );

export const deleteCompanyAction = (token: string, companyId: number) =>
    asyncActionCreator(
        ENTITIES_PUT_COMPANY,
        () => ClientApi.request(deleteCompanyRouteConfig, {token, id: companyId,}),
        companyId
    );

/**
 * COMPANY LOCATION
 */
export const getLocationsForCompanyAction = (token: string, companyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_LOCATIONS_FOR_COMPANY,
        () => ClientApi.request(getCompanyLocationListRouteConfig, {
            token,
            companyId,
            ...parameters,
        })
    );

export const getLocationsForCompanyDropdownAction = (token: string, companyId: number) =>
    asyncActionCreator(
        ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN,
        () => ClientApi.request(getCompanyLocationListRouteConfig, {
            token,
            companyId,
            orderBy: 'city',
            orderDirection: ESortingOptions.Ascending
        })
    );

export const getCompanyLocationListAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_COMPANY_LOCATION_LIST,
        () => ClientApi.request(getCompanyLocationListRouteConfig, {
            token,
            orderBy: 'company',
            orderDirection: ESortingOptions.Ascending
        })
    );

export const addCompanyLocationAction = (token: string, parameters: ICompanyLocationForApi) =>
    asyncActionCreator(
        ENTITIES_POST_COMPANY_LOCATION,
        () => ClientApi.request(postCompanyLocationRouteConfig, {
            token,
            ...parameters,
        })
    );

export const updateCompanyLocationAction = (token: string, parameters: ICompanyLocationForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_COMPANY_LOCATION,
        () => ClientApi.request(putCompanyLocationRouteConfig, {
            token,
            ...parameters,
        })
    );

export const deleteLocationAction = (token: string, locationId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_COMPANY_LOCATION,
        () => ClientApi.request(deleteCompanyLocationRouteConfig, {
            token,
            id: locationId,
        }),
        { locationId }
    );

/**
 * COMPANY CONTACTS
 */
export const getContactsForCompanyAction = (token: string, companyId: number, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY,
        () => ClientApi.request(getCompanyContactListRouteConfig, {
            token,
            companyId,
            ...parameters,
        })
    );

export const getContactsWithBirthdayAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY,
        () => ClientApi.request(getCompanyContactListRouteConfig, {
            token,
            hasBirthday: true,
            ...parameters
        })
    );

export const getContactsWithoutBirthDateAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE,
        () => ClientApi.request(getCompanyContactListRouteConfig, {
            token,
            withoutBirthDate: true
        })
    );

export const getContactsForDropdownAction = (token: string, companyId: number) =>
    asyncActionCreator(
        ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN,
        () => ClientApi.request(getCompanyContactListRouteConfig, {
            token,
            companyId,
            orderBy: 'firstName',
            orderDirection: ESortingOptions.Ascending
        }),
        { companyId }
    );

export const updateCompanyContactAction = (token: string, companyContact: Partial<ICompanyContactForApi>) =>
    asyncActionCreator(
        ENTITIES_PUT_COMPANY_CONTACT,
        () => ClientApi.request(putCompanyContactRouteConfig, {
            token,
            ...companyContact,
        })
    );

export const addCompanyContactAction = (token: string, companyContact: Partial<ICompanyContactForApi>) =>
    asyncActionCreator(
        ENTITIES_POST_COMPANY_CONTACT,
        () => ClientApi.request(postCompanyContactRouteConfig, {
            token,
            ...companyContact,
        })
    );

export const deleteCompanyContactAction = (token: string, contactId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_COMPANY_CONTACT,
        () => ClientApi.request(deleteCompanyContactRouteConfig, {
            token,
            id: contactId,
        }),
        { contactId }
    );

export const getCompanyContactAction = (token: string, contactId: number) =>
    asyncActionCreator(
        ENTITIES_GET_COMPANY_CONTACT,
        () => ClientApi.request(getCompanyContactRouteConfig, {
            token,
            contactId,
        }),
        { contactId }
    );

/**
 * EMPLOYEES
 */
export const getEmployeeListAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_EMPLOYEE_LIST,
        () => ClientApi.request(getEmployeeListRouteConfig, { token, ...parameters })
    );

export const getEmployeeAction = (token: string, employeeId: number) =>
    asyncActionCreator(
        ENTITIES_GET_EMPLOYEE,
        () => ClientApi.request(getEmployeeRouteConfig, { token, employeeId })
    );

export const addEmployeeAction = (token: string, employee: IEmployeeForApi) =>
    asyncActionCreator(
        ENTITIES_POST_EMPLOYEE,
        () => ClientApi.request(postEmployeeRouteConfig, { token, ...employee })
    );

export const updateEmployeeAction = (token: string, employee: IEmployeeForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_EMPLOYEE,
        () => ClientApi.request(putEmployeeRouteConfig, { token, ...employee })
    );

/**
 * JOB TITLES
 */
export const getJobTitleListAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_JOB_TITLE_LIST,
        () => ClientApi.request(getJobTitleListRouteConfig, { token, ...parameters })
    );

export const updateJobTitleAction = (token: string, jobTitle: IJobTitleForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_JOB_TITLE,
        () => ClientApi.request(putJobTitleRouteConfig, { token, ...jobTitle })
    );

/**
 * JOB TEMPLATES
 */
export const getJobTemplateListAction = (token: string, parameters: Partial<IGetRequestObject>) =>
    asyncActionCreator(
        ENTITIES_GET_JOB_TEMPLATE_LIST,
        () => ClientApi.request(getJobTemplateListRouteConfig, { token, ...parameters })
    );

export const getJobTemplateNameListAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_JOB_TEMPLATE_NAME_LIST,
        () => ClientApi.request(getJobTemplateNameListRouteConfig, { token })
    );

export const getJobTemplateAction = (token: string, jobTemplateId: number) =>
    asyncActionCreator(
        ENTITIES_GET_JOB_TEMPLATE,
        () => ClientApi.request(getJobTemplateRouteConfig, { token, jobTemplateId })
    );

export const addJobTemplateAction = (token: string, jobTemplate: IJobTemplateForApi, closeEditView: boolean) =>
    asyncActionCreator(
        ENTITIES_POST_JOB_TEMPLATE,
        () => ClientApi.request(postJobTemplateRouteConfig, { token, ...jobTemplate }),
        {closeEditView}
    );

export const updateJobTemplateAction = (token: string, jobTemplate: IJobTemplateForApi, closeEditView: boolean) =>
    asyncActionCreator(
        ENTITIES_PUT_JOB_TEMPLATE,
        () => ClientApi.request(putJobTemplateRouteConfig, { token, ...jobTemplate }),
        {closeEditView}
    );

/**
 * SKILLS
 */
export const getSkillAction = (token: string, skillId: number) =>
    asyncActionCreator(
        ENTITIES_GET_SKILL,
        () => ClientApi.request(getSkillConfig, { token, skillId })
    );

export const getExternalSkillAction = (token: string, skillId: number) =>
    asyncActionCreator(
        ENTITIES_GET_EXTERNAL_SKILL,
        () => ClientApi.request(getExternalSkillConfig, { token, skillId })
    );

export const getAllSkillsAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_SKILL_LIST,
        () => ClientApi.request(getSkillListConfig, { token })
    );

export const getSkillTreeAction = (token: string, filter: ISkillTreeFilter) =>
     asyncActionCreator(
        ENTITIES_GET_SKILL_TREE,
        () => ClientApi.request(getSkillTreeRouteConfig, {
            token,
            ...filter
        })
    );

export const addSkillAction = (token: string, skill: IPostSkillForAPI) =>
    asyncActionCreator(
        ENTITIES_POST_SKILL,
        () => ClientApi.request(postSkillRouteConfig, { token, ...skill })
    );

export const updateSkillAction = (token: string, skill: IPutSkillForAPI, closeEditView: boolean, reload: boolean) =>
    asyncActionCreator(
        ENTITIES_PUT_SKILL,
        () => ClientApi.request(putSkillRouteConfig, { token, ...skill }),
        {
            closeEditView,
            reload
        }
    );

export const updateExternalSkillAction = (token: string, skill: IExternalSkillForApi) =>
    asyncActionCreator(
        ENTITIES_PUT_EXTERNAL_SKILL,
        () => ClientApi.request(putExternalSkillRouteConfig, { token, ...skill })
    );

export const deleteSkillAction = (token: string, skillId: number) =>
    asyncActionCreator(
        ENTITIES_DELETE_SKILL,
        () => ClientApi.request(deleteSkillRouteConfig, {token, id: skillId,}),
        skillId
    );

/**
 * mail addresses
 */
export const getMailAddressesAction = (token: string) =>
    asyncActionCreator(
        ENTITIES_GET_MAIL_ADDRESSES,
        () => ClientApi.request(getMailAddressesRouteConfig, {
            token,
        })
    );
