import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { ISplitButtonConfig } from '../../definitions/components.definitions';
import { Divider, ListItemIcon } from '@material-ui/core';
import { IconFactory } from '../Icons/IconFactory';
import styles from './CustomSplitButton.module.css';

interface IProps {
    options: ISplitButtonConfig[];
    label: string;
    size?: 'small'|'medium';
}

const CustomSplitButton: React.FC<IProps> = (props) => {
    const { options } = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const size = props.size;

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: any) => {
        if (anchorRef.current) {
            const current = anchorRef.current as any;

            if (current.contains(event.target)) {
                return;
            }
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup
                className={styles.root + ' ' + (props.size === 'small' ? styles.sizeSmall : '')}
                ref={anchorRef}
                variant={"text"}
            >
                <Button
                    color={"secondary"}
                    onClick={handleToggle}
                >
                    {props.label}
                </Button>
                <Button
                    color={"secondary"}
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <div key={index}>
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => {
                                                    setOpen(false);
                                                    option.action();
                                                }}
                                            >
                                                {option.icon &&
                                                    <ListItemIcon>{IconFactory.getIconComponent(option.icon)}</ListItemIcon>
                                                }
                                                {option.label}
                                            </MenuItem>
                                            <Divider />
                                        </div>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default CustomSplitButton;
