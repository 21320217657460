import { EIcons, IconFactory } from '../Icons/IconFactory';
import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { translate } from '../../translation/translate.utils';
import { ESuggestionStatus, IVacancyCount, IVacancyListModel } from '../../definitions/entities.definition';
import { setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';
import moment from 'moment/moment';
import { setVacancyPageSuggestionListOfVacancyFilterAction } from '../../redux/vacancyPage/vacancyPage.actions';
import CustomHoverIcon from '../CustomInput/CustomHoverIcon';
import { COLOR_RED } from '../../theme/theme';
import { useDispatch } from 'react-redux';
import styles from '../../Pages/RecruitingPage/components/VacancyOpenList.module.css';
import VacancyCountsCellRenderer from './VacancyCountsCellRenderer';
import CustomClickableIcon from '../CustomInput/CustomClickableIcon';

interface IProps {
    vacancy: IVacancyListModel;
}

const VacancyDetailCellRenderer: React.FC<IProps> = (props) => {
    const vacancy = props.vacancy;
    const [expand, setExpand] = useState(false);

    return <>
        <div className={"flexContainerColumn "}>
            <div>
                <VacancyCountsCellRenderer
                    counts={vacancy.counts.overall}
                    orderReceivedDate={vacancy.orderReceivedDate}
                    id={vacancy.id}
                />
            </div>
            <div className={"flexContainerRow alignVerticalCenter "} style={{minWidth: 0}}>
                {(vacancy.webVacancy && !vacancy.webVacancy.online) && <>
                    <CustomHoverIcon iconStyle={{color: COLOR_RED}} icon={EIcons.ErrorOutlined} tooltip={"Stelle nicht mehr online"}/>
                    &nbsp;
                </>}
                <Tooltip title={vacancy.title}>
                    <div className={""}>
                        <b>{vacancy.title}</b>
                    </div>
                </Tooltip>
            </div>
            <div className={"formContent"}>
                {!expand &&
                    <div className={"flexContainerRow cursorPointer alignVerticalCenter"} onClick={() => {
                        if (vacancy.companyLocations.length > 1) {
                            setExpand(true);
                        }
                    }}>
                        {vacancy.companyLocations.map((location) => location.city).join(', ')}
                        {vacancy.companyLocations.length > 1 &&
                            <CustomClickableIcon
                                onClick={() => setExpand(true)}
                                icon={EIcons.ExpandMore}
                                tooltip={"Details zu Standorten einblenden"}
                            />
                        }
                    </div>
                }


                {expand &&
                    <>
                        <div className={"flexContainerRow cursorPointer alignVerticalCenter"} onClick={() => setExpand(false)}>
                            Details zu Standorten
                            <CustomClickableIcon
                                onClick={() => setExpand(false)}
                                icon={EIcons.ExpandLess}
                                tooltip={"Details zu Standorten einblenden"}
                            />
                        </div>
                        {vacancy.companyLocations.map((location) =>
                            <>
                                <div className={"marginTop10"}>
                                    <VacancyCountsCellRenderer
                                        key={location.id}
                                        size={'small'}
                                        counts={vacancy.counts['location_' + location.id]}
                                        orderReceivedDate={vacancy.orderReceivedDate}
                                        id={vacancy.id}
                                        locationId={location.id}
                                    />
                                    {location.city}
                                </div>
                            </>
                        )}
                    </>
                }
            </div>
        </div>
    </>
};

export default VacancyDetailCellRenderer;
