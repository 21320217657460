import React from 'react';
import {
    ECandidateSource,
    EEntities,
    ERecruitingStatus,
    ERecruitingType,
    ICandidate,
    IEmployee,
    IRecruitingListModel,
    IRecruitingTimelineInformation,
    IVacancy,
    TBrainzEntity,
} from '../../../definitions/entities.definition';
import { translate } from '../../../translation/translate.utils';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import VacancyCellRenderer from '../../../components/CustomCellRenderer/VacancyCellRenderer';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { ECellAlign, ESortingOptions, ICustomTableColumnConfig } from '../../../definitions/components.definitions';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { getDefaultLastActivityColumn, INFINITE_SCROLL_INTERVAL } from '../../../utils/components.utils';
import DateDiffCellRenderer from '../../../components/CustomCellRenderer/DateDiffCellRenderer';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { Link, Tooltip } from '@material-ui/core';
import EmployeeTimeCellRenderer from '../../../components/CustomCellRenderer/EmployeeTimeCellRenderer';
import SourceRenderer from '../../../components/CustomCellRenderer/SourceRenderer';
import { setUiEditView, setUiInterviewAddDataAction } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import WebVacancyCellRenderer from '../../../components/CustomCellRenderer/WebVacancyCellRenderer';
import UrlCellRenderer from '../../../components/CustomCellRenderer/UrlCellRenderer';
import { ICustomerRecruitingListModel } from '../../../definitions/entities.customer.definitions';
import RecruitingStatusTimelineCellRenderer
    from '../../../PagesCustomer/RecruitingPage/components/components/RecruitingStatusTimelineCellRenderer';
import { COLOR_RED } from '../../../theme/theme';
import { Rating } from '@material-ui/lab';
import { IRecruitingFilter } from '../../../definitions/recruitingPage.definitions';
import {
    setRecruitingPageFilterAction,
    setRecruitingPageRecruitingToContractAction,
    setRecruitingPageRecruitingToDeclineAction,
    setRecruitingPageRecruitingToDeleteAction,
    setRecruitingPageSortingOptionAction,
} from '../../../redux/recruitingPage/recruitingPage.actions';
import { updateRecruitingAction } from '../../../redux/entities/entities.actions';
import { fixUrl } from '../../../utils/application.utils';

interface IProps {
    recruitings: IRecruitingListModel[];
    status: ERecruitingStatus;
    entity: EEntities;
    count?: number;
    total?: number;
    filter?: IRecruitingFilter;
    onReload?: () => void;
}

const RecruitingList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    const entity = props.entity;
    const filter = props.filter;
    const recruitings = props.recruitings;
    const total = props.total;
    const count = props.count;
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.recruitingsLoading);

    const columnConfig: ICustomTableColumnConfig = {
        actions: {
            header: <>Status<br/>ändern</>,
            property: 'status',
            width: 80,
            align: ECellAlign.center,
            disableOrder: true,
            cellRenderer: (status: ERecruitingStatus, entity) => {
                const recruiting = entity as IRecruitingListModel;

                if (status === ERecruitingStatus.STATUS_SUGGEST) {
                    return <>
                        <CustomClickableIcon
                            tooltip={"Prozess auf Status Absage ändern"}
                            onClick={() => dispatch(setRecruitingPageRecruitingToDeclineAction(entity.id))} icon={EIcons.ThumbDown}
                        />
                        <CustomClickableIcon
                            tooltip={"Prozess auf Status Vorstellungsgespräch ändern (ohne Termin)"}
                            onClick={() => dispatch(updateRecruitingAction(token, {
                                id: recruiting.id,
                                status: ERecruitingStatus.STATUS_INTERVIEW
                            }))}
                            icon={EIcons.Group}
                        />
                        <CustomClickableIcon
                            tooltip={"Prozess auf Status Vorstellungsgespräch ändern (mit Termin)"}
                            onClick={() => dispatch(setUiInterviewAddDataAction({
                                add: true,
                                recruitingId: recruiting.id,
                                preSelectedEmployee: recruiting.responsibleEmployee
                            }))}
                            icon={EIcons.Calendar}
                        />
                    </>
                }

                if (status === ERecruitingStatus.STATUS_INTERVIEW) {
                    return <>
                        <CustomClickableIcon
                            tooltip={"Prozess abbrechen"}
                            onClick={() => dispatch(setRecruitingPageRecruitingToDeclineAction(entity.id))}
                            icon={EIcons.ThumbDown}
                        />
                        {
                            recruiting.interviewActionIsRequired && <>
                                <CustomClickableIcon
                                    tooltip={"Vorstellungsgespräch hinzufügen"}
                                    onClick={() => dispatch(setUiInterviewAddDataAction({
                                        add: true,
                                        recruitingId: recruiting.id,
                                        preSelectedEmployee: recruiting.responsibleEmployee
                                    }))}
                                    icon={EIcons.Calendar}
                                />
                            </>
                        }
                        <CustomClickableIcon
                            tooltip={"Prozess auf Status Vermittelt ändern"}
                            onClick={() => dispatch(setRecruitingPageRecruitingToContractAction(entity.id))}
                            icon={EIcons.ThumbUp}
                        />
                    </>
                }
            }
        },
        status: {
            header: 'Status',
            property: "interviews",
            width: 240,
            orderByProperty: "nextAppointment",
            cellRenderer: (interviews: IRecruitingTimelineInformation[], entity) => {
                const entityCast = entity as ICustomerRecruitingListModel;
                return <RecruitingStatusTimelineCellRenderer recruiting={entityCast} allowOpenInterview/>
            },
        },
        hot: {
            header: IconFactory.getIconComponent(EIcons.Whatshot),
            property: 'hot',
            width: 25,
            align: ECellAlign.center,
            cellRenderer: (hot: boolean, entity) => {
                const recruiting = entity as IRecruitingListModel;

                return <Rating
                    name={"isHot" + recruiting.id}
                    size={'large'}
                    value={hot ? 1 : 0}
                    max={1}
                    icon={IconFactory.getIconComponent(EIcons.Whatshot, {color: COLOR_RED, width: 26, height: 26})}
                    emptyIcon={IconFactory.getIconComponent(EIcons.Whatshot, {color: 'rgba(0, 0, 0, 0.26)', width: 26, height: 26})}
                    onChange={() => {
                        dispatch(updateRecruitingAction(token, {
                            id: recruiting.id,
                            hot: !recruiting.hot
                        }))}
                    }
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                />
            }
        },
        type: {
            header: '#',
            property: 'type',
            width: 25,
            disableOrder: true,
            align: ECellAlign.center,
            cellRenderer: (type: ERecruitingType) => {
                if (type !== ERecruitingType.TYPE_PROJECT) {
                    return (
                        <Tooltip title={"Festanstellung"}>
                            {IconFactory.getIconComponent(EIcons.Work)}
                        </Tooltip>
                    );
                }

                return (
                    <Tooltip title={"Projekt"}>
                        {IconFactory.getIconComponent(EIcons.AvTimer)}
                    </Tooltip>
                );
            }
        },
        source: {
            header: translate('misc.source'),
            property: 'source',
            width: 45,
            align: ECellAlign.center,
            cellRenderer: (source: ECandidateSource) => {
                return <SourceRenderer source={source}/>
            }
        },
        vacancy: {
            header: translate('pages.vacancy.properties.company') + ' & ' + translate('pages.activity.properties.vacancy') + ' | ' + translate('misc.webVacancy') + ' | URL',
            property: "vacancy",
            cellRenderer: (vacancy: null | IVacancy, entity) => {
                const recruiting = entity as IRecruitingListModel;

                if (vacancy) {
                    return <>
                        <div className={"flexContainerColumn gap5"}>
                            <VacancyCellRenderer vacancy={vacancy} showCompany />
                        </div>
                    </>;
                }

                if (recruiting.webVacancy) {
                    return <>
                        <WebVacancyCellRenderer id={recruiting.webVacancy.id} title={recruiting.webVacancy.title} company={recruiting.webVacancy.company.name}/>
                    </>;
                }

                if (recruiting.url) {
                    return <div className={"flexContainerColumn"}>
                        <div className={"flexContainerRow alignVerticalCenter"}>
                            {recruiting.jobTitle}
                            <CustomClickableIcon
                                tooltip={"Url öffnen"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(fixUrl(recruiting.url || ''));
                                }}
                                icon={EIcons.Launch}
                            />
                        </div>
                        <div className={"formContent"}>
                            bei {recruiting.company.name}
                        </div>
                    </div>;
                }
            },
        },
        candidate: {
            header: translate('pages.activity.properties.candidate'),
            property: "candidate",
            width: 150,
            cellRenderer: (candidate: ICandidate) => (
                <CandidateCellRenderer candidate={candidate}/>
            ),
        },
        expectedSalary: {
            header: translate('pages.candidate.properties.expectedSalary'),
            property: "expectedSalaryString",
            align: ECellAlign.right,
            width: 170,
            cellRenderer: (expectedSalary: string, entity: TBrainzEntity) => {
                const recruiting = entity as IRecruitingListModel;

                return <>
                    <div className={"flexContainerColumn"}>
                        <div>
                            Gehaltsvorstellung: {expectedSalary ? expectedSalary + ' €' : 'Keine Angabe'}
                        </div>
                        {Boolean(recruiting.desiredYearSalary) &&
                        <div className={'formContent alignHorizontalRight'}>
                            Vorgeschlagen für: {recruiting.desiredYearSalary/1000}k €
                        </div>
                        }
                    </div>
                </>;
            },
        },
        possibleCommission: {
            header: translate('pages.vacancy.possibleCommission'),
            property: "possibleCommission",
            width: 80,
            align: ECellAlign.right,
            cellRenderer: (commission: number) => {
                if (commission) {
                    return <CurrencyCellRenderer roundedToThousand value={commission} />
                }
                return '-';
            },
        },
        commission: {
            header: 'Provision Gesamt & abgerechnet',
            property: "possibleCommission",
            width: 120,
            align: ECellAlign.right,
            cellRenderer: (commission: number, entity) => {
                const recruiting = entity as IRecruitingListModel;

                if (recruiting.type !== ERecruitingType.TYPE_PROJECT && commission) {
                    return <>
                        <div className={"flexContainerColumn"}>
                            <div>
                                <CurrencyCellRenderer roundedToThousand value={commission} />
                            </div>
                            {recruiting.contract &&
                                <div className={'formContent'}>
                                    <CurrencyCellRenderer roundedToThousand value={recruiting.contract.billedCommission} />
                                </div>
                            }
                        </div>
                    </>;
                }

                if (commission) {
                    return (<CurrencyCellRenderer roundedToThousand value={commission} />)
                }
                return '-';
            },
        },
        openCommission: {
            header: <div className="content" dangerouslySetInnerHTML={{__html: translate('pages.recruiting.openCommission')}} />,
            property: "contract.openCommission",
            orderByProperty: "contract.endOfTrialPeriod",
            width: 100,
            align: ECellAlign.right,
            cellRenderer: (commission: number, entity) => {
                const recruiting = entity as IRecruitingListModel;

                if (recruiting.type !== ERecruitingType.TYPE_PROJECT && commission && commission > 0) {
                    return <>
                        <div className={"flexContainerColumn"}>
                            <div>
                                <CurrencyCellRenderer roundedToThousand value={commission} />
                            </div>
                            <div className={'formContent'}>
                                <DateDiffCellRenderer date={recruiting.contract.endOfTrialPeriod as string}/>
                            </div>
                        </div>
                    </>;
                }

                return '-';
            }
        },
        lastActivity: getDefaultLastActivityColumn(),
        responsibleEmployee: {
            header: translate('misc.employeeShort'),
            property: "responsibleEmployee",
            align: ECellAlign.center,
            width: 100,
            cellRenderer: (responsibleEmployee: IEmployee, recruiting: TBrainzEntity) => {
                const recruitingCast = recruiting as IRecruitingListModel;

                return (
                    <>
                        <div className={"flex flexCentered"}>
                            {recruitingCast.recommendationBy &&
                                <Tooltip title={translate('pages.recruiting.recommendationBy') + ': ' + recruitingCast.recommendationBy}>
                                    <div>
                                        {IconFactory.getIconComponent(EIcons.Flag)}&nbsp;
                                    </div>
                                </Tooltip>
                            }
                            <EmployeeCellRenderer employee={responsibleEmployee} />
                            { (recruitingCast.suggestedBy && recruitingCast.suggestedBy.id !== responsibleEmployee.id) &&
                                <>
                                    <h1 style={{margin:0,fontWeight: "normal"}}>&nbsp;|&nbsp;</h1>
                                    <EmployeeCellRenderer employee={recruitingCast.suggestedBy} />
                                </>
                            }
                        </div>
                    </>
                );
            },
        },
        employeeTimes: {
            header: 'Beteiligung',
            property: "responsibleEmployee",
            width: 120,
            cellRenderer: (responsibleEmployee: IEmployee, recruiting: TBrainzEntity) => {
                const recruitingCast = recruiting as IRecruitingListModel;

                return <div style={{height: 30, flex: 1}}>
                    <EmployeeTimeCellRenderer times={recruitingCast.employeeTimes} recruitingId={recruitingCast.id} />
                </div>
            }
        },
    };

    const status = props.status;
    if (status === ERecruitingStatus.STATUS_SUGGEST) {
        delete columnConfig.openCommission;
        delete columnConfig.commission;
        delete columnConfig.hot;
    }

    if (status === ERecruitingStatus.STATUS_INTERVIEW) {
        delete columnConfig.openCommission;
        delete columnConfig.commission;
    }

    if (status === ERecruitingStatus.STATUS_CONTRACT || status === ERecruitingStatus.STATUS_FINISH || status === ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD) {
        delete columnConfig.actions;
        delete columnConfig.lastActivity;
        delete columnConfig.possibleCommission;
        delete columnConfig.hot;
    }

    if (status === ERecruitingStatus.STATUS_ABORT) {
        delete columnConfig.actions;
        delete columnConfig.lastActivity;
        delete columnConfig.openCommission;
        delete columnConfig.commission;
        delete columnConfig.hot;
    }

    /*this.props.getRecruitingListAction(
        token,
        this.props.requestObject,
        this.props.status
    );*/

    const groupBy = status === ERecruitingStatus.STATUS_CONTRACT ? (entity: TBrainzEntity) => {
        const entityCast = entity as IRecruitingListModel;
        if (entityCast.status === ERecruitingStatus.STATUS_CONTRACT) {
            return 'Probezeit ausstehend';
        }
        if (entityCast.status === ERecruitingStatus.STATUS_FINISH) {
            return 'Probezeit bestanden'
        }
        if (entityCast.status === ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD) {
            return 'Probezeit nicht bestanden'
        }
        return entityCast.status;
    } : undefined;

    let footerText = <></>;
    if (status === ERecruitingStatus.STATUS_INTERVIEW) {
        footerText = <>
            {recruitings.filter((recruiting: IRecruitingListModel) => Boolean(recruiting.hot)).length}
            x
            {IconFactory.getIconComponent(EIcons.Whatshot, {color: COLOR_RED, width: 26, height: 26})}
            ({(recruitings.filter((recruiting) => recruiting.hot).reduce((a, recruiting) => a + recruiting.possibleCommission || 0, 0)/1000).toFixed(1)} k€)
        </>
    }

    return <>
        <CustomTable
            config={{
                loading: loading,
                entity: entity,
                listActionButtons: [{
                    action: (id) =>  dispatch(setRecruitingPageRecruitingToDeleteAction(id)),
                    icon: EIcons.Delete,
                    translationKey: 'pages.recruiting.deleteDialog.title'
                }],
                groupBy,
                columnConfig,
                sortedEntries: recruitings,
                onRowDoubleClick: (id) => dispatch(setUiEditView(EEntityView.recruiting, id)),
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) => {
                        dispatch(setRecruitingPageSortingOptionAction(propertyToSort, sortOption))
                    }
                },
                scrollCallback: () => {
                    if (filter && filter.limit && filter.limit < total) {
                        dispatch(setRecruitingPageFilterAction({
                            start: 0,
                            limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                        }, false));
                    }
                },
                footerText: footerText,
                count: count,
                total: total,
                onReload: props.onReload
            }}
        />
    </>;
}

export default RecruitingList;
