import React from 'react';
import { EEntities } from '../../../../../definitions/entities.definition';
import { translate } from '../../../../../translation/translate.utils';
import { EIcons } from '../../../../../components/Icons/IconFactory';
import { EFilterPanelType } from '../../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../../definitions/ui.definitions';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    setRecruitingPageFastSearchAction,
    setRecruitingPageStartRecruitingAction,
} from '../../../../../redux/recruitingPage/recruitingPage.actions';

const FastSearchPanelRecruitingList: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchRecruiting = useSelector((store: IStore) => store.recruitingPage.filterRecruitingsGlobal.fastSearch);

    const configuration: IAppBarConfig = {
        entity: EEntities.Vacancy,
        value: fastSearchRecruiting ?? '',
        emptyValue: translate('pages.recruiting.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setRecruitingPageFastSearchAction(value)),
        rightButtons: [{
            action: () => dispatch(setRecruitingPageStartRecruitingAction({open: true})),
            icon: EIcons.AddBox,
            tooltip: translate('misc.add')
        }],
        filterPanelType: EFilterPanelType.sidePanel
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelRecruitingList;
