import React from 'react';
import { IInvoiceFormState } from '../../../../definitions/billingPage.definitions';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import LegacyCompanyAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyAutocompleteDropDown';
import { ETermsOfPayment, ICompany } from '../../../../definitions/entities.definition';
import { getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setBillingPageInvoiceFormAction } from '../../../../redux/billingPage/billingPage.actions';
import { translate } from '../../../../translation/translate.utils';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import TermsOfPaymentDropDown from '../../../../components/CustomInput/CustomDropDownInput/TermsOfPaymentDropDown';
import moment from 'moment';
import { momentToDate } from '../../../../utils/date.utils';
import { EIcons } from '../../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../../components/CustomInput/CustomClickableIcon';
import CompanyLocationAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyLocationAutocomplete';
import CompanyContactAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';

interface IProps {
    formState: IInvoiceFormState;
    addInvoice?: boolean;
}

const InvoiceHeaderView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const formState = props.formState;
    const validationErrors = useSelector(getValidationErrorsFromStore);
    const addInvoice = props.addInvoice || false;

    return (
        <>
            <PaperRow>
                <LegacyCompanyAutocompleteDropDown
                    value={formState.company}
                    error={Boolean(validationErrors.companyId)}
                    helperText={validationErrors.companyId}
                    onChange={(event, value: ICompany) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            company: value,
                            companyName: value.name
                        }))
                    }
                />
                <CustomDatePicker
                    label={translate('misc.date')}
                    value={formState.date}
                    onChange={(value) => {
                        let days = 21;
                        if (formState.termsOfPaymentId === ETermsOfPayment.Days14) {
                            days = 14;
                        }
                        const dueDate = moment(value).add(days, 'days');

                        dispatch(setBillingPageInvoiceFormAction({
                            date: value,
                            dueDate: momentToDate(dueDate)
                        }))
                    }}
                    error={Boolean(validationErrors.date)}
                    helperText={validationErrors.date}
                />
                <CustomDatePicker
                    label={translate('pages.billing.dueDate')}
                    value={formState.dueDate}
                    onChange={(value) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            dueDate: value
                        }))
                    }
                    error={Boolean(validationErrors.dueDate)}
                    helperText={validationErrors.dueDate}
                />
                {!addInvoice &&
                    <CustomTextField
                        label={translate('pages.billing.invoiceNumber')}
                        value={formState.number}
                        onChange={(value) =>
                            dispatch(setBillingPageInvoiceFormAction({
                                number: value
                            }))
                        }
                        disabled={formState.numberDisabled}
                        InputProps={{endAdornment: formState.numberDisabled && <CustomClickableIcon onClick={() => dispatch(setBillingPageInvoiceFormAction({
                                numberDisabled: false
                            }))} icon={EIcons.Edit}/>}}
                        error={Boolean(validationErrors.number)}
                        helperText={validationErrors.number}
                    />
                }

            </PaperRow>
            <PaperRow>
                <CompanyLocationAutocomplete
                    onChange={(value) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            companyLocation: value || undefined,
                            address: value?.address,
                            zipCode: value?.zipCode,
                            city: value?.city,
                        }))
                    }
                    addEnabled
                    error={Boolean(validationErrors.companyLocationId)}
                    helperText={validationErrors.companyLocationId}
                    value={formState.companyLocation || null}
                    companyId={formState.company?.id}
                    filterByCompany={true}
                />
                <CompanyContactAutocomplete
                    onChange={(value) => dispatch(setBillingPageInvoiceFormAction({
                        companyContact: value||undefined
                    }))}
                    addEnabled
                    value={formState.companyContact}
                    companyId={formState.company?.id}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    label={translate('misc.company')}
                    value={formState.companyName}
                    onChange={(value) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            companyName: value
                        }))
                    }
                    error={Boolean(validationErrors.companyName)}
                    helperText={validationErrors.companyName}
                />
                <CustomTextField
                    label={translate('misc.addressLine1')}
                    value={formState.address}
                    onChange={(value) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            address: value
                        }))
                    }
                    error={Boolean(validationErrors.address)}
                    helperText={validationErrors.address}
                />
                <CustomTextField
                    label={translate('misc.addressLine2')}
                    value={formState.addressLine2}
                    onChange={(value) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            addressLine2: value
                        }))
                    }
                    error={Boolean(validationErrors.addressLine2)}
                    helperText={validationErrors.addressLine2}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    label={translate('misc.zipCode')}
                    value={formState.zipCode}
                    onChange={(value) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            zipCode: value
                        }))
                    }
                    error={Boolean(validationErrors.zipCode)}
                    helperText={validationErrors.zipCode}
                />
                <CustomTextField
                    label={translate('misc.city')}
                    value={formState.city}
                    onChange={(value) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            city: value
                        }))
                    }
                    error={Boolean(validationErrors.city)}
                    helperText={validationErrors.city}
                />
                <TermsOfPaymentDropDown
                    label={"Zahlungsbedingung"}
                    required={true}
                    error={Boolean(validationErrors.termsOfPaymentId)}
                    helperText={validationErrors.termsOfPaymentId}
                    value={formState.termsOfPaymentId}
                    onChange={(event) => {
                        const termsOfPaymentId = event.target.value;

                        dispatch(setBillingPageInvoiceFormAction({
                            termsOfPaymentId
                        }));

                        let days = 21;
                        if (termsOfPaymentId === ETermsOfPayment.Days14) {
                            days = 14;
                        }

                        const dueDate = moment(formState.date).add(days, 'days');
                        dispatch(setBillingPageInvoiceFormAction({
                            dueDate: momentToDate(dueDate)
                        }))
                    }}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    multiline
                    rows={3}
                    label={translate('pages.billing.comment')}
                    value={formState.comment}
                    onChange={(value) =>
                        dispatch(setBillingPageInvoiceFormAction({
                            comment: value
                        }))
                    }
                    error={Boolean(validationErrors.comment)}
                    helperText={validationErrors.comment}
                />
            </PaperRow>
        </>
    );
}

export default InvoiceHeaderView;
