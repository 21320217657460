import React from 'react';
import {
    getSuggestionsForListViewFromStore,
    getVacancyPageRequestObjectForSuggestionsListView,
} from '../../../selectors/recruitingSuggestion.selectors';
import {
    ECandidateSource,
    EEntities,
    ESuggestionDeclineReason,
    ESuggestionStatus,
    ICandidate,
    ICandidateProfiles,
    IEmployee,
    ISuggestion,
    TBrainzEntity,
} from '../../../definitions/entities.definition';
import SourceRenderer from '../../../components/CustomCellRenderer/SourceRenderer';
import { ECellAlign, ESortingOptions, ICustomTableColumnConfig } from '../../../definitions/components.definitions';
import { IStore } from '../../../definitions/redux/store.definitions';
import { Rating } from '@material-ui/lab';
import StarIcon from '@material-ui/icons/Star';
import { translate } from '../../../translation/translate.utils';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { getSuggestionListActionButtons } from '../../../utils/suggestion.utils';
import {
    setVacancyPageSuggestionListViewSortingOptionAction,
    setVacancyPageSuggestionToAddActivityAction,
} from '../../../redux/vacancyPage/vacancyPage.actions';
import { getSuggestionListAction, updateSuggestionAction } from '../../../redux/entities/entities.actions';
import Nl2brCellRenderer from '../../../components/CustomCellRenderer/Nl2brCellRenderer';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { getToken } from '../../../selectors/app.selectors';
import { themeOrange } from '../../../theme/theme';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import { IconButton } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import CandidateProfilesRenderer from '../../../components/CustomCellRenderer/CandidateProfilesRenderer';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';

const SuggestionListViewList: React.FC = () => {
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.vacancyPage.suggestionListViewFilter);
    const suggestions = useSelector(getSuggestionsForListViewFromStore);
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.suggestionListViewLoading);
    const suggestionRequestObject = useSelector(getVacancyPageRequestObjectForSuggestionsListView);

    const columnConfig: ICustomTableColumnConfig = {
        ranking: {
            header: '#',
            property: 'ranking',
            width: 30,
        },
        status: {
            header: translate('misc.status'),
            property: 'status',
            width: 120,
            cellRenderer: (value: ESuggestionStatus) => {
                return translate('pages.vacancy.suggestionStatusValue.' + value);
            }
        },
        rating: {
            header: translate('pages.recruiting.rating'),
            property: 'rating',
            width: 100,
            cellRenderer: (rating: number, suggestion: TBrainzEntity) => {
                const suggestionEntity = suggestion as ISuggestion;

                return <Rating
                    size={'large'}
                    value={rating / 2}
                    max={3}
                    icon={<StarIcon style={{width: '1.2em', height: '1.2em', pointerEvents: 'auto'}}/>}
                    precision={0.5}
                    onChange={(event, value) => {
                        if (value) {
                            dispatch(updateSuggestionAction(token, {
                                id: suggestionEntity.id,
                                rating: value * 2,
                            }));
                        }
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                />;
            },
        },
        candidate: {
            header: translate('pages.activity.properties.candidate'),
            property: 'candidate',
            width: 160,
            cellRenderer: (candidate: ICandidate, suggestion: TBrainzEntity) => {
                const suggestionCast = suggestion as ISuggestion;
                if (candidate) {
                    return <CandidateCellRenderer candidate={candidate}/>;
                }

                return (
                    <>
                        {suggestionCast.fullName}
                        {suggestionCast.source === ECandidateSource.jobcenter && suggestionCast.externalCandidateReference && '(' + suggestionCast.externalCandidateReference + ')'}
                    </>
                );
            },
        },
        source: {
            header: translate('misc.source'),
            property: 'externalCandidateSource',
            width: 60,
            cellRenderer: (source: ECandidateSource) => {
                return <SourceRenderer source={source}/>
            }
        },
        addActivity: {
            header: '',
            property: 'id',
            width: 30,
            cellRenderer: (id, entity) => {
                const suggestion = entity as ISuggestion;

                return <IconButton size={"small"} onClick={() => dispatch(setVacancyPageSuggestionToAddActivityAction(suggestion.id))}>{IconFactory.getIconComponent(EIcons.Add)}</IconButton>;
            }
        },
        declineReason: {
            header: 'Grund der Absage',
            property: 'declineReason',
            width: 160,
            cellRenderer: (reason: ESuggestionDeclineReason) => {
                if (!reason) {
                    return translate('misc.noInformation');
                }
                return translate('pages.vacancy.declineSuggestionReasonValues.' + reason);
            }
        },
        comment: {
            header: translate('misc.comment'),
            property: 'comment',
            flex: 1,
            cellRenderer: (comment: string) => {
                return <Nl2brCellRenderer value={comment}/>;
            },
        },
        phoneNumber: {
            header: translate('misc.mobileNumber'),
            property: 'phoneNumber',
            flex: 1,
            cellRenderer: (phoneNumber: string, suggestion: TBrainzEntity) => {
                const suggestionCast = suggestion as ISuggestion;

                return suggestionCast.phoneNumber;
            },
        },
        profiles: {
            header: 'Profile',
            property: 'candidateProfiles',
            width: 80,
            cellRenderer: (profiles: ICandidateProfiles) => {
                return <CandidateProfilesRenderer profiles={profiles} />
            },
        },
        suggestedByEmployee: {
            header: <>Vorgeschlagen<br/>von</>,
            property: 'suggestedByEmployee',
            width: 130,
            align: ECellAlign.center,
            cellRenderer: (employee: IEmployee) => {
                return <EmployeeCellRenderer employee={employee}/>;
            },
        },
        responsibleEmployee: {
            header: <>Verantwortlicher<br/>Mitarbeiter</>,
            property: 'responsibleEmployee',
            width: 140,
            align: ECellAlign.center,
            cellRenderer: (employee: IEmployee) => {
                return <EmployeeCellRenderer employee={employee}/>;
            },
        },
    };

    if (filter.status !== undefined && filter.status.length > 0) {
        delete columnConfig.status;
    }

    if (filter.status !== ESuggestionStatus.OPEN) {
        delete columnConfig.ranking;
        delete columnConfig.phoneNumber;
        delete columnConfig.addActivity;
    }

    if (filter.status !== ESuggestionStatus.DECLINED) {
        delete columnConfig.declineReason;
    }

    return <>
        <CustomTable
            style={{marginLeft:10, marginRight:10}}
            config={{
                color: themeOrange.palette.primary.main,
                entity: EEntities.Suggestion,
                loading,
                listActionButtons: filter.status === ESuggestionStatus.OPEN ? getSuggestionListActionButtons() : undefined,
                columnConfig,
                sortedEntries: suggestions,
                onRowDoubleClick: (suggestionId: number) => {
                    dispatch(setUiEditView(EEntityView.suggestion, suggestionId));
                },
                count: suggestions.length,
                tableHeaderCallbacks: filter.status !== ESuggestionStatus.OPEN ? {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setVacancyPageSuggestionListViewSortingOptionAction(propertyToSort, sortOption)),
                } : undefined,
                onReload: () => {
                    dispatch(getSuggestionListAction(
                        token,
                        suggestionRequestObject,
                    ));
                },
            }}
        />
    </>;
};

export default SuggestionListViewList;
