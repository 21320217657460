import React, { ReactNode } from 'react';
import { IWebVacancyListModel, IWebVacancyPartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getWebVacancyListRouteConfig, getWebVacancyRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomLivesearch from '../CustomLivesearch';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../CustomClickableIcon';
import { EIcons } from '../../../Icons/IconFactory';

interface IProps {
    value: IWebVacancyPartial|IWebVacancyListModel|number|null;
    onChange: (value: IWebVacancyListModel|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    companyId?: number;
}

const WebVacancyAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;
    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as IWebVacancyListModel);
    }
    const getOptionLabel = (record: TBrainzEntity|null) => {
        if (props.companyId) {
            return (record as IWebVacancyListModel).title;
        }
        const webVacancy = record as IWebVacancyListModel;

        if (props.companyId) {
            return webVacancy.title
        }

        return `${webVacancy.title} (${webVacancy.company.name})`
    }
    const valueIsEntity: boolean = value !== null && value.constructor && value.constructor.name === 'Object';

    return <CustomLivesearch
        id={"vacancy-autocomplete"}
        label={props.label || translate('pages.vacancy.webVacancy')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getWebVacancyListRouteConfig}
        entityRouteConfig={getWebVacancyRouteConfig}
        entityRouteParam={"webVacancyId"}
        getOptionLabel={getOptionLabel}
        startAdornment={value && valueIsEntity ? (
            <InputAdornment position="end">
                <CustomClickableIcon onClick={() => window.open((value as IWebVacancyListModel).url)} icon={EIcons.Launch}/>
            </InputAdornment>
        ) : undefined}
        loadAll
        routeParams={{
            orderBy: 'title',
            orderDirection: 'DESC',
            start: 0,
            limit: 50,
            companyIds: props.companyId ? [props.companyId] : undefined,
            online: true
        }}
    />;
}

export default React.memo(WebVacancyAutocomplete);
