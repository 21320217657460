import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    setCompanyPageAddDialogOpenAction,
    setCompanyPageFastSearchValueAction,
} from '../../../../redux/companyPage/companyPage.actions';

const FastSearchPanelCompanyPage: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchCompany = useSelector((store: IStore) => store.companyPage.filter.fastSearch);

    const configuration: IAppBarConfig = {
        entity: EEntities.Company,
        value: fastSearchCompany ?? '',
        emptyValue: translate('pages.company.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setCompanyPageFastSearchValueAction(value)),
        rightButtons: [{
            action: () => dispatch(setCompanyPageAddDialogOpenAction(true)),
            icon: EIcons.AddBox,
            tooltip: translate('misc.add')
        }],
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelCompanyPage;
