import { TBrainzEntity } from '../../../definitions/entities.definition';
import { IRouteConfig } from '../../../definitions/requests.definitions';
import { useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from '@material-ui/core';
import { ClientApi } from '../../../requests/ClientApi';
import { executeLoadRecordsRequest } from './Autocomplete.utils';

export const useLivesearchHooks = (
    open: boolean,
    disabled: boolean,
    loadBeforeOpen: boolean,
    loadAll: boolean,
    value: null|TBrainzEntity|number,
    searchValue: string,
    routeParams: object,
    routeConfig: IRouteConfig,
    entityRouteConfig: IRouteConfig,
    entityRouteParam: string,
    getOptionLabel: (entity: TBrainzEntity) => string,
    loadCallback?: (entities: TBrainzEntity[]) => void,
) => {
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<TBrainzEntity[]>([]);
    const valueIsEntity: boolean = value !== null && value.constructor && value.constructor.name === 'Object';
    const [entityValue, setEntityValue] = useState<TBrainzEntity|null>(valueIsEntity ? value as null|TBrainzEntity : null);

    const loadData = (searchValue: string) => {
        if (disabled) {
            return;
        }
        executeLoadRecordsRequest(token, setLoading, setRecords, routeConfig, {
            ...routeParams,
            filterFastSearch: searchValue
        }, loadCallback);
    };

    const debouncedChangeHandler = useCallback(
        debounce(loadData, 300), []
    );

    const loadSelectedEntity = () => {
        if (entityValue !== null) {
            return;
        }
        setLoading(true);
        ClientApi.request(entityRouteConfig, {
            token,
            [entityRouteParam]: value
        }).then((result: TBrainzEntity) => {
            setEntityValue(result);
            setLoading(false);
            return;
        });
    };

    useEffect(() => {
        const searchValueIsSelectedValue = entityValue !== null && searchValue === getOptionLabel(entityValue);

        if (open && !loading && (searchValue.length > 2 || loadAll) && !searchValueIsSelectedValue) {
            debouncedChangeHandler(searchValue);
            return;
        }

        if (searchValueIsSelectedValue) {
            return;
        }
        setRecords([]);
    }, [searchValue]);

    useEffect(() => {
        if (open && !loading && loadAll) {
            loadData('');
        }
    }, [open, token]);

    useEffect(() => {
        if (loadBeforeOpen && !loading && records.length === 0) {
            loadData('');
        }
    }, [loadBeforeOpen]);

    useEffect(() => {
        if (valueIsEntity) {
            return;
        }

        if (value === null || value === 0) {
            setEntityValue(null);
            return;
        }

        loadSelectedEntity();
    }, [value]);


    return {
        calculatedEntityValue: entityValue,
        records,
        loading
    };
}
