import React, { memo } from 'react';
import { IApplicationPartial } from '../../definitions/entities.definition';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { useDispatch } from 'react-redux';
import styles from './VacancyCellRenderer.module.css';
import { darker } from '../../theme/theme';
import { translate } from '../../translation/translate.utils';
import { setUiCloseTopDrawer, setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';

interface IProps {
    application?: IApplicationPartial;
}

const ApplicationCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const application = props.application;

    if (!application) {
        return (<>
            {translate('misc.noInformation')}
        </>);
    }

    const label = <>Bewerbung öffnen</>;

    return (
        <Tooltip title={translate('misc.openRecruiting')} placement="top-start">
            <Chip
                icon={IconFactory.getIconComponent(EIcons.AccountBox, {color: darker})}
                color="primary"
                className={styles.chip}
                label={label}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setUiCloseTopDrawer());
                    dispatch(setUiEditView(EEntityView.application, application.id));
                }}
            />
        </Tooltip>
    );
};

export default memo(ApplicationCellRenderer);
