import React, { memo } from 'react';
import {
    EActivityAction,
    EActivityModule,
    IActivity,
    IRecruitingPartial,
} from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { darker } from '../../../../theme/theme';
import { Chip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import styles from './ActivityFurtherCellRenderer.module.css';
import { setTodoToEditAction } from '../../../../redux/todo/todo.actions';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../definitions/ui.definitions';

interface IProps {
    activity: IActivity;
    displayFullName?: boolean;
    returnNullOnEmpty?: boolean;
}

const ActivityFurtherCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const isSuggestionAction =
        props.activity.action === EActivityAction.declineSuggestion ||
        props.activity.action === EActivityAction.acceptSuggestion ||
        props.activity.action === EActivityAction.editSuggestion ||
        props.activity.action === EActivityAction.addSuggestion;

    if (props.activity.recruiting && !isSuggestionAction) {
        return <Chip
            key={props.activity.id}
            icon={IconFactory.getIconComponent(EIcons.ThumbsUpDown, {color: darker})}
            color="primary"
            className={styles.chip}
            label={props.displayFullName ? props.activity.recruiting.candidate.fullName :translate('misc.openRecruiting')}
            onClick={(event) => {
                event.stopPropagation();
                // @ts-ignore
                const recruiting = props.activity.recruiting as IRecruitingPartial;
                dispatch(setUiEditView(EEntityView.recruiting, recruiting.id));
            }}
        />;
    }

    if (props.activity.application && props.activity.module == EActivityModule.application) {
        return <Chip
            key={props.activity.id}
            icon={IconFactory.getIconComponent(EIcons.AccountBox, {color: darker})}
            color="primary"
            className={styles.chip}
            label={props.displayFullName ? props.activity.application.candidate.fullName : 'Bewerbung öffnen'}
            onClick={(event) => {
                event.stopPropagation();
                // @ts-ignore
                dispatch(setUiEditView(EEntityView.application, props.activity.application.id))
            }}
        />;
    }
    if (props.activity.recruitingSuggestion) {
        return <Chip
            key={props.activity.id}
            icon={IconFactory.getIconComponent(EIcons.Star, {color: darker})}
            color="primary"
            className={styles.chip}
            label={props.displayFullName ? props.activity.recruitingSuggestion.fullName :translate('misc.openSuggestion')}
            onClick={(event) => {
                event.stopPropagation();
                if (!props.activity.recruitingSuggestion) {
                    return;
                }
                dispatch(setUiEditView(EEntityView.suggestion, props.activity.recruitingSuggestion.id));
            }}
        />;
    }
    if (props.activity.todo) {
        return <Chip
            key={props.activity.id}
            icon={IconFactory.getIconComponent(EIcons.Checkbox, {color: darker})}
            color="primary"
            className={styles.chip}
            label={translate('misc.openTodo')}
            onClick={(event) => {
                event.stopPropagation();
                // @ts-ignore
                dispatch(setTodoToEditAction(props.activity.todo.id))
            }}
        />;
    }

    if (props.returnNullOnEmpty) {
        return null;
    }
    return (<>
        {translate('misc.noInformation')}
    </>);

};

export default memo(ActivityFurtherCellRenderer);
