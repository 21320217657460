import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setBillingPageReloadInvoiceListAction } from '../../../redux/billingPage/billingPage.actions';
import { useLoadInvoice, useRecalculate } from './components/InvoiceAddEditView.hook';
import InvoiceItemsView from './components/InvoiceItemsView';
import InvoiceFooterView from './components/InvoiceFooterView';
import InvoiceHeaderView from './components/InvoiceHeaderView';
import { translate } from '../../../translation/translate.utils';
import { IInvoiceForApi } from '../../../definitions/entities.definition';
import { updateInvoiceAction } from '../../../redux/entities/entities.actions';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { getToken } from '../../../selectors/app.selectors';
import moment from 'moment';
import { API_URL } from '../../../requests/routes';
import InvoicePaymentRemindView from '../../BillingPage/components/components/InvoicePaymentRemindView';
import InvoiceDunningLetterView from '../../BillingPage/components/components/InvoiceDunningLetterView';
import { EDocumentStatus } from '../../../definitions/billingPage.definitions';

interface IProps {
    id: number;
}

const InvoiceView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const invoiceId = props.id;

    const invoice = useSelector((state: IStore) => state.entities.invoice.byId[invoiceId]);
    const invoiceForm = useSelector((state: IStore) => state.billingPage.invoiceForm);
    const invoiceFormItems = useSelector((state: IStore) => state.billingPage.invoiceForm.items);

    useLoadInvoice(invoiceId);
    useRecalculate();

    const onSave = (closeWindow: boolean) => {
        const invoiceForApi: IInvoiceForApi = {
            id: invoice.id,
            number: invoiceForm.numberDisabled ? undefined : invoiceForm.number,
            date: moment(invoiceForm.date).format("Y-MM-DD"),
            dueDate: moment(invoiceForm.dueDate).format("Y-MM-DD"),
            companyId: invoiceForm.company?.id,
            companyLocationId: invoiceForm.companyLocation?.id,
            companyContactId: invoiceForm.companyContact ? invoiceForm.companyContact.id : null,
            companyName: invoiceForm.companyName,
            address: invoiceForm.address,
            addressLine2: invoiceForm.addressLine2,
            zipCode: invoiceForm.zipCode,
            city: invoiceForm.city,
            discountInPercent: invoiceForm.discountInPercent,
            discountSum: invoiceForm.discountSum,
            comment: invoiceForm.comment,
            items: invoiceFormItems,
            termsOfPaymentId: invoiceForm.termsOfPaymentId,
            remindOfPaymentCreated: invoiceForm.remindOfPaymentCreated,
            remindOfPaymentDate: invoiceForm.remindOfPaymentDate ? moment(invoiceForm.remindOfPaymentDate).format("Y-MM-DD") : null,
            remindOfPaymentDueDate: invoiceForm.remindOfPaymentDueDate ? moment(invoiceForm.remindOfPaymentDueDate).format("Y-MM-DD") : null
        };
        dispatch(updateInvoiceAction(token, invoiceForApi, closeWindow));
        return;
    }

    if (invoiceId === 0) {
        return null;
    }

    return <>
        <div className={"flexContainerColumn flex"} style={{maxWidth: 1400}}>
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <InvoiceHeaderView formState={invoiceForm}/>
                {invoice && (invoice.isRemindAble || invoice.remindOfPaymentCreated) &&
                    <InvoicePaymentRemindView formState={invoiceForm} />
                }
                <InvoiceDunningLetterView invoice={invoice} />
                <InvoiceItemsView items={invoiceFormItems} />
                <InvoiceFooterView formState={invoiceForm} />
            </div>
            <CustomDialogActions
                buttons={[{
                    action: () => {
                        window.open(`${API_URL}/invoice/${invoiceId}/print?Authorization=${token}`);
                        dispatch(setBillingPageReloadInvoiceListAction(true));
                    },
                    label: translate('misc.print'),
                }, {
                    action: () => onSave(false),
                    label: translate('misc.buttons.save'),
                    hidden: invoice?.status === EDocumentStatus.closed
                }]}
            />
        </div>
    </>;
}
export default InvoiceView;

