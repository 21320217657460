import React from 'react';
import {
    ICustomTableColumn,
    ICustomTableColumnConfig,
    ITableHeaderCallbacks,
} from '../../definitions/components.definitions';
import { EEntities } from '../../definitions/entities.definition';
import { Divider, useTheme } from '@material-ui/core';
import CustomTableHeaderCell from './CustomTableHeaderCell';

interface IProps {
    entity: EEntities;
    tableHeaderCallbacks?: ITableHeaderCallbacks;
    columnConfig: ICustomTableColumnConfig;
    countActionButtons: number;
    color?: string;
    roundBorder?: boolean;
}

const CustomTableHeader: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const cells = Object.values(props.columnConfig).map((columnConfig: ICustomTableColumn, index) => {
        return (
            <CustomTableHeaderCell
                key={index + 1}
                entity={props.entity}
                tableHeaderCallbacks={props.tableHeaderCallbacks}
                value={columnConfig.header}
                columnConfig={columnConfig}
                color={props.color}
                align={columnConfig.align}
                startOrderDesc={columnConfig.startOrderDesc}
            />
        );
    });

    return <>
        <div
            className={"flexContainerRow"}
            style={{
                backgroundColor: props.color ? props.color : 'var(--brainz-darkest)',
                color: theme.palette.common.white,
                borderRadius: props.roundBorder ? '6px 6px 0px 0px' : '0px'
            }}
        >
                {cells}
            <div key={1} style={{width: 1, height: 40, padding: 5}} />
        </div>
        <Divider style={{background: 'var(--brainz-darker)', height: 2}} />
    </>;
};

export default CustomTableHeader;
