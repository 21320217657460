import { IRouteConfig } from '../definitions/requests.definitions';

let apiUrl = '';
if (process.env.NODE_ENV === 'development') {
    apiUrl = 'http://localhost:8000';
}
if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENV === 'staging') {
        apiUrl = 'https://api-staging.br4inz.de';
    } else {
        apiUrl = 'https://api.br4inz.de';
    }
}
export const API_URL = apiUrl;

export const getRandomGifUrl: IRouteConfig = {
    url: `https://api.giphy.com/v1/gifs/random?api_key=%apiKey%&tag=%tag%`,
    method: 'GET',
    parameter: ['apiKey', 'tag'],
}

export const loginRouteConfig: IRouteConfig = {
    url: `${API_URL}/auth/login`,
    method: 'POST',
    parameter: ['login', 'password'],
};

export const loginTokenRouteConfig: IRouteConfig = {
    url: `${API_URL}/auth/login/token`,
    method: 'POST',
    parameter: ['token'],
};

export const refreshTokenRouteConfig: IRouteConfig = {
    url: `${API_URL}/auth/token/refresh`,
    method: 'POST',
    parameter: ['token'],
};

export const changePasswordRouteConfig: IRouteConfig = {
    url: `${API_URL}/auth/user/changePassword`,
    method: 'POST',
    parameter: ['token', 'userId', 'userEmail', 'currentPassword', 'newPassword', 'newPasswordConfirmation'],
};

/**
 * WEB VACANCY ROUTES
 */
export const getWebVacancyListRouteConfig: IRouteConfig = {
    url: `${API_URL}/web-vacancies?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'jobTemplateId',
        'filterFastSearch',
        'onlyNotLinked',
        'onlyWithContracts',
        'responsibleEmployeeId',
        'branchIds',
        'companyIds',
        'skillIds',
        'orderBy',
        'orderDirection',
        'location',
        'radius',
        'languageId',
        'onlyPartTime',
        'remote',
        'onlyEqualToVacancyTitles',
        'dateRangeFilter'
    ],
    method: 'GET',
};
export const getWebVacancyRouteConfig: IRouteConfig = {
    url: `${API_URL}/web-vacancy/%webVacancyId%`,
    parameter: ['webVacancyId'],
    method: 'GET',
};
export const putWebVacancyRouteConfig: IRouteConfig = {
    url: `${API_URL}/web-vacancy`,
    method: 'PUT',
};

/**
 * GLOBAL ACTIVITY ROUTES
 */
export const getGlobalSearchListRouteConfig: IRouteConfig = {
    url: `${API_URL}/search?filterFastSearch=%filterFastSearch%`,
    parameter: ['filterFastSearch'],
    optionalParameter: ['searchInComments'],
    method: 'GET',
};
export const getSuccessActivityListRouteConfig: IRouteConfig = {
    url: `${API_URL}/success-activities`,
    method: 'GET',
};
export const getActivityListRouteConfig: IRouteConfig = {
    url: `${API_URL}/activities?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'modules',
        'module',
        'filterFastSearch',
        'orderBy',
        'orderDirection',
        'dateFrom',
        'dateTo',
        'type',
        'candidateId',
        'companyId',
        'vacancyId',
        'employeeId',
        'action',
        'withTimes',
        'timeRange',
        'recruitingId',
        'onlySuccessActions',
        'considerShowOnlyMineSetting'
    ],
    method: 'GET',
};
export const getActivityChatListRouteConfig: IRouteConfig = {
    url: `${API_URL}/activities/chat?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'modules',
        'filterFastSearch',
        'orderBy',
        'orderDirection',
        'dateFrom',
        'dateTo',
        'type',
        'candidateId',
        'suggestionId',
        'companyContactId',
        'companyId',
        'vacancyId',
        'employeeId',
        'action',
        'withTimes',
        'timeRange',
        'recruitingId',
        'onlySuccessActions',
        'considerShowOnlyMineSetting',
        'onlyWithContact'
    ],
    method: 'GET',
};
export const getActivityRouteConfig: IRouteConfig = {
    url: `${API_URL}/activity/%activityId%`,
    method: 'GET',
    parameter: ['activityId'],
};

/**
 * INVOICE ROUTES
 */
export const deleteInvoiceRouteConfig: IRouteConfig = {
    url: `${API_URL}/invoice`,
    method: 'DELETE',
};
export const postDunningLetterRouteConfig: IRouteConfig = {
    url: `${API_URL}/dunning-letter`,
    method: 'POST',
}
export const putDunningLetterRouteConfig: IRouteConfig = {
    url: `${API_URL}/dunning-letter`,
    method: 'PUT',
}
export const getInvoiceGroupedEvaluationRouteConfig: IRouteConfig = {
    url: `${API_URL}/invoice-grouped-evaluation?year=%year%&groupBy=%groupBy%`,
    parameter: ['year', 'groupBy'],
    method: 'GET',
    optionalParameter: ['employeeId'],
};
export const getInvoiceListRouteConfig: IRouteConfig = {
    url: `${API_URL}/invoices?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    method: 'GET',
    optionalParameter: [
        'dateFrom',
        'recruitingId',
        'companyId',
        'dateTo',
        'filterFastSearch',
        'orderBy',
        'orderDirection',
        'companyId',
        'status'
    ],
};
export const getInvoiceRouteConfig: IRouteConfig = {
    url: `${API_URL}/invoice/%id%`,
    parameter: ['id'],
    method: 'GET',
};
export const getFutureInvoiceListRouteConfig: IRouteConfig = {
    url: `${API_URL}/future-invoices`,
    method: 'GET',
}
export const getBillableRecruitingListRouteConfig: IRouteConfig = {
    url: `${API_URL}/billable-recruitings`,
    method: 'GET',
}
export const postInvoiceListRouteConfig: IRouteConfig = {
    url: `${API_URL}/generate-invoices`,
    method: 'POST',
}
export const downloadPrintInvoiceRouteConfig: IRouteConfig = {
    url: `${API_URL}/invoice/%invoiceId%/print?preview=%preview%`,
    parameter: ['invoiceId', 'preview'],
    method: 'GET',
}
export const postPaymentRouteConfig: IRouteConfig = {
    url: `${API_URL}/invoice/%invoiceId%/payment`,
    parameter: ['invoiceId'],
    method: 'POST',
}
export const postInvoiceRouteConfig: IRouteConfig = {
    url: `${API_URL}/invoice`,
    method: 'POST',
}
export const putInvoiceRouteConfig: IRouteConfig = {
    url: `${API_URL}/invoice`,
    method: 'PUT',
}

/**
 * TARGET ROUTES
 */
export const getTargetListByMonthRouteConfig: IRouteConfig = {
    url: `${API_URL}/target-month-list?year=%year%`,
    parameter: ['year'],
    method: 'GET',
    optionalParameter: ['employeeId'],
};

/**
 * PROCESS AGGREGATION ROUTES
 */
export const getProcessAggregationListByCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/process-aggregation`,
    parameter: ['candidateId'],
    method: 'GET',
};

/**
 * RECRUITING ROUTES
 */
export const getRecruitingGroupedEvaluationRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting-grouped-evaluation?year=%year%&groupBy=%groupBy%`,
    parameter: ['year', 'groupBy'],
    method: 'GET',
    optionalParameter: ['employeeId'],
};
export const getRecruitingListRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruitings?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    method: 'GET',
    optionalParameter: [
        'filterFastSearch',
        'orderBy',
        'orderDirection',
        'employeeId',
        'vacancyId',
        'status',
        'candidateId',
        'companyId',
        'companyContactId',
        'statusArray',
        'companyId',
        'month',
        'year',
        'dateFrom',
        'dateTo',
        'possibleCommissionFrom',
        'possibleCommissionTo',
        'suggestedByEmployeeId',
        'branchId',
        'candidateSource',
        'hideProjects',
        'onlyOpen'
    ],
};


export const getRecruitingGroupedListRouteConfig: IRouteConfig = {
    url: `${API_URL}/analytics/recruitings-grouped?year=%year%`,
    parameter: ['year'],
    optionalParameter: ['month', 'employeeId'],
    method: 'GET',
};

export const getRecruitingRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting/%recruitingId%`,
    method: 'GET',
    parameter: ['recruitingId'],
};
export const getCongratulationMailTemplateRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting/%recruitingId%/congratulation-mail?`,
    method: 'GET',
    parameter: ['recruitingId'],
    optionalParameter: ['useFormalTerm', 'languageId']
};
export const postCongratulationMailRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting/%recruitingId%/congratulation-mail`,
    method: 'POST',
    parameter: ['recruitingId'],
};
export const postRecruitingRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting`,
    method: 'POST',
};
export const postRecruitingActionRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting-action`,
    method: 'POST',
};
export const putRecruitingRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting`,
    method: 'PUT',
};
export const deleteRecruitingRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting`,
    method: 'DELETE',
};

/**
 * CONTRACT ROUTES
 */
export const postContractRouteConfig: IRouteConfig = {
    url: `${API_URL}/contract`,
    method: 'POST',
};
export const getContractRouteConfig: IRouteConfig = {
    url: `${API_URL}/contract/%contractId%`,
    method: 'GET',
    parameter: ['contractId'],
};
export const putContractRouteConfig: IRouteConfig = {
    url: `${API_URL}/contract/%contractId%`,
    method: 'PUT',
    parameter: ['contractId'],
};
export const deleteContractRouteConfig: IRouteConfig = {
    url: `${API_URL}/contract/%contractId%`,
    method: 'DELETE',
    parameter: ['contractId'],
};

/**
 * RECRUITING DOCUMENT ROUTES
 */
export const getRecruitingDocumentsRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting-documents?recruitingId=%recruitingId%&start=%start%&limit=%limit%`,
    parameter: ['recruitingId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection'],
    method: 'GET',
};
export const postRecruitingDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting-document`,
    method: 'POST',
};
export const putRecruitingDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting-document`,
    method: 'PUT',
};
export const deleteRecruitingDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting-document`,
    parameter: ['id'],
    method: 'DELETE',
};
export const downloadRecruitingDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting-document/%recruitingDocumentId%/download`,
    parameter: ['recruitingDocumentId'],
    method: 'GET',
};

/**
 * RECRUITING SUGGESTION ROUTES
 */
export const postSuggestionActivityRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion-activity`,
    method: 'POST',
};
export const postSuggestionRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion`,
    method: 'POST',
};
export const putSuggestionRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion`,
    method: 'PUT',
};
export const deleteSuggestionRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion`,
    method: 'DELETE',
};
export const putSuggestionDeclineRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion/%recruitingSuggestionId%/decline`,
    method: 'PUT',
    parameter: ['recruitingSuggestionId'],
};
export const putSuggestionReopenRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion/%recruitingSuggestionId%/reopen`,
    method: 'PUT',
    parameter: ['recruitingSuggestionId'],
};
export const getSuggestionRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion/%recruitingSuggestionId%`,
    method: 'GET',
    parameter: ['recruitingSuggestionId'],
};


export const getDeclineSuggestionMailTemplateRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion/%suggestionId%/decline-mail?`,
    method: 'GET',
    parameter: ['suggestionId'],
    optionalParameter: ['variant', 'languageId', 'useFormalTerm']
};

export const getSuggestionListRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestions?start=%start%&limit=%limit%`,
    method: 'GET',
    parameter: ['start', 'limit'],
    optionalParameter: [
        'contact',
        'companyLocationId',
        'vacancyId',
        'candidateId',
        'status',
        'declineReason',
        'source',
        'dateRange',
        'responsibleEmployeeId',
        'minimumRating',
        'orderBy',
        'orderDirection',
        'onlyOpenVacancies',
        'vacancyResponsibleEmployeeId',
        'suggestedByEmployeeId',
        'filterFastSearch',
        'externalCandidateReference'
    ],
};

/**
 * COUNTRY ROUTES
 */
export const getCountryListRouteConfig: IRouteConfig = {
    url: `${API_URL}/countries`,
    method: 'GET',
};

/**
 * LANGUAGE ROUTES
 */
export const getLanguageListRouteConfig: IRouteConfig = {
    url: `${API_URL}/languages`,
    optionalParameter: [
        'onlyWithWebVacancies'
    ],
    method: 'GET',
};

/**
 * COMPANY ROUTES
 */
export const getCompanyListRouteConfig: IRouteConfig = {
    url: `${API_URL}/companies?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'filterFastSearch',
        'isCustomer',
        'rating',
        'name',
        'taxNumber',
        'responsibleEmployeeId',
        'salesTaxIdentificationNumber',
        'comment',
        'employeeSizeCategory',
        'homepageUrl',
        'mailAddress',
        'phoneNumber',
        'branchIds',
        'orderBy',
        'orderDirection',
        'onlyWithCrawler',
        'onlyWithRecruitings',
        'onlyWithInvoice',
        'onlyWithVacancies',
        'onlyWithoutParent'
    ],
    method: 'GET',
};
export const postCompanyRouteConfig: IRouteConfig = {
    url: `${API_URL}/company`,
    method: 'POST',
};
export const putCompanyRouteConfig: IRouteConfig = {
    url: `${API_URL}/company`,
    method: 'PUT',
};
export const deleteCompanyRouteConfig: IRouteConfig = {
    url: `${API_URL}/company`,
    method: 'DELETE',
};
export const getCompanyRouteConfig: IRouteConfig = {
    url: `${API_URL}/company/%companyId%`,
    method: 'GET',
    parameter: ['companyId'],
};

/**
 * CANDIDATE ROUTES
 */

export const getBirthdayMailTemplateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/birthday-mail?`,
    method: 'GET',
    parameter: ['candidateId'],
    optionalParameter: ['useFormalTerm', 'languageId', 'vacancyId']
};
export const putMergeCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/merge`,
    method: 'PUT'
};
export const postBirthdayMailRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/birthday-mail?`,
    method: 'POST',
    parameter: ['candidateId'],
    optionalParameter: ['useFormalTerm', 'languageId', 'vacancyId']
};

export const postCandidateMailAddressRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/mail-address`,
    parameter: ['candidateId'],
    method: 'POST',
};

export const putCandidateMailAddressRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/mail-address/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'PUT',
};

export const deleteCandidateMailAddressRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/mail-address/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'DELETE',
};


export const postCandidateAddressRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/address`,
    parameter: ['candidateId'],
    method: 'POST',
};

export const putCandidateAddressRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/address/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'PUT',
};

export const deleteCandidateAddressRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/address/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'DELETE',
};


export const postCandidatePhoneRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/phone`,
    parameter: ['candidateId'],
    method: 'POST',
};

export const putCandidatePhoneRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/phone/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'PUT',
};

export const deleteCandidatePhoneRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/phone/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'DELETE',
};


export const postCandidateUrlRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/url`,
    parameter: ['candidateId'],
    method: 'POST',
};

export const putCandidateUrlRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/url/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'PUT',
};

export const deleteCandidateUrlRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/url/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'DELETE',
};

export const postCandidateProfessionRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/profession`,
    parameter: ['candidateId'],
    method: 'POST',
};

export const putCandidateProfessionRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/profession/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'PUT',
};

export const deleteCandidateProfessionRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/profession/%id%`,
    parameter: ['candidateId', 'id'],
    method: 'DELETE',
};

export const getExternalCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/external-candidate?source=%source%&url=%url%`,
    parameter: ['source', 'url'],
    method: 'GET'
}

export const getCandidatePartialListRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidates/partial?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'orderBy',
        'orderDirection',
        'filterFastSearch'
    ],
    method: 'GET',
};

export const getCandidateDuplicatesRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/check-duplicates`,
    optionalParameter: [
        'firstName',
        'name',
        'zipCode',
        'externalReference',
        'ignoreId'
    ],
    method: 'GET',
}

export const getCandidateListRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidates?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'orderBy',
        'hasDocuments',
        'orderDirection',
        'filterFastSearch',
        'topCandidate',
        'salutation',
        'skillIds',
        'optionalSkillIds',
        'languageIds',
        'leader',
        'yearsOfWorkExperience',
        'jobDescription',
        'distanceSearchCompanyLocationId',
        'distanceSearchKm',
        'ageFrom',
        'ageTo',
        'checkInTouchWithVacancyId',
        'responsibleEmployeeId',
        'salaryFrom',
        'salaryTo',
        'searchForVacancyId',
        'searchForWebVacancyId',
        'onlyFollowups',
        'source',
        'isFreelancer',
        'homeOfficeDesire',
        'hasDriverLicense',
        'source',
        'dateRangeFilter',
        'recruitingFilter',
        'loadHasContact',
        'filterNameSearch',
        'topInCommunication'
    ],
    method: 'GET',
};

export const postCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate`,
    method: 'POST',
};

export const putCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate`,
    method: 'PUT',
};

export const putCandidateImportDataRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/import-data`,
    method: 'PUT',
    parameter: ['candidateId'],
};

export const getCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%`,
    method: 'GET',
    parameter: ['candidateId'],
};

export const getCandidatePartialRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/partial`,
    method: 'GET',
    parameter: ['candidateId'],
};

export const getCandidateProfilesRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/profiles`,
    method: 'GET',
    parameter: ['candidateId'],
};
export const getParseCandidateCvDataRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/parse-cv`,
    method: 'GET',
    parameter: ['candidateId'],
};
export const getCandidatePhotoRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/photo`,
    method: 'GET',
    parameter: ['candidateId'],
};

export const postParseCvDataRouteConfig: IRouteConfig = {
    url: `${API_URL}/parse-cv`,
    method: 'POST'
};

export const getCandidatesCreatedNewRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidates?orderBy=candidate.created&orderDirection=DESC&createdSince=%createdSince%`,
    method: 'GET',
    parameter: ['createdSince'],
    optionalParameter: [
        'orderBy',
        'orderDirection'
    ]
};

export const getCandidatesWithBirthdayRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidates?hasBirthday=1`,
    method: 'GET',
    optionalParameter: [
        'orderBy',
        'orderDirection'
    ]
};

export const deleteCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate`,
    method: 'DELETE',
};

/**
 * NOTIFY
 */
export const getNotifyUnreadCountRouteConfig: IRouteConfig = {
    url: `${API_URL}/notify/count-unread`,
    method: 'GET',
};

export const getNotificationsRouteConfig: IRouteConfig = {
    url: `${API_URL}/notifications`,
    method: 'GET',
};

export const putNotificationReadRouteConfig: IRouteConfig = {
    url: `${API_URL}/notification/%id%/read`,
    parameter: [
        'id',
    ],
    method: 'GET',
};

/**
 * MAILING
 */
export const getMailRouteConfig: IRouteConfig = {
    url: `${API_URL}/mail/%id%`,
    parameter: [
        'id',
    ],
    method: 'GET',
};

export const putMailRouteConfig: IRouteConfig = {
    url: `${API_URL}/mail/%id%`,
    parameter: [
        'id',
    ],
    method: 'PUT',
};

export const postMailRelationRouteConfig: IRouteConfig = {
    url: `${API_URL}/mail/%id%/relation`,
    parameter: [
        'id',
    ],
    method: 'POST',
};

export const getMailListByFolderRouteConfig: IRouteConfig = {
    url: `${API_URL}/folder/%folderId%/mails?start=%start%&limit=%limit%`,
    parameter: ['folderId', 'start', 'limit'],
    optionalParameter: [
        'mailboxId',
        'syncFolder'
    ],
    method: 'GET',
};

export const getMailListBySearchRouteConfig: IRouteConfig = {
    url: `${API_URL}/mails?start=%start%&limit=%limit%&filterFastSearch=%fastSearch%&searchAllMailBoxes=%searchAllMailBoxes%`,
    parameter: ['fastSearch', 'start', 'limit', 'searchAllMailBoxes'],
    method: 'GET',
};

export const getMailListByChannelRouteConfig: IRouteConfig = {
    url: `${API_URL}/mails`,
    optionalParameter: [
        'channel'
    ],
    method: 'GET',
};

export const deleteMailRelationRouteConfig: IRouteConfig = {
    url: `${API_URL}/mail-relation/%id%`,
    parameter: [
        'id',
    ],
    method: 'DELETE',
};

export const putMarkMailAsReadRouteConfig: IRouteConfig = {
    url: `${API_URL}/mail/%id%/read`,
    parameter: [
        'id',
    ],
    method: 'PUT',
};

export const putMoveMailRouteConfig: IRouteConfig = {
    url: `${API_URL}/mail/%id%/move`,
    parameter: [
        'id',
    ],
    method: 'PUT',
};

export const getMailboxMailMessageRouteConfig: IRouteConfig = {
    url: `${API_URL}/mailbox-mail/%id%/message`,
    parameter: [
        'id',
    ],
    method: 'GET',
};

export const fetchMailboxRouteConfig: IRouteConfig = {
    url: `${API_URL}/mailbox/fetch`,
    method: 'GET',
};

export const getMailboxFolderTreeRouteConfig: IRouteConfig = {
    url: `${API_URL}/mailbox/folder-tree?mailboxId=%mailboxId%`,
    parameter: [
        'mailboxId'
    ],
    method: 'GET',
};

export const getMailboxListRouteConfig: IRouteConfig = {
    url: `${API_URL}/mailboxes`,
    method: 'GET',
};

/**
 * NEWS
 */
export const getNewsListRouteConfig: IRouteConfig = {
    url: `${API_URL}/news?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    method: 'GET',
};
export const putNewsReadTimestampRouteConfig: IRouteConfig = {
    url: `${API_URL}/news/set-read-timestamp`,
    method: 'PUT',
};

/**
 * APPLICATION
 */
export const postApplicationRouteConfig: IRouteConfig = {
    url: `${API_URL}/application`,
    method: 'POST',
};

export const getApplicationRouteConfig: IRouteConfig = {
    url: `${API_URL}/application/%applicationId%`,
    method: 'GET',
    parameter: ['applicationId']
};

export const getApplicationListRouteConfig: IRouteConfig = {
    url: `${API_URL}/applications?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'orderBy',
        'onlyMineAndUnassigned',
        'status',
        'companyId',
        'candidateId',
        'employeeId',
        'vacancyId',
        'orderDirection',
        'filterFastSearch',
        'dateFrom',
        'dateTo'
    ],
    method: 'GET',
};

export const getDeclineApplicationMailTemplateRouteConfig: IRouteConfig = {
    url: `${API_URL}/application/%applicationId%/decline-mail?`,
    method: 'GET',
    parameter: ['applicationId'],
    optionalParameter: ['variant', 'languageId', 'useFormalTerm']
};

export const putDeclineApplicationRouteConfig: IRouteConfig = {
    url: `${API_URL}/application/%applicationId%/decline`,
    method: 'PUT',
    parameter: ['applicationId']
};

export const putApplicationRouteConfig: IRouteConfig = {
    url: `${API_URL}/application/%id%`,
    method: 'PUT',
    parameter: ['id']
};

/**
 * TODOS
 */
export const putTodosReadTimestampRouteConfig: IRouteConfig = {
    url: `${API_URL}/todos/set-read-timestamp`,
    method: 'PUT',
};
export const getTodoRouteConfig: IRouteConfig = {
    url: `${API_URL}/todo/%todoId%`,
    method: 'GET',
    parameter: ['todoId'],
};
export const postTodoRouteConfig: IRouteConfig = {
    url: `${API_URL}/todo`,
    method: 'POST',
};
export const putTodoRouteConfig: IRouteConfig = {
    url: `${API_URL}/todo`,
    method: 'PUT',
};
export const deleteTodoRouteConfig: IRouteConfig = {
    url: `${API_URL}/todo`,
    parameter: ['id'],
    method: 'DELETE',
};
export const getTodoListOfEmployeeRouteConfig: IRouteConfig = {
    url: `${API_URL}/employee-todos`,
    method: 'GET',
};
export const getTodoListRouteConfig: IRouteConfig = {
    url: `${API_URL}/todos`,
    optionalParameter: ['onlyOpen', 'filterFastSearch', 'employeeId'],
    method: 'GET',
};

/**
 * ANALYTICS
 */
export const getDashboardDataRouteConfig: IRouteConfig = {
    url: `${API_URL}/analytics/dashboard`,
    method: 'GET',
};
export const getCompanyMetricsDataRouteConfig: IRouteConfig = {
    url: `${API_URL}/company/%companyId%/analytics`,
    parameter: ['companyId'],
    optionalParameter: ['dateFrom', 'dateTo', 'orderBy', 'orderDirection'],
    method: 'GET',
};
export const getEmployeePerformanceRouteConfig: IRouteConfig = {
    url: `${API_URL}/analytics/employee-performance`,
    method: 'GET',
    optionalParameter: ['year', 'month', 'orderBy', 'orderDirection'],
};
export const getEmployeeLeagueRouteConfig: IRouteConfig = {
    url: `${API_URL}/analytics/employee-league`,
    method: 'GET',
};

/**
 * CANDIDATE DOCUMENT ROUTES
 */
export const getCandidateDocumentsForCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate-documents?candidateId=%candidateId%`,
    parameter: ['candidateId'],
    optionalParameter: ['orderBy', 'orderDirection'],
    method: 'GET',
};
export const postCandidateDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate-document`,
    method: 'POST',
};
export const putCandidateDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate-document`,
    method: 'PUT',
};
export const postCandidateDocumentImportRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate-document/import`,
    method: 'POST',
};
export const deleteCandidateDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate-document`,
    parameter: ['id'],
    method: 'DELETE',
};
export const downloadCandidateDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate-document/%candidateDocumentId%/download`,
    parameter: ['candidateDocumentId'],
    method: 'GET',
};

/**
 * COMPANY DOCUMENT ROUTES
 */
export const getCompanyDocumentsForCompanyRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-documents?companyId=%companyId%&start=%start%&limit=%limit%`,
    parameter: ['companyId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection'],
    method: 'GET',
};
export const postCompanyDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-document`,
    method: 'POST',
};
export const putCompanyDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-document`,
    method: 'PUT',
};
export const deleteCompanyDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-document`,
    parameter: ['id'],
    method: 'DELETE',
};
export const downloadCompanyDocumentRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-document/%companyDocumentId%/download`,
    parameter: ['companyDocumentId'],
    method: 'GET',
};

/**
 * GENERAL ACTIVITY ROUTES
 */
export const postActivityRouteConfig: IRouteConfig = {
    url: `${API_URL}/activity`,
    method: 'POST',
};
export const putActivityRouteConfig: IRouteConfig = {
    url: `${API_URL}/activity`,
    method: 'PUT',
};
export const postContractOfferRouteConfig: IRouteConfig = {
    url: `${API_URL}/contract-offer`,
    method: 'POST',
};
export const putContractOfferRouteConfig: IRouteConfig = {
    url: `${API_URL}/contract-offer`,
    method: 'PUT',
};
export const postInterviewRouteConfig: IRouteConfig = {
    url: `${API_URL}/interview`,
    method: 'POST',
};
export const putInterviewRouteConfig: IRouteConfig = {
    url: `${API_URL}/interview`,
    method: 'PUT',
};
export const deleteActivityRouteConfig: IRouteConfig = {
    url: `${API_URL}/activity`,
    parameter: ['id'],
    method: 'DELETE',
};

export const getActivityListForCandidateRouteConfig: IRouteConfig = {
    url: `${API_URL}/candidate/%candidateId%/activities?start=%start%&limit=%limit%`,
    parameter: ['candidateId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection', 'filterFastSearch'],
    method: 'GET',
};

export const getActivityListForApplicationRouteConfig: IRouteConfig = {
    url: `${API_URL}/application/%applicationId%/activities?start=%start%&limit=%limit%`,
    parameter: ['applicationId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection', 'filterFastSearch'],
    method: 'GET',
};

export const getActivityListForCompanyRouteConfig: IRouteConfig = {
    url: `${API_URL}/company/%companyId%/activities?start=%start%&limit=%limit%`,
    parameter: ['companyId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection', 'filterFastSearch', 'modules', 'onlyWithContact'],
    method: 'GET',
};

export const getActivityListForVacancyRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy/%vacancyId%/activities?start=%start%&limit=%limit%`,
    parameter: ['vacancyId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection', 'filterFastSearch', 'modules'],
    method: 'GET',
};

export const getActivityListForWebVacancyRouteConfig: IRouteConfig = {
    url: `${API_URL}/web-vacancy/%webVacancyId%/activities?start=%start%&limit=%limit%`,
    parameter: ['webVacancyId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection', 'filterFastSearch'],
    method: 'GET',
};

export const getActivityListForSuggestionRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion/%suggestionId%/activities?start=%start%&limit=%limit%`,
    parameter: ['suggestionId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection', 'filterFastSearch'],
    method: 'GET',
};

export const getActivityListForTodoRouteConfig: IRouteConfig = {
    url: `${API_URL}/todo/%todoId%/activities?start=%start%&limit=%limit%`,
    parameter: ['todoId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection', 'filterFastSearch'],
    method: 'GET',
};

export const getActivityListForRecruitingRouteConfig: IRouteConfig = {
    url: `${API_URL}/recruiting/%recruitingId%/activities?start=%start%&limit=%limit%`,
    parameter: ['recruitingId', 'start', 'limit'],
    optionalParameter: ['orderBy', 'orderDirection', 'filterFastSearch'],
    method: 'GET',
};

/**
 * VACANCY ROUTES
 */
export const getVacancyListRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancies?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'filterFastSearch',
        'completed',
        'companyId',
        'title',
        'description',
        'comment',
        'responsibleEmployeeId',
        'skillIds',
        'optionalSkillIds',
        'orderBy',
        'orderDirection',
        'onlyWithUrl'
    ],
    method: 'GET',
};

export const getSuggestionGroupListRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy/%vacancyId%/suggestion-groups`,
    parameter: ['vacancyId'],
    method: 'GET',
};

export const postSuggestionGroupRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion-group`,
    method: 'POST',
};

export const putSuggestionGroupRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion-group/%groupId%`,
    parameter: ['groupId'],
    method: 'PUT',
};

export const getSuggestionGroupRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion-group/%groupId%`,
    parameter: ['groupId'],
    method: 'GET',
};

export const deleteSuggestionGroupRouteConfig: IRouteConfig = {
    url: `${API_URL}/suggestion-group/%groupId%`,
    parameter: ['groupId'],
    method: 'DELETE',
};

export const getVacancyListOfCompanyRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancies?companyId=%companyId%&start=%start%&limit=%limit%`,
    parameter: ['companyId', 'start', 'limit'],
    optionalParameter: [
        'filterFastSearch',
        'completed',
        'orderBy',
        'orderDirection'
    ],
    method: 'GET',
};

export const getVacancyListOfCompanyContactRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancies?companyContactId=%companyContactId%&start=%start%&limit=%limit%`,
    parameter: ['companyContactId', 'start', 'limit'],
    optionalParameter: [
        'filterFastSearch',
        'completed',
        'orderBy',
        'orderDirection'
    ],
    method: 'GET',
};

export const postVacancyRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy`,
    method: 'POST',
};
export const postVacancyPublicationRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy/%vacancyId%/publication`,
    parameter: ['vacancyId'],
    method: 'POST',
};
export const putVacancyPublicationRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy/%vacancyId%/publication/%id%`,
    parameter: ['vacancyId', 'id'],
    method: 'PUT',
};
export const deleteVacancyPublicationRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy/%vacancyId%/publication/%id%`,
    parameter: ['vacancyId', 'id'],
    method: 'DELETE',
};
export const putVacancyRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy`,
    method: 'PUT',
};
export const putVacancyBulkUpdateInvoiceCreationVariantRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy-invoice-creation`,
    method: 'PUT',
};
export const deleteVacancyRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy`,
    parameter: ['id'],
    method: 'DELETE',
};
export const getVacancyRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy/%vacancyId%`,
    method: 'GET',
    parameter: ['vacancyId'],
};
export const getVacancyStatisticRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy/%vacancyId%/statistic?`,
    method: 'GET',
    parameter: ['vacancyId'],
    optionalParameter: ['timeRange', 'declineReasonSource']
};

export const getVacancyAndWebVacancyListRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancy-and-web-vacancies?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'filterFastSearch',
        'onlyWithContracts',
        'responsibleEmployeeId',
        'searchForCandidateId',
        'branchIds',
        'companyIds',
        'skillIds',
        'orderBy',
        'orderDirection',
        'distance',
        'languageId',
        'onlyPartTime',
        'remote'
    ],
    method: 'GET',
};

/**
 * COMPANY CONTACT ROUTES
 */
export const getCompanyContactRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-contact/%contactId%`,
    method: 'GET',
    parameter: ['contactId'],
};
export const getCompanyContactListRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-contacts`,
    method: 'GET',
    optionalParameter: ['orderBy', 'orderDirection', 'companyId', 'hasBirthday','withoutBirthDate', 'filterFastSearch']
};
export const postCompanyContactRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-contact`,
    parameter: ['companyId'],
    method: 'POST',
};
export const putCompanyContactRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-contact`,
    method: 'PUT',
};
export const deleteCompanyContactRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-contact`,
    parameter: ['id'],
    method: 'DELETE',
};

/**
 * COMPANY VACANCIES ROUTES
 */
export const getVacanciesOfCompanyRouteConfig: IRouteConfig = {
    url: `${API_URL}/vacancies?companyId=%companyId%&start=%start%&limit=%limit%`,
    parameter: ['start', 'limit', 'companyId'],
    optionalParameter: ['orderBy', 'orderDirection'],
    method: 'GET',
};

/**
 * COMPANY LOCATION ROUTES
 */
export const getCompanyLocationListRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-locations`,
    optionalParameter: ['companyId', 'orderBy', 'orderDirection', 'filterFastSearch'],
    method: 'GET',
};
export const getCompanyLocationRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-location/%locationId%`,
    method: 'GET',
    parameter: ['locationId'],
};
export const postCompanyLocationRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-location`,
    method: 'POST',
};
export const putCompanyLocationRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-location`,
    method: 'PUT',
};
export const deleteCompanyLocationRouteConfig: IRouteConfig = {
    url: `${API_URL}/company-location`,
    parameter: ['id'],
    method: 'DELETE',
};

/**
 * EMPLOYEE ROUTES
 */
export const getEmployeeListRouteConfig: IRouteConfig = {
    url: `${API_URL}/employees?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'filterFastSearch',
        'orderBy',
        'orderDirection',
        'hidden',
        'active'
    ],
    method: 'GET',
};
export const getEmployeeRouteConfig: IRouteConfig = {
    url: `${API_URL}/employee/%employeeId%`,
    method: 'GET',
    parameter: ['employeeId'],
};
export const postEmployeeRouteConfig: IRouteConfig = {
    url: `${API_URL}/employee`,
    method: 'POST',
};
export const putEmployeeRouteConfig: IRouteConfig = {
    url: `${API_URL}/employee`,
    method: 'PUT',
};
export const putEmployeeShowMineRouteConfig: IRouteConfig = {
    url: `${API_URL}/employee/onlyShowMine`,
    method: 'PUT',
};

/**
 * JOB TITLE ROUTES
 */
export const getJobTitleListRouteConfig: IRouteConfig = {
    url: `${API_URL}/job-titles?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'filterFastSearch',
        'skillIds',
        'onlyWithoutJobTemplate',
        'orderBy',
        'orderDirection',
    ],
    method: 'GET',
};
export const putJobTitleRouteConfig: IRouteConfig = {
    url: `${API_URL}/job-title`,
    method: 'PUT',
};

/**
 * JOB TEMPLATE ROUTES
 */
export const getJobTemplateListRouteConfig: IRouteConfig = {
    url: `${API_URL}/job-templates?start=%start%&limit=%limit%`,
    parameter: ['start', 'limit'],
    optionalParameter: [
        'filterFastSearch',
        'orderBy',
        'orderDirection'
    ],
    method: 'GET',
};
export const getJobTemplateNameListRouteConfig: IRouteConfig = {
    url: `${API_URL}/job-template-names`,
    method: 'GET',
};
export const getJobTemplateRouteConfig: IRouteConfig = {
    url: `${API_URL}/job-template/%jobTemplateId%`,
    method: 'GET',
    parameter: ['jobTemplateId'],
};
export const postJobTemplateRouteConfig: IRouteConfig = {
    url: `${API_URL}/job-template`,
    method: 'POST',
};

export const putJobTemplateRouteConfig: IRouteConfig = {
    url: `${API_URL}/job-template`,
    method: 'PUT',
};

/**
 * BRANCH ROUTES
 */
export const getBranchListRouteConfig: IRouteConfig = {
    url: `${API_URL}/branches`,
    method: 'GET',
};

/**
 * BILLING CONFIGURATION ROUTES
 */
export const putBillingConfigurationRouteConfig: IRouteConfig = {
    url: `${API_URL}/billing-configuration`,
    method: 'PUT',
};

/**
 * BILLING VARINT TEMPLATE ROUTES
 */
export const getBillingVariantTemplateListRouteConfig: IRouteConfig = {
    url: `${API_URL}/billing-variant-templates`,
    method: 'GET',
};

/**
 * SKILL ROUTES
 */
export const getSkillConfig: IRouteConfig = {
    url: `${API_URL}/skill/%skillId%`,
    method: 'GET',
    parameter: ['skillId'],
};

export const getExternalSkillConfig: IRouteConfig = {
    url: `${API_URL}/external-skill/%skillId%`,
    method: 'GET',
    parameter: ['skillId'],
};

export const deleteSkillRouteConfig: IRouteConfig = {
    url: `${API_URL}/skill`,
    method: 'DELETE',
};

export const getSkillListConfig: IRouteConfig = {
    url: `${API_URL}/skills`,
    method: 'GET',
    optionalParameter: [
        'start',
        'orderBy',
        'orderDirection',
        'limit',
        'filterFastSearch'
    ],
};

export const getSkillDetailsListConfig: IRouteConfig = {
    url: `${API_URL}/skill-details`,
    optionalParameter: [
        'filterFastSearch',
        'orderBy',
        'orderDirection',
        'suggestForJobTitle',
        'jobTemplateId'
    ],
    method: 'GET',
};

export const getSkillTreeRouteConfig: IRouteConfig = {
    url: `${API_URL}/skill-tree`,
    optionalParameter: [
        'filterFastSearch',
        'usedCountFrom',
        'usedCountTo',
        'onlyWithUsages',
        'onlyWithoutUsages',
        'onlyWithKeywords',
        'onlyWithoutKeywords',
        'jobTemplateId',
        'suggestForJobTitle'
    ],
    method: 'GET',
};

export const postSkillRouteConfig: IRouteConfig = {
    url: `${API_URL}/skill`,
    method: 'POST',
};

export const putSkillRouteConfig: IRouteConfig = {
    url: `${API_URL}/skill`,
    method: 'PUT',
};

export const putExternalSkillRouteConfig: IRouteConfig = {
    url: `${API_URL}/external-skill`,
    method: 'PUT',
};

/**
 * MAIL ADRESSES
 */
export const getMailAddressesRouteConfig: IRouteConfig = {
    url: `${API_URL}/mail-addresses`,
    method: 'GET',
};
