import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerError, registerInfo } from '../../../../../../redux/error/error.actions';
import { EIcons } from '../../../../../../components/Icons/IconFactory';
import {
    deleteVacancyPublicationRouteConfig,
    putVacancyPublicationRouteConfig,
} from '../../../../../../requests/routes';
import { IListActionButtonConfig } from '../../../../../../definitions/components.definitions';
import CustomEditableValue from '../../../../../../components/CustomInput/CustomEditableValue';
import CustomTextField from '../../../../../../components/CustomInput/CustomTextField';
import { getToken } from '../../../../../../selectors/app.selectors';
import CustomContextMenuEntity from '../../../../../../components/CustomContextMenu/CustomContextMenuEntity';
import {
    EPublicationSource,
    ICandidateMailAddress,
    IVacancyPublication,
} from '../../../../../../definitions/entities.definition';
import { ClientApi } from '../../../../../../requests/ClientApi';
import Nl2brCellRenderer from '../../../../../../components/CustomCellRenderer/Nl2brCellRenderer';
import { translate } from '../../../../../../translation/translate.utils';
import UrlCellRenderer from '../../../../../../components/CustomCellRenderer/UrlCellRenderer';
import SourceRenderer from '../../../../../../components/CustomCellRenderer/SourceRenderer';
import { getVacancyAction } from '../../../../../../redux/entities/entities.actions';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../../../../../components/CustomInput/CustomClickableIcon';
import { fixUrl } from '../../../../../../utils/application.utils';
import VacancyPublicationSourceDropDown
    from '../../../../../../components/CustomInput/CustomDropDownInput/VacancyPublicationSourceDropDown';
import { setVacancyPageDeletePublicationFromFormAction } from '../../../../../../redux/vacancyPage/vacancyPage.actions';

interface IProps {
    index: number;
    vacancyId: number;
    publication: IVacancyPublication;
}

const VacancyPublicationItemView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.vacancyId;
    const publication = props.publication;
    const index = props.index;

    const [urlText, setUrlText] = useState(publication.url);
    const [source, setSource] = useState<EPublicationSource>(publication.source);
    const [comment, setComment] = useState(publication.comment);

    const onDelete = () => {
        if (!vacancyId) {
            dispatch(setVacancyPageDeletePublicationFromFormAction(index));
            return;
        }

        ClientApi.request(deleteVacancyPublicationRouteConfig, {
            token,
            vacancyId,
            id: publication.id,
        }).then(() => {
            dispatch(registerInfo('Datensatz wurde gelöscht.'));
            dispatch(setVacancyPageDeletePublicationFromFormAction(index));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht gelöscht werden'));
        });
    };

    const onUpdate = () => {
        if (urlText === publication.url && comment === publication.comment && source === publication.type) {
            return Promise.resolve();
        }

        return ClientApi.request(putVacancyPublicationRouteConfig, {
            token,
            vacancyId,
            id: publication.id,
            url: urlText,
            comment,
            source
        }).then(() => {
            dispatch(getVacancyAction(token, vacancyId, false));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht aktualisiert werden'));
        });
    };
    const contextMenu: IListActionButtonConfig[] = [{
        icon: EIcons.Remove,
        action: () => onDelete(),
        translationKey: 'tables.openDeleteView',
    }, {
        icon: EIcons.Copy,
        action: () => {
            navigator.clipboard.writeText(publication.url);
            dispatch(registerInfo('Url wurde kopiert'));
        },
        translationKey: 'misc.copy',
    }];

    return <>
        <CustomContextMenuEntity
            index={index}
            entityId={publication.id}
            entity={publication}
            menuHeading={(entity) => {
                const entityCast = entity as IVacancyPublication;
                return entityCast.url;
            }}
            menuItems={contextMenu}
            style={{flex: 1}}
        >
            <CustomEditableValue
                theme={'light'}
                input={<div className={"flexContainerColumn flex1 gap5"}>
                    <CustomTextField
                        required
                        autoFocus
                        size={'small'}
                        value={urlText}
                        onChange={(value) => setUrlText(value)}
                        label={translate('misc.url')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <CustomClickableIcon onClick={() => {
                                    navigator.clipboard.writeText(urlText);
                                    dispatch(registerInfo('Url wurde kopiert'));
                                }} icon={EIcons.Copy}/>
                            </InputAdornment>
                        }}
                    />
                    <VacancyPublicationSourceDropDown
                        value={source}
                        disablePortal
                        size={'small'}
                        onChange={(event) => setSource(event.target.value)}
                    />
                    <CustomTextField
                        multiline
                        size={'small'}
                        rows={5}
                        label={translate('misc.comment')}
                        value={comment}
                        onChange={(value) => setComment(value)}
                    />
                </div>}
                value={publication.url}
                onSave={onUpdate}
            >
                <div className={'flexContainerRow alignVerticalCenter'}>
                    <UrlCellRenderer hideLaunchIcon value={
                        <>
                            <SourceRenderer renderHomepageAsWebsite source={publication.source}/>
                            &nbsp;
                            {(new URL(fixUrl(publication.url))).pathname}
                        </>
                    } url={publication.url} />
                </div>
                {publication.comment &&
                    <div className={'formContent'}>
                        <Nl2brCellRenderer value={publication.comment}/>
                    </div>
                }
            </CustomEditableValue>
        </CustomContextMenuEntity>
    </>;
};

export default VacancyPublicationItemView;
