import React from 'react';
import CustomQuickFilterButton from './CustomQuickFilterButton';
import { Badge, Divider } from '@material-ui/core';
import CustomQuickFilterContainer from './CustomQuickFilterContainer';
import { ESuggestionStatus, ICompanyLocationPartial, IVacancyCount } from '../../../definitions/entities.definition';
import { ISuggestionFilter } from '../../../definitions/recruitingPage.definitions';
import CustomQuickFilterDivider from './CustomQuickFilterDivider';

interface IProps {
    disabled: boolean;
    counts: {
        overall: IVacancyCount;
        [location: string]: IVacancyCount;
    },
    countNeedAction: number;
    status?: ESuggestionStatus;
    contact?: boolean;
    actionNeeded?: boolean;
    groupByProgressStatus?: boolean;
    setFilterAction: (filter: Partial<ISuggestionFilter>) => void;
    locations: ICompanyLocationPartial[];
    locationId?: number;
}

const CustomQuickFilterSuggestionList: React.FC<IProps> = (props) => {
    const counts = props.counts;
    const locationId = props.locationId;

    const countIndex = locationId ? 'location_' + locationId : 'overall';
    const countOpen = counts[countIndex].countSuggestionsWithStatusOpen;
    const countOpenAndContact = counts[countIndex].countSuggestionsWithStatusOpenAndContact;
    const countDeclined = counts[countIndex].countSuggestionsWithStatusDeclined;
    const countAccepted = counts[countIndex].countSuggestionsWithStatusAccepted;
    const countNeedAction = props.countNeedAction;
    const status = props.status;
    const actionNeeded = props.actionNeeded;
    const disabled = props.disabled;
    const contact = props.contact;
    const locations = props.locations;
    const groupByProgressStatus = props.groupByProgressStatus || false;

    return <>
        {locations.length > 1 &&
            <CustomQuickFilterContainer disabled={disabled}>
                <CustomQuickFilterButton
                    disabled={disabled}
                    onClick={() => props.setFilterAction({companyLocationId: undefined})}
                    isActive={locationId === undefined}
                >
                    Alle Standorte &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={counts.overall.countSuggestions.toString()}/>&nbsp;
                </CustomQuickFilterButton>
                {locations.map((location) =>
                    <CustomQuickFilterButton
                        key={location.id}
                        disabled={disabled}
                        onClick={() => props.setFilterAction({
                            companyLocationId: location.id,
                        })}
                        isActive={locationId === location.id}
                    >
                        {location.city} &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={counts['location_' + location.id].countSuggestions.toString()}/>&nbsp;
                    </CustomQuickFilterButton>
                )}

            </CustomQuickFilterContainer>
        }
        <CustomQuickFilterContainer disabled={disabled}>
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({status: ESuggestionStatus.OPEN, contact: undefined})}
                isActive={status === ESuggestionStatus.OPEN && !contact}
            >
                Offen &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countOpen.toString()}/>&nbsp;
            </CustomQuickFilterButton>
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({status: ESuggestionStatus.OPEN, contact: true})}
                isActive={status === ESuggestionStatus.OPEN && contact}
            >
                In Kontakt &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countOpenAndContact.toString()}/>&nbsp;
            </CustomQuickFilterButton>
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({status: ESuggestionStatus.DECLINED})}
                isActive={status === ESuggestionStatus.DECLINED}
            >
                Abgelehnt &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countDeclined.toString()}/>&nbsp;
            </CustomQuickFilterButton>
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({status: ESuggestionStatus.ACCEPTED})}
                isActive={status === ESuggestionStatus.ACCEPTED}
            >
                Vorgeschlagen &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countAccepted.toString()}/>&nbsp;
            </CustomQuickFilterButton>
            <CustomQuickFilterDivider/>
            {actionNeeded &&
                <>
                    <CustomQuickFilterButton
                        disabled={disabled}
                        onClick={() => props.setFilterAction({actionNeeded: !actionNeeded})}
                        isActive={actionNeeded}
                    >
                        Handlungsbedarf
                        {countNeedAction > 0 &&
                            <>&nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countNeedAction.toString()}/>&nbsp;</>
                        }
                    </CustomQuickFilterButton>
                    <Divider orientation={"vertical"} style={{height: '90%', alignSelf: 'center', backgroundColor: 'rgba(233, 237, 239, 0.2)'}}/>
                </>
            }
            <CustomQuickFilterButton
                disabled={disabled || status === undefined}
                onClick={() => props.setFilterAction({groupByProgressStatus: !groupByProgressStatus})}
                isActive={groupByProgressStatus && status !== undefined}
            >
                Nach Kontakt gruppieren
            </CustomQuickFilterButton>
        </CustomQuickFilterContainer>
    </>;
}

export default CustomQuickFilterSuggestionList;
