import React, { useState } from 'react';
import VacancyApplicationList from './component/VacancyApplicationList';
import CustomTableFooter from '../../../../components/CustomTable/CustomTableFooter';
import { getApplicationListForVacancyAction } from '../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getVacancyPageRequestObjectForApplicationsInEditView } from '../../../../selectors/application.selectors';
import { getToken } from '../../../../selectors/app.selectors';
import FastSearchPanelApplicationList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelApplicationList';
import AddApplicationView from '../../../ApplicationPage/AddApplicationView';
import { EIcons } from '../../../../components/Icons/IconFactory';

interface IProps {
    vacancyId: number;
}

const VacancyApplicationPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.vacancyId;
    const requestObject = useSelector(getVacancyPageRequestObjectForApplicationsInEditView);
    const total = useSelector((store: IStore) => store.entities.applications.totalByVacancy[vacancyId]);
    const [addApplicationViewOpen, setAddApplicationViewOpen] = useState(false);

    return <>
        <AddApplicationView
            vacancyId={vacancyId}
            onClose={() => setAddApplicationViewOpen(false)}
            open={addApplicationViewOpen}
        />
        <div className={"flexColumn padding5"}>
            <FastSearchPanelApplicationList
                fastSearch={''}
                setFastSearch={() => console.log(1)}
                actionButtons={[{
                    action: () => setAddApplicationViewOpen(true),
                    tooltip: "Neue Bewerbung anlegen",
                    icon: EIcons.Add
                }]}
            />
            <VacancyApplicationList vacancyId={vacancyId}/>
            <CustomTableFooter
                style={{height: 35}}
                text={'Bewerbungen: ' + total}
                onReload={() => {
                    dispatch(getApplicationListForVacancyAction(token, vacancyId, requestObject));
                }}
            />
        </div>
    </>;
}

export default React.memo(VacancyApplicationPanel);
