import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import {
    EEntities,
    IEmployee,
    IVacancyListModel,
    IVacancyPublication,
    TBrainzEntity,
} from '../../../definitions/entities.definition';
import { ECellAlign, ESortingOptions } from '../../../definitions/components.definitions';
import RatingIcon from '../../../components/CustomRating/RatingIcon';
import { translate } from '../../../translation/translate.utils';
import { Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';
import { getDefaultCompanyColumn } from '../../../utils/components.utils';
import { getToken } from '../../../selectors/app.selectors';
import { getOpenVacancyListModels, getVacancyPageOpenListRequestObject } from '../../../selectors/vacancy.selectors';
import { getFilterMenuBadgeCount } from '../../../selectors/entity.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { EEntityView } from '../../../definitions/ui.definitions';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import {
    changeVacancyOrder,
    getAllOpenVacanciesAction,
    updateVacancyAction,
} from '../../../redux/entities/entities.actions';
import {
    setVacancyPageAddSuggestionAction,
    setVacancyPageSortingOptionAction,
    setVacancyPageVacancyToArchiveAction,
    setVacancyPageVacancyToDeleteAction,
} from '../../../redux/vacancyPage/vacancyPage.actions';
import { setRecruitingPageStartRecruitingAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import DateDiffCellRenderer from '../../../components/CustomCellRenderer/DateDiffCellRenderer';
import { COLOR_GREEN, COLOR_RED, COLOR_TEXT, COLOR_YELLOW } from '../../../theme/theme';
import { getDayDifference } from '../../../utils/date.utils';
import moment from 'moment';
import { isOnlyShowMineModeActive } from '../../../selectors/employee.selectors';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';
import UrlCellRenderer from '../../../components/CustomCellRenderer/UrlCellRenderer';
import CustomClickableDiv from '../../../components/CustomInput/CustomClickableDiv';
import CustomSideFilterPanel from '../../../components/CustomFilter/CustomSideFilter/CustomSideFilterPanel';
import SourceRenderer from '../../../components/CustomCellRenderer/SourceRenderer';
import VacancyDetailCellRenderer from '../../../components/CustomCellRenderer/VacancyDetailCellRenderer';
import VacancyOrderReceivedCellRenderer from '../../../components/CustomCellRenderer/VacancyOrderReceivedCellRenderer';
import { setCompanyPageActivityFilterAction } from '../../../redux/companyPage/companyPage.actions';

const getSuggestionDueDateIcon = (vacancy: IVacancyListModel) => {
    const suggestionDueDate = vacancy.suggestionDueDate as string;

    if (vacancy.hasProcessesForDueDate) {
        return IconFactory.getIconComponent(EIcons.Flag, {color: COLOR_GREEN});
    }

    const dateDifference = getDayDifference(suggestionDueDate);
    if (dateDifference >= 0) {
        return IconFactory.getIconComponent(EIcons.Alarm, {color: COLOR_RED});
    }
    if (dateDifference >= -1) {
        return IconFactory.getIconComponent(EIcons.Alarm, {color: COLOR_YELLOW});
    }

    return IconFactory.getIconComponent(EIcons.Alarm);
}

const VacancyOpenList: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.openVacanciesLoading);
    const sortedEntries = useSelector(getOpenVacancyListModels);
    const sorting = useSelector((store: IStore) => store.vacancyPage.vacancyListSorting);
    const requestObject = useSelector(getVacancyPageOpenListRequestObject);
    const order = useSelector((store: IStore) => store.entities.vacancies.order);
    const filterCount = useSelector((store: IStore) => getFilterMenuBadgeCount(store, EEntities.Vacancy));
    const draggable = filterCount === 0 && (Object.keys(sorting)[0] === 'pin' || Object.values(sorting)[0] === ESortingOptions.None);
    const onlyShowMine = useSelector(isOnlyShowMineModeActive);

    const editAction = (vacancyId: number) => {
        dispatch(setUiEditView(EEntityView.vacancy, vacancyId));
    }

    const load = () => {
        dispatch(getAllOpenVacanciesAction(token, requestObject))
    }

    const onDragEnd = (result: DropResult) => {
        const vacancy = sortedEntries[result.source.index];
        if (!result.destination) {
            return;
        }

        const newOrder = order;
        const [reorderedItem] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, reorderedItem);

        dispatch(updateVacancyAction(
            token, {
                id: vacancy.id,
                ranking: sortedEntries[result.destination.index].ranking
            }
        ));

        if (filterCount && filterCount > 0) {
            load();
        } else {
            dispatch(changeVacancyOrder(newOrder));
        }
    };

    return <>
        <DragDropContext onDragEnd={onDragEnd}>
            <CustomTable
                config={{
                    entity: EEntities.Vacancy,
                    dragAndDrop: !onlyShowMine,
                    isRecordDraggable: (entity: TBrainzEntity) => (entity as IVacancyListModel).pin && draggable,
                    onRowDoubleClick: (id) => editAction(id),
                    footerText: 'Gesamt: ' + sortedEntries.length + ' | Aufträge: ' + sortedEntries.filter((vacancy: IVacancyListModel) => Boolean(vacancy.orderReceivedDate)).length + ' | Gepinnte: ' + sortedEntries.filter((vacancy: IVacancyListModel) => vacancy.pin).length,
                    listActionHeading: (entity) => {
                        const entityCast = entity as IVacancyListModel;
                        return entityCast.title;
                    },
                    listActionButtons: [{
                        action: (vacancyId) => dispatch(setVacancyPageAddSuggestionAction({
                            open: true,
                            vacancyId
                        })),
                        translationKey: 'pages.vacancy.addSuggestion',
                        icon: EIcons.Star,
                    }, {
                        action: (vacancyId) => dispatch(setRecruitingPageStartRecruitingAction({
                            open: true,
                            vacancyId
                        })),
                        translationKey: 'pages.vacancy.startRecruiting',
                        icon: EIcons.ThumbsUpDown,
                    }, {
                        action: (vacancyId) => dispatch(setVacancyPageVacancyToArchiveAction(vacancyId)),
                        translationKey: 'pages.vacancy.archiveDialog.title',
                        icon: EIcons.CloudOff,
                    }, {
                        action: (vacancyId) => dispatch(setVacancyPageVacancyToDeleteAction(vacancyId)),
                        translationKey: 'pages.vacancy.deleteDialog.title',
                        icon: EIcons.Delete,
                    }],
                    columnConfig: {
                        orderReceivedDate: {
                            header: '#',
                            property: 'orderReceivedDate',
                            width: 40,
                            align: ECellAlign.center,
                            cellRenderer: (orderReceivedDate?: null|string, todo) => {
                                const vacancyCast = todo as IVacancyListModel;

                                return (
                                    <VacancyOrderReceivedCellRenderer
                                        id={vacancyCast.id}
                                        orderReceivedDate={vacancyCast.orderReceivedDate}
                                        callback={load}
                                    />
                                );
                            }
                        },
                        pin: {
                            header: '#',
                            property: 'pin',
                            width: 40,
                            align: ECellAlign.center,
                            cellRenderer: (pin: boolean, todo) => {
                                const vacancyCast = todo as IVacancyListModel;

                                return (
                                    <RatingIcon
                                        value={vacancyCast.pin}
                                        onChangePin={() => {
                                            dispatch(updateVacancyAction(
                                                token, {
                                                    id: vacancyCast.id,
                                                    pin: !vacancyCast.pin
                                                },
                                                () => load()
                                            ))
                                        }}
                                        icon={EIcons.Assign}
                                        tooltip={translate('pages.vacancy.pin')}
                                    />
                                );
                            }
                        },
                        company: getDefaultCompanyColumn(),
                        vacancy: {
                            header: 'Vakanz',
                            property: "status",
                            width: 340,
                            cellRenderer: (value, entity: TBrainzEntity) => {
                                const vacancy = entity as IVacancyListModel;

                                return <VacancyDetailCellRenderer vacancy={vacancy} />
                            }
                        },
                        count: {
                            header: 'Ziel',
                            width: 50,
                            startOrderDesc: true,
                            align: ECellAlign.center,
                            property: 'count'
                        },
                        suggestionDueDate: {
                            header: 'Frist',
                            property: "suggestionDueDate",
                            align: ECellAlign.center,
                            width: 50,
                            cellRenderer: (suggestionDueDate: string, entity) => {
                                const entityCast = entity as IVacancyListModel;
                                if (!suggestionDueDate) {
                                    return '-';
                                }

                                return <>
                                    <Tooltip title={<><DateDiffCellRenderer date={suggestionDueDate} /><DateCellRenderer date={suggestionDueDate} /> </>}>
                                        {getSuggestionDueDateIcon(entityCast)}
                                    </Tooltip>
                                </>
                            },
                        },
                        countOpenProcesses: {
                            header: 'Proz.',
                            property: "countOpenProcesses",
                            width: 50,
                            startOrderDesc: true,
                            align: ECellAlign.center,
                            cellRenderer: (value: number, entity: TBrainzEntity) => {
                                const vacancyListModel = entity as IVacancyListModel;

                                return (
                                    <CustomClickableDiv
                                        onClick={() => dispatch(setUiEditView(EEntityView.vacancy, entity.id, 1))}
                                        className={"hoverOpacity flexContainerRow cursorPointer"}>
                                        <div style={{float: 'left', color: vacancyListModel.counts.overall.countRecruitingsWithStatusOpen > 0 ? COLOR_GREEN : COLOR_TEXT}}>{vacancyListModel.counts.overall.countRecruitingsWithStatusOpen}</div>
                                        <div>&nbsp;|&nbsp;</div>
                                        <div style={{float: 'left', color: vacancyListModel.counts.overall.countRecruitingsWithStatusAbort > 0 ? COLOR_RED : COLOR_TEXT}}>{vacancyListModel.counts.overall.countRecruitingsWithStatusAbort}</div>
                                    </CustomClickableDiv>
                                );
                            }
                        },
                        countOpenApplications: {
                            header: 'Bewerb.',
                            property: "countOpenApplications",
                            width: 50,
                            startOrderDesc: true,
                            align: ECellAlign.center,
                            cellRenderer: (value: number, entity: TBrainzEntity) => {
                                const vacancyListModel = entity as IVacancyListModel;

                                return (
                                    <CustomClickableDiv
                                        onClick={() => dispatch(setUiEditView(EEntityView.vacancy, entity.id, 3))}
                                        className={"hoverOpacity flexContainerRow cursorPointer"}>
                                        <div style={{float: 'left', color: vacancyListModel.counts.overall.countApplicationsWithStatusOpen > 0 ? COLOR_GREEN : COLOR_TEXT}}>{vacancyListModel.counts.overall.countApplicationsWithStatusOpen}</div>
                                        <div>&nbsp;|&nbsp;</div>
                                        <div style={{float: 'left', color: COLOR_TEXT}}>{vacancyListModel.counts.overall.countApplications}</div>
                                    </CustomClickableDiv>
                                );
                            }
                        },
                        publications: {
                            header: 'Veröffentlichungen',
                            property: 'publications',
                            disableOrder: true,
                            width: 140,
                            cellRenderer: (publications: IVacancyPublication[], entity) => {
                                const vacancy = entity as IVacancyListModel;
                                const elements = [];
                                if (vacancy.webVacancy) {
                                    elements.push(<UrlCellRenderer
                                        hideLaunchIcon
                                        value={<Tooltip title={"verlinke Crawler-Stelle"}>
                                            {IconFactory.getIconComponent(vacancy.webVacancy.online ? EIcons.CloudDone : EIcons.CloudOff , {color: vacancy.webVacancy.online ? COLOR_GREEN : COLOR_RED})}
                                            </Tooltip>
                                        }
                                        url={vacancy.webVacancy.url}
                                    />)
                                }

                                if (publications.length > 0) {
                                    elements.push(<>{publications.map((publication) => <UrlCellRenderer key={publication.id} hideLaunchIcon value={
                                        <>
                                            <SourceRenderer renderHomepageAsWebsite source={publication.source}/>
                                        </>
                                    } url={publication.url} />)}</>)
                                }

                                if (elements.length > 0) {
                                    return <div className={"flexContainerRow gap5"}>{elements}</div>
                                }

                                return '-';
                            },
                        },
                        possibleCommission: {
                            header: translate('pages.vacancy.possibleCommission'),
                            property: "possibleCommission",
                            startOrderDesc: true,
                            width: 80,
                            align: ECellAlign.right,
                            cellRenderer: (commission: number, entity) => {
                                const vacancy = entity as IVacancyListModel;
                                if (commission) {
                                    return (
                                        <Tooltip title={<>Von: {vacancy.salaryFrom} k <br/>Bis: {vacancy.salaryTo} k</>}>
                                            <div>
                                                <CurrencyCellRenderer value={commission * vacancy.count} />
                                            </div>
                                        </Tooltip>
                                    )
                                }
                                return '-';
                            },
                        },
                        lastActivity: {
                            header: translate('misc.lastActivity'),
                            property: "lastActivity",
                            align: ECellAlign.right,
                            width: 100,
                            cellRenderer: (lastActivity: string, entity) => <>
                                <div className={"cursorPointer"} onClick={() => dispatch(setUiEditView(EEntityView.vacancy, entity.id, 4))}>
                                    <DateDiffCellRenderer ignoreTime markOverdueDays={7} date={lastActivity} />
                                </div>
                            </>,
                        },
                        lastActivityCustomer: {
                            header: 'Kundenkontakt',
                            property: "company.lastContact",
                            align: ECellAlign.right,
                            width: 100,
                            cellRenderer: (lastActivity: string, entity) => {
                                const vacancy = entity as IVacancyListModel;
                                return <>
                                    <div className={"cursorPointer"}
                                         onClick={() => {
                                             dispatch(setCompanyPageActivityFilterAction({onlyWithContact: true}))
                                             dispatch(setUiEditView(EEntityView.company, vacancy.company.id, 6))
                                         }}>
                                        <DateDiffCellRenderer ignoreTime markOverdueDays={7} date={vacancy.company.lastContact}/>
                                    </div>
                                </>;
                            }
                        },
                        responsibleEmployee: {
                            header: translate('misc.employeeShort'),
                            property: "responsibleEmployee",
                            cellRenderer: (employee?: null|IEmployee) => {
                                if (!employee) {
                                    return <>-</>;
                                }
                                return <EmployeeCellRenderer employee={employee} />;
                            },
                        }
                    },
                    sortedEntries: sortedEntries,
                    loading: loading,
                    tableHeaderCallbacks: {
                        setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                            dispatch(setVacancyPageSortingOptionAction(
                                propertyToSort,
                                sortOption
                            ))
                    },
                    onReload: () => load(),
                }}
            />
        </DragDropContext>
    </>;
}

export default React.memo(VacancyOpenList);
