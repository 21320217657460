import React from 'react';
import { IAppBarActionButtonConfig } from '../../../definitions/components.definitions';
import { IconButton, Tooltip } from '@material-ui/core';
import { IconFactory } from '../../Icons/IconFactory';

interface IProps {
    buttons: IAppBarActionButtonConfig[];
}

const CustomFastSearchPanelActionButtons: React.FC<IProps> = (props) => {
    return <>
        {
            props.buttons.map((button: IAppBarActionButtonConfig) => {
                return (
                    <Tooltip key={button.icon} title={button.tooltip || ''} placement="top-start">
                        <IconButton onClick={button.action}>
                            {IconFactory.getIconComponent(button.icon, button.iconStyle)}
                        </IconButton>
                    </Tooltip>
                );
            })
        }
    </>
}

export default React.memo(CustomFastSearchPanelActionButtons);
