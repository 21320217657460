import React from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { IPluginCandidate } from '../../../definitions/entities.definition';
import CandidateImportExternalForm from './CandidateImportExternalForm';
import { ClientApi } from '../../../requests/ClientApi';
import { putCandidateImportDataRouteConfig } from '../../../requests/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { registerSuccess } from '../../../redux/error/error.actions';
import { getCandidateAction } from '../../../redux/entities/entities.actions';

interface IProps {
    open: boolean;
    importData?: IPluginCandidate;
    onClose: () => void;
    candidateId: number;
}

const CandidateImportExternalView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const open = props.open;
    const importData = props.importData;
    const token = useSelector(getToken);
    const candidateId = props.candidateId;

    const onClose = () => props.onClose();
    const onSave = () => {
        ClientApi.request(putCandidateImportDataRouteConfig, {
            token,
            candidateId,
            importData,
        }).then(() => {
            dispatch(getCandidateAction(token, candidateId, false));
            dispatch(registerSuccess('Daten wurden erfolgreich importiert'));
            onClose();
        });
    };

    if (!open || !importData) {
        return null;
    }

    return (
        <CustomDialog maxWidth={"md"} fullWidth open={open} onClose={onClose}>
            <CustomDialogHeader string="Daten aus LinkedIn importieren" onClose={onClose} />
            <div className={"padding10 flexContainerColumn overflow"}>
                <CandidateImportExternalForm importData={importData} />
            </div>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    action: onSave,
                    label: "Daten importieren"
                }]}
            />
        </CustomDialog>
    );
};

export default CandidateImportExternalView;
