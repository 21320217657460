import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    ECellAlign,
    ESortingOptions,
    ICustomTableColumn,
    ITableHeaderCallbacks,
} from '../../definitions/components.definitions';
import { EEntities } from '../../definitions/entities.definition';
import { useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getSortingOptionFromEntity } from '../../selectors/entity.selectors';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { darker } from '../../theme/theme';
import clsx from 'clsx';

interface IStyleProps {
    color?: string;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
    root: (props) => ({
        borderRight: "1px solid " + darker,
        //backgroundColor: props.color,
        fontWeight: 500,
        fontSize: 14,
        color: 'var(--brainz-color-text-table-and-tab-headers)',
        '&> .MuiSvgIcon-root': {
            color: 'var(--brainz-color-text-table-and-tab-headers) !important',
        },
    })
}));

interface IProps {
    entity: EEntities;
    value: string | ReactNode | EIcons;
    tableHeaderCallbacks?: ITableHeaderCallbacks;
    columnConfig: ICustomTableColumn;
    color?: string;
    align?: ECellAlign;
    startOrderDesc?: boolean;
}

const getArrowIconBySorting = (sorting: undefined|ESortingOptions) => {
    const color = 'var(--brainz-color-text)';

    if (sorting === ESortingOptions.Ascending) {
        return IconFactory.getIconComponent(EIcons.ArrowUp, {color});
    }

    if (sorting === ESortingOptions.Descending) {
        return IconFactory.getIconComponent(EIcons.ArrowDown, {color});
    }

    return null;
};

const CustomTableHeaderCell: React.FC<IProps> = (props) => {
    const styles = useStyles({
        color: props.color
    });

    let flexConfig = "1";
    const sorting = useSelector((store: IStore) =>
        getSortingOptionFromEntity(store, props.entity, props.columnConfig.orderByProperty || props.columnConfig.property)
    );

    if (props.columnConfig.flex) {
        flexConfig = props.columnConfig.flex.toString();
    } else if (props.columnConfig.width) {
        flexConfig = `0 0 ${props.columnConfig.width}px`;
    }

    const onClick = () => {
        if (props.columnConfig.disableOrder) {
            return ;
        }
        if (!props.tableHeaderCallbacks) {
            return ;
        }
        let sortingLogic = (sorting === ESortingOptions.Descending) ? ESortingOptions.None : (sorting === ESortingOptions.None ? ESortingOptions.Ascending : ESortingOptions.Descending);
        if (props.startOrderDesc) {
            sortingLogic = (sorting === ESortingOptions.Ascending) ? ESortingOptions.None : (sorting === ESortingOptions.None ? ESortingOptions.Descending : ESortingOptions.Ascending);
        }

        props.tableHeaderCallbacks.setSorting(
            props.columnConfig.orderByProperty || props.columnConfig.property,
            sortingLogic
        );
    };
    const arrowIcon = getArrowIconBySorting(sorting);

    return (
        <>
        <div
            className={
                clsx({
                    ['cursorPointer']: !props.columnConfig.disableOrder,
                    [styles.root]: true,
                    ['tableCell']: true,
                    ['hoverContainer']: true,
                    ['flexContainerRow']: true,
                    ['alignVerticalCenter']: true,
                    ['alignHorizontalLeft']: props.align === undefined || props.align === ECellAlign.left,
                    ['alignHorizontalCenter']: props.align !== undefined && props.align === ECellAlign.center,
                    ['alignHorizontalRight']: props.align !== undefined && props.align === ECellAlign.right
                })
            }
            style={{ flex: flexConfig }}
            onClick={() => onClick()}
        >
            {arrowIcon
                ?
                <div className={"flexContainerColumn"}>
                    {props.value}
                    <div className={"alignCenter"}>{arrowIcon}</div>
                </div>
                :
                props.value
            }
        </div>

        </>
    );
};

export default CustomTableHeaderCell;
