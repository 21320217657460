import React from 'react';
import { EEntities, IVacancyCount, IVacancyListModel } from '../../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCompanyPageContactActivitySortingOptionAction,
    setCompanyPageVacancyToDeleteAction,
} from '../../../../../redux/companyPage/companyPage.actions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../../translation/translate.utils';
import { EIcons } from '../../../../../components/Icons/IconFactory';
import { ESortingOptions } from '../../../../../definitions/components.definitions';
import YesNoCellRenderer from '../../../../../components/CustomCellRenderer/YesNoCellRenderer';
import { getToken } from '../../../../../selectors/app.selectors';
import { getCompanyPageRequestObjectForVacanciesInContactEditView } from '../../../../../selectors/company.selectors';
import { getAllVacanciesOfCompanyContactAction } from '../../../../../redux/entities/entities.actions';
import { COLOR_BLUE } from '../../../../../theme/theme';
import { getDefaultEmployeeColumn } from '../../../../../utils/components.utils';
import { setUiEditView } from '../../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../../definitions/ui.definitions';
import { useCompanyContactVacancyListHook } from './CompanyContactVacancyList.hooks';

interface IProps {
    companyContactId: number;
}

const CompanyContactVacancyList: React.FC<IProps> = (props) => {
    const companyContactId = props.companyContactId;
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const companyVacanciesRequestObject = useSelector(getCompanyPageRequestObjectForVacanciesInContactEditView);

    const { loading, vacancies, total } = useCompanyContactVacancyListHook(companyContactId);

    return (
        <CustomTable
            config={{
                color: COLOR_BLUE,
                entity: EEntities.CompanyContactVacancy,
                loading,
                columnConfig: {
                    title: {
                        header: translate('pages.vacancy.properties.title'),
                        property: "title",
                    },
                    completed: {
                        header: translate('pages.vacancy.properties.completed'),
                        width: 80,
                        property: "completed",
                        cellRenderer: (completed: boolean) => {
                            if (completed) {
                                return <>{translate('misc.yes')}</>;
                            }
                            return <>{translate('misc.no')}</>;
                        }
                    },
                    countRecruitingsWithStatusContract: {
                        header: translate('pages.vacancy.hasBeenContracted'),
                        property: "counts.overall",
                        width: 80,
                        cellRenderer: (counts: IVacancyCount) => <YesNoCellRenderer value={Boolean(counts.countRecruitingsWithStatusContract)} />
                    },
                    responsibleEmployee: getDefaultEmployeeColumn(),
                },
                sortedEntries: vacancies,
                listActionButtons: [{
                    action: (vacancyId: number) => dispatch(setCompanyPageVacancyToDeleteAction(vacancyId)),
                    translationKey: "tables.openDeleteView",
                    icon: EIcons.Delete,
                }],
                onRowDoubleClick: (vacancyId: number, entity) => {
                    const vacancy = entity as IVacancyListModel;
                    dispatch(setUiEditView(EEntityView.vacancy, vacancy.id));
                },
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setCompanyPageContactActivitySortingOptionAction(propertyToSort, sortOption)),
                },
                total: total,
                onReload: () => {
                    dispatch(getAllVacanciesOfCompanyContactAction(
                        token,
                        companyContactId,
                        companyVacanciesRequestObject
                    ));
                }
            }}
        />
    );
};

export default CompanyContactVacancyList;
