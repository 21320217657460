import React, { useEffect, useState } from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { translate } from '../../../translation/translate.utils';
import {
    ICompanyContactPartial,
    ICompanyLocationPartial,
    ICompanyPartial,
    IVacancyPartial,
} from '../../../definitions/entities.definition';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { momentToDate } from '../../../utils/date.utils';
import CustomDatePicker from '../../../components/CustomInput/CustomDatePicker';
import moment from 'moment';
import LegacyYesNoDropDown from '../../../components/CustomInput/CustomDropDownInput/LegacyYesNoDropDown';
import EmployeeAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import CompanyAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/CompanyAutocomplete';
import VacancyAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import { ClientApi } from '../../../requests/ClientApi';
import { getContractRouteConfig, postContractRouteConfig, putContractRouteConfig } from '../../../requests/routes';
import { getProcessAggregationListByCandidateAction } from '../../../redux/entities/entities.actions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import CompanyLocationAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/CompanyLocationAutocomplete';
import CompanyContactAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';

interface IProps {
    candidateId?: number;
    contractId?: number;
    onClose: () => void;
}

const AddEditContractForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const contractId = props.contractId;
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);

    const [contractSigningDate, setContractSigningDate] = useState<undefined|Date>();
    const [contractBeginningDate, setContractBeginningDate] = useState<undefined|Date>();
    const [endOfTrialPeriod, setEndOfTrialPeriod] = useState<undefined|Date>();
    const [finishedTrialPeriod, setFinishedTrialPeriod] = useState<undefined|boolean>();
    const [company, setCompany] = useState<null|ICompanyPartial>();
    const [vacancy, setVacancy] = useState<null|IVacancyPartial>();
    const [jobTitle, setJobTitle] = useState<undefined|string>();
    const [responsibleEmployeeId, setResponsibleEmployeeId] = useState<number>(loggedInEmployee.id);
    const [contractSalary, setContractSalary] = useState<number>(0);
    const [companyContact, setCompanyContact] = useState<null|ICompanyContactPartial>(null);
    const [companyLocation, setCompanyLocation] = useState<null|ICompanyLocationPartial>(null);
    const [validationErrors, setValidationErrors]= useState([]);

    useEffect(() => {
        if (!contractId) {
            return;
        }

        setSaving(true);

        ClientApi.request(getContractRouteConfig, {
            token,
            contractId
        }).then((result) => {
            setSaving(false);
            setContractSigningDate(result.contractSigningDate);
            setContractBeginningDate(result.contractBeginningDate);
            setEndOfTrialPeriod(result.endOfTrialPeriod);
            setFinishedTrialPeriod(result.finishedTrialPeriod);
            setCompany(result.company);
            setVacancy(result.vacancy || null);
            setJobTitle(result.jobTitle);
            setResponsibleEmployeeId(result.responsibleEmployee.id || null);
            setContractSalary(result.contractSalary);
            setCompanyContact(result.companyContact || null);
            setCompanyLocation(result.companyLocation || null);
        })
    }, [contractId])

    const [saving, setSaving] = useState(false);

    const onSave = () => {
        setSaving(true);
        if (contractId) {
            ClientApi.request(putContractRouteConfig, {
                token,
                contractId,
                id: contractId,
                contractSigningDate: contractSigningDate ? moment(contractSigningDate).format("Y-MM-DD") : undefined,
                contractBeginningDate: contractBeginningDate ? moment(contractBeginningDate).format("Y-MM-DD") : undefined,
                endOfTrialPeriod: endOfTrialPeriod ? moment(endOfTrialPeriod).format("Y-MM-DD") : undefined,
                finishedTrialPeriod,
                jobTitle,
                responsibleEmployeeId,
                contractSalary,
                companyContactId: companyContact ? companyContact.id : undefined,
                companyLocationId: companyLocation ? companyLocation.id : undefined,
                companyId: company ? company.id : undefined,
                vacancyId: vacancy ? vacancy.id : undefined
            }).then((result) => {
                dispatch(getProcessAggregationListByCandidateAction(token, result.candidate.id));
                props.onClose();
                setSaving(false);
            }).catch((error) => {
                if (error && error.includes("validationErrors: ")) {
                    setValidationErrors(JSON.parse(error.replace("validationErrors: ", "")));
                }
                setSaving(false);
            });
            return;
        }

        ClientApi.request(postContractRouteConfig, {
            token,
            candidateId,
            contractSigningDate: contractSigningDate ? moment(contractSigningDate).format("Y-MM-DD") : undefined,
            contractBeginningDate: contractBeginningDate ? moment(contractBeginningDate).format("Y-MM-DD") : undefined,
            endOfTrialPeriod: endOfTrialPeriod ? moment(endOfTrialPeriod).format("Y-MM-DD") : undefined,
            finishedTrialPeriod,
            jobTitle,
            responsibleEmployeeId,
            contractSalary,
            companyContactId: companyContact ? companyContact.id : undefined,
            companyLocationId: companyLocation ? companyLocation.id : undefined,
            companyId: company ? company.id : undefined,
            vacancyId: vacancy ? vacancy.id : undefined
        }).then(() => {
            dispatch(getProcessAggregationListByCandidateAction(token, candidateId));
            props.onClose();
            setSaving(false);
        }).catch((error) => {
            if (error && error.includes("validationErrors: ")) {
                setValidationErrors(JSON.parse(error.replace("validationErrors: ", "")));
            }
            setSaving(false);
        });
    }

    return (
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
            <CustomBackdropLoadingInsideDiv open={saving} />
            <PaperRow>
                <EmployeeAutocomplete
                    label={translate('misc.responsibleEmployee')}
                    value={responsibleEmployeeId}
                    required={true}
                    onChange={(value) => {
                        if (!value) {
                            return;
                        }

                        setResponsibleEmployeeId(value.id)
                    }}
                />
                <CompanyAutocomplete
                    required
                    value={company || null}
                    onChange={(company) => {
                        if (!company) {
                            return;
                        }
                        setCompany(company);
                    }}
                    error={Boolean(validationErrors.companyId)}
                    helperText={validationErrors.companyId}
                />
                <CompanyLocationAutocomplete
                    onChange={(value) => setCompanyLocation(value || null)}
                    value={companyLocation}
                    disabled={!company}
                    companyId={company?.id}
                    addEnabled
                />
                <CompanyContactAutocomplete
                    onChange={(value) => setCompanyContact(value)}
                    addEnabled
                    disabled={!company}
                    value={companyContact}
                    companyId={company?.id}
                />
            </PaperRow>
            <PaperRow>
                <VacancyAutocomplete
                    label={translate('misc.vacancy')}
                    value={vacancy || null}
                    disabled={!company}
                    onChange={(vacancy) => {
                        setVacancy(vacancy)
                        setJobTitle(vacancy?.title);
                    }}
                    companyId={company?.id}
                />
                <CustomTextField
                    label={'Job-Titel'}
                    value={jobTitle}
                    onChange={(jobTitle) => setJobTitle(jobTitle)}
                    error={Boolean(validationErrors.jobTitle)}
                    helperText={validationErrors.jobTitle}
                />
            </PaperRow>
            <h4 style={{margin: 5}}>{translate('pages.recruiting.contractInformation')}</h4>
            <PaperRow>
                <CustomDatePicker
                    flex
                    label={translate('pages.recruiting.contractSigningDate')}
                    value={contractSigningDate}
                    onChange={(value) => setContractSigningDate(value)}
                    error={Boolean(validationErrors.contractSigningDate)}
                    helperText={validationErrors.contractSigningDate}
                />
                <CustomDatePicker
                    flex
                    label={translate('pages.recruiting.contractBeginningDate')}
                    value={contractBeginningDate}
                    error={Boolean(validationErrors.contractBeginningDate)}
                    helperText={validationErrors.contractBeginningDate}
                    onChange={(value) => {
                        const endOfTrialPeriod = moment(value).add(6, 'months');

                        setContractBeginningDate(value);
                        setEndOfTrialPeriod(momentToDate(endOfTrialPeriod));
                    }}
                />
            </PaperRow>
            <PaperRow>
                <CustomDatePicker
                    flex
                    label={translate('pages.recruiting.endOfTrialPeriodDate')}
                    value={endOfTrialPeriod}
                    onChange={(value) => this.setState({
                        endOfTrialPeriod: value
                    })}
                />
                <LegacyYesNoDropDown
                    onChange={(event) => {
                        const value = event.target.value;
                        setFinishedTrialPeriod(value === 1 ? true : value === 0 ? false : undefined);
                    }}
                    value={finishedTrialPeriod}
                    label={translate('pages.recruiting.finishedTrialPeriod')}
                    error={Boolean(validationErrors.finishedTrialPeriod)}
                    helperText={validationErrors.finishedTrialPeriod}
                />
                <CustomTextField
                    label={translate('pages.recruiting.contractSalary')}
                    value={contractSalary}
                    onChange={(value) => setContractSalary(value)}
                    error={Boolean(validationErrors.contractSalary)}
                    helperText={validationErrors.contractSalary}
                />
            </PaperRow>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: onSave,
                    label: translate('misc.buttons.save')
                }]}
            />
        </div>
    );
}

export default AddEditContractForm;
