import { declineSuggestionAction } from '../../redux/entities/entities.actions';
import { IStore } from '../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import CustomTypography from '../../components/CustomTypography/CustomTypography';
import PaperRow from '../../components/CustomPaper/PaperRow';
import CustomDumbTextField from '../../components/CustomInput/CustomDumbTextField';
import { translate } from '../../translation/translate.utils';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { setVacancyPageSuggestionToDeclineAction } from '../../redux/vacancyPage/vacancyPage.actions';
import React, { useEffect, useState } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import SuggestionDeclineReasonDropDown
    from '../../components/CustomInput/CustomDropDownInput/SuggestionDeclineReasonDropDown';
import { ESuggestionDeclineReason } from '../../definitions/entities.definition';
import CustomNumberInput from '../../components/CustomInput/CustomNumberInput';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CustomCheckboxInput from '../../components/CustomInput/CustomCheckboxInput';
import moment from 'moment';
import CustomDateTimePicker from '../../components/CustomInput/CustomDateTimePicker';
import DeclineApplicationMailVariantDropDown
    from '../../components/CustomInput/CustomDropDownInput/DeclineApplicationMailVariantDropDown';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import { ClientApi } from '../../requests/ClientApi';
import { getDeclineSuggestionMailTemplateRouteConfig } from '../../requests/routes';
import { registerError } from '../../redux/error/error.actions';

const SuggestionDeclineView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.vacancyPage.suggestionToDecline.id));
    const idToDecline = useSelector((store: IStore) => store.vacancyPage.suggestionToDecline.id);
    const suggestion = useSelector((store: IStore) => store.entities.recruitingSuggestion.byId[idToDecline]);
    const [additionalInput, setAdditionalInput] = useState("");
    const [followupTimeRange, setFollowupTimeRange] = useState(3);
    const [reason, setReason] = useState(ESuggestionDeclineReason.REASON_NO_INTEREST);
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const [createFollowup, setCreateFollowup] = useState(false);
    const [text, setText] = useState('');
    const [template, setTemplate] = useState('');

    const date = moment().add(1, 'day');
    date.set({hour:9,minute:0,second:0,millisecond:0})
    const [sendDeclineMailAt, setSendDeclineMailAt] = useState(date.format("Y-MM-DD H:mm:ss"));
    const [sendDeclineMail, setSendDeclineMail] = useState(false);
    const [variant, setVariant] = useState(0);
    const [useEnglish, setUseEnglish] = useState(false);
    const [useFormalTerm, setUseFormalTerm] = useState(false);
    const [initialLoaded, setInitialLoaded] = useState(false);

    const loadMailTemplates = () => {
        ClientApi.request(getDeclineSuggestionMailTemplateRouteConfig, initialLoaded ? {
            token,
            suggestionId: idToDecline,
            variant,
            useFormalTerm,
            languageId: useEnglish ? 38 : 33
        } : {
            token,
            suggestionId: idToDecline
        }).then((result: {
            template: string,
            text: string,
            languageId: number,
            useFormalTerm: boolean,
            variant: number
        }) => {
            setText(result.text);
            setTemplate(result.template)

            if (!initialLoaded) {
                setVariant(result.variant);
                setUseEnglish(result.languageId === 38);
                setUseFormalTerm(result.useFormalTerm);
            }
            setInitialLoaded(true);
        }).catch((error) => {
            if (error !== 'Candidate has no E-Mail Address') {
                dispatch(registerError(error));
                return;
            }
        });
    }

    useEffect(() => {
        if (!open) {
            return;
        }
        loadMailTemplates();
    }, [dispatch, idToDecline]);

    useEffect(() => {
        if (!open || !initialLoaded) {
            return;
        }
        loadMailTemplates();
    }, [variant, useFormalTerm, useEnglish]);

    if (!open) {
        return null;
    }

    const onClose = () => {
        dispatch(setVacancyPageSuggestionToDeclineAction(0, false));
        setAdditionalInput('');
        setReason(ESuggestionDeclineReason.REASON_NO_INTEREST);
    }

    return (
        <>
            <CustomDialog maxWidth={sendDeclineMail ? "md" : "sm"} onClose={onClose} open={true}>
                <CustomDialogHeader string={translate('pages.vacancy.followupSuggestion')} onClose={onClose} />
                <CustomDialogBody>
                    <CustomTypography translation={"pages.vacancy.declineSuggestionText"} />
                <PaperRow>
                    <SuggestionDeclineReasonDropDown
                        onChange={(reason) => setReason(reason)}
                        value={reason}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomDumbTextField
                        fullWidth
                        label={translate('pages.activity.properties.userInput')}
                        value={additionalInput}
                        multiline={true}
                        onChange={(event) => setAdditionalInput(event.target.value)}
                        rows={6}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomCheckboxInput
                        checked={createFollowup}
                        onChange={() => setCreateFollowup(!createFollowup)}
                        label={"Wiedervorlage erstellen"}
                    />
                    {createFollowup &&
                        <CustomNumberInput
                            label={'In x Monaten wiedervorlegen'}
                            value={followupTimeRange}
                            onChange={(value) => setFollowupTimeRange(value)}
                        />
                    }
                </PaperRow>
                <PaperRow>
                    <div className={"flexContainerColumn"}>
                        <CustomCheckboxInput
                            label={"Absage per E-Mail verschicken"}
                            checked={sendDeclineMail}
                            onChange={() => setSendDeclineMail(!sendDeclineMail)}
                            disabled={suggestion.candidate.candidateProfiles.mailAddresses.length === 0}
                        />
                        {suggestion.candidate.candidateProfiles.mailAddresses.length === 0 &&
                            <div className={"formContent"}>Kandidat hat keine Mail-Adresse hinterlegt</div>
                        }
                    </div>
                    { sendDeclineMail &&
                    <CustomDateTimePicker
                        label={'E-Mail versenden am/um'}
                        value={new Date(sendDeclineMailAt)}
                        onChange={(value) =>
                            setSendDeclineMailAt(moment(value).format("Y-MM-DD H:mm:ss"))
                        }
                    />
                    }
                </PaperRow>
                { sendDeclineMail &&
                    <>
                        <PaperRow>
                            <CustomCheckboxInput
                                label={"Formele Ansprache (Sie) nutzen"}
                                checked={useFormalTerm}
                                onChange={() => setUseFormalTerm(!useFormalTerm)}
                            />
                            <CustomCheckboxInput
                                label={"Englisches Anschreiben nutzen"}
                                checked={useEnglish}
                                onChange={() => setUseEnglish(!useEnglish)}
                            />
                            <DeclineApplicationMailVariantDropDown
                                onChange={(event) => setVariant(event.target.value)}
                                value={variant}
                            />
                        </PaperRow>
                        <PaperRow>
                            <CustomTextField
                                multiline
                                rows={15}
                                value={text}
                                onChange={(value) => setText(value)}
                            />
                        </PaperRow>
                        <div className="mailContent" dangerouslySetInnerHTML={{__html: template.replace('{template}', text)}}></div>
                    </>
                }
                </CustomDialogBody>
                <CustomDialogActions
                    onClose={() => dispatch(setVacancyPageSuggestionToDeclineAction(0, false))}
                    buttons={[{
                        action: () => {
                            dispatch(declineSuggestionAction(
                                token,
                                idToDecline,
                                reason,
                                createFollowup,
                                '+ ' + followupTimeRange + ' month',
                                additionalInput,
                                sendDeclineMail ? text : undefined,
                                sendDeclineMailAt,
                                sendDeclineMail,
                                onClose
                            ));
                            dispatch(setVacancyPageSuggestionToDeclineAction(0, false));
                        },
                        label: translate('misc.buttons.save')
                    }]}
                />
            </CustomDialog>
        </>
    );
};

export default SuggestionDeclineView;
