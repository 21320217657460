import { addSuggestionActivityAction } from '../../redux/entities/entities.actions';
import { IStore } from '../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import PaperRow from '../../components/CustomPaper/PaperRow';
import { translate } from '../../translation/translate.utils';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { setVacancyPageSuggestionToAddActivityAction } from '../../redux/vacancyPage/vacancyPage.actions';
import React, { useEffect, useState } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { ECandidateSource, ESuggestionAction } from '../../definitions/entities.definition';
import CustomCheckboxInput from '../../components/CustomInput/CustomCheckboxInput';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CustomDatePicker from '../../components/CustomInput/CustomDatePicker';
import moment from 'moment';
import CustomDateTimePicker from '../../components/CustomInput/CustomDateTimePicker';
import PortalDropDown from '../../components/CustomInput/CustomDropDownInput/PortalDropDown';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';

const SuggestionAddActionView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.vacancyPage.suggestionToAddAction));
    const suggestionId = useSelector((store: IStore) => store.vacancyPage.suggestionToAddAction);
    const suggestion = useSelector((store: IStore) => store.entities.recruitingSuggestion.byId[suggestionId] || store.entities.recruitingSuggestion.byId[suggestionId]);

    const [dateTime, setDateTime] = useState<Date|undefined>(new Date());
    const [userInput, setUserInput] = useState("");
    const [action, setAction] = useState<ESuggestionAction>();
    const [notReachableUntil, setNotReachableUntil] = useState(moment().format("Y-MM-DD"));
    const [messagePortalSource, setMessagePortalSource] = useState(suggestion.source);
    const [callAppointmentAt, setCallAppointmentAt] = useState(moment().format("Y-MM-DD H:mm:ss"));
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    useEffect(() => {
        if (suggestion && suggestion.callAppointmentAt) {
            setCallAppointmentAt(suggestion.callAppointmentAt);
        }
    }, [suggestion])

    if (!open || !suggestion) {
        return null;
    }

    const onClose = () => {
        dispatch(setVacancyPageSuggestionToAddActivityAction(0));
        setUserInput('');
        setNotReachableUntil(moment().format("Y-MM-DD"));
        setCallAppointmentAt(moment().format("Y-MM-DD H:mm:ss"));
        setAction(undefined);
    }

    const actionDisplayConfig = [
        [ESuggestionAction.ACTION_BOOKED_JOBCENTER],
        [ESuggestionAction.ACTION_MESSAGE_MAIL, ESuggestionAction.ACTION_MESSAGE_PORTAL, ESuggestionAction.ACTION_MESSAGE_MOBILE, ESuggestionAction.ACTION_LETTER_SEND, ESuggestionAction.ACTION_CALL, ESuggestionAction.ACTION_CALL_NOT_REACHED],
        [ESuggestionAction.ACTION_MESSAGE_RECEIVED_MAIL, ESuggestionAction.ACTION_MESSAGE_RECEIVED_PORTAL, ESuggestionAction.ACTION_MESSAGE_RECEIVED_MOBILE, 'SPACE', ESuggestionAction.ACTION_CALL_RECEIVED],
        [ESuggestionAction.ACTION_CALL_COORDINATION, ESuggestionAction.ACTION_CALL_DATE_SET, ESuggestionAction.ACTION_CALL_DATE_CHANGE, ESuggestionAction.ACTION_NOT_REACHABLE_UNTIL],
    ];

    const actionDisplayHeadings = [
        'Arbeitsamt',
        'ausgehender Kontakt / Kontakt-Versuch',
        'eingehender Kontakt',
        'Koordinierung',
    ];

    if (suggestion.source !== ECandidateSource.jobcenter) {
        delete actionDisplayConfig[0];
        delete actionDisplayHeadings[0];
    }

    return (
        <>
            <CustomDialog open={open} onClose={onClose} maxWidth={"sm"}>
                <CustomDialogHeader string={translate("pages.activity.add")} onClose={onClose}  />
                <CustomDialogBody>
                    <PaperRow>
                        <CustomDateTimePicker
                            label={translate('pages.activity.properties.dateTime')}
                            value={dateTime}
                            onChange={(value) => setDateTime(value)}
                        />
                    </PaperRow>
                    <div className={"flexContainerColumn marginLeft5"}>
                        {
                            actionDisplayConfig.map((actions, index) => <>
                                <h4 style={{margin: 5}}>{actionDisplayHeadings[index]}</h4>
                                <div className={"flexContainerRow marginLeft5"} style={{flexWrap: 'wrap'}}>
                                    {actions.map((actionValue: 'SPACE'|ESuggestionAction, index: number) => {
                                        if (actionValue === 'SPACE') {
                                            return <div key={index} style={{width: 150}}>&nbsp;</div>
                                        }
                                        const hideChangeAppointment  = actionValue === ESuggestionAction.ACTION_CALL_DATE_CHANGE && !suggestion.callAppointmentAt;
                                        const hideSetAppointment = actionValue === ESuggestionAction.ACTION_CALL_DATE_SET && suggestion.callAppointmentAt;
                                        if (hideChangeAppointment || hideSetAppointment) {
                                            return;
                                        }
                                        return (
                                            <div key={index} style={{width: 140}}>
                                                <CustomCheckboxInput
                                                    disabled={actionValue === ESuggestionAction.ACTION_MESSAGE_MAIL || actionValue === ESuggestionAction.ACTION_MESSAGE_RECEIVED_MAIL}
                                                    checked={action === actionValue}
                                                    onChange={() => setAction(actionValue)}
                                                    label={translate('pages.activity.suggestionActionValueShort.' + actionValue)}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                            )
                        }
                    </div>
                    {(action === ESuggestionAction.ACTION_CALL_DATE_SET || action === ESuggestionAction.ACTION_CALL_DATE_CHANGE) &&
                        <PaperRow>
                            <CustomDateTimePicker
                                label={action === ESuggestionAction.ACTION_CALL_DATE_SET ? "Telefontermin geplant für" : "Telefontermin verschoben auf"}
                                value={new Date(callAppointmentAt)}
                                onChange={(value) =>
                                    setCallAppointmentAt(moment(value).format("Y-MM-DD H:mm:ss"))
                                }
                            />
                        </PaperRow>
                    }

                    {action === ESuggestionAction.ACTION_NOT_REACHABLE_UNTIL &&
                        <PaperRow>
                            <CustomDatePicker
                                label={"wieder erreichbar ab"}
                                value={new Date(notReachableUntil)}
                                onChange={(value) =>
                                    setNotReachableUntil(moment(value).format("Y-MM-DD"))
                                }
                            />
                        </PaperRow>
                    }
                    {(action === ESuggestionAction.ACTION_MESSAGE_PORTAL || action === ESuggestionAction.ACTION_MESSAGE_RECEIVED_PORTAL) &&
                        <PaperRow>
                            <PortalDropDown
                                value={messagePortalSource}
                                label={'Portal'}
                                onChange={(event) => setMessagePortalSource(event.target.value)}
                            />
                        </PaperRow>
                    }
                    <br/>
                    <PaperRow>
                        <CustomTextField
                            label={translate('pages.activity.properties.userInput')}
                            value={userInput}
                            multiline={true}
                            onChange={(value) => setUserInput(value)}
                            rows={10}
                        />
                    </PaperRow>
                </CustomDialogBody>
                <CustomDialogActions
                    onClose={() => dispatch(setVacancyPageSuggestionToAddActivityAction(0))}
                    buttons={[{
                        action: () => {
                            dispatch(addSuggestionActivityAction(token, {
                                action,
                                suggestionId,
                                dateTime: moment(dateTime).format("DD.MM.Y H:mm:ss"),
                                callAppointmentAt: callAppointmentAt === '' ? undefined : callAppointmentAt,
                                notReachableUntil: notReachableUntil === '' ? undefined : notReachableUntil,
                                userInput: userInput,
                                messagePortalSource: messagePortalSource,
                            }, onClose));
                        },
                        label: translate('misc.buttons.save')
                    }]}
                />
            </CustomDialog>
        </>
    );
};

export default SuggestionAddActionView;
