import React from 'react';
import { ECandidateSource, TExternalCandidateSource } from '../../../../definitions/entities.definition';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SourceRenderer from '../../../CustomCellRenderer/SourceRenderer';

interface IProps {
    value?: ECandidateSource;
    onChange: (value?: ECandidateSource) => void;
}

const SkillFilter: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value: 0|ECandidateSource) => {
        if (value === 0) {
            props.onChange(undefined);
            return;
        }
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const deletable = Boolean(value);
    const valueRendered = (deletable) ? translate('pages.vacancy.externalCandidateSourceValues.' + value) : null;

    const sources: TExternalCandidateSource[] = [
        ECandidateSource.jobcenter,
        ECandidateSource.freeLancerMap,
        ECandidateSource.homepage,
        ECandidateSource.indeed,
        ECandidateSource.joyn,
        ECandidateSource.linkedIn,
        ECandidateSource.xing,
        ECandidateSource.further,
    ];
    const toggleButtons = (props.validValues ? props.validValues : sources).map((source: TExternalCandidateSource, index: number) => (
        <ToggleButton key={index + 1} value={source} style={{border: '1px solid var(--brainz-color-text-secondary)', color: 'var(--brainz-color-text-secondary)'}}>
            <SourceRenderer source={source}/>
        </ToggleButton>
    ));

    return <>
        <CustomFilterItem
            title={"Quelle"}
            icon={EIcons.Search}
            renderedValue={valueRendered}
            active={deletable}
            removeFilterAction={resetFilter}
            width={"auto"}
            autoClose
        >
            <ToggleButtonGroup
                color="secondary"
                value={value}
                exclusive
                onChange={(event, value) => onChange(value)}
                aria-label="text alignment"
            >
                { toggleButtons }
                <ToggleButton value={0} aria-label="justified" style={{border: '1px solid var(--brainz-color-text-secondary)', color: 'var(--brainz-color-text-secondary)'}}>
                    Egal
                </ToggleButton>
            </ToggleButtonGroup>
        </CustomFilterItem>
    </>;
};

export default React.memo(SkillFilter);
