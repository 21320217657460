import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import CustomDialogHeader from '../CustomDialog/CustomDialogHeader';
import CustomDialogActions from '../CustomInput/CustomDialogActions';
import CustomTypography from '../CustomTypography/CustomTypography';
import CustomDialogBody from '../CustomDialog/CustomDialogBody';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { actorChangePassword } from '../../redux/actor/actor.actions';
import PaperRow from '../CustomPaper/PaperRow';
import CustomTextField from '../CustomInput/CustomTextField';
import { translate } from '../../translation/translate.utils';
import { registerError } from '../../redux/error/error.actions';
import { getValidationErrorsFromStore, useChangePasswordData, useToken } from '../../selectors/app.selectors';

const ChangePasswordView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useToken();
    const userData = useChangePasswordData();
    const validationErrors = useSelector(getValidationErrorsFromStore);
    const open = useSelector((store: IStore) => store.ui.renewPassword);
    const onClose = () => {
        return null;
    };
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    return (
        <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
            <CustomDialogHeader translationString={"misc.renewPassword"} onClose={onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <CustomTypography translation={"misc.renewPasswordBody"} />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('login.password')}
                        type={"password"}
                        value={currentPassword}
                        onChange={(value) => setCurrentPassword(value)}
                        error={Boolean(validationErrors.currentPassword)}
                        helperText={validationErrors.currentPassword}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('login.newPassword')}
                        type={"password"}
                        value={newPassword}
                        onChange={(value) => setNewPassword(value)}
                        error={Boolean(validationErrors.newPassword)}
                        helperText={validationErrors.newPassword}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('login.newPasswordControl')}
                        type={"password"}
                        value={newPasswordConfirmation}
                        onChange={(value) => setNewPasswordConfirmation(value)}
                        error={Boolean(validationErrors.newPasswordControl)}
                        helperText={validationErrors.newPasswordControl}
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                buttons={[{
                    action: () => {
                        if (newPassword !== newPasswordConfirmation) {
                                dispatch(registerError(translate('misc.passwordsMustBeEqual')));
                            return;
                        }

                        if (!newPassword || !newPasswordConfirmation) {
                            dispatch(registerError(translate('misc.passwordsMustNotBeEmpty')));
                            return;
                        }

                        dispatch(
                            actorChangePassword(token, {
                                userId: userData.userId,
                                userEmail: userData.userEmail,
                                currentPassword,
                                newPassword,
                                newPasswordConfirmation,
                            })
                        );
                    },
                    label: translate('misc.buttons.save')
                }]}
            />
        </Dialog>
    );
};

export default ChangePasswordView;
