import React from 'react';
import { closeUiRightDrawerAction } from '../../../../redux/ui/ui.actions';
import { ERightDrawerViews } from '../../../../definitions/ui.definitions';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';

const CloseMailDrawerElement: React.FC = () => {
    const dispatch = useDispatch();
    const drawerOpen = useSelector((store: IStore) => store.ui.rightDrawerMailOpen);

    return <IconButton
        onClick={() => {
            if (drawerOpen) {
                dispatch(closeUiRightDrawerAction(ERightDrawerViews.viewMails));
                return;
            }
        }}
    >
        {IconFactory.getIconComponent(EIcons.ChevronRight)}
    </IconButton>
}

export default CloseMailDrawerElement;
