import React, { ReactNode } from 'react';
import {
    ICompanyContactPartial,
    ICompanyLocation,
    ICompanyLocationPartial,
    TBrainzEntity,
} from '../../../../definitions/entities.definition';
import {
    getCompanyContactListRouteConfig,
    getCompanyContactRouteConfig,
    getCompanyLocationListRouteConfig, getCompanyLocationRouteConfig,
} from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomLivesearch from '../CustomLivesearch';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../CustomClickableIcon';
import { EIcons } from '../../../Icons/IconFactory';
import { getCustomerContactListRouteConfig } from '../../../../requests/routes.customer';
import { setUiCompanyLocationAddDataAction } from '../../../../redux/ui/ui.actions';
import { useDispatch } from 'react-redux';

interface IProps {
    value: ICompanyLocation|ICompanyLocationPartial|number|null;
    onChange: (value: ICompanyLocation|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    companyId?: number;
    addEnabled?: boolean;
}

const CompanyContactAutocomplete: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const value = props.value;
    const addEnabled = props.addEnabled || false;
    const companyId = props.companyId;

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as ICompanyLocation);
    }

    const onAdd = () => {
        if (!companyId) {
            return;
        }
        dispatch(setUiCompanyLocationAddDataAction({
            companyId
        }));
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        const entityCast = record as ICompanyLocation;
        if (!props.companyId) {
            return `${entityCast.company.name} ${entityCast.zipCode} ${entityCast.city}, ${entityCast.address}`
        }
        return `${entityCast.zipCode} ${entityCast.city}, ${entityCast.address}`
    }

    return <CustomLivesearch
        id={"company-location-autocomplete"}
        label={props.label || translate('pages.companyLocation.companyLocation')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getCompanyLocationListRouteConfig}
        entityRouteConfig={getCompanyLocationRouteConfig}
        entityRouteParam={"locationId"}
        getOptionLabel={getOptionLabel}
        startAdornment={(props.companyId && addEnabled) ? (
            <InputAdornment position="end">
                <CustomClickableIcon onClick={onAdd} icon={EIcons.Add}/>
            </InputAdornment>
        ) : undefined}
        loadAll
        routeParams={{
            companyId: props.companyId ? props.companyId : undefined
        }}
    />;
}

export default CompanyContactAutocomplete;

