import React from 'react';
import VacancyRecruitingList from './component/VacancyRecruitingList';
import { useDispatch } from 'react-redux';
import FastSearchPanelRecruitingList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelRecruitingList';
import { setRecruitingPageStartRecruitingAction } from '../../../../redux/recruitingPage/recruitingPage.actions';
import { EIcons } from '../../../../components/Icons/IconFactory';

interface IProps {
    vacancyId: number;
}

const VacancyRecruitingPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const vacancyId = props.vacancyId;

    return <>
        <div className={"flexColumn padding5"}>
            <FastSearchPanelRecruitingList
                fastSearch={''}
                setFastSearch={() => console.log(1)}
                actionButtons={[{
                    action: () => dispatch(setRecruitingPageStartRecruitingAction({
                        open: true,
                        vacancyId
                    })),
                    tooltip: "Kandidat vorschlagen",
                    icon: EIcons.Add
                }]}
            />
            <VacancyRecruitingList vacancyId={vacancyId}/>
        </div>
    </>;
};

export default React.memo(VacancyRecruitingPanel);
