import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import LegacyCandidateAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCandidateAutocompleteDropDown';
import { IRecruitingPartial, IVacancyPartial, TEntity } from '../../../../definitions/entities.definition';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import OpenRecruitingAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/Legacy/OpenRecruitingAutocompleteDropDown';
import { getToken } from '../../../../selectors/app.selectors';
import VacancyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import CustomDialogBody from '../../../../components/CustomDialog/CustomDialogBody';
import CustomRadioGroupInput from '../../../../components/CustomInput/CustomRadioGroupInput';
import {
    getMailListByChannelAction,
    getMailListByFolderAction,
    postMailRelationAction,
} from '../../../../redux/entities/entities.actions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';

interface IProps {
    mailId: number;
    open: boolean;
    onClose: () => void;
}

const AssignMailView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const mailId = props.mailId;

    const mail = useSelector((store: IStore) => store.entities.mail.byId[mailId]);
    const [entityType, setRecord] = useState<TEntity>('ENTITY_RECRUITING');

    const [candidateId, setCandidateId] = useState<undefined|number>(undefined);
    const [recruiting, setRecruiting] = useState<undefined|IRecruitingPartial>(undefined);
    const [vacancy, setVacancy] = useState<null|IVacancyPartial>(null);
    const [addMailAddress, setAddMailAddress] = useState<null|string>(null);
    const [errors, setErrors] = useState<{vacancy?: string, company?: string, candidateId?: string, recruiting?: string}>({});
    const [saving, setSaving] = useState(false);

    const filter = useSelector((state: IStore) => state.mailing.filter);
    const paging = useSelector((state: IStore) => state.mailing.paging);
    const selectedMailboxId = useSelector((state: IStore) => state.mailing.selectedMailboxId);

    const loadMails = () => {
        if (filter.folderId && selectedMailboxId) {
            dispatch(getMailListByFolderAction(token, filter.folderId, selectedMailboxId, paging.start, paging.limit,false));
            return;
        }
        if (filter.channel) {
            dispatch(getMailListByChannelAction(token, filter.channel));
            return;
        }
    }

    const onSave = () => {
        setErrors({});
        if (entityType === 'ENTITY_RECRUITING' && !recruiting) {
            setErrors({
                recruiting: 'This value should not be blank'
            });
            return
        }
        if (entityType === 'ENTITY_CANDIDATE' && !candidateId) {
            setErrors({
                candidateId: 'This value should not be blank'
            });
            return
        }
        if (entityType === 'ENTITY_VACANCY' && !vacancy) {
            setErrors({
                vacancy: 'This value should not be blank'
            });
            return
        }

        let entityId = 0;
        switch (entityType) {
            case 'ENTITY_RECRUITING':
                entityId = recruiting?.id;
                break;
            case 'ENTITY_VACANCY':
                entityId = vacancy?.id;
                break;
            case 'ENTITY_CANDIDATE':
                entityId = candidateId;
                break;
        }

        setSaving(true);
        dispatch(postMailRelationAction(token, addMailAddress, props.mailId, 'content', entityType, entityId)).then(() => {
            setSaving(false);
            props.onClose();
            loadMails();
        }).catch(() => setSaving(false));
    }

    if (!props.open) {
        return null;
    }

    const mails = [...mail.to, ...mail.cc, ...[mail.from]].filter((mail) => !mail.isInternalMailAddress && !mail.entityId);

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <CustomDialogHeader string="E-Mail mit Datensatz verknüpfen" onClose={props.onClose} />
            <CustomDialogBody>
                <CustomBackdropLoadingInsideDiv open={saving}/>
                <PaperRow>
                    <CustomRadioGroupInput label={"Verknüpfen mit"} value={entityType} onChange={(record) => setRecord(record)} states={[{
                        label: 'Prozess',
                        value: 'ENTITY_RECRUITING'
                    }, {
                        label: 'Vakanz',
                        value: 'ENTITY_VACANCY'
                    }, {
                        label: 'Kandidat',
                        value: 'ENTITY_CANDIDATE'
                    }]}/>
                </PaperRow>
                {(mails.length > 0 && entityType === 'ENTITY_CANDIDATE') &&
                    <PaperRow>
                        <CustomRadioGroupInput
                            label={"E-Mail Adresse dem Kandidaten hinzufügen"}
                            states={mails.map((mail) => {
                                return {
                                    value: mail.mailAddress,
                                    label: mail.mailAddress
                                }
                            })}
                            onChange={(value) => setAddMailAddress(value)} />
                    </PaperRow>
                }
                {entityType === 'ENTITY_RECRUITING' &&
                    <PaperRow>
                        <OpenRecruitingAutocompleteDropDown
                            value={recruiting}
                            error={Boolean(errors.recruiting)}
                            helperText={errors.recruiting}
                            required
                            onChange={(event, value: IRecruitingPartial) => setRecruiting(value)}
                        />
                    </PaperRow>
                }
                {entityType === 'ENTITY_VACANCY' &&
                    <PaperRow>
                        <VacancyAutocomplete
                            value={vacancy}
                            error={Boolean(errors.vacancy)}
                            helperText={errors.vacancy}
                            required
                            onChange={(value) => setVacancy(value)}
                        />
                    </PaperRow>
                }
                {entityType === 'ENTITY_CANDIDATE' &&
                    <PaperRow>
                        <LegacyCandidateAutocompleteDropDown
                            value={candidateId}
                            error={Boolean(errors.candidateId)}
                            helperText={errors.candidateId}
                            required
                            onChange={(event: any, value) => {
                                if (value) {
                                    setCandidateId(value.id)
                                } else {
                                    setCandidateId(0);
                                }
                            }}
                        />
                    </PaperRow>
                }
            </CustomDialogBody>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: () => onSave(),
                    label: 'Verknüpfung hinzufügen'
                }]}
            />
        </Dialog>
    );
};

export default AssignMailView;
