import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { ESuggestionStatus, IVacancyCount, IVacancyListModel } from '../../definitions/entities.definition';
import { setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';
import moment from 'moment/moment';
import { setVacancyPageSuggestionListOfVacancyFilterAction } from '../../redux/vacancyPage/vacancyPage.actions';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../Pages/RecruitingPage/components/VacancyOpenList.module.css';
import RatingIcon from '../CustomRating/RatingIcon';
import { updateVacancyAction } from '../../redux/entities/entities.actions';
import { EIcons } from '../Icons/IconFactory';
import { getToken } from '../../selectors/app.selectors';
import CustomDeleteDialog from '../CustomDialog/CustomDeleteDialog';

interface IProps {
    id: number;
    orderReceivedDate?: string;
    callback: () => void;
}

const VacancyOrderReceivedCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const id = props.id;
    const orderReceivedDate = props.orderReceivedDate;
    const load = () => {
        props.callback;
        setOpenDelete(false);
    }

    const [openDelete, setOpenDelete] = useState(false);
    const change = () => {
        dispatch(updateVacancyAction(
            token, {
                id: id,
                orderReceivedDate: orderReceivedDate ? null : moment().format('YYYY-MM-DD'),
            },
            () => load()
        ))
    }

    return (
        <>
            <CustomDeleteDialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                onDelete={change}
                titleTranslation={"pages.vacancy.deleteOrderReceivedDateDialog.title"}
                bodyTranslation={"pages.vacancy.deleteOrderReceivedDateDialog.body"}
            />
            <RatingIcon
                value={Boolean(orderReceivedDate)}
                onChangePin={() => {
                    if (orderReceivedDate) {
                        setOpenDelete(true);
                        return;
                    }
                    change();
                }}
                icon={EIcons.VerifiedUser}
                tooltip={orderReceivedDate ? 'Auftrag erhalten am ' + moment(orderReceivedDate).format('DD.MM') : 'Auftrag erhalten'}
            />
        </>
    );
};

export default VacancyOrderReceivedCellRenderer;
