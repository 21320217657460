import React, { useEffect, useState } from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { translate } from '../../../translation/translate.utils';
import {
    ECandidateSource,
    ICandidate,
    ICandidateDocument,
    ICompany,
    ICompanyContactPartial,
    ICompanyForApi,
    ICompanyLocationPartial,
} from '../../../definitions/entities.definition';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import {
    addCompanyAction,
    getApplicationAction,
    startRecruitingAction,
} from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../components/CustomDialog/CustomDialogBody';
import { Dialog } from '@material-ui/core';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import EmployeeAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import CandidateAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';
import CandidateSourceDropDown from '../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import CompanyAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/CompanyAutocomplete';
import VacancyAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import { ClientApi } from '../../../requests/ClientApi';
import { getCandidateRouteConfig } from '../../../requests/routes';
import { getDayDifference } from '../../../utils/date.utils';
import WebVacancyAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/WebVacancyAutocomplete';
import CustomRadioGroupInput from '../../../components/CustomInput/CustomRadioGroupInput';
import CustomNumberInput from '../../../components/CustomInput/CustomNumberInput';
import CustomUrlField from '../../../components/CustomInput/CustomUrlField';
import RecruitingSharedDocumentAutocomplete from './RecruitingSharedDocumentAutocomplete';
import CompanyLocationAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/CompanyLocationAutocomplete';
import CompanyContactAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';

interface IProps {
    onClose: () => void;
}

const StartRecruitingForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const initialCandidateId = useSelector((store: IStore) => store.recruitingPage.startRecruitingView.candidateId);
    const initialVacancyId = useSelector((store: IStore) => store.recruitingPage.startRecruitingView.vacancyId);
    const initialWebVacancyId = useSelector((store: IStore) => store.recruitingPage.startRecruitingView.webVacancyId);
    const initialCompanyId = useSelector((store: IStore) => store.recruitingPage.startRecruitingView.companyId);
    const initialResponsibleId = useSelector((store: IStore) => store.recruitingPage.startRecruitingView.responsibleEmployeeId);
    const initialSuggestedByEmployeeId = useSelector((store: IStore) => store.recruitingPage.startRecruitingView.suggestedByEmployeeId);
    const applicationId = useSelector((store: IStore) => store.recruitingPage.startRecruitingView.applicationId);
    const errors = useSelector(getValidationErrorsFromStore);

    const [responsibleEmployeeId, setResponsibleEmployeeId] = useState<number>(initialResponsibleId || loggedInEmployee.id);
    const [suggestedByEmployeeId, setSuggestedByEmployeeId] = useState<number>(initialSuggestedByEmployeeId || loggedInEmployee.id);
    const [candidateId, setCandidateId] = useState(initialCandidateId || null);
    const [vacancyId, setVacancyId] = useState(initialVacancyId || null);
    const [webVacancyId, setWebVacancyId] = useState(initialWebVacancyId || null);
    const [desiredSalary, setDesiredSalary] = useState(0);
    const [companyId, setCompanyId] = useState<null|number>(null);
    const [companyContact, setCompanyContact] = useState<null|ICompanyContactPartial>(null);
    const [companyLocation, setCompanyLocation] = useState<undefined|ICompanyLocationPartial>(undefined);
    const [source, setSource] = useState(ECandidateSource.brainz);
    const [type, setType] = useState<'vacancy'|'webVacancy'|'initiative'>('vacancy');
    const [addCompany, setAddCompany] = useState(false);
    const [addCompanyName, setAddCompanyName] = useState('');
    const [url, setUrl] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [selectedDocuments, setSelectedDocuments] = useState<ICandidateDocument[]>([]);

    useEffect(() => {
        if (!candidateId) {
            return;
        }
        ClientApi.request(getCandidateRouteConfig, {
            token,
            candidateId
        }).then((candidate: ICandidate) => {
            if (getDayDifference(candidate.created) <= 30) {
                setSource(candidate.source);
                return;
            }
            setSource(ECandidateSource.brainz);
        });
    }, [candidateId]);

    const addNewCompany = () => {
        const company: ICompanyForApi = {
            name: addCompanyName,
            responsibleEmployeeId: loggedInEmployee.id,
        };

        dispatch(addCompanyAction(token, company, (response: ICompany) => {
            setAddCompany(false);
            setAddCompanyName('');
            setCompanyId(response.id);
            setCompanyContact(null);
        }));
        return;
    }

    const onSave = () => {
        dispatch(startRecruitingAction(
            type,
            jobTitle,
            token,
            companyId,
            vacancyId,
            candidateId,
            responsibleEmployeeId,
            suggestedByEmployeeId,
            companyLocation ? companyLocation.id : null,
            source,
            false,
            applicationId || null,
            webVacancyId,
            url,
            desiredSalary,
            selectedDocuments.map((document) => document.id)
        )).then(() => {
            if (applicationId) {
                dispatch(getApplicationAction(token, applicationId));
            }
            props.onClose();
        });
    }

    return (
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
            <PaperRow>
                <CustomRadioGroupInput
                    value={type}
                    onChange={(type) => {setType(type)}}
                    states={[{
                        value: 'vacancy',
                        label: 'bei Vakanz'
                    }, {
                        value: 'webVacancy',
                        label: 'für Stelle'
                    }, {
                        value: 'initiative',
                        label: 'initiativ'
                    }]}
                />
            </PaperRow>
            {type === 'initiative' &&
                <PaperRow>
                    <CustomTextField
                        value={jobTitle}
                        required
                        label={'Vorgeschlagen als/Job-Titel'}
                        onChange={(value) => setJobTitle(value)}
                        error={Boolean(errors.jobTitle)}
                        helperText={errors.jobTitle}
                    />
                    <CustomUrlField
                        url={url}
                        label={'URL zur Stelle'}
                        value={url}
                        onChange={(value) => setUrl(value)}
                    />
                </PaperRow>
            }
            <PaperRow>
                {type === 'vacancy' &&
                    <VacancyAutocomplete
                        value={vacancyId}
                        required
                        error={Boolean(errors.vacancyId)}
                        helperText={errors.vacancyId}
                        companyId={initialCompanyId}
                        onChange={(value) => {
                            if (value === null) {
                                setVacancyId(null);
                                setCompanyLocation(undefined);
                                setCompanyId(null);
                                setCompanyContact(null);
                                setJobTitle(null);
                                return;
                            }
                            setVacancyId(value.id);
                            setCompanyLocation(value.companyLocations[0]);
                            setJobTitle(value.title);
                            setCompanyContact(value.responsibleContact || null);
                            setCompanyId(value.company.id);
                        }}
                    />
                }
                {type === 'webVacancy' &&
                    <>
                        <WebVacancyAutocomplete
                            value={webVacancyId}
                            companyId={initialCompanyId}
                            required
                            error={Boolean(errors.webVacancyId)}
                            helperText={errors.webVacancyId}
                            onChange={(value) => {
                                if (value === null) {
                                    setWebVacancyId(null);
                                    setCompanyId(null);
                                    return;
                                }
                                setWebVacancyId(value.id);
                                setCompanyId(value.company.id);
                                setCompanyLocation(value.matchedLocations[0]);
                                setJobTitle(value.title);
                            }}
                        />
                        <CompanyLocationAutocomplete
                            onChange={(value) => setCompanyLocation(value || undefined)}
                            value={companyLocation || null}
                            addEnabled
                            companyId={companyId || initialCompanyId}
                        />
                    </>
                }
                { type === 'initiative' &&
                <>
                    <>
                        <CompanyAutocomplete
                            onAdd={() => setAddCompany(true)}
                            required
                            value={companyId}
                            error={Boolean(errors.companyId)}
                            helperText={errors.companyId}
                            onChange={(company) => setCompanyId(company ? company.id : null)}
                        />
                        <Dialog open={addCompany} onClose={() => setAddCompany(false)}>
                            <CustomDialogHeader translationString={'pages.company.add'} onClose={() => setAddCompany(false)} />
                            <CustomDialogBody>
                                <CustomTextField
                                    required={true}
                                    value={addCompanyName}
                                    onChange={(value) => setAddCompanyName(value)}
                                    label={translate('pages.company.properties.name')}
                                />
                            </CustomDialogBody>
                            <CustomDialogActions
                                onClose={() => setAddCompany(false)}
                                buttons={[{
                                    action: () => addNewCompany(),
                                    label: translate('pages.company.add')
                                }]}
                            />
                        </Dialog>
                        <CompanyLocationAutocomplete
                            onChange={(value) => setCompanyLocation(value || undefined)}
                            value={companyLocation || null}
                            addEnabled
                            companyId={companyId || undefined}
                        />
                    </>
                </>
                }
            </PaperRow>
            <PaperRow>
                <CustomNumberInput
                    label={translate('pages.recruiting.suggestedWithSalary')}
                    value={desiredSalary}
                    onChange={(value) => setDesiredSalary(value || 0)}
                />
                <CompanyContactAutocomplete
                    value={companyContact}
                    onChange={(value) => setCompanyContact(value)}
                    disabled={!companyId}
                    addEnabled
                    companyId={companyId || undefined}
                />
            </PaperRow>
            <PaperRow>
                <CandidateAutocomplete
                    value={candidateId || null}
                    required
                    error={Boolean(errors.candidateId)}
                    helperText={errors.candidateId}
                    onChange={(candidate) => {
                        if (candidate) {
                            setCandidateId(candidate.id);
                            return;
                        }
                        setCandidateId(0);
                    }}
                />
                <CandidateSourceDropDown
                    value={source}
                    onChange={(event) => setSource(event.target.value)}
                />
            </PaperRow>
            <PaperRow>
                <EmployeeAutocomplete
                    required
                    label={translate('pages.vacancy.suggestedByEmployeeFormLabel')}
                    value={suggestedByEmployeeId}
                    onChange={(value) => {
                        if (!value) {
                            return;
                        }
                        setSuggestedByEmployeeId(value.id)
                    }}
                />
                <EmployeeAutocomplete
                    required
                    label={translate('misc.responsibleEmployee')}
                    value={responsibleEmployeeId}
                    onChange={(value) => {
                        if (!value) {
                            return;
                        }
                        setResponsibleEmployeeId(value.id)
                    }}
                />
            </PaperRow>
            {(companyId === 233 && candidateId) && <PaperRow>
                <RecruitingSharedDocumentAutocomplete
                    initialDocumentIds={[]}
                    setSelectedDocuments={setSelectedDocuments}
                    candidateId={candidateId}
                    error={Boolean(errors.selectedDocuments)}
                    helperText={errors.selectedDocuments}
                />
            </PaperRow>
            }
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: onSave,
                    label: translate('misc.buttons.save')
                }]}
            />
        </div>
    );
}

export default StartRecruitingForm;
