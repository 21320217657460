import React from 'react';
import CustomDrawerItem from '../../components/CustomDrawer/CustomDrawerItem';
import { ERoutes } from '../../definitions/app.definitions';
import { EIcons } from '../../components/Icons/IconFactory';
import { COLOR_BLUE, COLOR_GREEN, COLOR_ORANGE, COLOR_PURPLE, COLOR_RED, COLOR_YELLOW, dark } from '../../theme/theme';
import { getLoggedInEmployeeFromStore, userHasRole } from '../../selectors/employee.selectors';
import { ROLE_BILLING } from '../../definitions/actor.definitions';
import { setUiCurrentRouteAction } from '../../redux/ui/ui.actions';
import List from '@material-ui/core/List';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import PinnedViewsMenuItem from './TopBar/MenuItem/PinnedViewsMenuItem';
import ShowOnlyMyDataModeMenuItem from './TopBar/MenuItem/ShowOnlyMyDataModeMenuItem';
import { IStore } from '../../definitions/redux/store.definitions';

const LeftDrawerNavigation: React.FC = () => {
    const dispatch = useDispatch();
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const history = useHistory();
    const onClick = (route: ERoutes) => {
        dispatch(setUiCurrentRouteAction(route));
        history.push(route);
    };
    const employeeData = useSelector((store: IStore) => store.dashboardPage.employeeData);

    return <>
        <Drawer variant="permanent">
            <List className={"AppNavigation gap10"}>
                <CustomDrawerItem
                    currentRoute={history.location.pathname}
                    route={ERoutes.Dashboard}
                    onClick={onClick}
                    translation={"pages.dashboard.title"}
                    icon={EIcons.Dashboard}
                    color={COLOR_YELLOW}
                    customKey={1}
                />
                <CustomDrawerItem
                    currentRoute={history.location.pathname}
                    badgeContent={employeeData.countOpenApplications}
                    route={ERoutes.Applications}
                    onClick={onClick}
                    translation={"pages.application.title"}
                    icon={EIcons.AccountBox}
                    color={COLOR_ORANGE}
                    customKey={10}
                />
                <CustomDrawerItem
                    currentRoute={history.location.pathname}
                    route={ERoutes.Recruitings}
                    onClick={onClick}
                    translation={"pages.recruiting.title"}
                    icon={EIcons.ThumbsUpDown}
                    color={COLOR_ORANGE}
                    customKey={2}
                />
                <CustomDrawerItem
                    currentRoute={history.location.pathname}
                    route={ERoutes.Candidates}
                    onClick={onClick}
                    translation={"pages.candidate.title"}
                    icon={EIcons.People}
                    color={COLOR_GREEN}
                    customKey={3}
                />
                <CustomDrawerItem
                    route={ERoutes.WebVacancies}
                    onClick={onClick}
                    translation={"pages.webVacancy.title"}
                    icon={EIcons.CloudDownload}
                    color={COLOR_RED}
                    customKey={4}
                />
                <CustomDrawerItem
                    route={ERoutes.Companies}
                    onClick={onClick}
                    translation={"pages.company.title"}
                    icon={EIcons.Apartment}
                    color={COLOR_BLUE}
                    customKey={5}
                />
                { userHasRole(ROLE_BILLING) &&
                    <>
                        <CustomDrawerItem
                            badgeContent={employeeData.countBillableInvoices + employeeData.countOverdueInvoices}
                            route={ERoutes.Billing}
                            onClick={onClick}
                            translation={"pages.billing.title"}
                            icon={EIcons.Money}
                            color={COLOR_PURPLE}
                            customKey={6}
                        />
                    </>
                }
                <CustomDrawerItem
                    route={ERoutes.ActivityOverview}
                    onClick={onClick}
                    translation={"pages.activityOverview.title"}
                    icon={EIcons.History}
                    color={dark}
                    customKey={7}
                />
                { (loggedInEmployee.id === 1) &&
                    <CustomDrawerItem
                        currentRoute={history.location.pathname}
                        route={ERoutes.Administration}
                        onClick={onClick}
                        translation={"pages.administration.title"}
                        icon={EIcons.Settings}
                        color={dark}
                        customKey={10}
                    />
                }
            </List>
            <List style={{flex: 1, display: 'flex', flexDirection: "column", justifyContent: 'end'}}>
                <PinnedViewsMenuItem/>
                <ShowOnlyMyDataModeMenuItem/>
            </List>
        </Drawer>
    </>
}

export default React.memo(LeftDrawerNavigation);
