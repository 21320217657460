import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ICompanyLocationPartial } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: number|null;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    values: ICompanyLocationPartial[];
}

const CompanyLocationDropDown: React.FC<IProps> = (props) => {
    return (
        <CustomDropDownInput
            onChange={props.onChange}
            required={props.required}
            value={props.value}
            id={"company-location-dropdown"}
            error={props.error}
            helperText={props.helperText}
            label={"Für Standort"}
        >
            {props.values.map((location) => <MenuItem key={location.id} value={location.id}>
                {location.city}
            </MenuItem>)}
        </CustomDropDownInput>
    );
};

export default CompanyLocationDropDown;
