import React, { useState } from 'react';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../translation/translate.utils';
import { ICompanyContactForApi, ICompanyPartial, TSalutation } from '../../../../definitions/entities.definition';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { addCompanyContactAction, updateCompanyContactAction } from '../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { setCompanyPageCompanyContactToDeleteAction } from '../../../../redux/companyPage/companyPage.actions';
import CustomMailField from '../../../../components/CustomInput/CustomMailField';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import SalutationDropDown from '../../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import moment from 'moment';
import CompanyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyAutocomplete';

interface IProps {
    companyContactId: number;
}

const CompanyEditForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const companyContactId = props.companyContactId;
    const companyContact = useSelector((store: IStore) => store.entities.companyContacts.byId[companyContactId]);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const validationErrors = useSelector(getValidationErrorsFromStore);

    const [name, setName] = useState(companyContact?.name || "");
    const [firstName, setFirstName] = useState(companyContact?.firstName || "");
    const [salutation, setSalutation] = useState<TSalutation>(companyContact?.salutation || 'mr');
    const [company, setCompany] = useState<ICompanyPartial|null>(companyContact?.company || null);
    const [title, setTitle] = useState(companyContact?.title || "");
    const [position, setPosition] = useState(companyContact?.position || "");
    const [comment, setComment] = useState(companyContact?.comment || "");
    const [mailAddress, setMailAddress] = useState(companyContact?.mailAddress || "");
    const [mailAddress2, setMailAddress2] = useState(companyContact?.mailAddress2 || "");
    const [phoneNumber, setPhoneNumber] = useState(companyContact?.phoneNumber || "");
    const [birthDate, setBirthDate] = useState(companyContact?.birthDate || "");
    const [linkedInUrl, setlinkedInUrl] = useState(companyContact?.linkedInUrl || "");
    const [xingUrl, setXingUrl] = useState(companyContact?.xingUrl || "");

    const onSave = () => {
        const companyContactApiObject: ICompanyContactForApi = {
            name,
            firstName,
            salutation,
            companyId: company.id,
            title,
            position,
            comment,
            mailAddress,
            mailAddress2,
            phoneNumber,
            birthDate,
            linkedInUrl,
            xingUrl
        };

        if (!companyContact) {
            dispatch(addCompanyContactAction(token, companyContactApiObject));
            return;
        }

        companyContactApiObject.id = companyContact.id;
        dispatch(updateCompanyContactAction(token, companyContactApiObject));
        return;
    };

    const onDelete = () => {
        if (!companyContact) {
            return;
        }
        dispatch(setCompanyPageCompanyContactToDeleteAction(companyContact.id));
    }

    return <>
        <div style={{ height: "100%", width: "100%", overflow: "auto", flex: 1 }}>
            <PaperRow>
                <SalutationDropDown
                    onChange={(event: any) => setSalutation(event.target.value)}
                    value={salutation}
                    required={true}
                />
                <CustomTextField
                    label={translate('pages.companyContact.properties.title')}
                    value={title}
                    onChange={(value) => setTitle(value)}
                />
                <CustomTextField
                    flex={2}
                    label={translate('pages.companyContact.properties.name')}
                    value={name}
                    required={true}
                    onChange={(value) => setName(value)}
                    error={Boolean(validationErrors.name)}
                    helperText={validationErrors.name}
                />
                <CustomTextField
                    flex={2}
                    label={translate('pages.companyContact.properties.firstName')}
                    value={firstName}
                    required={true}
                    onChange={(value) => setFirstName(value)}
                    error={Boolean(validationErrors.firstName)}
                    helperText={validationErrors.firstName}
                />
            </PaperRow>
            <PaperRow>
                { companyContact &&
                <CompanyAutocomplete
                    required
                    value={company}
                    onChange={(value) => setCompany(value)}
                />
                }
                <CustomTextField
                    label={translate('pages.companyContact.properties.position')}
                    value={position}
                    onChange={(value) => setPosition(value)}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    label={translate('misc.phoneNumber')}
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                />
                <CustomDatePicker
                    label={translate('pages.candidate.properties.birthDate')}
                    value={birthDate}
                    onChange={(value) => setBirthDate(value ? moment(value).format("Y-MM-DD") : undefined)}
                />
            </PaperRow>
            <PaperRow>
                <CustomMailField
                    label={translate('pages.companyContact.properties.mailAddress')}
                    value={mailAddress}
                    onChange={(value) => setMailAddress(value)}
                />
                <CustomMailField
                    label={translate('pages.companyContact.properties.mailAddress') + ' 2'}
                    value={mailAddress2}
                    onChange={(value) => setMailAddress2(value)}
                />
            </PaperRow>
            <PaperRow>
                <CustomUrlField
                    label={'LinkedIn Url'}
                    value={linkedInUrl}
                    onChange={(value) => setlinkedInUrl(value)}
                />
                <CustomUrlField
                    label={'Xing Url'}
                    value={xingUrl}
                    onChange={(value) => setXingUrl(value)}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    label={translate('misc.comment')}
                    multiline
                    rows={8}
                    value={comment}
                    onChange={(value) => setComment(value)}
                />
            </PaperRow>
        </div>
        <CustomDialogActions
            buttons={[{
                action: onDelete,
                hidden: !companyContact,
                label: translate('misc.buttons.delete')
            }, {
                action: () => onSave(),
                label: translate('misc.buttons.save')
            }]}
        />
    </>;
};

export default CompanyEditForm;
