import React, { useState } from 'react';
import VacancySuggestionList from './component/VacancySuggestionList';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    setVacancyPageAddSuggestionAction,
    setVacancyPageSuggestionListOfVacancyFilterAction,
} from '../../../../redux/vacancyPage/vacancyPage.actions';
import FastSearchPanelSuggestionList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelSuggestionList';
import { EIcons } from '../../../../components/Icons/IconFactory';
import CustomQuickFilterSuggestionList
    from '../../../../components/CustomFilter/CustomQuickFilter/CustomQuickFilterSuggestionList';
import AddEditSuggestionGroupView from '../../../RecruitingPage/AddEditSuggestionGroupView';
import { getSuggestionsForVacancyAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';
import {
    getVacancyPageRequestObjectForSuggestionsInEditView,
} from '../../../../selectors/recruitingSuggestion.selectors';

interface IProps {
    vacancyId: number;
    archived: boolean;
}

const VacancySuggestionPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const vacancyId = props.vacancyId;
    const archived = props.archived;
    const token = useSelector(getToken);
    const suggestionRequestObject = useSelector(getVacancyPageRequestObjectForSuggestionsInEditView);
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId]);
    const filter = useSelector((store: IStore) => store.vacancyPage.suggestionOfVacancyFilter);
    const [addGroup, setAddGroup] = useState(false);

    return <>
        <div className={"flexColumn padding5"}>
            <AddEditSuggestionGroupView
                add={addGroup}
                vacancyId={vacancyId}
                onClose={() => setAddGroup(false)}
                onAdd={() => {
                    dispatch(getSuggestionsForVacancyAction(token, vacancyId, suggestionRequestObject));
                }}
            />
            <FastSearchPanelSuggestionList
                fastSearch={''}
                setFastSearch={(fastSearch) => dispatch(setVacancyPageSuggestionListOfVacancyFilterAction({fastSearch}))}
                actionButtons={[{
                    action: () => dispatch(setVacancyPageAddSuggestionAction({
                        open: true,
                        vacancyId: vacancyId
                    })),
                    tooltip: 'Neuen Favoriten hinzufügen',
                    icon: EIcons.Add
                }, {
                    action: () => setAddGroup(true),
                    tooltip: 'Neue Gruppe hinzufügen',
                    icon: EIcons.GroupAdd
                }]}
            />
            <CustomQuickFilterSuggestionList
                counts={vacancy.counts}
                disabled={filter.fastSearch !== undefined && filter.fastSearch.trim() !== ''}
                countNeedAction={0}
                actionNeeded={false}
                status={filter.status}
                contact={filter.contact}
                groupByProgressStatus={filter.groupByProgressStatus}
                setFilterAction={(filter) => {
                    dispatch(setVacancyPageSuggestionListOfVacancyFilterAction(filter))
                }}
                locations={vacancy.companyLocations}
                locationId={filter.companyLocationId}
            />
            <VacancySuggestionList
                vacancyId={vacancyId}
                archived={archived}
            />
        </div>
    </>;
};

export default VacancySuggestionPanel;
