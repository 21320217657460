import React from 'react';
import { ECandidateSource, EPublicationSource, TCandidateUrlType } from '../../definitions/entities.definition';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { Tooltip } from '@material-ui/core';

interface IProps {
    source: ECandidateSource|TCandidateUrlType|EPublicationSource;
    size? : 'small'|'medium';
    renderHomepageAsWebsite?: boolean;
}

const SourceRenderer: React.FC<IProps> = (props) => {
    const size = props.size || 'small';
    const width = size === 'small' ? 22 : 45;
    const getIcon = () => {
        switch (props.source) {
            case ECandidateSource.recommendation:
                return <Tooltip title={"Empfehlung"}>{IconFactory.getIconComponent(EIcons.ThumbUp)}</Tooltip>;
            case ECandidateSource.brainzShare:
                return IconFactory.getIconComponent(EIcons.Share);
            case ECandidateSource.brainz:
                return <img width={width} src={"/images/brain.png"} title={"Quelle: Brainz"} />;
            case ECandidateSource.freeLancerMap:
            case 'freelancerMap':
                return <img width={width} src={"/images/freelancer_map.jpg"} title={"Quelle: Freelancer Map"} />;
            case ECandidateSource.homepage:
                if (props.renderHomepageAsWebsite) {
                    return <Tooltip title={"eigene Homepage"}>
                        {IconFactory.getIconComponent(EIcons.Home)}
                    </Tooltip>
                }
                return <img width={width} src={"/images/peagle.png"} title={"Quelle: Homepage"} />;
            case ECandidateSource.indeed:
                return <img width={width} src={"/images/indeed.png"} title={"Quelle: Indeed"} />;
            case ECandidateSource.joyn:
                return <img width={width} src={"/images/join.png"} title={"Quelle: Join"} />;
            case ECandidateSource.linkedIn:
                return <img width={width} src={"/images/linkedIn.png"} title={"Quelle: Linked In"} />;
            case ECandidateSource.jobcenter:
                return <img width={width} src={"/images/arbeitsagentur.png"} title={"Quelle: Arbeitsagentur"} />;
            case ECandidateSource.xing:
                return <img width={width} src={"/images/xing.png"} title={"Quelle: Xing"} />;
            case ECandidateSource.github:
                return <img width={width} src={"/images/github.png"} title={"Quelle: Github"} />;
            case ECandidateSource.further:
                return <>Sons.</>;
            case EPublicationSource.CustomerHomepage:
                return <Tooltip title={"Homepage des Kunden"}>
                    {IconFactory.getIconComponent(EIcons.Apartment)}
                </Tooltip>;
        }

        return null;
    }

    return <>
        <div className={"flexContainerColumn"}>
            {getIcon()}
        </div>
    </>
}

export default React.memo(SourceRenderer);
