import React from 'react';
import { useDispatch } from 'react-redux';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { setUiCompanyContactAddDataAction } from '../../../../redux/ui/ui.actions';
import CompanyContactList from './components/CompanyContactList';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { EEntities } from '../../../../definitions/entities.definition';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';

interface IProps {
    companyId: number;
}

const CompanyContactPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyId = props.companyId;

    const configuration: IAppBarConfig = {
        entity: EEntities.Activity,
        value: '',
        emptyValue: 'Ansprechpartner suchen',
        fastSearchCallback: () => console.log(1),
        rightButtons: [{
            action: () => dispatch(setUiCompanyContactAddDataAction({companyId})),
            tooltip: "Neuen Ansprechpartner anlegen",
            icon: EIcons.Add
        }]
    };

    return <>
        <div className={"flexColumn padding5"}>
            <div className={"flexAutoGrow"}>
                <CustomFastSearchPanel appBarConfig={configuration}/>
            </div>
            <CompanyContactList companyId={companyId}/>
        </div>
    </>;
}

export default CompanyContactPanel;
