import React, { ReactNode } from 'react';

interface IProps {
    value: ReactNode;
    label: string;
    className?: string;
}

const CustomValueElement: React.FC<IProps> = (props) => {
    return (
        <div className={"formRowColumn " + props.className} style={{marginLeft: 5, marginTop: 5}}>
            <div style={{fontSize: 12}}>{props.label}:&nbsp;</div>
            <div style={{padding: 5}}>{props.value}</div>
        </div>
    );
}

export default CustomValueElement;
