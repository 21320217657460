import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { translate } from '../../translation/translate.utils';
import { Dialog } from '@material-ui/core';
import { setJobTemplatePageSetJobTemplateAction } from '../../redux/jobTemplate/jobTemplatePage.actions';
import PaperRow from '../../components/CustomPaper/PaperRow';
import { IJobTemplate } from '../../definitions/entities.definition';
import JobTemplateAutocompleteDropDown
    from '../../components/CustomInput/CustomAutocomplete/Legacy/JobTemplateAutocompleteDropDown';
import { updateJobTitleAction } from '../../redux/entities/entities.actions';

const SetJobTemplateView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.jobTemplatePage.setJobTemplateForJobTitle));
    const setForJobTitle = useSelector((store: IStore) => store.jobTemplatePage.setJobTemplateForJobTitle);
    const [jobTemplateId, setJobTemplateId] = useState<number|undefined>();
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    const save = () => {
        if (!jobTemplateId) {
            return;
        }

        dispatch(updateJobTitleAction(token, {
            id: setForJobTitle as number,
            jobTemplateId
        }));
        onClose();
    }

    if (!open) {
        return null;
    }

    const onClose = () => {
        dispatch(setJobTemplatePageSetJobTemplateAction(undefined));
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <CustomDialogHeader string={"Berufsgruppe setzen"} onClose={onClose} />
            <CustomDialogBody>
                {translate('misc.jobTitle')}: {setForJobTitle}
                <PaperRow>
                    <JobTemplateAutocompleteDropDown
                        onChange={(event, value: IJobTemplate) => setJobTemplateId(value.id) }
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    action: () => console.log(1),
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </Dialog>
    );
}

export default SetJobTemplateView;
