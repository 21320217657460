import React from 'react';
import { IVacancy, IVacancyPartial } from '../../definitions/entities.definition';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { useDispatch } from 'react-redux';
import styles from './VacancyCellRenderer.module.css';
import { darker } from '../../theme/theme';
import { EEntityView } from '../../definitions/ui.definitions';
import { setUiCloseTopDrawer, setUiEditView } from '../../redux/ui/ui.actions';

interface IProps {
    vacancy?: null|IVacancy|IVacancyPartial;
    onDelete?: () => void;
    showCompany?: boolean;
    hideIcon?: boolean;
}

const VacancyCellRenderer: React.FC<IProps> = (props) => {
    if (!props.vacancy) {
        return null;
    }
    const vacancy = props.vacancy;
    const hideIcon = props.hideIcon || false;

    const dispatch = useDispatch();

    const getSubLabel = () => {
        if (props.showCompany) {
            return <>
                <div className={"formContent"} style={{color: 'var(--brainz-darker)',fontSize: 10}}>
                    {vacancy.company.name + ' (' + vacancy.companyLocations.map((location) => location.city).join(', ') + ')'}
                </div>
            </>
        }

        return <>
            <div className={"formContent"} style={{color: 'var(--brainz-darker)',fontSize: 10}}>
                {vacancy.companyLocations.map((location) => location.city).join(', ')}
            </div>
        </>
    }

    const label = <>
        <div className={"flexContainerColumn"}>
            <div className={"flex1 ellipseText"}>{vacancy.title}</div>
            {getSubLabel()}
        </div>
    </>
    return (
        <Tooltip title={vacancy.title} placement="top-start">
            <Chip
                key={vacancy.id}
                icon={hideIcon ? undefined : IconFactory.getIconComponent(EIcons.Work, {color: darker})}
                color="primary"
                className={styles.chip}
                deleteIcon={props.onDelete ? IconFactory.getIconComponent(EIcons.Close, {color: darker}) : undefined}
                onDelete={props.onDelete}
                label={label}
                onClick={(event) => {
                    event.stopPropagation();
                    //@ts-ignore
                    dispatch(setUiCloseTopDrawer());
                    dispatch(setUiEditView(EEntityView.vacancy, vacancy.id))
                }}
            />
        </Tooltip>
    );
};

export default React.memo(VacancyCellRenderer);
