import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EPublicationSource } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: EPublicationSource;
    size?: 'small'|'medium';
    disablePortal?: boolean;
}

const VacancyPublicationSourceDropDown: React.FC<IProps> = (props) => {
    const sources: EPublicationSource[] = [
        EPublicationSource.LinkedIn,
        EPublicationSource.Xing,
        EPublicationSource.CustomerHomepage,
        EPublicationSource.Homepage,
        EPublicationSource.Indeed,
        EPublicationSource.FreelancerMap,
        EPublicationSource.JobCenter
    ];
    const menuItems = (sources).map((source: EPublicationSource, index: number) => (
        <MenuItem key={index + 1} value={source}>
            {translate('pages.vacancy.vacancyPublicationSource.' + source)}
        </MenuItem>
    ));

    return (
        <CustomDropDownInput
            size={props.size}
            onChange={props.onChange}
            value={props.value || 0}
            required
            id={"vacancy-publication-source-dropdown"}
            label={translate('misc.source')}
            disablePortal={props.disablePortal}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default VacancyPublicationSourceDropDown;
