import React from 'react';
import { EActivityAction, EActivityType, IChatNode } from '../../../definitions/entities.definition';
import styles from './Chat.module.css';
import { Avatar } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { COLOR_TEXT } from '../../../theme/theme';
import ChatNodeAvatar from './ChatNodeAvatar';
import { useDispatch } from 'react-redux';
import {
    setUiActivityToDeleteAction,
    setUiActivityToEditAction,
    setUiEditView,
    setUiInterviewToEditAction,
} from '../../../redux/ui/ui.actions';
import Nl2brCellRenderer from '../../../components/CustomCellRenderer/Nl2brCellRenderer';
import { EEntityView } from '../../../definitions/ui.definitions';
import CustomContextMenuEntity from '../../../components/CustomContextMenu/CustomContextMenuEntity';

interface IProps {
    hideSenderReceiverForInterview: boolean;
    node: IChatNode
}
const ChatNode: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const node = props.node;
    const hideSenderReceiverForInterview = props.hideSenderReceiverForInterview;
    const appendStyle = styles['direction' + node.direction[0].toUpperCase() + node.direction.slice(1)];

    const onClick = () => {

        if (node.type === EActivityType.systemInfo) {
            if (node.action === EActivityAction.editCandidate) {
                dispatch(setUiActivityToEditAction(node.id));
                return;
            }

            const receiver = node.receiver;
            const receiverType = node.receiverType;

            switch (receiverType) {
                case 'candidate':
                    dispatch(setUiEditView(EEntityView.candidate, receiver.id));
                    return;
                case 'company':
                    dispatch(setUiEditView(EEntityView.company, receiver.id));
                    return;
                case 'company_contact':
                    dispatch(setUiEditView(EEntityView.companyContact, receiver.id));
                    return;
                case 'suggestion':
                    dispatch(setUiEditView(EEntityView.suggestion, receiver.id));
                    return;
                case 'application':
                    dispatch(setUiEditView(EEntityView.application, receiver.id));
                    return;
                case 'vacancy':
                    dispatch(setUiEditView(EEntityView.vacancy, receiver.id));
                    return;
                case 'recruiting':
                    dispatch(setUiEditView(EEntityView.recruiting, receiver.id));
                    return;
                default:
                    return
            }
        }

        if (node.type === EActivityType.interview) {
            dispatch(setUiInterviewToEditAction(node.id));
            return;
        }
        dispatch(setUiActivityToEditAction(node.id));
    }

    return <>
        <CustomContextMenuEntity
            index={node.id}
            entityId={node.id}
            entity={node}
            menuItems={[{
                icon: EIcons.Remove,
                action: (id) => {dispatch(setUiActivityToDeleteAction(id))},
                translationKey: 'tables.openDeleteView',
                condition: (entity) => {
                    const entityCast = entity as IChatNode;
                    return entityCast.type !== EActivityType.systemInfo;
                }
            }]}>
            <div className={styles.chatMessage + ' ' + appendStyle}>
                {!(node.type === EActivityType.interview && hideSenderReceiverForInterview) &&
                    <div className={"flexContainerColumn alignVerticalTop "}>
                        <div className={"flexContainerRow alignVerticalCenter " + appendStyle}>
                            <ChatNodeAvatar entity={node.sender} type={node.senderType} />
                            {IconFactory.getIconComponent(node.direction === 'received' ? EIcons.ArrowRight : EIcons.ArrowLeft)}
                            <ChatNodeAvatar entity={node.receiver} type={node.receiverType} />
                        </div>
                    </div>
                }
                <div className={styles.chatBubble + ' ' + appendStyle} onClick={onClick}>
                    <div className={styles.chatContent}>
                        <div className={"flexContainerRow alignVerticalCenter gap5 " + ' ' + appendStyle} >
                            {node.icon &&
                                //@ts-ignore
                                <>
                                    <Avatar style={{width:25, height: 25, background: COLOR_TEXT}}>
                                    {IconFactory.getIconComponent(EIcons[node.icon], {width: 20, height: 20, color: 'var(--brainz-dark)'})}
                                    </Avatar>
                                </>
                            }
                            <Nl2brCellRenderer value={node.shortText}/>
                        </div>
                        {node.longText &&
                            <div className={styles.chatMessageSecondary}>
                                <Nl2brCellRenderer value={node.longText}/>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.chatBubbleSpacer}>&nbsp;</div>
            </div>
        </CustomContextMenuEntity>
    </>
}

export default ChatNode;
