import {
    IAssignLanguage,
    ICandidate,
    ICandidateForApi,
    ICandidateLanguage,
    ICandidateListModel,
    ICandidatePartial,
} from '../definitions/entities.definition';
import { calculateAge } from './date.utils';
import {
    ICandidateFilterApiProperties,
    ICandidateFilterMenu,
    ICandidateForm,
} from '../definitions/candidatePage.definitions';

export const createApiObject = (
    candidateForm: ICandidateForm,
    fieldsIgnoredForValidation: string[],
    addRequest: boolean
): ICandidateForApi => {
    const apiObject: ICandidateForApi = {
        topCandidate: candidateForm.topCandidate,
        topInCommunication: candidateForm.topInCommunication,
        moveWillingness: candidateForm.moveWillingness,
        typus: candidateForm.typus,
        proudOf: candidateForm.proudOf,
        comment: candidateForm.comment,
        thoughtsAboutTeamAndAppreciation: candidateForm.thoughtsAboutTeamAndAppreciation,
        flexibility: candidateForm.flexibility,
        responsibleId: candidateForm.responsible ? candidateForm.responsible.id : undefined,
        followupDate: candidateForm.followupDate,
        followupComment: candidateForm.followupComment,
        imagePath: candidateForm.imagePath,
        imageUrl: candidateForm.imageUrl,
        name: candidateForm.name || '',
        firstName: candidateForm.firstName || '',
        freelancer: candidateForm.freelancer,
        salutation: candidateForm.salutation,
        title: candidateForm.title,
        birthDate: candidateForm.birthDate || '',
        source: candidateForm.source,
        salaryUnit: candidateForm.salaryUnit,
        expectedSalary: candidateForm.expectedSalary,
        expectedSalaryTo: candidateForm.expectedSalaryTo,
        homeOfficeDesire: candidateForm.homeOfficeDesire || undefined,
        noticePeriod: candidateForm.noticePeriod || undefined,
        leader: candidateForm.leader,
        desiredJobDescription: candidateForm.desiredJobDescription,
        currentJobDescription: candidateForm.currentJobDescription,
        hasDriverLicense: candidateForm.hasDriverLicense,
        wishDateOfStart: candidateForm.wishDateOfStart,
        partTimeFullTime: candidateForm.partTimeFullTime,
        applicationVacancyId: candidateForm.applicationVacancy ? candidateForm.applicationVacancy.id : undefined,
        topInPotential: candidateForm.topInPotential,
        numberOfEmployers: candidateForm.numberOfEmployers || '',
        fieldsIgnoredForValidation,
        yearsOfWorkExperience: (!addRequest || fieldsIgnoredForValidation.indexOf('yearsOfWorkExperience') > -1) ? candidateForm.yearsOfWorkExperience || undefined : candidateForm.yearsOfWorkExperience || '',
        level: (!addRequest || fieldsIgnoredForValidation.indexOf('level') > -1) ? candidateForm.level : candidateForm.level || '',
        educationLevel: (!addRequest || fieldsIgnoredForValidation.indexOf('educationLevel') > -1) ? candidateForm.educationLevel : candidateForm.educationLevel || '',
        skills: candidateForm.skills.map((skill) => {
            return {
                id: skill.id,
                importantSkill: skill.importantSkill,
                skillId: skill.skill?.id,
                externalSkillId: skill.externalSkill?.id
            }
        }),
        languages: candidateForm.languages.map((language) => {
            return {
                id: language.id,
                languageId: language.language.id,
                level: language.level
            }
        }),
    };

    if (addRequest) {
        return {
            ...apiObject,
            addresses: candidateForm.addresses.map((address) => {
                return {
                    ...address,
                    countryId: address.country ? address.country.id : undefined
                }
            }),
            mailAddresses: candidateForm.mailAddresses,
            urls: candidateForm.urls,
            phoneNumbers: candidateForm.phoneNumbers
        };
    }

    return apiObject;
}

export const getFilterByFilterMenu = (filterMenu: ICandidateFilterMenu): ICandidateFilterApiProperties => {
    return {
        hasDocuments: filterMenu.hasDocuments,
        salutation: filterMenu.salutation,
        skillIds: filterMenu.skills ? filterMenu.skills.map(({id}) => id) : [],
        optionalSkillIds: filterMenu.optionalSkills ? filterMenu.optionalSkills.map(({id}) => id) : [],
        languageIds: filterMenu.languages ? filterMenu.languages.map(({id}) => id) : [],
        leader: filterMenu.leader,
        yearsOfWorkExperience: filterMenu.yearsOfWorkExperience !== "0" ? filterMenu.yearsOfWorkExperience : undefined,
        distanceSearchCompanyLocationId: filterMenu.distanceSearchCompanyLocation?.id,
        distanceSearchKm: filterMenu.distanceSearchKm,
        ageFrom: filterMenu.ageFrom,
        ageTo: filterMenu.ageTo,
        topCandidate: filterMenu.topCandidate,
        topInCommunication: filterMenu.topInCommunication,
        responsibleEmployeeId: filterMenu.responsibleEmployee?.id,
        salaryFrom: filterMenu.salaryFrom,
        salaryTo: filterMenu.salaryTo,
        jobDescription: filterMenu.jobDescription,
        source: filterMenu.source,
        isFreelancer: filterMenu.isFreelancer,
        hasDriverLicense: filterMenu.hasDriverLicense,
        homeOfficeDesire: filterMenu.homeOfficeDesire,
        onlyFollowups: filterMenu.onlyFollowups,
        dateRangeFilter: filterMenu.dateRangeFilter,
        recruitingFilter: filterMenu.recruitingFilter ? {
            type: filterMenu.recruitingFilter.type,
            abortedAfter: filterMenu.recruitingFilter.abortedAfter,
            dateRange: filterMenu.recruitingFilter.dateRange,
            abortedBy: filterMenu.recruitingFilter.abortedBy,
            companyIds: filterMenu.recruitingFilter.companies ? filterMenu.recruitingFilter.companies.map((company) => company.id) : [],
            employeeIds: filterMenu.recruitingFilter.employees ? filterMenu.recruitingFilter.employees.map((employee) => employee.id) : [],
            finishedTrialPeriod: filterMenu.recruitingFilter.finishedTrialPeriod
        } : undefined
    }
}

export const candidateToListModel = (candidateModel: ICandidate, initialData?: ICandidateListModel): ICandidateListModel => {
    return {
        distanceText: '',
        durationText: '',
        matchedOptionalSkillIds: [],
        hasOpenSuggestion: false,
        hasOpenRecruiting: false,
        hasContactWithRecruiter: false,
        totalContracts: candidateModel.totalContracts,
        ...initialData,
        type: candidateModel.type,
        age: candidateModel.birthDate ? calculateAge(candidateModel.birthDate) : undefined,
        id: candidateModel.id,
        name: candidateModel.name,
        firstName: candidateModel.firstName,
        salutation: candidateModel.salutation,
        topCandidate: candidateModel.topCandidate,
        topInCommunication: candidateModel.topInCommunication,
        birthDate: candidateModel.birthDate,
        skills: candidateModel.skills.length,
        lastActivity: candidateModel.lastActivity,
        latestDocumentCvId: undefined,
        sendBirthdayWishes: candidateModel.sendBirthdayWishes,
        desiredJobDescription: candidateModel.desiredJobDescription,
        currentJobDescription: candidateModel.currentJobDescription,
        source: candidateModel.source,
        responsible: candidateModel.responsible,
        followupDate: '',
        birthdayMailSend: false,
        fullName: candidateModel.fullName,
        hasContractRecruiting: initialData?.hasContractRecruiting || false,
        level: candidateModel.level,
        educationLevel: candidateModel.educationLevel,
        numberOfEmployers: candidateModel.numberOfEmployers,
        topInPotential: candidateModel.topInPotential,
        unattractive: candidateModel.unattractive,
        candidateProfiles: candidateModel.candidateProfiles,
        expectedSalaryString: candidateModel.expectedSalaryString,
    }
}

export const onAddLanguagesToCandidate = (
    addLanguages: IAssignLanguage[],
    languages: ICandidateLanguage[],
    setLanguages: (languages: ICandidateLanguage[]) => void,
    candidate?: ICandidatePartial
) => {
    const addLanguages2: ICandidateLanguage[] = [];
    addLanguages.forEach((addLanguage) => {
        if (languages.find((candidateLanguage) => candidateLanguage.language.id === addLanguage.language.id) !== undefined) {
            return;
        }

        addLanguages2.push({
            id: 0,
            candidate,
            language: addLanguage.language,
            level: addLanguage.level
        });
    })

    setLanguages([
        ...languages,
        ...addLanguages2
    ].sort((a, b) => a.language.name.localeCompare(b.language.name)))
}
