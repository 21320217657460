import React, { useEffect, useState } from 'react';
import { IDunningLetter } from '../../../../definitions/entities.definition';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../../components/CustomDialog/CustomDialogBody';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../../translation/translate.utils';
import { Dialog } from '@material-ui/core';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import { ClientApi } from '../../../../requests/ClientApi';
import { API_URL, postDunningLetterRouteConfig, putDunningLetterRouteConfig } from '../../../../requests/routes';
import { getToken } from '../../../../selectors/app.selectors';
import { setBillingPageReloadInvoiceListAction } from '../../../../redux/billingPage/billingPage.actions';

interface IProps {
    invoiceId: number;
    openSum?: number;
    addDunningLetter: boolean;
    editDunningLetter: null|IDunningLetter;
    onClose: () => void;
}

const DunningLetterAddEditView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    const [tax, setTax] = useState(1.33);
    const [fee, setFee] = useState(20);
    const [date, setDate] = useState(moment().format("Y-MM-DD"));
    const [dueDate, setDueDate] = useState(moment().add(7, 'days').format("Y-MM-DD"));
    const [openSum, setOpenSum] = useState(props.openSum || 0);

    useEffect(() => {
        if (!props.editDunningLetter) {
            return;
        }
        setTax(props.editDunningLetter.tax);
        setFee(props.editDunningLetter.fee);
        setDate(props.editDunningLetter.date);
        setDueDate(props.editDunningLetter.dueDate);
        setOpenSum(props.editDunningLetter.openSum);
    }, [props.editDunningLetter]);


    const onSave = () => {
        if (props.editDunningLetter) {
            ClientApi.request(putDunningLetterRouteConfig, {
                id: props.editDunningLetter.id,
                token,
                tax,
                fee,
                date,
                dueDate
            }).then((result) => {
                console.log("JO");
                props.onClose();
                dispatch(setBillingPageReloadInvoiceListAction(true));
            });
            return;
        }

        ClientApi.request(postDunningLetterRouteConfig, {
            token,
            tax,
            fee,
            date,
            dueDate,
            invoiceId: props.invoiceId
        }).then((result) => {
            props.onClose();
            dispatch(setBillingPageReloadInvoiceListAction(true));
        });
    }

    if (!props.addDunningLetter && !props.editDunningLetter) {
        return null;
    }

    return (
        <Dialog fullWidth maxWidth={"md"} open={props.addDunningLetter || props.editDunningLetter !== null} onClose={props.onClose}>
            <CustomDialogHeader string={props.editDunningLetter ? "Mahnung bearbeiten" : "Mahnung hinzufügen"} onClose={props.onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <CustomTextField
                        type={"number"}
                        label={translate('pages.billing.dunningFee')}
                        value={fee}
                        onChange={(value) => setFee(value)}
                    />
                    <CustomTextField
                        type={"number"}
                        label={translate('pages.billing.dunningTax')}
                        value={tax}
                        onChange={(value) => setTax(value)}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomDatePicker
                        label={'Datum der Mahnung'}
                        value={new Date(date)}
                        onChange={(value) => {
                            const dueDate = moment(value).add(7, 'days').format("Y-MM-DD");

                            setDate(moment(value).format("Y-MM-DD"));
                            setDueDate(dueDate);
                        }}
                    />
                    <CustomDatePicker
                        label={'Fälligkeitsdatum der Mahnung'}
                        value={new Date(dueDate)}
                        onChange={(value) =>
                            setDueDate(moment(value).format("Y-MM-DD"))
                        }
                    />
                </PaperRow>
                <PaperRow>
                    <div className={"flex flexCentered"}>
                        <div className={"flex flexCentered"}>
                            &nbsp;
                        </div>
                        <div style={{width: 220, fontWeight: "bold"}}>
                            Offener Betrag: <br/>
                            + Zinsen ({tax} %) <br/>
                            + Gebühr <br/>
                            = Summe der Mahnung
                        </div>
                        <div style={{width: 70, textAlign: "right", paddingRight: 25}}>
                            {openSum.toLocaleString("de-DE")} € <br/>
                            {(openSum/100*tax).toLocaleString("de-DE")} € <br/>
                            {fee.toLocaleString("de-DE")} € <br/>
                            {(openSum + (openSum/100*tax) + fee).toLocaleString("de-DE")} €
                        </div>
                    </div>
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: () => {
                        window.open(`${API_URL}/dunning-letter/${props.editDunningLetter?.id}/print?Authorization=${token}`);
                    },
                    hidden: props.editDunningLetter === null,
                    label:translate('misc.print')
                }, {
                    action: onSave,
                    label:translate('misc.buttons.saveAndClose')
                }]}
            />
        </Dialog>
    )
}

export default DunningLetterAddEditView;
