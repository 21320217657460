import React, { useEffect, useState } from 'react';
import { EIcons } from '../../../components/Icons/IconFactory';
import { useDispatch, useSelector } from 'react-redux';
import CustomDashboardWidgetPaper from '../../../components/CustomPaper/CustomDashboardWidgetPaper';
import { IEmployeePerformance } from '../../../definitions/entities.definition';
import { ClientApi } from '../../../requests/ClientApi';
import { getEmployeePerformanceRouteConfig } from '../../../requests/routes';
import { getToken } from '../../../selectors/app.selectors';
import { ArgumentScale, BarSeries, EventTracker, Stack } from '@devexpress/dx-react-chart';
import { ArgumentAxis, Chart, Tooltip, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { COLOR_BLUE, COLOR_GREEN_DARK, COLOR_ORANGE, COLOR_RED } from '../../../theme/theme';
import { scaleBand } from '@devexpress/dx-chart-core';

interface IProps {
    year: number;
    month?: number;
}

const EmployeePerformanceChart: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const year = props.year;
    const month = props.month;

    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [entries, setEntries] = useState<IEmployeePerformance[]>([]);
    const token = useSelector(getToken);

    const load = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        ClientApi.request(getEmployeePerformanceRouteConfig, {
            token,
            orderBy: 'countContract',
            orderDirection: 'DESC',
            month,
            year
        }).then((result: IEmployeePerformance[]) => {
            setReload(false);
            setLoading(false);
            setEntries(result);
        }).catch(() => {
            setReload(false);
            setLoading(false);
        });
    }

    useEffect(() => {
        load();
    }, [dispatch, year, month]);

    useEffect(() => {
        if (!reload) {
            return;
        }
        load();
    }, [reload]);

    return <>
        <CustomDashboardWidgetPaper icon={EIcons.BarChart} headline={'Mitarbeiter Performance'}>
            <CustomBackdropLoadingInsideDiv open={loading} />
            <Chart data={entries} rotated height={380} >
                <ArgumentScale factory={scaleBand} />
                <ArgumentAxis />
                <ValueAxis />

                <BarSeries valueField={"countContract"} name={"contract"} argumentField={"fullName"} color={COLOR_BLUE} />
                <BarSeries valueField={"countSuggested"} name={"suggested"} argumentField={"fullName"} color={COLOR_ORANGE} />
                <BarSeries valueField={"countInterviews"} name={"interviews"} argumentField={"fullName"} color={COLOR_GREEN_DARK} />
                <BarSeries valueField={"countDeclined"} name={"declined"} argumentField={"fullName"} color={COLOR_RED} />

                <Stack />
                <EventTracker/>
                <Tooltip contentComponent={(contentProps) => {
                    const data = entries[contentProps.targetItem.point];
                    return (<>
                        <ul>
                            <li style={{color: COLOR_ORANGE}}>{data.countSuggested} Vorschläge</li>
                            <li style={{color: COLOR_GREEN_DARK}}>{data.countInterviews} Interviews</li>
                            <li style={{color: COLOR_RED}}>{data.countDeclined} Absagen</li>
                            <li style={{color: COLOR_BLUE}}>{data.countContract} Vermittlungen</li>
                        </ul>
                    </>)
                }}/>
            </Chart>
        </CustomDashboardWidgetPaper>
    </>;
}

export default EmployeePerformanceChart;
