import {
    debounce,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    getMailboxListAction,
    getMailListByChannelAction,
    getMailListByFolderAction,
    getMailListBySearchAction,
} from '../../../redux/entities/entities.actions';
import { IAppBarConfig } from '../../../definitions/ui.definitions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import MailList from './Mailing/MailList';
import { useDrawerStyles } from './Drawer.styles';
import MailboxView from './Mailing/MailboxView';
import {
    setMailingChannelAction,
    setMailingFilterAction,
    setMailingSearchOpenAction,
} from '../../../redux/mailing/mailing.actions';
import CloseMailDrawerElement from './Mailing/CloseMailDrawerElement';
import { EEntities } from '../../../definitions/entities.definition';
import CustomFastSearchPanel from '../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { COLOR_GREEN } from '../../../theme/theme';

const RightDrawerMailView = () => {
    const classes = useDrawerStyles();
    const dispatch = useDispatch();
    const drawerOpen = useSelector((store: IStore) => store.ui.rightDrawerMailOpen);
    const token = useSelector((store: IStore) => store.actor.token);
    const loading = useSelector((state: IStore) => state.ui.mailsLoading);
    const filter = useSelector((state: IStore) => state.mailing.filter);
    const paging = useSelector((state: IStore) => state.mailing.paging);
    const selectedMailboxId = useSelector((state: IStore) => state.mailing.selectedMailboxId);
    const searchActive = useSelector((state: IStore) => state.mailing.search);
    const searchAllMailboxes = useSelector((state: IStore) => state.mailing.filter.searchAllMailboxes);
    const [fastSearch, setFastSearch] = useState('');

    const loadMails = (syncFolder:boolean) => {
        console.log("JO");
        if (filter.folderId && selectedMailboxId) {
            dispatch(getMailListByFolderAction(token, filter.folderId, selectedMailboxId, paging.start, paging.limit, syncFolder));
            return;
        }
        if (filter.channel) {
            dispatch(getMailListByChannelAction(token, filter.channel));
            return;
        }
        console.log(searchActive);
        console.log(filter.fastSearch);
        if (searchActive && filter.fastSearch) {
            dispatch(getMailListBySearchAction(token, filter.fastSearch, filter.searchAllMailboxes || false, paging.start, paging.limit));
            return;
        }
    }

    useEffect(() => {
        dispatch(getMailboxListAction(token));
    }, [dispatch])

    useEffect(() => {
        if (drawerOpen && !loading) {
            loadMails(false);
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (drawerOpen && !loading) {
            loadMails(false);
        }
    }, [JSON.stringify(filter)])

    useEffect(() => {
        if (drawerOpen && !loading) {
            loadMails(false);
        }
    }, [JSON.stringify(paging)])

    const changeHandler = (value: string) => {
        dispatch(setMailingFilterAction({fastSearch: value}));
    };
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );

    const configuration: IAppBarConfig = {
        entity: EEntities.MailboxMail,
        value: filter.fastSearch ?? '',
        emptyValue: "Betreff und Inhalt durchsuchen",
        leftButtons: [{
            action: () => dispatch(setMailingFilterAction({searchAllMailboxes: !searchAllMailboxes})),
            tooltip: 'Alle Postfächer durchsuchen',
            iconStyle: searchAllMailboxes ? {color: COLOR_GREEN} : undefined,
            icon: EIcons.Group
        }],
        fastSearchCallback: (value: string) => {
            setFastSearch(value);
            debouncedChangeHandler(value);
        },
    };

    const renderContent = () => {
        return <>
            <div style={{display: "flex", flexDirection: "column", borderRight: '1px solid #444', width: 200}}>
                <MailboxView/>
                <List dense subheader={<ListSubheader style={{backgroundColor:'var(--brainz-darkest)'}}>Kanäle</ListSubheader>}>
                    <ListItem
                        button
                        selected={filter.channel === 'debug'}
                        onClick={() => dispatch(setMailingChannelAction('debug'))}
                    >
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.BugReport)}</ListItemIcon>
                        <ListItemText primary={"Debug"} />
                    </ListItem>
                </List>
                <List dense subheader={<ListSubheader style={{backgroundColor:'var(--brainz-darkest)'}}>Suche</ListSubheader>}>
                    <ListItem
                        button
                        selected={searchActive}
                        onClick={() => dispatch(setMailingSearchOpenAction(true))}
                    >
                        <ListItemIcon>{IconFactory.getIconComponentUnstyled(EIcons.Search)}</ListItemIcon>
                        <ListItemText primary={"Schnellsuche"} />
                    </ListItem>
                </List>
            </div>
            <div className={"flexColumn"} style={{width: 800, flex: 1}}>
                {!searchActive ?
                    <div className={classes.toolbar}>
                        <div className={"flex alignVerticalCenter"}>
                            <ListSubheader className={"alignVerticalCenter"} disableGutters>
                                E-Mails
                                <Tooltip title={"E-Mails synchronisieren"}>
                                    <IconButton onClick={() => loadMails(true)}>
                                        {IconFactory.getIconComponent(EIcons.CloudDownload)}
                                    </IconButton>
                                </Tooltip>
                            </ListSubheader>
                        </div>
                        <CloseMailDrawerElement/>
                    </div> :
                    <div className={classes.toolbar}>
                        <div className={"flex alignVerticalCenter"}>
                            <ListSubheader className={"alignVerticalCenter"} disableGutters>
                                Suchergebnisse
                            </ListSubheader>
                        </div>
                        <CloseMailDrawerElement/>
                    </div>
                }
                {searchActive &&
                    <div className={"padding5 flexAutoGrow"}>
                        <CustomFastSearchPanel appBarConfig={configuration}/>
                    </div>
                }
                {(!searchActive || filter.fastSearch) &&
                    <MailList/>
                }
            </div>
        </>
    }

    return (
        <>
            <Drawer
                anchor={"right"}
                open={drawerOpen}
                variant={'persistent'}
                classes={{paper: classes.drawerOpen + ' ' + classes.defaultWidth + ' ' + classes.boxShadow}}
            >
                {drawerOpen &&
                    <div className={"flexContainerRow flex1"} style={{overflow: 'hidden'}}>
                        {renderContent()}
                    </div>
                }
            </Drawer>
        </>
    )
}

export default RightDrawerMailView;
