import { IApiBaseFilter, ISorting } from './app.definitions';
import { ESortingOptions } from './components.definitions';
import {
    ECandidateSource,
    ERecruitingStatus,
    ESuggestionDeclineReason,
    ESuggestionStatus,
    IAlertPossibleResponsibleChange,
    IEmployee,
} from './entities.definition';

export enum ERecruitingPageTabs {
    tabVacancies,
    tabRecruitingsWithStatusSuggest,
    tabRecruitingsWithStatusInterview,
    tabRecruitingsWithStatusContract,
    tabRecruitingsWithStatusAbort
}

export enum EVacancyEditViewTabs {
    tabStatistic,
    tabData,
    tabSearchCandidate,
    tabActivities
}

export interface IStartRecruitingViewState {
    open: boolean;
    candidateId?: number;
    vacancyId?: number;
    webVacancyId?: number;
    applicationId?: number;
    companyId?: number;
    contactId?: number;
    responsibleEmployeeId?: number;
    suggestedByEmployeeId?: number;
}

export interface IAddSuggestionViewState {
    open: boolean;
    candidateId?: number;
    vacancyId?: number;
    applicationId?: number;
}

export interface IRecruitingPageState {
    alertChangeRecruitingResponsible?: IAlertPossibleResponsibleChange;
    reloadRecruiting: boolean;
    startRecruitingView: IStartRecruitingViewState;
    contractEditId: number;
    addSuggestionView: IAddSuggestionViewState;
    activeTab: ERecruitingPageTabs;
    recruitingToDelete: number;
    createCongratulationMailForRecruiting: number;
    activeTabVacancyEditView?: EVacancyEditViewTabs,
    reloadSuggestions: boolean;
    suggestionsOfVacancyInitialLoaded: boolean;
    recruitingsOfVacancyInitialLoaded: boolean;
    applicationsOfVacancyInitialLoaded: boolean;
    declineRecruitingId: number;
    contractRecruitingId: number;
    reloadSuggestedRecruitings: boolean;
    reloadInterviewRecruitings: boolean;
    reloadAllRecruitings: boolean;
    reloadVacancyList: boolean;
    editViewLoading: boolean;
    recruitingsSuggestInitialLoaded: boolean;
    recruitingsInterviewInitialLoaded: boolean;
    recruitingsContractInitialLoaded: boolean;
    recruitingsAbortInitialLoaded: boolean;
    sortingTabRecruitingsAbort: ISorting;
    filterTabRecruitingsAbort: IApiBaseFilter;
    sortingTabRecruitingsSuggest: ISorting;
    filterTabRecruitingsSuggest: IApiBaseFilter;
    sortingTabRecruitingsInterview: ISorting;
    filterTabRecruitingsInterview: IApiBaseFilter;
    sortingTabRecruitingsContract: ISorting;
    filterTabRecruitingsContract: IApiBaseFilter;
    filterRecruitingsGlobal: IRecruitingFilter;
    filterTabSuggestion: ISuggestionFilter;
    suggestionSorting: ISorting,
    activitySorting: ISorting;
    activityToDelete: number;
    suggestionToDecline: number;
    currentDocumentId: number;
    documentSorting: ISorting;
    documentToDelete: number;
    documentToPreview: number;
    activityFilter: IApiBaseFilter;
    recruitingListViewSorting: ISorting;
    recruitingListViewOpen: boolean;
    recruitingListViewFilter: IRecruitingFilter;
    recruitingListViewFilterMenu: IRecruitingFilterMenu;
}

export const defaultRecruitingFilter: IApiBaseFilter = {
    start: 0,
    limit: 150
}

export const preloadedStateRecruitingPage: IRecruitingPageState = {
    startRecruitingView: {
        open: false,
    },
    contractEditId: 0,
    addSuggestionView:  {
        open: false
    },
    recruitingsAbortInitialLoaded: false,
    recruitingsSuggestInitialLoaded: false,
    recruitingsContractInitialLoaded: false,
    recruitingsInterviewInitialLoaded: false,
    declineRecruitingId: 0,
    contractRecruitingId: 0,
    applicationsOfVacancyInitialLoaded: false,
    createCongratulationMailForRecruiting: 0,
    activeTab: ERecruitingPageTabs.tabVacancies,
    recruitingToDelete: 0,
    reloadAllRecruitings: false,
    reloadSuggestedRecruitings: false,
    reloadInterviewRecruitings: false,
    recruitingsOfVacancyInitialLoaded: false,
    suggestionsOfVacancyInitialLoaded: false,
    reloadSuggestions: false,
    reloadVacancyList: false,
    editViewLoading: false,
    reloadRecruiting: false,
    sortingTabRecruitingsAbort: {
        'dateOfAbort': ESortingOptions.Descending
    },
    filterTabRecruitingsAbort: defaultRecruitingFilter,
    sortingTabRecruitingsSuggest: {
        'created': ESortingOptions.Descending
    },
    filterTabRecruitingsSuggest: defaultRecruitingFilter,
    sortingTabRecruitingsInterview: {
        'nextAppointment': ESortingOptions.Descending
    },
    filterTabRecruitingsInterview: defaultRecruitingFilter,
    sortingTabRecruitingsContract: {
        'contract.contractBeginningDate': ESortingOptions.Descending
    },
    filterTabRecruitingsContract: defaultRecruitingFilter,
    filterRecruitingsGlobal: {},
    filterTabSuggestion: defaultRecruitingFilter,
    suggestionSorting: {},
    activitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    activityFilter: {
        start: 0,
        limit: 50
    },
    activityToDelete: 0,
    suggestionToDecline: 0,
    currentDocumentId: 0,
    documentSorting: {},
    documentToDelete: 0,
    documentToPreview: 0,
    recruitingListViewOpen: false,
    recruitingListViewFilter: {
        start: 0,
        limit: 50
    },
    recruitingListViewFilterMenu: {},
    recruitingListViewSorting: {
        created: ESortingOptions.Ascending
    }
};

export interface IRecruitingFilter extends Partial<IApiBaseFilter> {
    vacancyId?: number;
    employeeId?: number;
    companyId?: number;
    suggestedByEmployeeId?: number;
    status?: ERecruitingStatus;
    dateFrom?: string;
    dateTo?: string;
    possibleCommissionFrom?: number;
    possibleCommissionTo?: number;
}

export interface IRecruitingFilterMenu extends Omit<IRecruitingFilter, 'employeeId'|'suggestedByEmployeeId'> {
    employee?: IEmployee;
    suggestedByEmployee?: IEmployee
}

export interface ISuggestionFilter extends Partial<IApiBaseFilter> {
    vacancyId?: number;
    vacancyResponsibleEmployeeId?: number;
    responsibleEmployeeId?: number;
    suggestedByEmployeeId?: number;
    source?: ECandidateSource;
    declineReason?: ESuggestionDeclineReason;
    status?: ESuggestionStatus;
    companyLocationId?: number;
    contact?: boolean;
    groupByProgressStatus?: boolean;
    actionNeeded?: boolean;
    dateRange?: 'last7days'|'last14days'|'last30days';
}

export interface ISuggestionFilterMenu {
    vacancyResponsibleEmployee?: IEmployee;
    responsibleEmployee?: IEmployee;
    suggestedByEmployeeId?: IEmployee;
    source?: ECandidateSource;
    declineReason?: ESuggestionDeclineReason;
    status?: ESuggestionStatus;
    dateRange?: 'last7days'|'last14days'|'last30days';
}

