import React, { useEffect, useState } from 'react';
import { IInvoiceItem } from '../../../../definitions/entities.definition';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../../components/CustomDialog/CustomDialogBody';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../../translation/translate.utils';
import { Dialog } from '@material-ui/core';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import {
    setBillingPageAddInvoiceItemAction,
    setBillingPageUpdateInvoiceItemAction,
} from '../../../../redux/billingPage/billingPage.actions';
import { useDispatch } from 'react-redux';

interface IProps {
    addItem: boolean;
    editItemIndex?: number;
    editItem?: IInvoiceItem;
    onClose: () => void;
}

const InvoiceItemAddEditView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    const [amount, setAmount] = useState(props.editItem?.amount);
    const [price, setPrice] = useState(props.editItem?.price);
    const [title, setTitle] = useState(props.editItem?.title);
    const [comment, setComment] = useState(props.editItem?.comment);

    useEffect(() => {
        setAmount(props.editItem?.amount);
        setPrice(props.editItem?.price);
        setTitle(props.editItem?.title);
        setComment(props.editItem?.comment);
    }, [props.editItem]);

    interface IValidationErrors {
        amount?: string;
        price?: string;
        title?: string;
    }

    const validationErrors: IValidationErrors = {
        amount: undefined,
        price: undefined,
        title: undefined
    };

    const onSave = () => {
        if (!amount) {
            validationErrors.amount = 'This value should not be blank';
            return;
        }
        if (!price ) {
            validationErrors.price = 'This value should not be blank';
            return;
        }
        if (!title) {
            validationErrors.title = 'This value should not be blank';
            return;
        }
        if (!amount || !price || !title) {
            return;
        }

        if (props.editItemIndex !== undefined && props.editItem) {
            dispatch(setBillingPageUpdateInvoiceItemAction(
                props.editItemIndex, {
                    id: props.editItem.id,
                    ranking: props.editItem.ranking,
                    amount,
                    price,
                    title,
                    comment,
                    priceTotal: price * amount
                }
            ));
            props.onClose();
            return;
        }

        dispatch(setBillingPageAddInvoiceItemAction({
            amount,
            price,
            title,
            comment,
            priceTotal: price * amount
        }));
        props.onClose();
    }

    if (!props.addItem && !props.editItem) {
        return null;
    }

    return (
        <Dialog fullWidth maxWidth={"md"} open={props.addItem} onClose={props.onClose}>
            <CustomDialogHeader string={props.editItem ? "Position bearbeiten" : "Position hinzufügen"} onClose={props.onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <CustomTextField
                        type={"number"}
                        label={translate('pages.billing.amount')}
                        value={amount}
                        onChange={(value) => setAmount(value)}
                        error={Boolean(validationErrors.amount)}
                        helperText={validationErrors.amount}
                    />
                    <CustomTextField
                        type={"number"}
                        label={translate('pages.billing.price')}
                        value={price}
                        onChange={(value) => setPrice(value)}
                        error={Boolean(validationErrors.price)}
                        helperText={validationErrors.price}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={"Bezeichnung"}
                        value={title}
                        onChange={(value) => setTitle(value)}
                        error={Boolean(validationErrors.title)}
                        helperText={validationErrors.title}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('misc.comment')}
                        multiline
                        rows={3}
                        value={comment}
                        onChange={(value) => setComment(value)}
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: onSave,
                    label:translate('misc.buttons.saveAndClose')
                }]}
            />
        </Dialog>
    )
}

export default InvoiceItemAddEditView;
