import React, { useEffect, useState } from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { translate } from '../../../translation/translate.utils';
import {
    ECandidateSource,
    ICandidate,
    IEmployeePartial,
    ISuggestionForApi,
    ISuggestionGroup, IVacancyPartial,
} from '../../../definitions/entities.definition';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { addSuggestionAction, getApplicationAction } from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import EmployeeAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import CandidateAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';
import CandidateSourceDropDown from '../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import VacancyAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import { ClientApi } from '../../../requests/ClientApi';
import { getCandidateRouteConfig } from '../../../requests/routes';
import { getDayDifference } from '../../../utils/date.utils';
import CustomUrlField from '../../../components/CustomInput/CustomUrlField';
import SuggestionGroupAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/SuggestionGroupAutocomplete';
import CompanyLocationDropDown from '../../../components/CustomInput/CustomDropDownInput/CompanyLocationDropDown';

interface IProps {
    onClose: () => void;
}

const AddSuggestionForCandidateForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const initialCandidateId = useSelector((store: IStore) => store.recruitingPage.addSuggestionView.candidateId);
    const initialVacancyId = useSelector((store: IStore) => store.recruitingPage.addSuggestionView.vacancyId);
    const applicationId = useSelector((store: IStore) => store.recruitingPage.addSuggestionView.applicationId);
    const errors = useSelector(getValidationErrorsFromStore);

    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial|null>(loggedInEmployee as IEmployeePartial);
    const [externalCandidateReference, setExternalCandidateReference] = useState<string>("");
    const [candidateId, setCandidateId] = useState(initialCandidateId || undefined);
    const [vacancyId, setVacancyId] = useState(initialVacancyId || undefined);
    const [vacancy, setVacancy] = useState<IVacancyPartial>(null);
    const [suggestionGroup, setSuggestionGroup] = useState<ISuggestionGroup|null>(null);
    const [source, setSource] = useState(ECandidateSource.brainz);
    const [companyLocationId, setCompanyLocationId] = useState<number|null>(null);

    useEffect(() => {
        if (!candidateId) {
            return;
        }
        ClientApi.request(getCandidateRouteConfig, {
            token,
            candidateId
        }).then((candidate: ICandidate) => {
            if (getDayDifference(candidate.created) <= 30) {
                setSource(candidate.source);
                return;
            }
            setSource(ECandidateSource.brainz);
        });
    }, [candidateId]);


    const onSave = () => {
        const suggestion: Partial<ISuggestionForApi> = {
            suggestedByEmployeeId: loggedInEmployee.id,
            candidateId: candidateId,
            vacancyId: vacancyId,
            responsibleEmployeeId: responsibleEmployee?.id,
            companyLocationId: companyLocationId || undefined,
            externalCandidateReference,
            source,
            groupId: suggestionGroup?.id,
            applicationId: applicationId
        };
        dispatch(addSuggestionAction(
            token,
            suggestion
        )).then(() => {
            if (applicationId) {
                dispatch(getApplicationAction(token, applicationId));
            }
        });
    }

    return (
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
            <PaperRow>
                <VacancyAutocomplete
                    value={vacancyId}
                    required
                    error={Boolean(errors.vacancy)}
                    helperText={errors.vacancyId}
                    onChange={(value) => {
                        if (value === null) {
                            setVacancyId(null);
                            setVacancy(null);
                            return;
                        }
                        setVacancyId(value.id);
                        setVacancy(value);
                        setCompanyLocationId(value.companyLocations[0].id);
                    }}
                />
            </PaperRow>
            <PaperRow>
                <SuggestionGroupAutocomplete
                    value={suggestionGroup}
                    required
                    error={Boolean(errors.suggestionGroupId)}
                    helperText={errors.suggestionGroupId}
                    onChange={(value) => {
                        setSuggestionGroup(value);
                    }}
                    disabled={!vacancyId}
                    vacancyId={vacancyId}
                />
                {(vacancy && vacancy.companyLocations.length > 1) &&
                    <CompanyLocationDropDown
                        onChange={(event) => setCompanyLocationId(event.target.value)}
                        value={companyLocationId}
                        values={vacancy.companyLocations}
                        required
                        error={Boolean(errors.companyLocationId)}
                        helperText={errors.companyLocationId}
                    />
                }
            </PaperRow>
            <PaperRow>
                <CandidateAutocomplete
                    value={candidateId || null}
                    required
                    error={Boolean(errors.candidateId)}
                    helperText={errors.candidateId}
                    onChange={(candidate) => {
                        if (candidate) {
                            setCandidateId(candidate.id);
                            return;
                        }
                        setCandidateId(0);
                    }}
                />
                <CandidateSourceDropDown
                    value={source}
                    onChange={(event) => setSource(event.target.value)}
                />
            </PaperRow>
            {source === ECandidateSource.jobcenter &&
                <PaperRow>
                    <CustomUrlField
                        flex={2}
                        required
                        url={externalCandidateReference}
                        label={translate('pages.candidate.properties.externalReference')}
                        value={externalCandidateReference}
                        onChange={(value) => setExternalCandidateReference(value)}
                        error={Boolean(errors.externalCandidateReference)}
                        helperText={errors.externalCandidateReference}
                    />
                </PaperRow>
            }
            <PaperRow>
                <EmployeeAutocomplete
                    required
                    label={translate('misc.responsibleEmployee')}
                    value={responsibleEmployee}
                    onChange={(value) => setResponsibleEmployee(
                        value
                    )}
                />
            </PaperRow>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    action: onSave,
                    label: translate('misc.buttons.save')
                }]}
            />
        </div>
    );
};

export default AddSuggestionForCandidateForm;
