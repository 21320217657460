import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { EIcons } from '../../../Icons/IconFactory';
import CustomDialogActions from '../../../CustomInput/CustomDialogActions';
import { useDispatch } from 'react-redux';
import { registerInfo } from '../../../../redux/error/error.actions';
import CustomFilterItemIcon from './CustomFilterItemIcon';
import CustomFilterItemRemoveIcon from './CustomFilterItemRemoveIcon';
import CustomClickAwayPopper from '../../../CustomPaper/CustomClickAwayPopper';
import { addOnKeydownEventHandler } from '../../../../utils/components.utils';

interface IProps extends PropsWithChildren<any> {
    icon: EIcons;
    title: string;
    renderedValue?: ReactNode;
    active: boolean;
    removeFilterAction: () => void;
    disabled?: boolean;
    disabledHint?: string;
    width?: string|number;
    autoClose?: boolean;

    //for complexer filters with more than one field
    showDialog?: boolean;
    submit?: () => void;
    dataConsistent?: boolean;
    dataValid?: boolean;
}

const CustomFilterItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement|null>(null);
    const active = props.active;
    const disabled = props.disabled;
    const title = props.title;
    const renderedValue = props.renderedValue;
    const removeFilterAction = props.removeFilterAction;
    const children = props.children;
    const showDialog = props.showDialog;
    const dataConsistent = props.dataConsistent;
    const dataValid = props.dataValid;
    const width = props.width;
    const icon = props.icon;

    const submit = () => {
        if (props.submit) {
            props.submit();
            setAnchorEl(null);
            return;
        }

        alert("submit action not defined");
    }

    const handleOpenFilter = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
        if (dataConsistent === false) {
            dispatch(registerInfo('Sie haben den Filter nicht gespeichert'));
        }
    };

    addOnKeydownEventHandler("Escape",(e) => {
        const noInputOpen = document.getElementsByClassName('MuiAutocomplete-popper').length === 0;
        if (e.target && (e.target.id === 'editableValue' || e.target.closest('#editableValue') !== null)) {
            return;
        }
        if (anchorEl && noInputOpen) {
            handleCloseFilter();
        }
    });

    const filterActive = Boolean(anchorEl);

    useEffect(() => {
        if (props.autoClose) {
            handleCloseFilter();
        }
    }, [renderedValue]);

    return <>
        <ListItem
            button
            selected={filterActive}
            disabled={disabled}
            onClick={handleOpenFilter}
        >
            <CustomFilterItemIcon icon={icon} active={active}/>
            <ListItemText primary={title} secondary={renderedValue}/>
            <CustomFilterItemRemoveIcon removeFilterAction={removeFilterAction} active={active} />
        </ListItem>
        <CustomClickAwayPopper
            anchorEl={anchorEl}
            removeAnchor={handleCloseFilter}
            width={width}
        >
            {children}
            {showDialog &&
                <CustomDialogActions
                    onClose={handleCloseFilter}
                    buttons={[{
                        disabled: dataConsistent || dataValid === false,
                        action: submit,
                        label: "Filter Speichern"
                    }]}
                />
            }
        </CustomClickAwayPopper>
    </>;
};

export default React.memo(CustomFilterItem);
