import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { EEntities, IVacancyCount } from '../../../definitions/entities.definition';
import { getAllArchivedVacanciesAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import {
    getArchivedVacancyListModels,
    getVacancyPageArchivedListRequestObject,
} from '../../../selectors/vacancy.selectors';
import { setVacancyPageFilterAction } from '../../../redux/vacancyPage/vacancyPage.actions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { translate } from '../../../translation/translate.utils';
import {
    getDefaultCompanyColumn,
    getDefaultCreatedColumn,
    getDefaultEmployeeColumn,
    INFINITE_SCROLL_INTERVAL,
} from '../../../utils/components.utils';
import { ESortingOptions } from '../../../definitions/components.definitions';
import autobind from 'autobind-decorator';
import YesNoCellRenderer from '../../../components/CustomCellRenderer/YesNoCellRenderer';
import { setArchivePageSortingOptionAction } from '../../../redux/archivePage/archivePage.actions';
import { dark } from '../../../theme/theme';
import { EEntityView } from '../../../definitions/ui.definitions';
import { setUiEditView } from '../../../redux/ui/ui.actions';

class VacancyArchivedList extends React.PureComponent<PropsFromRedux> {
    constructor(props: PropsFromRedux) {
        super(props);
    }

    componentDidMount = () => {
        if (!this.props.initialVacanciesLoaded) {
            this.props.getAllArchivedVacanciesAction(this.props.token, this.props.requestObject);
        }
    };

    componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
        const needToFetch =
            JSON.stringify(this.props.sorting) !== JSON.stringify(prevProps.sorting) ||
            JSON.stringify(this.props.filter) !== JSON.stringify(prevProps.filter);

        if (needToFetch) {
            this.props.getAllArchivedVacanciesAction(this.props.token, this.props.requestObject);
        }
    }

    @autobind
    editAction(vacancyId: number, index: number) {
        const vacancy = this.props.sortedEntries[index];
        this.props.setUiEditView(EEntityView.vacancy, vacancyId);
    }

    render() {
        return (
            <>
                <CustomTable
                    config={{
                        color: dark,
                        entity: EEntities.VacancyArchive,
                        onRowDoubleClick: (id, entity, index) => this.editAction(id, index),
                        columnConfig: {
                            company: getDefaultCompanyColumn(),
                            countSuggestionsWithStatusOpen: {
                                header: translate('pages.vacancy.favorites'),
                                property: "countSuggestionsWithStatusOpen",
                                width: 90,
                            },
                            countProcesses: {
                                header: translate('pages.vacancy.processes'),
                                property: "countProcesses",
                                width: 90,
                            },
                            countRecruitingsWithStatusContract: {
                                header: translate('pages.vacancy.hasBeenContracted'),
                                property: "counts.overall",
                                width: 80,
                                cellRenderer: (counts: IVacancyCount) => <YesNoCellRenderer value={Boolean(counts.countRecruitingsWithStatusContract)} />
                            },
                            title: {
                                header: translate('pages.vacancy.properties.title'),
                                property: "title",
                            },
                            created: getDefaultCreatedColumn(),
                            responsibleEmployee: getDefaultEmployeeColumn(),
                        },
                        sortedEntries: this.props.sortedEntries,
                        loading: this.props.loading,
                        scrollCallback: () => {
                            if (this.props.filter.limit && this.props.filter.limit < this.props.total) {
                                this.props.setFilterAction({
                                    start: 0,
                                    limit: this.props.filter.limit + INFINITE_SCROLL_INTERVAL,
                                });
                            }
                        },
                        tableHeaderCallbacks: {
                            setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                                this.props.setSortingOptionAction(
                                    propertyToSort,
                                    sortOption
                                )
                        },
                        count: this.props.count,
                        total: this.props.total,
                        onReload: () => {
                            this.props.getAllArchivedVacanciesAction(this.props.token, this.props.requestObject);
                        }
                    }}
                />
            </>
        );
    }
}

const mapState = (store: IStore) => ({
    count: store.entities.vacancies.orderArchived.length,
    total: store.entities.vacancies.totalArchived,
    token: getToken(store),
    loading: store.ui.archivedVacanciesLoading,
    sortedEntries: getArchivedVacancyListModels(store),
    filter: store.archivePage.filterVacancyList,
    sorting: store.archivePage.sortingVacancyList,
    requestObject: getVacancyPageArchivedListRequestObject(store),
    initialVacanciesLoaded: store.archivePage.vacanciesLoaded,
});

const mapDispatch = {
    setSortingOptionAction: setArchivePageSortingOptionAction,
    setUiEditView,
    setFilterAction: setVacancyPageFilterAction,
    getAllArchivedVacanciesAction,
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VacancyArchivedList);

