import React, { useState } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { useSelector } from 'react-redux';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import { translate } from '../../translation/translate.utils';
import PaperRow from '../../components/CustomPaper/PaperRow';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import { EInterviewType, ERecruitingStatus, ICompanyContactPartial } from '../../definitions/entities.definition';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import CustomBackdropLoadingInsideDiv from '../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { ClientApi } from '../../requests/ClientApi';
import { putAcceptRecruitingRouteConfig } from '../../requests/routes.customer';
import InterviewTypeDropDown from '../../components/CustomInput/CustomDropDownInput/InterviewTypeDropDown';
import CompanyContactAutocomplete
    from '../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';
import CustomRadioGroupInput from '../../components/CustomInput/CustomRadioGroupInput';
import moment from 'moment';
import CustomDatePicker from '../../components/CustomInput/CustomDatePicker';
import CustomNumberInput from '../../components/CustomInput/CustomNumberInput';
import AddContactView from './AddContactView';

interface IProps {
    recruitingId: number;
    status: ERecruitingStatus;
    desiredYearSalary: number;
    open: boolean;
    onClose: () => void;
    callback: () => void;
}

const AcceptRecruitingView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const open = props.open;
    const recruitingId = props.recruitingId;

    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [type, setType] = useState<'interview'|'contract'>('interview');
    const [followingInterviewType, setFollowingInterviewType] = useState<EInterviewType|null>(null);
    const [contactPerson, setContactPerson] = useState<ICompanyContactPartial|null>(null);
    const [validationErrors, setValidationErrors] = useState<{[field: string]: string}>({});
    const [offeredContractBeginningDate, setOfferedContractBeginningDate] = useState<Date|null>(null);
    const [offeredContractSalary, setOfferedContractSalary] = useState<number>(props.desiredYearSalary);
    const [addCompanyContact, setAddCompanyContact] = useState(false);

    const onClose = () => {
        props.onClose();
    };

    const save = () => {
        setLoading(true);
        ClientApi.request(putAcceptRecruitingRouteConfig, {
            token,
            recruitingId,
            followingInterviewType,
            comment,
            contactPersonId:  contactPerson?.id,
            contractOffered: type === 'contract',
            offeredContractBeginningDate: (offeredContractBeginningDate) ? moment(offeredContractBeginningDate).format("Y-MM-DD") : '',
            offeredContractSalary
        }).then((result) => {
            setLoading(false);
            props.callback();
            onClose();
        }).catch((result) => {
            if (result && result.includes("validationErrors: ")) {
                setValidationErrors(JSON.parse(result.replace("validationErrors: ", "")));
            }
            setLoading(false);
        });
    }

    if (!open) {
        return null;
    }

    return <>
        <CustomDialog maxWidth={"sm"} open={open} onClose={onClose}>
            <AddContactView
                open={addCompanyContact}
                onClose={() => setAddCompanyContact(false)}
                callback={(contact) => setContactPerson(contact as ICompanyContactPartial)}
            />
            <CustomBackdropLoadingInsideDiv open={loading} />
            <CustomDialogHeader string={"Kandidat zusagen"} onClose={onClose} />
            <CustomDialogBody>
                {props.status === ERecruitingStatus.STATUS_INTERVIEW &&
                    <PaperRow>
                        <CustomRadioGroupInput
                            value={type}
                            //@ts-ignore
                            onChange={(type) => setType(type)}
                            states={[{
                                value: 'interview',
                                label: 'nächstes Interview planen'
                            }, {
                                value: 'contract',
                                label: 'Kandidat hat Angebot erhalten'
                            }]}
                        />
                    </PaperRow>
                }
                {type === 'interview' &&
                    <>
                        <PaperRow>
                            <InterviewTypeDropDown
                                value={followingInterviewType}
                                onChange={(event) => setFollowingInterviewType(event.target.value)}
                                label={"Art des nächsten Interviews"}
                                error={Boolean(validationErrors.followingInterviewType)}
                                helperText={validationErrors.followingInterviewType}
                            />
                        </PaperRow>
                        <PaperRow>
                            <CompanyContactAutocomplete
                                onChange={(value) => setContactPerson(value)}
                                value={contactPerson}
                                customerPortal
                                label={"nächstes Interview mit Ansprechpartner"}
                                error={Boolean(validationErrors.contactPersonId)}
                                helperText={validationErrors.contactPersonId}
                                onAdd={() => {setAddCompanyContact(true)}}
                            />
                        </PaperRow>
                    </>
                }
                {type === 'contract' &&
                    <PaperRow>
                        <CustomDatePicker
                            flex
                            label={'Geplanter Vertragsbeginn'}
                            value={offeredContractBeginningDate}
                            onChange={(value) => {
                                const endOfTrialPeriod = moment(value).add(6, 'months');

                                setOfferedContractBeginningDate(value || null);
                            }}
                        />
                        <CustomNumberInput
                            suffix={'k p.a.'}
                            label={'Gebotenes Jahresgehalt'}
                            value={offeredContractSalary}
                            onChange={(value) => setOfferedContractSalary(value)}
                        />
                    </PaperRow>
                }
                <PaperRow>
                    <CustomTextField
                        multiline
                        label={"aktuelles Feedback (optional)"}
                        value={comment}
                        onChange={(value) => setComment(value)}
                        rows={7}
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    action: () => save(),
                    label: translate('misc.buttons.save')
                }]}
            />
        </CustomDialog>
    </>;
};

export default AcceptRecruitingView;
