import React, { ReactNode, useState } from 'react';
import { ISuggestionGroup, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getSuggestionGroupListRouteConfig, getSuggestionGroupRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomLivesearch from '../CustomLivesearch';
import CustomClickableIcon from '../../CustomClickableIcon';
import { EIcons } from '../../../Icons/IconFactory';
import { InputAdornment } from '@material-ui/core';
import AddEditSuggestionGroupView from '../../../../Pages/RecruitingPage/AddEditSuggestionGroupView';
import { getVacancyAction } from '../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';

interface IProps {
    value: ISuggestionGroup|number|null;
    onChange: (value: ISuggestionGroup|null) => void;
    label?: string;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    vacancyId?: number;
}

const SuggestionGroupAutocomplete: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const value = props.value;
    const [reload, setReload] = useState(false);

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as ISuggestionGroup);
    }

    const getOptionLabel = (record: TBrainzEntity) => {
        const group = record as ISuggestionGroup;
        return group.ranking + '. ' + group.title;
    }

    const [add, setAdd] = useState(false);

    return <>
        <AddEditSuggestionGroupView
            add={add}
            vacancyId={props.vacancyId || 0}
            onClose={() => setAdd(false)}
            onAdd={(suggestionGroup) => {
                setReload(true);
                dispatch(getVacancyAction(token, props.vacancyId || 0, false));
                onChange(suggestionGroup);
            }}
        />
        <CustomLivesearch
            id={"suggestion-group-autocomplete"}
            label={props.label || translate('misc.group')}
            value={value}
            disabled={props.disabled}
            error={props.error}
            helperText={props.helperText}
            required={props.required}
            onChange={onChange}
            routeConfig={getSuggestionGroupListRouteConfig}
            entityRouteConfig={getSuggestionGroupRouteConfig}
            entityRouteParam={"groupId"}
            getOptionLabel={getOptionLabel}
            reload={{
                reload: reload,
                reloadFinishedCallback: () => {
                    setReload(false);
                }
            }}
            startAdornment={<InputAdornment position="start">
                <CustomClickableIcon
                    icon={EIcons.Add} onClick={() =>
                    setAdd(true)}
                />
            </InputAdornment>}
            loadCallback={(records) => {
                if (value) {
                    return;
                }

                let selectValue = null;
                const recordsCast = records as ISuggestionGroup[];
                recordsCast.forEach((group) => {
                    if (group.default) {
                        selectValue = group;
                    }
                });

                onChange(selectValue);
            }}
            loadAll
            loadBeforeOpen
            routeParams={{
                vacancyId: props.vacancyId
            }}
        />
    </>;
}

export default React.memo(SuggestionGroupAutocomplete);
