import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { setUiInterviewAddDataAction, setUiInterviewToEditAction } from '../../../redux/ui/ui.actions';
import { resetFormValidation } from '../../../redux/error/error.actions';
import InterviewForm from './components/InterviewForm';
import CustomCellTopBar from '../../../components/ListAndPreview/CustomCellTopBar';
import { getActivityAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';

const InterviewAddEditView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const addActivity = useSelector((state: IStore) => state.ui.addInterviewData.add);
    const editActivity = useSelector((state: IStore) => Boolean(state.ui.editInterviewId));
    const addActivityData = useSelector((state: IStore) => state.ui.addInterviewData);

    const editActivityId = useSelector((state: IStore) => state.ui.editInterviewId);
    const initialActivity = useSelector((state: IStore) => state.entities.activities.byId[state.ui.editInterviewId]);
    const recruiting = useSelector((store: IStore) => store.entities.recruiting.listModelById[addActivityData.recruitingId || (initialActivity && initialActivity.recruiting?.id)])

    useEffect(() => {
        if (editActivity && !initialActivity) {
            dispatch(getActivityAction(token, editActivityId));
        }
    }, [editActivity, initialActivity, editActivityId]);

    if (editActivity && !initialActivity) {
        return null;
    }

    const open = addActivity || editActivity;
    let title = 'pages.recruiting.addInterview';

    if (editActivity) {
        title = 'pages.recruiting.editInterview';
    }

    const onClose = () => {
        dispatch(setUiInterviewAddDataAction({add: false}));
        dispatch(setUiInterviewToEditAction(0));
        dispatch(resetFormValidation());
    };

    return (
        <CustomDialog maxWidth={"md"} onClose={onClose} open={open}>
            <CustomDialogHeader translationString={title} onClose={onClose}>
                <CustomCellTopBar
                    candidate={editActivity ? initialActivity.candidate : recruiting.candidate}
                    company={editActivity ? initialActivity.company : recruiting.company}
                    vacancy={editActivity ? initialActivity.vacancy : recruiting.vacancy}
                    recruiting={editActivity ? initialActivity.recruiting : recruiting}
                    recruitingSuggestion={editActivity ? initialActivity.recruitingSuggestion : undefined}
                />
            </CustomDialogHeader>
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <InterviewForm onClose={onClose} />
            </div>
        </CustomDialog>
    );
}
export default InterviewAddEditView;

