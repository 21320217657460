import { IApiBaseFilter, ISorting } from './app.definitions';
import { EActivityModule, IEmployee, TActivityType } from './entities.definition';
import { ESortingOptions } from './components.definitions';

export const preloadedStateActivityPage: IActivityPageState = {
    initialActivitiesLoaded: false,
    sorting: {
        dateTime: ESortingOptions.Descending,
    },
    filter: {
        fastSearch: '',
        start: 0,
        limit: 50,
    },
    filterMenu: {
        dateFrom: '',
        dateTo: '',
        employee: undefined,
    }
};

export interface IActivityPageState {
    initialActivitiesLoaded: boolean;
    sorting: ISorting;
    filter: IActivityFilter;
    filterMenu: IActivityFilterMenu;
}

export interface IActivityFilter extends Partial<IApiBaseFilter> {
    companyId?: number;
    candidateId?: number;
    vacancyId?: number;
    recruitingId?: number;
    module?: EActivityModule;
    timeRange?: 7|14|30;
    onlyWithTimes?: boolean;
    employeeId?: number;
    type?: TActivityType;
    dateFrom?: string;
    dateTo?: string;
    onlyWithContact?: boolean;
}

export interface IActivityFilterMenu {
    companyId?: number;
    candidateId?: number;
    vacancyId?: number;
    recruitingId?: number;
    module?: EActivityModule;
    timeRange?: 7|14|30;
    employee?: IEmployee;
    type?: TActivityType;
    dateFrom?: string;
    dateTo?: string;
}
