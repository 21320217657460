import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { addCompanyContactAction, updateCompanyContactAction } from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import { ICompany, ICompanyContactForApi, TSalutation } from '../../../definitions/entities.definition';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import autobind from 'autobind-decorator';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import SalutationDropDown from '../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import CustomMailField from '../../../components/CustomInput/CustomMailField';
import LegacyCompanyAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyAutocompleteDropDown';
import moment from 'moment';
import CustomDatePicker from '../../../components/CustomInput/CustomDatePicker';

interface IProps extends PropsFromRedux {
    onClose: () => void;
}

type TState = {
    name: string;
    firstName: string;
    salutation: TSalutation;
    company?: ICompany;
    title: string;
    position: string;
    comment: string;
    mailAddress: string;
    phoneNumber: string;
    birthDate?: string;
}

class CompanyContactForm extends React.PureComponent<IProps, TState> {
    constructor(props: IProps) {
        super(props);

        const initialCompanyContact = props.initialCompanyContact;

        this.state = {
            name: initialCompanyContact?.name || '',
            firstName: initialCompanyContact?.firstName || '',
            salutation: initialCompanyContact?.salutation || 'mr',
            title: initialCompanyContact?.title || '',
            position: initialCompanyContact?.position || '',
            comment: initialCompanyContact?.comment || '',
            mailAddress: initialCompanyContact?.mailAddress || '',
            phoneNumber: initialCompanyContact?.phoneNumber || '',
            birthDate: initialCompanyContact?.birthDate?.substring(0, 10) || undefined,
            company: initialCompanyContact?.company
        }
    }

    @autobind
    save() {
        const companyContact: ICompanyContactForApi = {
            name: this.state.name,
            firstName: this.state.firstName,
            salutation: this.state.salutation,
            title: this.state.title,
            position: this.state.position,
            comment: this.state.comment,
            mailAddress: this.state.mailAddress,
            phoneNumber: this.state.phoneNumber,
            companyId: this.props.addData && this.props.addData.companyId ? this.props.addData.companyId : this.state.company?.id,
            birthDate: this.state.birthDate,
        };

        if (this.props.addData.companyId) {
            this.props.addCompanyContactAction(this.props.token, companyContact);
            return;
        }

        companyContact.id = this.props.initialCompanyContact.id;
        this.props.updateCompanyContactAction(this.props.token, companyContact);
        return;
    }

    render() {
        return (
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <PaperRow>
                    { this.props.initialCompanyContact &&
                        <LegacyCompanyAutocompleteDropDown
                            label={translate('misc.company')}
                            value={this.state.company}
                            onChange={(event, value) => this.setState({
                                company: value
                            })}
                        />
                    }
                    <CustomTextField
                        label={translate('pages.companyContact.properties.position')}
                        value={this.state.position}
                        onChange={(value) => {
                            this.setState({
                                position: value
                            })
                        }}
                    />
                </PaperRow>
                <PaperRow>
                    <SalutationDropDown
                        onChange={(event: any) => {
                            this.setState({
                                salutation: event.target.value
                            })
                        }}
                        value={this.state.salutation}
                        required={true}
                        error={Boolean(this.props.validationErrors.salutation)}
                        helperText={this.props.validationErrors.salutation}
                    />
                    <CustomTextField
                        label={translate('pages.companyContact.properties.title')}
                        value={this.state.title}
                        onChange={(value) => {
                            this.setState({
                                title: value
                            })
                        }}
                    />
                    <CustomTextField
                        flex={2}
                        label={translate('pages.companyContact.properties.firstName')}
                        value={this.state.firstName}
                        required={true}
                        onChange={(value) => {
                            this.setState({
                                firstName: value
                            })
                        }}
                        error={Boolean(this.props.validationErrors.firstName)}
                        helperText={this.props.validationErrors.firstName}
                    />
                    <CustomTextField
                        flex={2}
                        label={translate('pages.companyContact.properties.name')}
                        value={this.state.name}
                        required={true}
                        onChange={(value) => {
                            this.setState({
                                name: value
                            })
                        }}
                        error={Boolean(this.props.validationErrors.name)}
                        helperText={this.props.validationErrors.name}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomMailField
                        label={translate('pages.companyContact.properties.mailAddress')}
                        value={this.state.mailAddress}
                        onChange={(value) => {
                            this.setState({
                                mailAddress: value
                            })
                        }}
                    />
                    <CustomTextField
                        label={translate('misc.phoneNumber')}
                        value={this.state.phoneNumber}
                        onChange={(value) => {
                            this.setState({
                                phoneNumber: value
                            })
                        }}
                    />
                    <CustomDatePicker
                        required
                        label={translate('pages.candidate.properties.birthDate')}
                        value={this.state.birthDate}
                        onChange={(value) => {
                            this.setState({
                                birthDate: value ? moment(value).format("Y-MM-DD") : undefined
                            })
                        }}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('misc.comment')}
                        multiline
                        rows={8}
                        value={this.state.comment}
                        onChange={(value) => {
                            this.setState({
                                comment: value
                            })
                        }}
                    />
                </PaperRow>
                <CustomDialogActions
                    onClose={this.props.onClose}
                    buttons={[{
                        action: this.save,
                        label: translate('misc.buttons.saveAndClose')
                    }]}
                />
            </div>
        );
    }
}

const mapState = (store: IStore) => {
    return ({
        token: getToken(store),
        validationErrors: getValidationErrorsFromStore(store),
        loggedInEmployee: getLoggedInEmployeeFromStore(store),
        initialCompanyContact: store.entities.companyContacts.byId[store.ui.editCompanyContactId],
        addData: store.ui.addCompanyContactData,
    });
}

const mapDispatch = {
    updateCompanyContactAction,
    addCompanyContactAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CompanyContactForm);
