import React from 'react';
import { ICompanyPartial, IEmployeePartial } from '../../../../definitions/entities.definition';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import EmployeeAutocomplete from '../../../CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import CompanyAutocomplete from '../../../CustomInput/CustomAutocomplete/components/CompanyAutocomplete';

interface IProps {
    value: null|ICompanyPartial;
    onChange: (value: null|ICompanyPartial) => void;
    onlyWithVacancies?: boolean;
    onlyWithoutParent?: boolean;
}

const CompanyFilter: React.FC<IProps> = (props) => {
    const value = props.value;
    const onlyWithVacancies = props.onlyWithVacancies;
    const onlyWithoutParent = props.onlyWithoutParent;

    const onChange = (value: null|ICompanyPartial) => {
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange(null);
    }

    const deletable = Boolean(value);
    const valueRendered = (deletable) ? value?.name : null;

    return <>
        <CustomFilterItem
            title={translate('pages.vacancy.properties.company')}
            icon={EIcons.Apartment}
            renderedValue={valueRendered}
            active={deletable}
            removeFilterAction={resetFilter}
            width={400}
            autoClose
        >
            <CompanyAutocomplete
                autoFocus
                open
                label={translate('pages.vacancy.properties.company')}
                value={value}
                onChange={(value: null|ICompanyPartial) => onChange(value)}
                onlyWithVacancies={onlyWithVacancies}
                onlyWithoutParent={onlyWithoutParent}
            />
        </CustomFilterItem>
    </>;
};

export default React.memo(CompanyFilter);
