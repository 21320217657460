import React, { useState } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { useSelector } from 'react-redux';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import { translate } from '../../translation/translate.utils';
import PaperRow from '../../components/CustomPaper/PaperRow';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import CustomBackdropLoadingInsideDiv from '../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { ClientApi } from '../../requests/ClientApi';
import { putInquireForRecruitingRouteConfig } from '../../requests/routes.customer';

interface IProps {
    recruitingId: number;
    open: boolean;
    onClose: () => void;
    callback: () => void;
}

const InquireForRecruitingView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const open = props.open;
    const recruitingId = props.recruitingId;

    const [loading, setLoading] = useState(false);
    const [inquireText, setInquireText] = useState('');

    const onClose = () => {
        props.onClose();
    };

    const save = () => {
        setLoading(true);
        ClientApi.request(putInquireForRecruitingRouteConfig, {
            token,
            recruitingId,
            inquireText
        }).then((result) => {
            setLoading(false);
            props.callback();
            onClose();
        }).catch(() => {
            setLoading(false);
        });
    }

    if (!open) {
        return null;
    }

    return (
        <CustomDialog maxWidth={"sm"} open={open} onClose={onClose}>
            <CustomBackdropLoadingInsideDiv open={loading} />
            <CustomDialogHeader string={"Rückfrage an Peagle senden"} onClose={onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <CustomTextField
                        multiline
                        required
                        label={'Rückfrage'}
                        value={inquireText}
                        onChange={(value) => setInquireText(value)}
                        rows={7}
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    action: () => save(),
                    label: translate('misc.buttons.save')
                }]}
            />
        </CustomDialog>
    );
};

export default InquireForRecruitingView;
