import {
    IGetCompanyListAction,
    IPostCompanyAction,
    ISetCloseViewAction,
    ISetCompanyPageAddDialogAction,
    ISetCompanyPageCompanyContactSortingOptionAction,
    ISetCompanyPageCompanyContactToDeleteAction,
    ISetCompanyPageCompanyForDeleteViewAction,
    ISetCompanyPageDocumentSortingOptionAction,
    ISetCompanyPageDocumentToDeleteAction,
    ISetCompanyPageDocumentToEditAction,
    ISetCompanyPageEditViewActivityFilterAction,
    ISetCompanyPageEditViewActivitySortingOptionAction,
    ISetCompanyPageEditViewInvoiceFilterAction,
    ISetCompanyPageEditViewInvoiceSortingOptionAction,
    ISetCompanyPageFastSearchAction,
    ISetCompanyPageFilterAction,
    ISetCompanyPageFilterMenuAction,
    ISetCompanyPageLocationSortingOptionAction,
    ISetCompanyPageLocationToDeleteAction,
    ISetCompanyPageRecruitingListFilterAction,
    ISetCompanyPageRecruitingListFilterMenuAction,
    ISetCompanyPageSortingOptionAction,
    ISetCompanyPageVacancyListFilterAction,
    ISetCompanyPageVacancySortingOptionAction,
    ISetCompanyPageVacancyToDeleteAction,
    ISetUiCurrentRouteAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    defaultCompanyPageFilter,
    defaultCompanyPageFilterMenu,
    ICompanyPageState,
    preloadedStateCompanyPage,
} from '../../definitions/companyPage.definitions';
import {
    COMPANY_PAGE_RESET_FILTER,
    COMPANY_PAGE_SET_ADD_DIALOG,
    COMPANY_PAGE_SET_COMPANY_TO_DELETE,
    COMPANY_PAGE_SET_CONTACT_RECRUITING_LIST_FILTER,
    COMPANY_PAGE_SET_CONTACT_VACANCY_LIST_FILTER,
    COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER,
    COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACT_TO_DELETE,
    COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACTS_SORTING_OPTION,
    COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_FILTER,
    COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_SORTING_OPTION,
    COMPANY_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT,
    COMPANY_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION,
    COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_FILTER,
    COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_SORTING_OPTION,
    COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_SORTING_OPTION,
    COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_TO_DELETE,
    COMPANY_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE,
    COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_SORTING_OPTION,
    COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_TO_DELETE,
    COMPANY_PAGE_SET_FAST_SEARCH,
    COMPANY_PAGE_SET_FILTER,
    COMPANY_PAGE_SET_FILTER_MENU,
    COMPANY_PAGE_SET_RECRUITING_LIST_FILTER,
    COMPANY_PAGE_SET_RECRUITING_LIST_FILTER_MENU,
    COMPANY_PAGE_SET_RELOAD_COMPANY,
    COMPANY_PAGE_SET_SORTING_OPTION,
    COMPANY_PAGE_SET_VACANCY_LIST_FILTER,
} from './companyPage.actions';
import {
    ENTITIES_DELETE_COMPANY_FULFILLED,
    ENTITIES_GET_COMPANY_FULFILLED,
    ENTITIES_GET_COMPANY_LIST_FULFILLED,
    ENTITIES_GET_COMPANY_PENDING,
    ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_FULFILLED,
    ENTITIES_POST_COMPANY_FULFILLED,
    ENTITIES_POST_RECRUITING_FULFILLED,
    ENTITIES_PUT_RECRUITING_FULFILLED,
} from '../entities/entities.actions';
import { UI_SET_CLOSE_VIEW, UI_SET_CURRENT_ROUTE } from '../ui/ui.actions';
import { ERoutes } from '../../definitions/app.definitions';
import { EEntityView } from '../../definitions/ui.definitions';

const companyPageReducer = (state = preloadedStateCompanyPage, action: TReducerAction): ICompanyPageState => {
    switch (action.type) {
        case UI_SET_CURRENT_ROUTE: {
            const actionCast = action as ISetUiCurrentRouteAction;

            if (actionCast.payload !== ERoutes.Companies) {
                return {
                    ...state,
                    filter: defaultCompanyPageFilter,
                    filterMenu: defaultCompanyPageFilterMenu,
                    initialCompaniesLoaded: state.initialCompaniesLoaded && state.filter.fastSearch === defaultCompanyPageFilter.fastSearch
                }
            }

            return {
                ...state
            }
        }
        case UI_SET_CLOSE_VIEW: {
            const actionCast = action as ISetCloseViewAction;

            if (actionCast.payload.view === EEntityView.company) {
                return {
                    ...state,
                    activityFilter: preloadedStateCompanyPage.activityFilter
                }
            }

            return {
                ...state
            }
        }
        case COMPANY_PAGE_SET_FILTER_MENU: {
            const actionCast = action as ISetCompanyPageFilterMenuAction;

            return {
                ...state,
                filterMenu: { ...state.filterMenu, ...actionCast.payload },
            };
        }
        case COMPANY_PAGE_RESET_FILTER: {
            return {
                ...state,
                filter: preloadedStateCompanyPage.filter,
                filterMenu: preloadedStateCompanyPage.filterMenu,
            };
        }
        case COMPANY_PAGE_SET_FILTER: {
            const actionCast = action as ISetCompanyPageFilterAction;

            return {
                ...state,
                filter: { ...state.filter, ...actionCast.payload },
            };
        }
        case ENTITIES_GET_COMPANY_LIST_FULFILLED: {
            const actionCast = action as IGetCompanyListAction;

            return {
                ...state,
                initialCompaniesLoaded: actionCast.meta,
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_FULFILLED: {
            return {
                ...state,
                reloadRecruitingsOfCompany: false
            }
        }
        case ENTITIES_PUT_RECRUITING_FULFILLED:
        case ENTITIES_POST_RECRUITING_FULFILLED: {
            return {
                ...state,
                reloadRecruitingsOfCompany: true
            }
        }
        case COMPANY_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetCompanyPageSortingOptionAction;

            return {
                ...state,
                sorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case COMPANY_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetCompanyPageFastSearchAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    fastSearch: actionCast.payload,
                },
            };
        }
        case ENTITIES_POST_COMPANY_FULFILLED: {
            const actionCast = action as IPostCompanyAction;

            return {
                ...state,
                addDialogOpen: false,
                companyToEdit: actionCast.payload.isCustomer ? actionCast.payload.id : 0,
            };
        }
        case COMPANY_PAGE_SET_ADD_DIALOG: {
            const actionCast = action as ISetCompanyPageAddDialogAction;

            return {
                ...state,
                addDialogOpen: actionCast.payload,
            };
        }
        case COMPANY_PAGE_SET_COMPANY_TO_DELETE: {
            const actionCast = action as ISetCompanyPageCompanyForDeleteViewAction;
            return {
                ...state,
                companyToDelete: actionCast.payload,
            };
        }
        case ENTITIES_DELETE_COMPANY_FULFILLED: {
            return {
                ...state,
                companyToDelete: 0,
            };
        }

        /**
         * RECRUITINGS
         */
        case COMPANY_PAGE_SET_RECRUITING_LIST_FILTER: {
            const actionCast = action as ISetCompanyPageRecruitingListFilterAction;

            return {
                ...state,
                filterRecruitingList: {
                    ...state.filterRecruitingList,
                    ...actionCast.payload
                }
            }
        }
        case COMPANY_PAGE_SET_RECRUITING_LIST_FILTER_MENU: {
            const actionCast = action as ISetCompanyPageRecruitingListFilterMenuAction;

            return {
                ...state,
                filterMenuRecruitingList: {
                    ...state.filterMenuRecruitingList,
                    ...actionCast.payload
                }
            }
        }
        case COMPANY_PAGE_SET_CONTACT_RECRUITING_LIST_FILTER: {
            const actionCast = action as ISetCompanyPageRecruitingListFilterAction;

            return {
                ...state,
                filterContactRecruitingList: {
                    ...state.filterContactRecruitingList,
                    ...actionCast.payload
                }
            }
        }

        /**
         * DATENSATZ ZUM BEARBEITEN LADEN JA/NEIN
         */
        case ENTITIES_GET_COMPANY_PENDING: {
            return {
                ...state,
                editViewLoading: true,
            };
        }
        case COMPANY_PAGE_SET_RELOAD_COMPANY: {
            return {
                ...state,
                reloadCompany: true
            }
        }
        case ENTITIES_GET_COMPANY_FULFILLED: {
            return {
                ...state,
                editViewLoading: false,
                reloadCompany: false
            };
        }

        /**
         * INVOICE
         */
        case COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_SORTING_OPTION: {
            const actionCast = action as ISetCompanyPageEditViewInvoiceSortingOptionAction;
            return {
                ...state,
                invoiceSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_FILTER: {
            const actionCast = action as ISetCompanyPageEditViewInvoiceFilterAction;
            return {
                ...state,
                invoiceFilter: {
                    ...state.activityFilter,
                    ...actionCast.payload
                }
            };
        }

        /**
         * ACTIVITY
         */
        case COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION: {
            const actionCast = action as ISetCompanyPageEditViewActivitySortingOptionAction;
            return {
                ...state,
                activitySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_SORTING_OPTION: {
            const actionCast = action as ISetCompanyPageEditViewActivitySortingOptionAction;
            return {
                ...state,
                contactActivitySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER: {
            const actionCast = action as ISetCompanyPageEditViewActivityFilterAction;
            return {
                ...state,
                activityFilter: {
                    ...state.activityFilter,
                    ...actionCast.payload
                }
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_FILTER: {
            const actionCast = action as ISetCompanyPageEditViewActivityFilterAction;
            return {
                ...state,
                contactActivityFilter: {
                    ...state.activityFilter,
                    ...actionCast.payload
                }
            };
        }

        /**
         * COMPANY CONTACT
         */
        case COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACTS_SORTING_OPTION: {
            const actionCast = action as ISetCompanyPageCompanyContactSortingOptionAction;
            return {
                ...state,
                companyContactSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACT_TO_DELETE: {
            const actionCast = action as ISetCompanyPageCompanyContactToDeleteAction;

            return {
                ...state,
                companyContactToDelete: actionCast.payload,
            };
        }

        /**
         * COMPANY LOCATION
         */
        case COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_SORTING_OPTION: {
            const actionCast = action as ISetCompanyPageLocationSortingOptionAction;
            return {
                ...state,
                companyLocationSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_TO_DELETE: {
            const actionCast = action as ISetCompanyPageLocationToDeleteAction;

            return {
                ...state,
                locationToDelete: actionCast.payload,
            };
        }

        /**
         * VAKANZEN
         */
        case COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_SORTING_OPTION: {
            const actionCast = action as ISetCompanyPageVacancySortingOptionAction;
            return {
                ...state,
                companyVacancySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_TO_DELETE: {
            const actionCast = action as ISetCompanyPageVacancyToDeleteAction;

            return {
                ...state,
                companyVacancyToDelete: actionCast.payload,
            };
        }

        case COMPANY_PAGE_SET_VACANCY_LIST_FILTER: {
            const actionCast = action as ISetCompanyPageVacancyListFilterAction;

            return {
                ...state,
                companyVacancyFilter: {
                    ...state.companyVacancyFilter,
                    ...actionCast.payload
                }
            }
        }

        case COMPANY_PAGE_SET_CONTACT_VACANCY_LIST_FILTER: {
            const actionCast = action as ISetCompanyPageVacancyListFilterAction;

            return {
                ...state,
                companyContactVacancyFilter: {
                    ...state.companyContactVacancyFilter,
                    ...actionCast.payload
                }
            }
        }

        /**
         * DOCUMENTS
         */
        case COMPANY_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION: {
            const actionCast = action as ISetCompanyPageDocumentSortingOptionAction;
            return {
                ...state,
                documentSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT: {
            const actionCast = action as ISetCompanyPageDocumentToEditAction;
            return {
                ...state,
                currentDocumentId: actionCast.payload,
            };
        }
        case COMPANY_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE: {
            const actionCast = action as ISetCompanyPageDocumentToDeleteAction;
            return {
                ...state,
                documentToDelete: actionCast.payload,
            };
        }

        default:
            break;
    }

    return state;
};

export default companyPageReducer;
