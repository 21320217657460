import React from 'react';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { EApplicationStatus, IApplication } from '../../../definitions/entities.definition';
import { groupApplicationsByStatus } from '../../../selectors/application.selectors';
import RenderApplicationListGroup from './RenderApplicationListGroup';

interface IProps {
    total: number;
    applications: IApplication[];
    loading: boolean;
    parentView: 'candidate'|'vacancy'|'company';
}

const ApplicationList: React.FC<IProps> = (props) => {
    const total = props.total;
    const applicationGroups = groupApplicationsByStatus(props.applications);
    const loading = props.loading;
    const parentView = props.parentView;

    return <>
        <div className={"overflow flex1 marginTop10 flexContainerColumn gap5"}>
            <CustomBackdropLoadingInsideDiv open={loading} />
            {total === 0 &&
                <>Keine Daten vorhanden</>
            }
            {[EApplicationStatus.open, EApplicationStatus.recruiting, EApplicationStatus.inspection, EApplicationStatus.declined ].map((status) => {
                const applications = applicationGroups[status];
                if (applications === undefined || applications.length === 0) {
                    return null;
                }
                return <>
                    <RenderApplicationListGroup
                        key={status}
                        status={status}
                        applications={applications}
                        parentView={parentView}
                    />
                </>
            })}
        </div>
    </>;
}

export default React.memo(ApplicationList);
