import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { EEntities, EVacancyInvoiceCreationAfter, IBillingSetting } from '../../../definitions/entities.definition';
import { getToken } from '../../../selectors/app.selectors';
import { EBillingCalculationBy, EBillingType } from '../../../definitions/billingConfiguration.definitions';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import BillingTypeDropDown from '../../../components/CustomInput/CustomDropDownInput/BillingTypeDropDown';
import BillingConfigurationTypeCustomizedForm from './BillingConfigurationTypeCustomizedForm';
import BillingConfigurationTypeTemplateForm from './BillingConfigurationTypeTemplateForm';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import LegacyYesNoDropDown from '../../../components/CustomInput/CustomDropDownInput/LegacyYesNoDropDown';
import { ClientApi } from '../../../requests/ClientApi';
import { putBillingConfigurationRouteConfig } from '../../../requests/routes';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';
import { setCompanyPageReloadCompanyAction } from '../../../redux/companyPage/companyPage.actions';
import { setVacancyPageReloadVacancyAction } from '../../../redux/vacancyPage/vacancyPage.actions';
import { registerSuccess } from '../../../redux/error/error.actions';
import { setRecruitingPageReloadRecruitingAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import CompanyInvoiceCreationVariantChangedView from '../../RecruitingPage/CompanyInvoiceCreationVariantChangedView';

interface IProps {
    open: boolean;
    onClose: () => void;
    billingSetting: IBillingSetting;
    entityId: number;
    entity: EEntities.Vacancy|EEntities.Company|EEntities.Recruiting
}

const BillingConfigurationEditView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    const [saving, setSaving] = useState(false);
    const [billingSetting, setBillingSetting] = useState(props.billingSetting);
    const [openBulkChangeView, setOpenBulkChangeView] = useState(false);
    const updateBillingSetting = (setting: Partial<IBillingSetting>) => {
        setBillingSetting({
            ...billingSetting,
            ...setting
        })
    }

    const onSave = () => {
        setSaving(true);
        ClientApi.request(putBillingConfigurationRouteConfig, {
            token,
            entity: props.entity === EEntities.Vacancy ? 'vacancy' : props.entity === EEntities.Company ? 'company' : 'recruiting',
            entityId: props.entityId,
            billingVariantTemplateId: billingSetting.billingVariantTemplate?.id,
            ...billingSetting
        }).then((result: IBillingSetting) => {
            dispatch(registerSuccess());
            if (props.entity === EEntities.Company) {
                dispatch(setCompanyPageReloadCompanyAction());
                setOpenBulkChangeView(true);
            }
            if (props.entity === EEntities.Vacancy) {
                dispatch(setVacancyPageReloadVacancyAction());
            }
            if (props.entity === EEntities.Recruiting) {
                dispatch(setRecruitingPageReloadRecruitingAction());
            }
            setSaving(false);
            props.onClose();
        }).catch(() => {
            setSaving(false);
        });
    }

    useEffect(() => {
        if (!open) {
            return;
        }

        setBillingSetting({
            billingType: props.billingSetting.billingType || EBillingType.BILLING_TYPE_TEMPLATE,
            billingVariantTemplate: props.billingSetting.billingVariantTemplate,
            invoiceCreationAfter: props.billingSetting?.invoiceCreationAfter || EVacancyInvoiceCreationAfter.signing,
            automaticGeneration: props.billingSetting.billingType ? props.billingSetting.automaticGeneration : true,
            customBillingCalculationBy: props.billingSetting?.customBillingCalculationBy || EBillingCalculationBy.CALCULATION_BY_YEAR_SALARY,
            customBillingCalculationFactorInvoice1: props.billingSetting?.customBillingCalculationFactorInvoice1 || 0,
            customBillingCalculationFactorInvoice2: props.billingSetting?.customBillingCalculationFactorInvoice2 || 0,
            customBillingSecondInvoice: props.billingSetting?.customBillingSecondInvoice || false
        });
    }, [open]);

    const onClose = () => {
        updateBillingSetting(props.billingSetting);
        props.onClose();
    };

    return (
        <>
            <CompanyInvoiceCreationVariantChangedView open={openBulkChangeView} companyId={props.entityId} onClose={() => setOpenBulkChangeView(false)}/>
            <CustomDialog maxWidth={"sm"} onClose={onClose} open={props.open}>
                <CustomBackdropLoading open={saving} />
                <CustomDialogHeader string={'Abrechnungs-Einstellung'} onClose={onClose} />
                <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                    <PaperRow>
                        <BillingTypeDropDown
                            value={billingSetting.billingType}
                            onChange={(event) => {
                                const newBillingType = event.target.value;

                                const billingTypeSwitchedFromTemplateToCustom = newBillingType === EBillingType.BILLING_TYPE_CUSTOMIZE && billingSetting.billingType === EBillingType.BILLING_TYPE_TEMPLATE;
                                const billingTypeCustomNotConfigures = billingSetting.customBillingCalculationFactorInvoice1 === null || billingSetting.customBillingCalculationFactorInvoice1 === 0;

                                if (billingTypeSwitchedFromTemplateToCustom && billingTypeCustomNotConfigures && billingSetting.billingVariantTemplate) {
                                    updateBillingSetting({
                                        billingType: newBillingType,
                                        customBillingCalculationFactorInvoice1: billingSetting.billingVariantTemplate.calculationFactorInvoice1,
                                        customBillingCalculationFactorInvoice2: billingSetting.billingVariantTemplate.calculationFactorInvoice2,
                                        customBillingCalculationBy: billingSetting.billingVariantTemplate.calculationBy,
                                        customBillingSecondInvoice: billingSetting.billingVariantTemplate.secondInvoice
                                    });
                                    return;
                                }
                                updateBillingSetting({billingType: newBillingType});
                            }}
                        />
                        <LegacyYesNoDropDown
                            value={billingSetting.automaticGeneration}
                            onChange={(event) => updateBillingSetting({automaticGeneration: event.target.value === 1})}
                            hideChoose
                            required
                            label={'Rechnungsstellung'}
                            yesText={'Automatisch'}
                            noText={billingSetting.automaticGeneration ? 'Manuell' : 'Manuell (keine Erinnerung)'}
                        />
                    </PaperRow>
                    {billingSetting.billingType === EBillingType.BILLING_TYPE_CUSTOMIZE &&
                    <BillingConfigurationTypeCustomizedForm
                        billingSetting={billingSetting}
                        updateBillingSetting={updateBillingSetting}
                    />
                    }
                    {billingSetting.billingType === EBillingType.BILLING_TYPE_TEMPLATE &&
                    <BillingConfigurationTypeTemplateForm
                        billingSetting={billingSetting}
                        updateBillingSetting={updateBillingSetting}
                    />
                    }
                    {billingSetting.billingType === EBillingType.BILLING_TYPE_PROJECT &&
                    <PaperRow>
                        Details zur Prognose im Prozess pflegen
                    </PaperRow>
                    }
                </div>
                <CustomDialogActions
                    onClose={onClose}
                    buttons={[{
                        action: onSave,
                        label: translate('misc.buttons.saveAndClose')
                    }]}
                />
            </CustomDialog>
        </>
    );
}
export default BillingConfigurationEditView;

