import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { ICandidateMailAddress } from '../../../../../definitions/entities.definition';
import CustomEditableValue from '../../../../../components/CustomInput/CustomEditableValue';
import { EIcons, IconFactory } from '../../../../../components/Icons/IconFactory';
import { ClientApi } from '../../../../../requests/ClientApi';
import { postCandidateMailAddressRouteConfig } from '../../../../../requests/routes';
import { setCandidatePageAddMailToFormAction } from '../../../../../redux/candidatePage/candidatePage.actions';
import { registerError } from '../../../../../redux/error/error.actions';
import CandidateMailItemView from './components/CandidateMailItemView';
import CustomTextField from '../../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../../translation/translate.utils';
import { getSuggestionAction } from '../../../../../redux/entities/entities.actions';

interface IProps {
    candidateId?: number;
    records: ICandidateMailAddress[];
    suggestionId?: number;
}

const CandidateMailListView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const mailAddresses = props.records;
    const suggestionId = props.suggestionId;

    const [mailAddress, setMailAddress] = useState('');
    const [comment, setComment] = useState('');

    const onAdd = () => {
        if (!candidateId) {
            if (!mailAddress) {
                return Promise.resolve();
            }
            dispatch(setCandidatePageAddMailToFormAction({
                id: 0,
                mailAddress,
                comment,
                default: mailAddresses.length === 0
            }));
            setComment('');
            setMailAddress('');
            return Promise.resolve();
        }

        return ClientApi.request(postCandidateMailAddressRouteConfig, {
            token,
            candidateId,
            mailAddress,
            comment,
            default: mailAddresses.length === 0
        }).then((mail: ICandidateMailAddress) => {
            setComment('');
            setMailAddress('');

            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }

            dispatch(setCandidatePageAddMailToFormAction(mail));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht hinzugefügt werden'));
        });
    }

    return <>
        <div className={"flexContainerColumn"} style={{overflow: 'auto', maxHeight: 300}}>
            {mailAddresses.map((mailAddress, index) => {
                return <CandidateMailItemView
                    key={mailAddress.id}
                    index={index}
                    candidateId={candidateId}
                    suggestionId={suggestionId}
                    mailAddresses={mailAddresses}
                    mailAddress={mailAddress}
                />
            })}
        </div>
        <CustomEditableValue
            theme={'light'}
            input={<div className={"flexContainerColumn flex1 gap5"}>
                <CustomTextField
                    required
                    autoFocus
                    size={'small'}
                    value={mailAddress}
                    onChange={(value) => setMailAddress(value)}
                    label={translate('misc.mailAddress')}
                />
                <CustomTextField
                    multiline
                    size={'small'}
                    rows={5}
                    label={translate('misc.comment')}
                    value={comment}
                    onChange={(value) => setComment(value)}
                />
            </div>}
            emptyValue={
                <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.Add)}
                    E-Mail Adresse hinzufügen
                </div>
            }
            onSave={onAdd}
        />
    </>
}

export default CandidateMailListView;
