import React from 'react';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { translate } from '../../../../translation/translate.utils';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomDateTimePicker from '../../../../components/CustomInput/CustomDateTimePicker';

interface IProps {
    onClose: () => void;
}

const EntityEditDataView: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const validationErrors = useSelector(getValidationErrorsFromStore);
    const activity = useSelector((store: IStore) => store.entities.activities.byId[store.ui.editActivityId]);

    if (!activity) {
        return null;
    }

    console.log(activity.data);
    console.log(Object.entries(activity.data));
    return <>
        <div>
            <PaperRow>
                <CustomDateTimePicker
                    label={translate('pages.activity.properties.dateTime')}
                    value={new Date(activity.dateTime)}
                    disabled
                />
                <LegacyEmployeeAutocompleteDropDown
                    value={activity.employee}
                    disabled
                    error={Boolean(validationErrors.employeeId)}
                    helperText={validationErrors.employeeId}
                />
            </PaperRow>
            <>
                {Object.entries(activity.data).map((value) => {
                    return <PaperRow key={value[0]}>
                        {(value[1].valueAfter)
                            ? translate('pages.candidate.properties.' + value[0]) + ' - ' + value[1].valueBefore + ' -> ' + value[1].valueAfter
                            : translate('pages.candidate.properties.' + value[0])
                        }
                        {(value[1].entriesAdded && Object.entries(value[1].entriesAdded).length > 0) && <>&nbsp;+ {Object.entries(value[1].entriesAdded).map((valueSub) => valueSub[1].value).join(', ')}</>}
                        {(value[1].entriesDeleted && Object.entries(value[1].entriesDeleted).length > 0) && <>&nbsp;- {Object.entries(value[1].entriesDeleted).map((valueSub) => valueSub[1].value).join(', ')}</>}
                    </PaperRow>

                })}
            </>
            <CustomDialogActions
                onClose={props.onClose}
            />
        </div>
    </>;
}



export default EntityEditDataView;
