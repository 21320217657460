import React from 'react';
import { ICompanyPartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getCompanyListRouteConfig, getCompanyRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomLivesearch from '../CustomLivesearch';

interface IProps {
    value: ICompanyPartial|number|null;
    onChange: (value: ICompanyPartial|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    onAdd?: () => void;
    onlyWithInvoice?: boolean;
    onlyWithVacancies?: boolean;
    onlyWithoutParent?: boolean;
    open?: boolean;
}

const CompanyAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;
    const onAdd = props.onAdd;
    const onlyWithInvoice = props.onlyWithInvoice;
    const onlyWithVacancies = props.onlyWithVacancies;
    const onlyWithoutParent = props.onlyWithoutParent;

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as ICompanyPartial);
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        return (record as ICompanyPartial).name;
    }

    return <CustomLivesearch
        id={"company-autocomplete"}
        label={props.label || translate('misc.company')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        open={props.open}
        onChange={onChange}
        routeConfig={getCompanyListRouteConfig}
        entityRouteConfig={getCompanyRouteConfig}
        entityRouteParam={"companyId"}
        getOptionLabel={getOptionLabel}
        onAdd={onAdd}
        routeParams={{
            orderBy: 'name',
            orderDirection: 'ASC',
            start: 0,
            limit: 0,
            onlyWithInvoice,
            onlyWithVacancies,
            onlyWithoutParent
        }}
    />;
}

export default React.memo(CompanyAutocomplete);
