import React from 'react';
import {
    getSuggestionsForVacancyAction,
    getVacancyAction,
    updateSuggestionAction,
    updateSuggestionInStoreAction,
} from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { DropResult } from 'react-beautiful-dnd';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { useVacancySuggestionListHook } from './VacancySuggestionList.hooks';
import { getVacancyPageRequestObjectForSuggestionsInEditView } from '../../../../../selectors/recruitingSuggestion.selectors';
import SuggestionList from '../../../../Shared/SuggestionList/SuggestionList';
import { EEntityView } from '../../../../../definitions/ui.definitions';

interface IProps {
    vacancyId: number;
    archived: boolean;
}

const VacancySuggestionList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.vacancyId;
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId])
    const suggestionRequestObject = useSelector(getVacancyPageRequestObjectForSuggestionsInEditView);
    const { loading, suggestions } = useVacancySuggestionListHook(vacancyId);
    const filter = useSelector((store: IStore) => store.vacancyPage.suggestionOfVacancyFilter);

    const onDragEnd = (result: DropResult) => {
        const suggestionId = parseInt(result.draggableId);
        let suggestionIndex = null;

        suggestions.forEach((suggestion, index) => {
            if (suggestion.id === suggestionId) {
                suggestionIndex = index;
            }
        });

        if (suggestionIndex === null) {
            return;
        }

        const suggestion = suggestions[suggestionIndex];
        if (!result.destination || !suggestion) {
            return;
        }
        const newGroupId = parseInt(result.destination.droppableId);
        const suggestionsOfTargetGroup = suggestions.filter((suggestion) => suggestion.group.id === newGroupId).sort((a, b) => a.ranking < b.ranking ? -1 : 1);
        const newRanking = result.destination.index === 0 ? 1 : suggestionsOfTargetGroup[result.destination.index-1].ranking+1;

        if (newRanking === suggestion.ranking && newGroupId === suggestion.group.id) {
            return;
        }

        const group = vacancy.groups.filter((group) => group.id === newGroupId)[0];

        dispatch(updateSuggestionInStoreAction({
            ...suggestion,
            ranking: newRanking,
            group
        }))

        dispatch(updateSuggestionAction(
            token, {
                id: suggestion.id,
                ranking: newRanking,
                groupId: newGroupId
            }
        )).catch(() => {
            dispatch(getSuggestionsForVacancyAction(token, vacancyId, suggestionRequestObject));
        });
    };

    const groupBy = filter.status === undefined ? 'status' : filter.groupByProgressStatus ? 'progressStatus' : 'group';

    return <SuggestionList
        vacancyId={vacancyId}
        suggestions={suggestions}
        parentView={EEntityView.vacancy}
        total={suggestions.length}
        loading={loading}
        onDragEnd={onDragEnd}
        groupBy={groupBy}
        onReload={() => {
            dispatch(getVacancyAction(token, vacancyId, false)).then(() =>
                dispatch(getSuggestionsForVacancyAction(token, vacancyId, suggestionRequestObject))
            );
        }}
    />
}

export default VacancySuggestionList;
