import React, { PropsWithChildren } from 'react';
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import styles from './CustomClickAwayPopper.module.css';

interface IProps extends PropsWithChildren<any>{
    anchorEl: null | EventTarget;
    removeAnchor: () => void;
    width?: string|number;
}

const CustomClickAwayPopper: React.FC<IProps> = (props) => {
    const anchorEl = props.anchorEl;
    const handleClose = (event: React.MouseEvent<Document>) => {
        //@ts-ignore
        if (event.target.localName === 'body') {
            event.preventDefault();
            return;
        }
        props.removeAnchor();
    };

    if (!anchorEl) {
        return null;
    }

    return <>
        <Popper open={true} style={{zIndex: 10}} anchorEl={anchorEl} placement={'right'} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault()
        }}>
            <ClickAwayListener onClickAway={handleClose}>
                <Paper square variant={"elevation"} className={styles.popper}>
                    <div style={{minWidth: props.width || 500, padding: 10}}>
                        {props.children}
                    </div>
                </Paper>
            </ClickAwayListener>
        </Popper>
    </>;
};

export default CustomClickAwayPopper;
