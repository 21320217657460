import React, { useEffect, useState } from 'react';
import { getCompanyAction } from '../../../redux/entities/entities.actions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { themeBlue } from '../../../theme/theme';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import { ITab } from '../../../definitions/components.definitions';
import CompanyEditForm from './components/CompanyEditForm';
import CompanyRecruitingPanel from './components/CompanyRecruitingPanel';
import CompanyActivityChatPanel from './components/CompanyActivityChatPanel';
import CompanyApplicationPanel from './components/CompanyApplicationPanel';
import CompanyContactPanel from './components/CompanyContactPanel';
import CompanyLocationPanel from './components/CompanyLocationPanel';
import CompanyDocumentPanel from './components/CompanyDocumentPanel';
import CompanyWebVacancyList from './components/components/CompanyWebVacancyList';
import CompanyVacancyPanel from './components/CompanyVacancyPanel';

interface IProps {
    id: number;
    initialTab?: number;
}

const CompanyView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const companyId = props.id;
    const company = useSelector((store: IStore) => store.entities.companies.byId[companyId]);
    const [activeTab, setActiveTab] = useState(props.initialTab || 0);

    const companyLoaded = companyId > 0 && company !== undefined && companyId === company.id;
    useEffect(() => {
        if (companyId > 0 && (!company || company.id !== companyId)) {
            dispatch(getCompanyAction(token, companyId));
        }
    }, [companyId]);

    if (companyId === 0) {
        return null;
    }

    if (!companyLoaded || !company) {
        return <>
            <div className={"flexContainerRow flex"}>
                <CustomBackdropLoadingInsideDiv open={true} />
            </div>
        </>;
    }

    const tabs: ITab[] = [/*{
        label: 'Übersicht',
        component: <>
            <CompanyOverviewPanel companyId={companyId}  />
        </>
    }, */{
        label: 'Stammdaten',
        component: <>
            <CompanyEditForm companyId={companyId}  />
        </>
    }, {
        label: 'Vakanzen',
        component: <>
            <CompanyVacancyPanel companyId={companyId} />
        </>
    }, {
        label: 'Prozesse',
        component: <>
            <CompanyRecruitingPanel companyId={companyId}/>
        </>
    }, {
        label: 'Ansprechpartner',
        component: <>
            <CompanyContactPanel companyId={companyId}/>
        </>
    }, {
        label: 'Standorte',
        component: <>
            <CompanyLocationPanel companyId={companyId}/>
        </>
    }, {
        label: 'Bewerbungen',
        component: <>
            <CompanyApplicationPanel companyId={companyId}/>
        </>
    }, {
        label: 'Historie',
        component: <>
            <CompanyActivityChatPanel companyId={companyId}/>
        </>
    }, {
        label: 'Dokumente',
        component: <>
            <CompanyDocumentPanel companyId={companyId}/>
        </>
    }];

    if (company.crawler || company.parentCompany?.crawler) {
        tabs.push({
            label: 'Stellen/Crawler',
            component: <>
                <CompanyWebVacancyList companyId={companyId} />
            </>
        })
    }

    return <>
        <div className={'flexContainerColumn flex'} style={{width: 900}}>
            <CustomTabContainer
                color={themeBlue.palette.primary.main}
                activeTab={activeTab}
                verticalWidth={170}
                roundBorder
                orientation={"vertical"}
                tabs={tabs}
            />
        </div>
    </>;
};

export default CompanyView;
