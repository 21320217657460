import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CandidateAddView from './components/CandidateAddView';
import CandidateImportCvView from './components/CandidateImportCvView';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';
import { translate } from '../../translation/translate.utils';
import CustomSideFilterPanel from '../../components/CustomFilter/CustomSideFilter/CustomSideFilterPanel';
import { EEntities, ICvParseResult } from '../../definitions/entities.definition';
import { ClientApi } from '../../requests/ClientApi';
import { postParseCvDataRouteConfig } from '../../requests/routes';
import { getToken } from '../../selectors/app.selectors';
import { setCandidatePageCvToImportAction } from '../../redux/candidatePage/candidatePage.actions';
import { registerError } from '../../redux/error/error.actions';
import CandidateList from './components/CandidateList';
import { IStore } from '../../definitions/redux/store.definitions';

const CandidatePage: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const [openCvUpload, setOpenCvUpload] = useState(false);
    const [uploadFile, setUploadFile] = useState<FileObject|null>(null);
    const addViewOpen = useSelector((store: IStore) => store.candidatePage.addDialogOpen);
    const importViewOpen = useSelector((store: IStore) => store.candidatePage.importFromCvParser !== undefined);

    const onSave = (uploadFile: FileObject) => {
        ClientApi.request(postParseCvDataRouteConfig, {
            token: token,
            fileContent: uploadFile.data
        }).then((result: ICvParseResult) => {
            dispatch(setCandidatePageCvToImportAction(
                result,
                uploadFile
            ));
            setOpenCvUpload(false);
            setUploadFile(null);
        }).catch(() => {
            dispatch(registerError('Das Dokument konnte nicht verarbeitet werden.'));
            dispatch(setCandidatePageCvToImportAction(
                undefined,
                uploadFile
            ));
            setOpenCvUpload(false);
            setUploadFile(null);
        });
    };


    const fileObjects = uploadFile ? [uploadFile] : [];

    return <>
        <div
            style={{height: "100%", display: "flex"}}
            onDragEnter={() => setOpenCvUpload(true)}
        >
            {addViewOpen && <CandidateAddView/>}
            {importViewOpen && <CandidateImportCvView/>}
            <DropzoneDialogBase
                fullWidth={true}
                maxWidth={"lg"}
                dialogTitle={"Kandidaten importieren"}
                acceptedFiles={["application/pdf"]}
                fileObjects={fileObjects}
                cancelButtonText={translate('misc.buttons.close')}
                submitButtonText={translate('misc.buttons.save')}
                previewChipProps={{ color: "primary" }}
                maxFileSize={25000000}
                filesLimit={1}
                open={openCvUpload}
                onAdd={(newFileObjs) => {
                    onSave(newFileObjs[0]);
                }}
                onClose={() => setOpenCvUpload(false)}
                useChipsForPreview={true}
                showPreviews={false}
                showFileNamesInPreview={true}
            />

            <CustomSideFilterPanel entity={EEntities.Candidate}>
                <CandidateList />
            </CustomSideFilterPanel>
        </div>
    </>;
};

export default CandidatePage;
